import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Row, Space, Tabs} from 'antd';
import {PlusOutlined} from "@ant-design/icons";

import {showErrorNotification, showSuccessNotification} from "../../../../../../utils/helper";
import StepTwoCarouselComponent from "../CarouselComponent";
import {WhatsappTemplateDetails} from "../../../../../../models";


export interface DynamicComponentsContainerProps {
  form?: any;
  onAddNewCardChanged?: any;
  onMainSave?: any;
  inputDirection?: any;
  editItem?: WhatsappTemplateDetails;
}

const DynamicComponentsContainer = (props: DynamicComponentsContainerProps) => {

  const {form, onAddNewCardChanged, editItem, onMainSave, inputDirection} = props;
  const [activeKey, setActiveKey] = useState<string>('0');
  const [numTabs, setNumTabs] = useState(1);
  const [cardData, setCardData] = useState([]);
  const newTabIndex = useRef(0);
  const [isCardSaved, setIsCardSaved] = useState(false);

  const handleTabChange = (key: any) => {
    setActiveKey(key);
  };
  const handleAddComponent = () => {
    const newTabIndexValue = newTabIndex.current + 1;
    if (!isCardSaved) {
      newTabIndex.current = newTabIndexValue;
      showErrorNotification('Please save the current card before adding a new one.');
      return;
    }
    if (numTabs < 10) {
      const newActiveKey = newTabIndexValue.toString();
      setNumTabs((prevNumTabs) => prevNumTabs + 1);
      setActiveKey(newActiveKey);
      setIsCardSaved(false);
      onMainSave(false);
      form.resetFields();
      newTabIndex.current = newTabIndexValue;
    } else {
      showErrorNotification("Cannot add more than 10 carousel cards.");
    }
  };

  const handleRemoveComponent = (targetKey: any) => {
    if (numTabs > 1) {
      setNumTabs((prevNumTabs) => prevNumTabs - 1);
      // setActiveKey((prevActiveKey) =>
      //   String(Math.min(numTabs - 1, parseInt(prevActiveKey, 10)))
      // );
      setCardData((prevCardData) => {
        const updatedCardData = [...prevCardData];
        updatedCardData.splice(parseInt(targetKey, 10), 1);
        return updatedCardData;
      });
      // Check if the removed card is the currently active card
      if (activeKey === targetKey) {
        setIsCardSaved(true); // Set to true since the active card is removed
      }

      newTabIndex.current -= newTabIndex.current;

      setActiveKey((prevActiveKey) =>
        String(Math.max(0, parseInt(prevActiveKey, 10) - 1))
      );
    } else {
      showErrorNotification("At least one carousel card is required.");
    }
  };

  const onEdit = (targetKey: any, action: any) => {
    if (action === 'add') {
      handleAddComponent();
    } else if (action === 'remove' && typeof targetKey === 'string') {
      handleRemoveComponent(targetKey);
    }
  };

  const handleSaveCard = () => {
    // Perform the save action here
    showSuccessNotification('Card saved successfully!');
    setIsCardSaved(true);
    onMainSave(true);
  };

  const handleCardDataChange = (newCardData: any) => {
    // @ts-ignore
    setCardData((prevCardData) => [...prevCardData, newCardData]);
  };

  useEffect(() => {
    // This will be called whenever cardData changes
    if (onAddNewCardChanged) {
      onAddNewCardChanged(cardData);
    }
  }, [cardData, onAddNewCardChanged]);


  const tabPanes = Array.from({length: numTabs}, (_, index) => (
    <Tabs.TabPane tab={`Card ${index + 1}`} key={index.toString()} >
      <Card title={`Card ${index + 1}`}>
        <StepTwoCarouselComponent form={form} onCardDataChange={handleCardDataChange} onSaveCard={handleSaveCard} editItem={editItem} inputDirection={inputDirection}/>
      </Card>
    </Tabs.TabPane>
  ));


  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24}>
        <Space>
          <Button type="text" className="bg-gray-lightest mb-4" onClick={handleAddComponent}>
            <PlusOutlined/> Add Carousel Cards
          </Button>
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24}>
        <Tabs
          activeKey={activeKey}
          onChange={handleTabChange}
          type="editable-card"
          onEdit={onEdit}
        >
          {tabPanes}
        </Tabs>
      </Col>
       {/* {JSON.stringify(cardData, null, 2)} */}
    </Row>
  );
};

DynamicComponentsContainer.defaultProps = {
  form: undefined,
  onAddNewCardChanged: undefined,
  onMainSave: undefined,
  editItem: undefined,
  inputDirection: undefined,
};


export default DynamicComponentsContainer;
