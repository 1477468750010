/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react';
import { Button, Form, Input, Alert } from "antd";
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { motion } from "framer-motion";
import { loginService } from 'services/auth/LoginService';
import { LoginResponse } from 'models/responses';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'redux/constants/Auth';
import {
  authenticated,
  setPermissionMaps,
  setUser,
  setUserACL,
  setUserWallet,
  setUserTestCredit,
  setLocalCurrency
} from 'redux/actions';
import { getAccessListService, getUserService } from 'services/auth/UserService';
import { getUserWalletService } from 'services/dashboard/PaymentService';
import { getPermisionMapsByACL } from 'configs/PermissionConfig';
import { User } from 'models';
import { listApplicationService } from 'services/auth/ApplicationService';
import {analytics} from "utils/google_tag_manager_script";
import {getWhatsappSubscriptionService} from "../../../services/dashboard/WhatsappService";

const LoginForm = (props: {
  showForgetPassword?: boolean;
  extra?: any;
  from?: any
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const {
  //   redirect,
  //   token
  // } = useSelector((state: RootState): AuthState => state.auth);

  const {
    extra,
    showForgetPassword,
    from
  } = props;

  const onErrorLogin = (error: any) => {
    form.setFieldsValue({ password: '' });
    setMessage(error.message);
    setShowMessage(true);
    setLoading(false);
    setTimeout(() => {
      setShowMessage(false);
    }, 4000);
  };
  const fetchUser = async () => {
    const response = await getUserService();
    if (response) {
      dispatch(setUser(response));
      return response;
    }
    return null;
  };

  const getWhatsppSubscription = async () => {
    let karix: boolean;
    try {
      const response = await getWhatsappSubscriptionService();
      karix = response.service_provider === "karix";
    } catch (error) {
      karix = false;
    }
    return karix;
  };

  const fetchUserAccessList = async () => {
    const response = await getAccessListService();
    if (response) {
      const karixSubscription  = await getWhatsppSubscription();
      if (karixSubscription){ response.KARIX_WHATSAPP=["ALL_PERMISSION"]; }
      const permissions = getPermisionMapsByACL(response);
      dispatch(setUserACL(response));
      dispatch(setPermissionMaps(permissions));
      return response;
    }
    return false;
  };
  const fetchUserWallet = async () => {
    let status = true;
    try {
      const response = await getUserWalletService();
      if (response.credit !== undefined) {
        dispatch(setUserWallet(response.credit));
        dispatch(setUserTestCredit(response.test_credit));
        dispatch(setLocalCurrency(response.local_currency));
      }
    } catch (error) {
      status = false;
    }
    return status;

  };

  const onSuccessFetchInfos = async (user: User, acl:any) => {
    setLoading(false);
    let apps;
    if((acl && acl.DEVELOPER.length>0) || (acl && acl.ALL_MODULES && acl.ALL_MODULES?.length>0)){
      try {
        apps = await listApplicationService();
      } catch (error) {
        apps= [];
      }
    }
    if ((acl && acl.DEVELOPER.length>0) && (!user.profile.mobile_verified || !(apps && apps.length > 0))) {
      navigate('/sms/getting-started');
    }
    else if (from) {
      navigate(`/${from}`);
    }
    else {
      navigate('/dashboard');
    }
  };
  const saveToken = (response: LoginResponse) => {
    localStorage.setItem(AUTH_TOKEN, response.access_token);
    localStorage.setItem(REFRESH_TOKEN, response.refresh_token);
  };

  const loadUserInfo = async () => {
    Promise.all([fetchUser(), fetchUserAccessList(), fetchUserWallet()]).then(responses => {
      const user = responses[0];
      const acl = responses[1];
      if (user) {
        onSuccessFetchInfos(user, acl);
      } else {
        onErrorLogin({ message: 'Failed to fetch user info' });
      }
    }).catch((error) => {
      onErrorLogin(error);
    });
  };

  const onSuccessLogin = async (response: LoginResponse) => {
    form.setFieldsValue({ password: '' });
    dispatch(authenticated(response.access_token));
    saveToken(response);
    await loadUserInfo();
  };
  const onLogin = async (values: { username: string, password: string }) => {
    setLoading(true);
    try {
      const loginFormData = new FormData();
      loginFormData.append('username',values.username);
      loginFormData.append('password',values.password);
      const response = await loginService(loginFormData);
      if (response.access_token) {
        await onSuccessLogin(response);
      } else {
        onErrorLogin({ message: 'Login failed' });
      }
    } catch (error: any) {
      onErrorLogin(error);
    }
  };
  const onForgetPasswordClick = () => {
    navigate('/forgot-password');
  };

  useEffect(() => {
    analytics(window, document, 'script', 'dataLayer', 'GTM-N4P6V8W');
  },[]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: showMessage ? 1 : 0,
          marginBottom: showMessage ? 20 : 0
        }}>
        <Alert type="error" showIcon message={message} />
      </motion.div>
      <Form
        layout="vertical"
        name="login-form"
        onFinish={onLogin}
        form={form}
      >
        <Form.Item
          name="username"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please enter your email',
            },
            {
              type: 'email',
              message: 'Please enter a valid email!'
            }
          ]}>
          <Input prefix={<MailOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="password"
          label={
            <div className={`${showForgetPassword ? 'd-flex justify-content-between w-100 align-items-center' : ''}`}>
              <span>Password</span>
              {
                showForgetPassword &&
                <span
                  onClick={(e) => { onForgetPasswordClick(); }}
                  className="cursor-pointer font-size-sm font-weight-normal text-muted"
                >
                  &nbsp; &nbsp; Forgot Password?
                </span>
              }
            </div>
          }
          rules={[
            {
              required: true,
              message: 'Please enter your password',
            }
          ]}
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Sign In
          </Button>
        </Form.Item>

        {extra}
      </Form>
    </>
  );
};

LoginForm.defaultProps = {
  showForgetPassword: false,
  extra: null,
  from: null
};
export default LoginForm;
