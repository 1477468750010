import React, {useEffect, useState} from 'react';
import {Card, Col, notification, Row} from "antd";
import ChatbotListingTable from "../../../components/whatsapp-meta/MetaChatbot/ChatbotListingTable";
import PageHeader from "../../../components/layout/PageHeader";
import ChatbotAddingForm from "../../../components/whatsapp-meta/MetaChatbot/ChatbotAddingForm";
import {Chatbot} from "../../../models/chatbot";
import {deleteChatbotService, listChatbotService} from "../../../services/dashboard/ChatbotService";
import {listWhatsappContactService} from "../../../services/dashboard/WhatsappService";
import {WhatsappContactList} from "../../../models";

const Chatbots = () => {
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<WhatsappContactList[]>([]);
  const [chatbotList, setChatbotList] = useState<Chatbot[]>([]);
  const [showChatbotForm, setShowChatbotForm] = useState(false);
  const [chatbotToEdit, setChatbotToEdit] = useState<Chatbot>();

  const fetchChatbotList = async () => {
    setLoading(true);
    try {
      const response = await listChatbotService();
      setChatbotList(response);
      setLoading(false);
    } catch (error) {
      setChatbotList([]);
      setLoading(false);
    }
  };

  const fetchPhoneNumber = async () => {
    try {
      const fetchedPhoneNumbers: any = await listWhatsappContactService();
      if (fetchedPhoneNumbers && fetchedPhoneNumbers.length > 0) {
        setContacts(fetchedPhoneNumbers);
      }
    } catch (error) {
      // console.error('Error fetching templates:', error);
    }
  };

  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };
  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };

  const onSuccessDeleteApplication = (chatbotId: string) => {
    showSuccessNotification('chatbot deleted successfully');
    setLoading(false);
    const newChatbots = chatbotList.filter(c => c.id !== chatbotId);
    setChatbotList(newChatbots);
  };

  const onErrorDeleteApplication = (showNotification?: boolean) => {
    if (showNotification) {
      showErrorNotification('Failed to delete chatbot');
    }
    setLoading(false);
  };

  const deleteChatbot = async (chatbotId: string) => {
    setLoading(true);
    try {
      const response = await deleteChatbotService(chatbotId);
      if (response) {
        onSuccessDeleteApplication(chatbotId);
      }
    } catch (error) {
      onErrorDeleteApplication(true);
    }
  };

  const onClickDeleteChatbot = async (chatbotId: string) => {
    await deleteChatbot(chatbotId);
  };

  const openChatbotAddingForm = (_isEdit?: boolean, chatbot?: Chatbot) => {
    setChatbotToEdit(chatbot);
    setShowChatbotForm(true);
  };
  const onCloseForm = () => {
    setShowChatbotForm(false);
    setChatbotToEdit(undefined);
  };
  const onSaveChatbot = async (response: Chatbot, isNew: boolean) => {
    setLoading(true);
    onCloseForm();
    await fetchChatbotList();
  };

  useEffect(() => {
    fetchChatbotList();
    fetchPhoneNumber();
  }, []);
  return (
    <div>
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <PageHeader display title="page.whatsapp-meta.Chatbot" showBreadCrumb={false}/>
          <Card className='mt-4'>
            <ChatbotListingTable loading={loading}
                                 chatbots={chatbotList}
                                 openChatbotForm={openChatbotAddingForm}
                                 onClickDeleteChatbot={onClickDeleteChatbot}
            />
            <ChatbotAddingForm showChatbotForm={showChatbotForm}
                               editItem={chatbotToEdit}
                               onClose={onCloseForm}
                               onSave={onSaveChatbot}
            contacts={contacts}/>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Chatbots;
