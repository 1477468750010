import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message as antdMessage,
  Modal,
  Select,
  Tooltip,
  Upload
} from "antd";
import {CloudUploadOutlined, DeleteOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {uploadResumableFileService} from "../../../../../../services/dashboard/WhatsappService";
import {WhatsappTemplateDetails} from "../../../../../../models";
import {showErrorNotification} from "../../../../../../utils/helper";


export interface StepTwoHeaderComponentProps {
  form?: any;
  templateType?: any;
  onHeaderTextChanged?: any;
  onHeaderTypeChanged?: any;
  onExampleHeaderTextChanged?: any;
  onUploadFileChanged?: any;
  inputDirection?: any;
  editItem?: WhatsappTemplateDetails;
  isTemplateTypeChange?: boolean;
  isCategoryTypeChange?: boolean;
  currentStep: number;
}

const StepTwoHeaderComponent = (props: StepTwoHeaderComponentProps) => {

  const {
    currentStep,
    form,
    templateType,
    onHeaderTextChanged,
    onHeaderTypeChanged,
    onExampleHeaderTextChanged,
    onUploadFileChanged,
    editItem,
    isTemplateTypeChange, isCategoryTypeChange, inputDirection
  } = props;
  const [headerType, setHeaderType] = useState<string | undefined>(undefined);
  const [variableCount, setVariableCount] = useState(1);
  const [headerText, setHeaderText] = useState<string | undefined>(undefined);
  const [headerFormat, setHeaderFormat] = useState<string | undefined>(undefined);
  const [exampleHeaderValues, setExampleHeaderValues] = useState<any>({});
  const [buttonClicked, setButtonClicked] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  // const [isHeaderTypeChange, setIsHeaderTypeChange] = useState(false);


  const handleHeaderTypeChange = (value: any) => {
    if (value){
      setHeaderText(undefined);
      if (onHeaderTextChanged) {
        onHeaderTextChanged(undefined);
      }
      setHeaderFormat(undefined);
      if (onHeaderTypeChanged) {
        onHeaderTypeChanged(undefined);
      }
      form.setFieldsValue({ header_format: undefined });
    }
    setHeaderType(value);
    if (value === 'TEXT') {
      setHeaderFormat(undefined);
      if (onHeaderTypeChanged) {
        onHeaderTypeChanged(undefined);
      }
      form.setFieldsValue({ header_format: "TEXT" });
    }

    if (value === 'None') {
      setHeaderFormat(undefined);
      if (onHeaderTypeChanged) {
        onHeaderTypeChanged(undefined);
      }
      setHeaderText(undefined);
      if (onHeaderTextChanged) {
        onHeaderTextChanged(undefined);
      }
    }
  };

  const handleHeaderFormatChange = (value: any) => {
    setHeaderFormat(value);
    if (onHeaderTypeChanged) {
      onHeaderTypeChanged(value);
    }
    if (headerType === "TEXT"){
      setHeaderFormat("TEXT");
      form.setFieldsValue({ header_format: "TEXT" });
      if (onHeaderTypeChanged) {
        onHeaderTypeChanged("TEXT");
      }
    }
  };

  const handleHeaderTextChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = target;
    if (value === undefined){
      setHeaderText(undefined);
      if (onHeaderTextChanged) {
        onHeaderTextChanged(undefined);
      }
    }
    else {
      setHeaderText(value);
      if (onHeaderTextChanged) {
        onHeaderTextChanged(value);
      }
    }
  };


  const handleAddVariable = () => {
    if (headerText) {
      if (headerText.includes('{{1}}')) {
        // If '{{1}}' is already present, do not add another one.
        setVariableCount(1);
        return;
      }
    }
    // const HeaderTextValue
    // if (headerText === undefined){
    //   const HeaderTextValue = "";
    // }
    // else {
    //   H
    // }

    const newVariableIndex = variableCount.toString();
    const updatedHeaderText = headerText === undefined ? `{{${variableCount}}}` :`${headerText}{{${variableCount}}}`;
    setHeaderText((prevText) => prevText === undefined ? `{{${variableCount}}}` : `${prevText}{{${variableCount}}}`);
    // setHeaderText(updatedHeaderText);

    const updatedExampleHeaderValues = {
      ...exampleHeaderValues,
      [newVariableIndex]: '',
    };
    setExampleHeaderValues(updatedExampleHeaderValues);

    if (headerText || updatedHeaderText) {
      if (headerText?.includes('{{1}}')) {
        setButtonClicked(true);
      }
    }
    if (onHeaderTextChanged) {
      onHeaderTextChanged(updatedHeaderText);
    }

    if (onExampleHeaderTextChanged) {
      onExampleHeaderTextChanged(updatedExampleHeaderValues);
    }

    form.setFieldsValue({header_text: updatedHeaderText});
  };


  const fileUploadCustomRequest = (options: any) => {
    const isMedia = options.file.type;
    if (isMedia === "application/pdf" || isMedia === "image/jpeg" || isMedia === "image/png" || isMedia === "video/mp4" ||
       isMedia === "application/vnd.ms-excel" || isMedia === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    isMedia === "application/msword" || isMedia === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    isMedia === "application/vnd.ms-powerpoint" || isMedia === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
    isMedia === "video/3gp" || isMedia === "text/plain") {
      setUploadingFile(true);
      const data = new FormData();
      data.append('file', options.file);
      uploadResumableFileService(options.file)
        .then(response => {
            if (response.header_handle !== "None") {
              options.onSuccess(response, options.file);
            } else {
              antdMessage.error(`${options.file} file type is not supported.`);
            }
          }
        ).catch(error => {
        showErrorNotification(error.message);
        setUploadingFile(false);
        setFileList([]);
        form.setFieldsValue({media_url: ''});
      })
        .finally(() => setUploadingFile(false));
    } else {
      antdMessage.error(`${options.file} file type is not supported.`);
    }
    if (onUploadFileChanged) {
      onUploadFileChanged(uploadingFile);
    }
  };

  const onChangeFile = (info: any) => {
    const {status, response} = info.file;
    setFileList(info.fileList);
    if (status === "done") {
      antdMessage.success(`${info.file.name} file uploaded successfully.`);
      form.setFieldsValue({media_url: response.header_handle});
    } else if (status === "error") {
      setFileList([]);
      form.setFieldsValue({media_url: ''});
      antdMessage.error(`${info.file.name} file upload failed.`);
    } else if (status === "removed") {
      setFileList([]);
      form.setFieldsValue({media_url: ''});
    }
  };


  useEffect(() => {
    if (editItem) {
      setExampleHeaderValues(editItem.example_header_text);
      if (editItem.header_format === ('IMAGE' || 'VIDEO' || 'LOCATION' || 'DOCUMENT')) {
        setHeaderType('Select Media Type');
        setHeaderFormat(editItem.header_format);
        setHeaderText('');
        form.setFieldsValue({ header_type: 'Select Media Type' });
      } else {
        setHeaderType(editItem.header_format);
        setHeaderFormat('');
        setHeaderText(editItem.header_text);
      }
    } else if (form.getFieldValue('header_format') === ('IMAGE' || 'VIDEO' || 'LOCATION' || 'DOCUMENT')) {
      setHeaderType('Select Media Type');
      setHeaderFormat(form.getFieldValue('header_format'));
      setHeaderText('');
    } else {
      setHeaderType(form.getFieldValue('header_format'));
      setHeaderFormat('');
      setHeaderText(form.getFieldValue('header_text'));
      if (form.getFieldValue('example_header_text')) {
        setExampleHeaderValues(form.getFieldValue('example_header_text'));
      }
    }

    if (isTemplateTypeChange){
      setHeaderType(undefined);
      setHeaderText(undefined);
      setHeaderFormat(undefined);
      setExampleHeaderValues(undefined);
      setButtonClicked(false);
      setUploadingFile(false);
      if (onHeaderTextChanged) {
        onHeaderTextChanged(undefined);
      }
      if (onExampleHeaderTextChanged) {
        onExampleHeaderTextChanged(undefined);
      }
      if (onHeaderTypeChanged) {
        onHeaderTypeChanged(undefined);
      }
      form.setFieldsValue({ header_type: undefined });
    }

    if (isCategoryTypeChange){
      setHeaderType(undefined);
      setHeaderText(undefined);
      setHeaderFormat(undefined);
      setExampleHeaderValues(undefined);
      setButtonClicked(false);
      setUploadingFile(false);
      if (onHeaderTextChanged) {
        onHeaderTextChanged(undefined);
      }
      if (onExampleHeaderTextChanged) {
        onExampleHeaderTextChanged(undefined);
      }
      if (onHeaderTypeChanged) {
        onHeaderTypeChanged(undefined);
      }
      form.setFieldsValue({ header_type: undefined });
    }
  }, [editItem, currentStep]);

  // @ts-ignore
  return (
    <><Col xs={24} sm={24} md={24}>
      <h4>
        Header <small style={{color: 'gray', fontSize: '12px'}}> (Optional)</small>
      </h4>

    </Col>
      <Col xs={24} sm={12} md={12}>
      <Form.Item
        name="header_type"
        labelCol={{span: 3}}
        labelAlign="right"
      >
        <Select
          placeholder="Select a Header"
          onChange={handleHeaderTypeChange}
        >
          {templateType === 'LIMITED_TIME_OFFER' && (
            <><Select.Option value="Select Media Type">Media</Select.Option>
              <Select.Option hidden value="IMAGE">Media</Select.Option>
              <Select.Option hidden value="VIDEO">Media</Select.Option></>
          )}
          {templateType !== 'LIMITED_TIME_OFFER' && (
            <><Select.Option value="TEXT">Text</Select.Option>
              <Select.Option value="Select Media Type">Media</Select.Option>
              {/* <Select.Option hidden value="IMAGE">Media</Select.Option> */}
              {/* <Select.Option hidden value="VIDEO">Media</Select.Option> */}
              {/* <Select.Option hidden value="DOCUMENT">Media</Select.Option> */}
              {/* <Select.Option hidden value="LOCATION">Media</Select.Option> */}
              <Select.Option value="None">None</Select.Option></>
          )}
        </Select>
      </Form.Item>
    </Col>
      <Col xs={24} sm={12} md={12}>
        {headerType === 'TEXT' && (
          <Form.Item
            name="header_text"
            labelCol={{span: 3}}
            labelAlign="right"
            extra="Maximum length is 60 chars"
            rules={[
              {
                max: 60,
                message: 'Header text cannot exceed 60 characters',
              },
              {
                required: true,
                message: 'Please enter Header text',
              },
              {
                validator: (_, value) => {
                  if (value && (value.match(/{{\d+}}/g) || []).length > 1) {
                    return Promise.reject(new Error('Only one variable (like {{1}}) is allowed.'));
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  const variableRegex = /{{\d+}}/g;
                  const variableMatches = value.match(variableRegex);

                  if (!variableMatches) {
                    // If there are no variables, it's valid.
                    return Promise.resolve();
                  }

                  if (variableMatches.length === 1 && variableMatches[0] === '{{1}}') {
                    // If there is exactly one variable and it's '{{0}}', it's valid.
                    return Promise.resolve();
                  }

                  // If there are other variables or '{{0}}' is not the only variable, show an error.
                  return Promise.reject(new Error('Only {{1}} is allowed.'));
                },
              }
            ]}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Input
                placeholder="Enter header text"
                value={headerText}
                onChange={handleHeaderTextChange}
                dir={inputDirection}
              />
              <Button
                onClick={handleAddVariable}
                disabled={buttonClicked}
                style={{
                  border: 'none',
                  background: 'transparent',
                  marginLeft: '8px',
                }}
              >
                Add Variable +
              </Button>
            </div>
          </Form.Item>
        )}
        {headerType === 'Select Media Type' && (
          <Form.Item
            name="header_format"
            labelCol={{span: 3}}
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'Please select a media type',
              }
            ]}
          >
            <Select placeholder="Select media type" value={headerFormat}
                    onChange={handleHeaderFormatChange}>

              {templateType === 'LIMITED_TIME_OFFER' && (
                <>
                  <Select.Option value="IMAGE">Image</Select.Option>
                  <Select.Option value="VIDEO">Video</Select.Option>
                </>
              )}

              {templateType !== 'LIMITED_TIME_OFFER' && (
                <>
                  <Select.Option value="IMAGE">Image</Select.Option>
                  <Select.Option value="VIDEO">Video</Select.Option>
                  <Select.Option value="DOCUMENT">Document</Select.Option>
                  <Select.Option value="LOCATION">Location</Select.Option>
                </>
              )}
            </Select>
          </Form.Item>
        )}
      </Col>
      <Col xs={24} sm={24} md={24}>
        {headerFormat && ["IMAGE", "VIDEO", "DOCUMENT"].includes(headerFormat) && (
          <Form.Item
            label={<span>
                                  Upload sample file{' '}
              <Tooltip className="mx-1"
                       title="To help us understand what kind of message you want to send, you have the option to provide specific content examples for your template. Make sure not to include any actual user or customer information, and provide only sample content in your examples.">
                                      <InfoCircleOutlined/>
                                  </Tooltip>
                              </span>}
            name="media_url" rules={[
            {
              required: true,
              message: 'Please upload a file abc',
            }]}>
            <Upload
              name="file"
              // eslint-disable-next-line no-nested-ternary
              accept={headerFormat==="IMAGE"?".jpeg, .png":(headerFormat==="VIDEO"?".mp4, .3gp": ".pdf, .txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx")}
              maxCount={1}
              fileList={fileList}
              customRequest={fileUploadCustomRequest}
              onChange={onChangeFile}
              showUploadList={{
                showRemoveIcon: true,
                removeIcon: <DeleteOutlined/>,
              }}
            >
              <Button type='default' className="bg-gray-lightest mx-2 px-5 w-100"><CloudUploadOutlined/>Upload</Button>
            </Upload>
          </Form.Item>)}
      </Col>
      <Col xs={24} sm={24} md={24}>
        {/* Example Data Input for Header Text Variables */}
        {headerType === 'TEXT' && headerText && headerText.includes('{{') && (
          <Card
            title={<div style={{display: 'flex', alignItems: 'center'}}>
              Samples for Header Content
              <InfoCircleOutlined
                style={{fontSize: '18px', marginLeft: '8px'}}
                onClick={() => {
                  Modal.info({
                    title: "Variable Example Information",
                    content: (
                      <div>
                        To help us review your message template,
                        please add an example for each variable in
                        your header text. Do not use real customer
                        information.
                      </div>
                    ),
                  });
                }}/>
            </div>}
            style={{
              backgroundColor: 'lightyellow',
              marginBottom: '16px',
              textAlign: 'center'
            }}
          >

            <Form.Item
              name="example_header_text"
              labelCol={{span: 3}}
              labelAlign="right"
              rules={[
                {
                  required: !Object.values(exampleHeaderValues).some(
                    (value) => value !== undefined && value !== ''
                  ),
                  message: 'Please provide example values for all variables',
                },
              ]}
              /* eslint-disable react/no-array-index-key */>

              {headerText && headerText.split('{{').map((part, index) => {
                if (part && part.includes('}}')) {
                  const variableIndex: any = part.split('}}')[0];
                  return (
                    <Input
                      key={index}
                      name={`example_header_text[${variableIndex}]`}
                      dir={inputDirection}
                      placeholder={`Example value for {{${variableIndex}}}`}
                      style={{marginBottom: '8px'}}
                      value={exampleHeaderValues[variableIndex]}
                      onChange={(e) => {
                        const newExampleValues = {...exampleHeaderValues};
                        newExampleValues[variableIndex] = e.target.value;
                        setExampleHeaderValues(newExampleValues);
                        // Call the onExampleHeaderTextChanged function with the updated values
                        if (onExampleHeaderTextChanged) {
                          onExampleHeaderTextChanged(newExampleValues);
                        }
                      }}/>
                  );
                }
                return null;
              })}
            </Form.Item>
          </Card>
        )}
      </Col></>
  );
};

StepTwoHeaderComponent.defaultProps = {
  editItem: undefined,
  templateType: undefined,
  form: undefined,
  onHeaderTextChanged: undefined,
  onHeaderTypeChanged: undefined,
  onExampleHeaderTextChanged: undefined,
  onUploadFileChanged: undefined,
  isTemplateTypeChange: undefined,
  isCategoryTypeChange: undefined,
  inputDirection: undefined,
};


export default StepTwoHeaderComponent;
