import React, {useEffect} from 'react';
import {Handle, Position, useReactFlow, useStoreApi} from 'reactflow';
import {Button, Card, Form, Input, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {TextNodeProps} from "../../models/chatbot";
import useFormErrors from "../../hooks/useFormErrors";

const TextMessageNode = (selectedNode: TextNodeProps) => {
  const {setNodes} = useReactFlow();
  const store = useStoreApi();
  const {id: selectedNodeId} = selectedNode;
  const [form] = Form.useForm();
  const {deleteElements} = useReactFlow();
  const { updateFormErrors, deleteFormError } = useFormErrors();

  useEffect(() => {
    const {data: {payload_info: payloadInfo}} = selectedNode;
    if (payloadInfo) {
      form.setFieldsValue({body: payloadInfo.body});
    }
  }, []);

  const updateNodeData = (updatedPayload: any) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === selectedNodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              payload_info: updatedPayload,
            },
          };
        }
        return node;
      })
    );
  };


  const handleMouseLeave = () => {
    form
      .validateFields()
      .then(() => {
         const {id} = selectedNode;
        updateFormErrors(id, false);
        updateNodeData(form.getFieldsValue());
      })
      .catch((errorInfo: any) => {
        const {id} = selectedNode;
        updateFormErrors(id, true);
      });
  };

  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteFormError(selectedNodeId);
    deleteElements({nodes: [{id: selectedNodeId}]});
  };

  return (
    <div style={{position: 'relative'}}>
      <Handle type="target" position={Position.Left} className='edgebutton-handle'/>
      <Tooltip
        color='white'
        title={
          <div
            style={{
              background: 'transparent',
              display: 'flex',
              flexDirection: 'column'
            }}
          >

            <Button
              title='Delete'
              onClick={(event) => onDeleteNode(event)}
              icon={<DeleteOutlined/>}
              style={{height: '35px', width: '35px'}}
            />
          </div>
        }
        placement='rightTop'

      >

        <div style={{position: 'relative', display: 'inline-block'}}>
          <Card title="Text Message" style={{width: '400px', border: "1px solid #173409FF"}} onMouseLeave={handleMouseLeave}>
            <Form form={form} name="dynamic_form" >
              <span style={{ color: 'red', fontSize:'16px'}}>*</span>
              <Form.Item name="body" rules={[{required: true, message: 'Body is required'}]}>
                <Input.TextArea placeholder="Body" autoSize={{minRows: 3}}/>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <Handle type="source" position={Position.Right} className='edgebutton-handle'/>
      </Tooltip>
    </div>
  );
};

export default TextMessageNode;
