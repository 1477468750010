import React, { useState, useRef, useEffect } from "react";
import { Button, notification, Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faDownload } from "@fortawesome/free-solid-svg-icons";

interface WhatsappAudioAttachmentProps {
  audioUrl: string | null;
  mediaSrc: string | null;
  handleDownload: () => void;
  isDownloading: boolean;
  downloadProgress: number;
}

const WhatsappAudioAttachment: React.FC<WhatsappAudioAttachmentProps> = ({
  audioUrl,
  mediaSrc,
  handleDownload,
  isDownloading,
  downloadProgress,
}) => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #d9d9d9",
    padding: "10px",
    maxWidth: "400px",
    minWidth: "300px",
    width: "100%",
    cursor: !mediaSrc ? "pointer" : "default",
  };

  const playPauseButtonStyle: React.CSSProperties = {
    marginRight: "10px",
  };

  const seekbarContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    marginRight: "10px",
    flexGrow: 1,
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  const timeStyle: React.CSSProperties = {
    color: "#888",
    fontSize: "12px",
    textAlign: "left",
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audio = audioRef.current;

    if (!audio){
      return () => {};
    }

    // Force audio to reload if mediaSrc changes
    audio.src = mediaSrc ?? "";

    const updateAudioData = () => {
      if (!Number.isNaN(audio.duration)) {
        setDuration(audio.duration);
      }
    };

    const updateAudioTime = () => {
      if (!Number.isNaN(audio.currentTime) && !Number.isNaN(audio.duration)) {
        setCurrentTime(audio.currentTime);
        setProgress((audio.currentTime / audio.duration) * 100);
      }
    };

    const handleEnd = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      setProgress(0);
    };

    audio.addEventListener("loadeddata", updateAudioData);
    audio.addEventListener("timeupdate", updateAudioTime);
    audio.addEventListener("ended", handleEnd);

    return () => {
      if (audio) {
        audio.removeEventListener("loadeddata", updateAudioData);
        audio.removeEventListener("timeupdate", updateAudioTime);
        audio.removeEventListener("ended", handleEnd);
      }
    };
  }, [mediaSrc]);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play().catch((error) => {
          // console.error("Error playing audio:", error);
          notification.error({
            message: "Error playing audio",
            description: error.message,
          });
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  if (!audioUrl) {
    return <div style={containerStyle}>This audio file is unavailable</div>;
  }

  return (
    <div style={containerStyle} onClick={!mediaSrc ? handleDownload : undefined} onKeyDown={!mediaSrc ? () => handleDownload() : undefined} role="button" tabIndex={0}>
      {mediaSrc ? (
        <div>
          <audio ref={audioRef} preload="auto">
            <track kind="captions" />
          </audio>
          <Button
            type="primary"
            shape="circle"
            icon={<FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />}
            onClick={togglePlayPause}
            style={playPauseButtonStyle}
          />
        </div>
      ) : (
        <div>
          {!isDownloading ? (
            <Button
              type="primary"
              shape="circle"
              icon={<FontAwesomeIcon icon={faDownload} />}
              style={playPauseButtonStyle}
            />
          ) : (
            <div style={playPauseButtonStyle}>
              <Progress
                type="circle"
                percent={downloadProgress}
                strokeWidth={12}
                width={40}
                strokeColor="green"
              />
            </div>
          )}
        </div>
      )}

      <div style={seekbarContainerStyle}>
        <Progress
          percent={progress}
          showInfo={false}
          strokeColor="#25d366"
          style={progressStyle}
        />
        <div style={timeStyle}>
          {formatTime(currentTime)} / {formatTime(duration)}
        </div>
      </div>
    </div>
  );
};

export default WhatsappAudioAttachment;
