import { PermissionMap, User } from "models";
import { LoginRequest } from 'models/requests';
import { AuthListResponse, UserResponse } from 'models/responses';
import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  FETCH_USER_SUCCESS,
  FETCH_USER,
  USER_ACL_LIST,
  FETCH_ACL_SUCCESS,
  NAVIGATE_TO_HOME,
  PRELOAD_DATA,
  SET_USER,
  SET_USER_ACL,
  SET_USER_WALLET,
  SET_USER_TEST_CREDIT,
  SET_PERMISSION_MAPS,
  FETCH_USER_WALLET, SET_LOCAL_CURRENCY
} from '../constants/Auth';


export const setUser = (user: User) => ({
  type: SET_USER,
  user
});
export const setUserACL = (acl?: AuthListResponse) => ({
  type: SET_USER_ACL,
  acl
});
export const setUserWallet = (wallet?: number) => ({
  type: SET_USER_WALLET,
  wallet
});

export const setLocalCurrency = (localCurrency?: string) => ({
  type: SET_LOCAL_CURRENCY,
  localCurrency
});


export const setUserTestCredit = (testCredit?: number) => ({
  type: SET_USER_TEST_CREDIT,
  testCredit
});
export const setPermissionMaps = (permissionMaps: PermissionMap[]) => ({
  type: SET_PERMISSION_MAPS,
  permissionMaps
});
export const signIn = (user: LoginRequest) => ({
  type: SIGNIN,
  payload: user
});

export const authenticated = (token: string) => ({
  type: AUTHENTICATED,
  token
});
export const fetchUser = () => ({
  type: FETCH_USER,
});
export const fetchUserSuccess = (user: UserResponse) => ({
  type: FETCH_USER_SUCCESS,
  user
});

export const signOut = () => ({
  type: SIGNOUT
});

export const signOutSuccess = () => ({
  type: SIGNOUT_SUCCESS,
});

export const signUp = (user: { email: string, password: string }) => ({
  type: SIGNUP,
  payload: user
});

export const signUpSuccess = (token: any) => ({
  type: SIGNUP_SUCCESS,
  token
});


export const showAuthMessage = (message: any) => ({
  type: SHOW_AUTH_MESSAGE,
  message
});

export const hideAuthMessage = () => ({
  type: HIDE_AUTH_MESSAGE,
});

export const showLoading = () => ({
  type: SHOW_LOADING,
});


export const fetchUserACLs = (redirect: boolean) => ({
  type: USER_ACL_LIST,
  redirect
});

export const fetchACLSuccess = (acl: AuthListResponse, redirect: boolean) => ({
  type: FETCH_ACL_SUCCESS,
  acl,
  redirect
});


export const navigateToHome = () => ({
  type: NAVIGATE_TO_HOME,
});

export const preloadData = () => ({
  type: PRELOAD_DATA,
});


export const fetchUserWallet = () => ({
  type: FETCH_USER_WALLET,
});
