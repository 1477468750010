import {
  ContactListGetRequest,
  GroupCreateRequest,
  GroupDeleteRequest,
  GroupUpdateRequest
} from 'models/requests';

import {
  createGroup,
  listGroup,
  deleteGroup,
  updateGroup,
  getContactsByGroup, syncContactsIntegrations, getMappingFromGroup, getContactsByGroupPaginate
} from 'api/dashboard/ContactGroupApi';

const createGroupService = async (data: GroupCreateRequest) => createGroup(data);
const listGroupService = async () => listGroup();
const deleteGroupService = async (data: GroupDeleteRequest) => deleteGroup(data);
const updateGroupService = async (data: GroupUpdateRequest) => updateGroup(data);
const getContactsByGroupService = async (groupId: number) => getContactsByGroup(groupId);
const getContactsByGroupPaginateService = async (groupId: number, data: ContactListGetRequest) => getContactsByGroupPaginate(groupId, data);
const syncContactFromIntegrationsService = async (groupId: number, integrationId: any) => syncContactsIntegrations(groupId, integrationId);

const getMappingFromGroupService = async (groupId: number) => getMappingFromGroup(groupId);
export {
  createGroupService,
  listGroupService,
  deleteGroupService,
  updateGroupService,
  getContactsByGroupService,
  syncContactFromIntegrationsService,
  getMappingFromGroupService, getContactsByGroupPaginateService
};
