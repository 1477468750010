import React from 'react';
import { RootState } from 'App';
import { useSelector } from 'react-redux';
import { Col, notification, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { userSetPasswordService } from 'services/auth/UserService';
import SetPasswordForm from 'components/setPassword';
import WelcomeSideBanner from 'components/layout/WelcomeSideBanner';

const SetPasswordPage = () => {
  const { token } = useParams();
  const theme = useSelector((state: RootState) => state.theme.currentTheme);
  const navigate = useNavigate();

  const onSuccess = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };
  const onError = (message: string) => {

    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };

  const setPassword = async (password: string) => {
    try {
      if (token) {
        const response = await userSetPasswordService({
          token,
          password
        });
        if (response.detail) {
          onSuccess('Password reset success');
          navigate('/signin');
        } else {
          onError('Password reset failed');
        }
      } else {
        onError('Invalid token');
      }
    } catch (error) {
      onError('Password reset failed');
    }
  };

  return (
    <div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                <h1>Set Your Password</h1>
                <div className="mt-4">
                  <SetPasswordForm onSubmit={setPassword} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={0} sm={0} md={0} lg={8}>
          <WelcomeSideBanner />
        </Col>
      </Row>
    </div>);
}
  ;

export default SetPasswordPage;
