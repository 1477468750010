import {
  postChatbotSaveRequest,
  getChatbotRequest,
  postChatbotDeployRequest,
  listChatbotRequest,
  createChatbotRequest,
  updateChatbotRequest,
  deleteChatbotRequest
} from "../../api/chatbot/ChatflowApi";
import {ChatbotCreate, ChatbotUpdate} from "../../models/requests";

const saveChatflowService = async (chatBotId: string, data: any) => postChatbotSaveRequest(chatBotId, data);
const getChatflowService = async (chatbotId: string) => getChatbotRequest(chatbotId);
const deployChatFlowService = async (chatBotId: string) => postChatbotDeployRequest(chatBotId);
const listChatbotService = async () => listChatbotRequest();
const createChatbotService = async (data: ChatbotCreate) => createChatbotRequest(data);
const updateChatbotService = async (chatBotId: string, data: ChatbotUpdate) => updateChatbotRequest(chatBotId, data);
const deleteChatbotService = async (chatBotId: string) => deleteChatbotRequest(chatBotId);


export {
  saveChatflowService,
  getChatflowService,
  deployChatFlowService,
  listChatbotService,
  createChatbotService,
  updateChatbotService,
  deleteChatbotService
};
