import React from 'react';
import {
  Row,
} from "antd";
import HeaderComponent from "./HeaderComponent";
import BodyComponent from "./BodyComponent";
import FooterComponent from "./FooterComponent";
import LTOComponent from "./LTOComponent";
import AuthenticationComponent from "./AuthenticationComponent";
import {WhatsappTemplateDetails} from "../../../../../models";
// import DynamicCarouselComponent from "./DynamicCarouselComponent";


export interface StepTwoProps {
  templateType?: any;
  onBodyTextChanged?: any;
  onFooterTextChanged?: any;
  onHeaderTextChanged?: any;
  onHeaderTypeChanged?: any;
  onExampleHeaderTextChanged?: any;
  onExampleBodyTextChanged?: any;
  onUploadFileChanged?: any;
  form?: any;
  inputDirection?: any;
  editItem?: WhatsappTemplateDetails;
  isTemplateTypeChange?: boolean;
  isCategoryTypeChange?: boolean;

}

const StepTwo = (props: StepTwoProps) => {

  const { templateType, onBodyTextChanged, onFooterTextChanged, onHeaderTextChanged, onHeaderTypeChanged, onExampleHeaderTextChanged, onExampleBodyTextChanged, onUploadFileChanged, editItem, form, isTemplateTypeChange, isCategoryTypeChange, inputDirection} = props;

  return (
    <Row gutter={16}>
      {(templateType === 'COUPON_CODE' || templateType === 'CUSTOM' || templateType === 'LIMITED_TIME_OFFER') && (
        <HeaderComponent currentStep={2} form={form} templateType={templateType} onHeaderTextChanged={onHeaderTextChanged} onHeaderTypeChanged={onHeaderTypeChanged} onExampleHeaderTextChanged={onExampleHeaderTextChanged} onUploadFileChanged={onUploadFileChanged} editItem={editItem} isTemplateTypeChange={isTemplateTypeChange} isCategoryTypeChange={isCategoryTypeChange} inputDirection={inputDirection}/>)}
      {(templateType === 'COUPON_CODE' || templateType === 'CUSTOM' || templateType === 'LIMITED_TIME_OFFER' || templateType === 'CAROUSEL') && (
        <BodyComponent currentStep={2} form={form} templateType={templateType} onBodyTextChanged={onBodyTextChanged} onExampleBodyTextChanged={onExampleBodyTextChanged} editItem={editItem} isTemplateTypeChange={isTemplateTypeChange} isCategoryTypeChange={isCategoryTypeChange} inputDirection={inputDirection}/>)}
      {(templateType === 'COUPON_CODE' || templateType === 'CUSTOM') && (
        <FooterComponent currentStep={2} form={form} onFooterTextChanged={onFooterTextChanged} inputDirection={inputDirection} editItem={editItem} isTemplateTypeChange={isTemplateTypeChange} isCategoryTypeChange={isCategoryTypeChange}/>)}
      {/* {templateType === 'CAROUSEL' && ( */}
      {/*  <DynamicCarouselComponent form={form} onAddNewCardChanged={onAddNewCardChanged}/>)} */}
      {templateType === 'AUTHENTICATION' && (
        <AuthenticationComponent/>)}
      {templateType === 'LIMITED_TIME_OFFER' && (
        <LTOComponent currentStep={2} form={form} inputDirection={inputDirection} editItem={editItem} isTemplateTypeChange={isTemplateTypeChange} isCategoryTypeChange={isCategoryTypeChange}/>)}
    </Row>
  );
};

StepTwo.defaultProps = {
  templateType: undefined,
  onBodyTextChanged: undefined,
  onFooterTextChanged: undefined,
  onHeaderTextChanged: undefined,
  onHeaderTypeChanged: undefined,
  onExampleHeaderTextChanged: undefined,
  onExampleBodyTextChanged: undefined,
  onUploadFileChanged: undefined,
  editItem: undefined,
  form: undefined,
  isTemplateTypeChange: undefined,
  isCategoryTypeChange: undefined,
  inputDirection: undefined,
};


export default StepTwo;
