import { useEffect, useState } from 'react';

const useAblyChannel = (ablyClient: any, channelName: string, enabled: boolean = true, trackPresence: boolean = false) => {
  const [message, setMessage] = useState<any>();

  useEffect(() => {
    let channel: any;
    const onMessage = (msg: any) => {
      // console.log(msg);
      setMessage(msg);
    };
    
    if (enabled && ablyClient) {
      channel = ablyClient.channels.get(channelName);

      if (channel) {
        channel.subscribe(onMessage);
        console.log('Subscribed to channel', channelName);
        
        // if (trackPresence && ablyClient.auth.clientId) {
        //   channel.presence.enter(null, (err: any) => {
        //     if (err) {
        //       console.error('Presence enter failed:', err);
        //     } else {
        //       console.log('Presence enter successful');
        //     }
        //   });
        // } else if (trackPresence) {
        //   console.error('ClientId is required for presence channels.');
        // }
      }

    }
    return () => {
      if (channel) {
        // if (trackPresence && ablyClient.auth.clientId) {
        //   channel.presence.leave((err: any) => {
        //     if (err) {
        //       console.error('Presence leave failed:', err);
        //     } else {
        //       console.log('Presence leave successful');
        //     }
        //   });
        // }
        channel.unsubscribe(onMessage);
        console.log('Unsubscribed from channel', channelName);
      }
    };
  }, [ablyClient, channelName, enabled, trackPresence]);

  return message;
};

export default useAblyChannel;
