// import { LoginRequest } from 'models/requests';
import { login, getTestToken, refreshToken } from 'api/auth/LoginApi';

const loginService = async (data:any) => login(data);
const getTestTokenService = async () => getTestToken();
const refreshTokenService = async (token: string) => refreshToken(token);

export {
  loginService,
  refreshToken,
  getTestTokenService,
  refreshTokenService
};