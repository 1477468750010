import React, {useEffect, useState} from 'react';
import {Card, notification, Modal, Form, Button} from 'antd';
import {
  listWhatsappMetaRegisteredNumbersService,
  onboardWhatsappBusinessAccountService,
  saveWhatsappTokenService
} from "services/dashboard/WhatsappService";
import WhatsappContactNumberList from "components/whatsapp-meta/WhatsappSettings/WhatsappNumberList";
import {useNavigate} from "react-router-dom";
import PageHeader from "../../../components/layout/PageHeader";
import {WHATSAPP_CONFIG_ID} from "../../../configs/AppConfig";


const RegisteredNumbers = () => {
  const [loading, setLoading] = useState(false);
  const [Contacts, setContacts] = useState([]);
  const [open, setOpen] = useState(false);
  const [acessToken, setAcessToken] = useState<string>('');
  const navigate = useNavigate();

  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };

  const onError = (message: string) => {
    showErrorNotification(message);
    setLoading(false);
  };

  const fetchPhoneNumber = async () => {
    setLoading(true);
    try {
      const fetchedPhoneNumbers: any = await listWhatsappMetaRegisteredNumbersService();
      setLoading(false);
      if (fetchedPhoneNumbers && fetchedPhoneNumbers.length > 0) {
        setContacts(fetchedPhoneNumbers);
      }
    } catch (error) {
      setLoading(false);
      onError("Failed to fetch onboarded whatsapp numbers!");
      // console.error('Error fetching templates:', error);
    }
  };
  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };

  const onSuccess = (message: string) => {
    showSuccessNotification(message);
    setLoading(false);
  };

  const onboardWhatsappBusinessAccount = async (phoneNumberId?: string, wabaId?: string, eventData?: JSON) => {
    try {
      const response = await onboardWhatsappBusinessAccountService({
        "event_data": eventData,
        "phone_number_id": phoneNumberId,
        "waba_id": wabaId,
        "access_token": acessToken
      });
      // eslint-disable-next-line no-console
      // console.log(response);
      if (response){
        onSuccess("Whatsapp Account onboarded Successfully");
        navigate(`/whatsapp-meta/registered-numbers`);
        await fetchPhoneNumber();
      }
    }catch (error: any) {
      onError(error.message);
      await fetchPhoneNumber();
    }
  };

  const sessionInfoListener = (event: any) => {
    // console.log("inside sessionInfoListener in numbers");
    if (event.origin == null) {
      return;
    }
    if (!event.origin.endsWith("facebook.com")) {
      return;
    }
    try {
      const data = JSON.parse(event.data);
      onboardWhatsappBusinessAccount(undefined, undefined, data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        if (data.event === "FINISH") {
          // eslint-disable-next-line camelcase
          const {phone_number_id, waba_id} = data.data;
          onboardWhatsappBusinessAccount(phone_number_id, waba_id, data);
          // eslint-disable-next-line no-console,camelcase
          // console.log(`Phone number ID: ${phone_number_id}, WABA ID: ${waba_id}`);
        } else {
          // eslint-disable-next-line camelcase
          const {current_step} = data.data;
          // eslint-disable-next-line camelcase
          onError(`Whatsapp Account Onboarding Cancelled at step ${current_step}`);
          // eslint-disable-next-line no-console,camelcase
          // console.log(`Cancelled at step: ${current_step}`);
        }
      }
    } catch (error: any) {
      if (error.name !== 'SyntaxError') {
        // eslint-disable-next-line no-console
        // console.log(error);
        navigate(`/whatsapp-meta/connect-whatsapp/failure`);
      }
    }
  };

  const saveToken = async (token: string) => {
    await saveWhatsappTokenService({"token": token});
  };

  const onClickWhatsappConnect = async () => {
    setOpen(false);
    window.FB.login((response: any) => {
      if (response.authResponse) {
        setAcessToken(response.authResponse.code);
        saveToken(response.authResponse.code);
      } else {
        onError(`User cancelled login or did not fully authorize.`);
        fetchPhoneNumber();
      }
    }, {
      config_id: WHATSAPP_CONFIG_ID,
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        feature: 'whatsapp_embedded_signup',
        sessionInfoVersion: 2 //  Receive Session Logging Info
      }
    },);
    // console.log("outside sessionInfoListener in numbers");
    window.addEventListener('message', sessionInfoListener);
  };

  const openInstructionForm = () => {
    setOpen(true);
  };


  useEffect(() => {
    Promise.all([fetchPhoneNumber()]);
  }, []);

  return (
    <>
      <PageHeader display title="page.whatsapp-meta.numbers" showBreadCrumb={false}/>
      <Card className="mt-4" loading={loading}>
        <WhatsappContactNumberList whatsappcontact={Contacts} openInstructionForm={openInstructionForm}/>
      </Card>
      <Modal
        title="Whatsapp Number"
        visible={open}
        onOk={(e)=>setOpen(false)}
        onCancel={(e)=>setOpen(false)}
        footer={null}
      >
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
          <p style={{color: "#455560"}}><strong>Make Sure you have,</strong></p>
          <p style={{color: "#455560"}}>1. Logged in to Facebook account.</p>
          <p style={{color: "#455560"}}>2. Access to Meta Business Manager. You can also create meta business account on the go.</p>
          <p style={{color: "#455560"}}>3. Phone Number with no active whatsapp account.</p>

        </div>
        <div >
          <Form.Item>
            <div className="mt-4 ">
                <Button
                  type="primary"
                  loading={loading}
                  onClick={onClickWhatsappConnect}
                >
                  Add Whatsapp Number
                </Button>
            </div>
          </Form.Item>
        </div>
      </Modal>
    </>

  );
};

export default RegisteredNumbers;
