import { Col, Row } from 'antd';
import React from 'react';

export const WelcomeSideBanner = () => {
  const backgroundStyle = {
    backgroundImage: 'url(/img/others/img-17.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  };

  return (<div className="d-flex flex-column justify-content-between h-100 px-4" style={backgroundStyle}>
    <div className="text-right">
      <img src="/img/logo-white.png" alt="logo" />
    </div>
    <Row justify="center">
      <Col xs={0} sm={0} md={0} lg={20}>
        <img className="img-fluid mb-5" src="/img/others/img-18.png" alt="" />
        <h1 className="text-white">Welcome to D7</h1>
        <p className="text-white">Reliable and Budget friendly Messaging solution with Global coverage and 24/7 Support.</p>
      </Col>
    </Row>
    <div className="d-flex justify-content-end pb-4">
      <div>
        <a className="text-white" href="https://d7networks.com/terms/" target='blank'>Terms of Service</a>
        <span className="mx-2 text-white"> | </span>
        <a className="text-white" href="https://d7networks.com/privacy/" target='blank'>Privacy Policy</a>
      </div>
    </div>
  </div>);
};

export default WelcomeSideBanner;
