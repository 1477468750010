import React, { useState } from 'react';
import { EditOutlined, StopOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {WhatsappCampaignListngModel, WhatsappMediaTemplate} from "models";
import TableList from 'components/shared/TableList';
import { Form } from 'antd';
import EditWhatsappCampaignModalForm from "../WhatsappCampaign/EditWhatsappCampaignModal";



export interface ManageWhatsappCampaignProps {
  whatsappCampaigns: WhatsappCampaignListngModel[];
  loading?: boolean;
  onPaginate?: (page: number, pageSize: number, searchText?: string) => void;
  onClickCancelCampaign?: (campaignId: string) => void;
  total: number;
}

const ManageWhatsappCampaignList = (props: ManageWhatsappCampaignProps) => {
  const { whatsappCampaigns, loading, onClickCancelCampaign, onPaginate, total } = props;

  const [searchText, setSearchText] = useState<string | undefined>();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [editCampaignId, setEditCampaignId] = useState('');
  const [showEditPopUp, setShowEditPopUp] = useState(false);
  const [whatsappCampaignForm] = Form.useForm();

  const onCloseModal = () => {
    setEditCampaignId('');
    whatsappCampaignForm.resetFields();
    setShowEditPopUp(false);
  };

  const onConfirmCancel = (item: WhatsappCampaignListngModel) => {
    onClickCancelCampaign?.(item.id);
  };

  const onEditSave = () => {
    onPaginate?.(page, pageSize);
    onCloseModal();
  };


  const actions = [
    {
      toolTip: 'Edit',
      icon: <EditOutlined />,
      onClick: (item: WhatsappCampaignListngModel) => {
        setEditCampaignId(item.id);
        setShowEditPopUp(true);
      }
    },
    {
      toolTip: 'Cancel',
      icon: <StopOutlined />,
      popConfirm: {
        title: 'Are you sure you want to cancel this whatsapp campaign?', onConfirm: onConfirmCancel
      },
      getDisabled: (item: WhatsappCampaignListngModel, index: number) => item.status === 'canceled' || item.status === 'sent'
    },
  ];

  const searchPredicate = (item: WhatsappCampaignListngModel, text: string) =>
    item.campaign_name.toLowerCase().includes(text)
    || item.id.toString().toLowerCase().includes(text)
    || `${item.status}`.toLowerCase().includes(text);

  const getDateString = (dateValue: Date) => {
    const arr = (dateValue).toString().split(/-|\s|\+|:|\./);// split string and create array.
    const dateStr =`${arr[0]}${"-"}${arr[1]}${"-"}${arr[2]}${":"}${arr[3]}${":"}${arr[4]}`;
    return dateStr.replace("T","  ");
  };

  const tableColumns: ColumnsType<WhatsappCampaignListngModel> = [
    {
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Created Date(UTC)',
      dataIndex: 'created_at',
      render: (_v, record) => getDateString(record.created_at)
    },
    {
      title: 'Scheduled Date',
      dataIndex: 'scheduled_at',
      // render: (_v, record) => getDateString(record.scheduled_at)
    },
    {
      title: 'Name',
      dataIndex: 'campaign_name',
    },
    {
      title: 'Total Recipients',
      dataIndex: 'total_recipients_count',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      render: (content: WhatsappMediaTemplate) => {
        if (content) {
          /* eslint-disable camelcase */
          const { template_id, body_parameter_values, media } = content;
          const templateId = template_id || 'N/A';
          let bodyParams;
          let mediaType;
          if (body_parameter_values){
            bodyParams = body_parameter_values
              ? Object.entries(body_parameter_values)
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ')
              : 'N/A';

          }
          if (media){
            mediaType = media.media_type || 'N/A';
          }

          return (
            <div>
              <div>Template ID: {templateId}</div>
              {bodyParams && (
                <div>Body Parameters: {bodyParams}</div>)}
              {mediaType && (
                <div>Media Type: {mediaType}</div>)}
            </div>
          );
        }
        return 'N/A';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    // {
    //   title: 'Log',
    //   dataIndex: 'log',
    // }
  ];

  const onSearch = (value: string) => {
    setSearchText(value);
    onPaginate?.(1, pageSize, value);
  };
  const onChangePage = (newPage: number, newSize: number) => {
    if (pageSize !== newSize) {
      setPageSize(newSize);
      setPage(1);
      onPaginate?.(1, newSize, searchText);
    } else {
      setPage(newPage);
      onPaginate?.(newPage, newSize, searchText);
    }
  };

  const getPaginationOption = () => ({
    onChangePage,
    pageSize,
    currentPage: page,
    total
  });

  return (
    <div>
      <TableList<WhatsappCampaignListngModel> items={whatsappCampaigns} columns={tableColumns} loading={loading}
        rowKey={(record: WhatsappCampaignListngModel) => record.id} showSearch actions={actions}
        searchPredicate={searchPredicate} hideRowSelection onSearch={onSearch}
        serverSidePaginationOption={getPaginationOption()} />

      <EditWhatsappCampaignModalForm campaignId={editCampaignId} form={whatsappCampaignForm} onSave={onEditSave} onCloseModal={onCloseModal} showEditPopUp={showEditPopUp} />
    </div>
  );
};

ManageWhatsappCampaignList.defaultProps = {
  loading: false,
  onClickCancelCampaign: undefined,
  onPaginate: undefined
};

export default ManageWhatsappCampaignList;
