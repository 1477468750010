import {
  AutoRechargeCreateRequest,
  AutoRechargeUpdateRequest,
  CaptureRequest,
  CheckoutRequest
} from 'models/requests';

import {
  checkoutPayment,
  capturePayment,
  setupCheckout,
  getSavedCards,
  getInvoices,
  downloadInvoice,
  getAutoRecharge,
  addAutoRecharge,
  deleteAutoRecharge,
  updateAutoRecharge,
  getUserWallet,
  deleteSavedCard
} from "api/dashboard/PaymentApi";

const checkoutPaymentService = async (data: CheckoutRequest) => checkoutPayment(data);
const capturePaymentService = async (data: CaptureRequest) => capturePayment(data);
const setupCheckoutService = async () => setupCheckout();
const getSavedCardsService = async () => getSavedCards();
const getInvoicesService = async () => getInvoices();
const downloadInvoiceService = (invoiceId: string) => downloadInvoice(invoiceId);

const getAutoRechargeService = async () => getAutoRecharge();
const addAutoRechargeService = async (data: AutoRechargeCreateRequest) => addAutoRecharge(data);
const updateAutoRechargeService = async (id: string, data: AutoRechargeUpdateRequest) => updateAutoRecharge(id, data);
const deleteAutoRechargeService = async (id: string) => deleteAutoRecharge(id);
const getUserWalletService = async () => getUserWallet();

const deleteSavedCardService = async (id: string) => deleteSavedCard(id);

export {
  checkoutPaymentService,
  capturePaymentService,
  setupCheckoutService,
  getSavedCardsService,
  getInvoicesService,
  downloadInvoiceService,
  getAutoRechargeService,
  addAutoRechargeService,
  updateAutoRechargeService,
  deleteAutoRechargeService,
  getUserWalletService,
  deleteSavedCardService
};
