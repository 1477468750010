import React, {useEffect, useState} from 'react';
import { Button, Form, Input, Select } from 'antd';
import { CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import FormItemPhoneInput from 'components/shared/FormItemPhoneInput';
import { useNavigate } from 'react-router-dom';
import { blockedRegisterService } from 'services/auth/RegisterService';
import { handleApiError, showSuccessNotification } from 'utils/helper';
import {analytics} from "utils/google_tag_manager_script";

const BasicInfoFrom = (props: { validatedEmail?: string }) => {
  const { validatedEmail } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onSuccessSubmitForm = () => {
    setLoading(false);
    showSuccessNotification('Successfully submitted your details');
    navigate('/signin');
  };
  const onErrorSubmitForm = (error: unknown) => {
    setLoading(false);
    handleApiError(error);
  };


  const getPhoneNumber = (phoneField: any) => {
    if (phoneField?.phone) {
      // eslint-disable-next-line no-nested-ternary
      const phone = phoneField.phone?.startsWith('+') ?
        phoneField.phone : phoneField.code? `+${phoneField.code}${phoneField.phone}`: `+${phoneField.phone}`;
      return phone.trim()
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('{', '')
        .replaceAll('}', '')
        .replaceAll('[', '')
        .replaceAll(']', '');
    }
    return undefined;
  };


  const onSubmitForm = async (values: {
    firstName: string,
    lastName: string,
    companyName: string,
    mobile: CountryPhoneInputValue,
    email: string,
    address1: string,
    address2: string,
    city: string,
    country: string,
    usecase: string
  }) => {
    setLoading(true);

    try {
      const {
        firstName,
        lastName,
        companyName,
        mobile,
        email,
        address1,
        address2,
        city,
        country,
        usecase
      } = values;
      const phone = getPhoneNumber(mobile);
      const response = await blockedRegisterService({
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        phone,
        email,
        address_1: address1,
        address_2: address2,
        city,
        country,
        usecase
      });
      if (response) {
        onSuccessSubmitForm();
      } else {
        onErrorSubmitForm({ message: 'Failed to submit your details', status: 0 });
      }
    } catch (error) {
      onErrorSubmitForm(error);
    }
  };

  useEffect(() => {
    analytics(window, document, 'script', 'dataLayer', 'GTM-N4P6V8W');
  },[]);


  return (<Form
    layout="vertical"
    name="registrationForm"
    form={form}
    onFinish={onSubmitForm}>
    <Form.Item
      label="First Name"
      name="firstName"
      rules={[
        {
          required: true,
          message: 'Please enter your first name',
        }
      ]}>
      <Input />
    </Form.Item>
    <Form.Item
      label="Last Name"
      name="lastName">
      <Input />
    </Form.Item>

    <Form.Item
      label="Email"
      name="email"
      initialValue={validatedEmail}
      rules={[
        {
          required: true,
          message: 'Please enter your email',
        },
        {
          type: 'email',
          message: 'Please enter a valid email!'
        }
      ]} >
      <Input type="email" readOnly />
    </Form.Item>
    <FormItemPhoneInput
      label="Mobile Number"
      name="mobile"
      initialValue={{ short: 'AE' }}
      form={form}
      onCountrySelectionChange={(phone) => { form.setFieldsValue({ country: phone.short }); }}
      onValidDialCode={(phone) => { form.setFieldsValue({ country: phone.short }); }}
    />


    <Form.Item
      label="Address 1"
      name="address1">
      <Input />
    </Form.Item>
    <Form.Item
      label="Address 2"
      name="address2">
      <Input />
    </Form.Item>
    <Form.Item
      label="City"
      name="city">
      <Input />
    </Form.Item>
    <Form.Item
      label="Country"
      initialValue='AE'
      name="country" rules={[
        {
          required: true,
          message: 'Please choose your country',
        }
      ]}>
      <Select className='w-100' showSearch filterOption={(input: any, option: any) =>
        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
        {en.map(e => <Select.Option key={e.alpha2} value={e.alpha2.toUpperCase()} title={e.name}><span className={`flag:${e.alpha2.toUpperCase()} mr-2`} />{e.name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Company Name"
      name="companyName">
      <Input />
    </Form.Item>
    <Form.Item
      label="Business Information"
      name="usecase">
      <Input />
    </Form.Item>

    <Form.Item>
      <div className="text-right mt-4">
        <Button type="primary" htmlType="submit" loading={loading}>
          Register
        </Button>
      </div>
    </Form.Item>
  </Form>);
};
BasicInfoFrom.defaultProps = {
  validatedEmail: undefined
};
export default BasicInfoFrom;
