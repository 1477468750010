import ExternalMessageClient from ".";

const ExternalMessageApi = {
  baseUrl: '/',
  getUrl: (url: string) => `${ExternalMessageApi.baseUrl}${url}`,
  getAuthHeader: (token: string) => ({ headers: { 'Authorization': `Bearer ${token}` } }),
  post: <R = any, D = any>(url: string, token: string, data?: D) => ExternalMessageClient.post<D, R>(ExternalMessageApi.getUrl(url), data,
    ExternalMessageApi.getAuthHeader(token)),
  put: <R = any, D = any>(url: string, token: string, data?: D) => ExternalMessageClient.put<D, R>(ExternalMessageApi.getUrl(url), data, ExternalMessageApi.getAuthHeader(token)),
  get: <R = any, D = any>(url: string, token: string, data?: D) => ExternalMessageClient.get<D, R>(ExternalMessageApi.getUrl(url), ExternalMessageApi.getAuthHeader(token)),
  delete: <R = any, D = any>(url: string, token: string, data?: D) => ExternalMessageClient.delete<D, R>(ExternalMessageApi.getUrl(url), ExternalMessageApi.getAuthHeader(token)),
};

export default ExternalMessageApi;





