import React, {useState} from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal
} from "antd";
import {InfoCircleOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";


export interface StepThreeLTOButtonComponentProps {
  // updateFormValues: (values: any) => void;
  // editItem?: boolean;
  hasExpiring: boolean;
  inputDirection?: any;
}

const StepThreeLTOButtonComponent = (props: StepThreeLTOButtonComponentProps) => {

  const {hasExpiring, inputDirection} = props;
  const [errorMessage] = useState('');
  const [buttonUrlTextValues, setButtonUrlTextValues] = useState<string[]>([]);
  const [buttonUrlValues, setButtonUrlValues] = useState<string[]>([]);


  const handleButtonUrlTextChange = (index: any, value: any) => {
    const newButtonUrlTextValues = [...buttonUrlTextValues];
    newButtonUrlTextValues[index] = value;
    setButtonUrlTextValues(newButtonUrlTextValues);
  };
  const handleButtonUrlChange = (index: any, value: any) => {
    const newButtonTextValues = [...buttonUrlValues];
    newButtonTextValues[index] = value;
    setButtonUrlValues(newButtonTextValues);
  };

  return (
    <>
      <Col xs={24} sm={24} md={24}>
        <Form.List
          name={['buttons', 'button_copy_code']}
          initialValue={['']}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <Form.Item
                    labelAlign='left'
                    labelCol={{ span: 4 }}
                    label="Example offer code"
                    name={[field.name, 'button_text_copy_code']}
                    fieldKey={[field.fieldKey, 'button_text_copy_code']}
                    rules={[
                      {
                        max: 15,
                        message: 'Example offer code cannot exceed 15 characters',
                      },
                      {
                        required: hasExpiring,
                        message: 'Please enter Example offer code',
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </Col>
      <Col xs={24} sm={24} md={24}>
        <h4>
          URL Button
        </h4>
      </Col>
      <Col xs={24} sm={24} md={24}>
        <Form.Item labelAlign='left' labelCol={{span: 4}} label="">
          <Form.List name={['buttons', 'button_url']}
                     initialValue={['']} // Initialize with an empty string
          >
            {(fields, {add, remove}) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <Form.Item
                      labelAlign='left'
                      labelCol={{span: 4}}
                      label="Button Text"
                      name={[field.name, 'button_text_url']}
                      fieldKey={[field.fieldKey, 'button_text_url']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Button text',
                        },
                        {
                          max: 25,
                          message: 'Body text cannot exceed 25 characters',
                        }
                      ]}
                    >
                      <Input placeholder="Enter button text"
                             value={buttonUrlTextValues[index] || ''}
                             onChange={(e) => handleButtonUrlTextChange(index, e.target.value)}
                             dir={inputDirection}/>
                    </Form.Item>
                    <Form.Item
                      labelAlign='left'
                      labelCol={{span: 4}}
                      label={
                        <span>
                                              URL <InfoCircleOutlined
                          style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                          onClick={() => {
                            Modal.info({
                              title: "URL",
                              content: (
                                <>
                                  <div>
                                    If you wants to add this button as <b>Dynamic URL</b> then add it like <b>https://www.luckyshrub.com/shop?promo={'{{'}1{'}}'}</b> then fill the <b>example
                                    url value</b>. It is mandatory for <b>Dynamic URL</b>.
                                  </div>
                                  <br/>
                                  <div style={{opacity: '0.5'}}>
                                    (Using a <b>Static URL</b>, you can give direct access via a single click
                                    to your website. You can also set up the button to visit a <b>Dynamic
                                    website</b> with your customer directed to a personalized website.)
                                  </div>
                                </>
                              ),
                            });
                          }}
                        />
                                          </span>
                      }
                      name={[field.name, 'button_url']}
                      fieldKey={[field.fieldKey, 'button_url']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Button URL value',
                        },
                        {
                          type: 'url',
                          message: 'Please enter a valid URL',
                        },
                        {
                          max: 2000,
                          message: 'URL must be at most 2000 characters',
                        }
                      ]}
                    >
                      <Input placeholder="Enter URL"
                             value={buttonUrlValues[index] || ''}
                             onChange={(e) => handleButtonUrlChange(index, e.target.value)}/>
                    </Form.Item>
                    {buttonUrlValues[index]?.includes('{{1}}')  && (
                    <Form.Item
                      labelAlign='left'
                      labelCol={{span: 4}}
                      label={
                        <span>
                                              Example URL Value <InfoCircleOutlined
                          style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                          onClick={() => {
                            Modal.info({
                              title: "Example URL Value",
                              content: (
                                <>
                                  <div>
                                    If you wants to add a <b>Dynamic URL</b> button then fill the <b>example
                                    url value</b>. It is mandatory for <b>Dynamic URL</b>.
                                  </div>
                                  <br/>
                                  <div style={{opacity: '0.5'}}>
                                    (Using a <b>Static URL</b>, you can give direct access via a single
                                    click to your website. You can also set up the button to visit a <b>Dynamic
                                    website</b> with your customer directed to a personalized website.)
                                  </div>
                                </>
                              ),
                            });
                          }}
                        />
                                          </span>
                      }
                      name={[field.name, 'example_url_values']}
                      fieldKey={[field.fieldKey, 'example_url_values']}
                    >

                      <Input/>
                    </Form.Item>)}
                    {/* {index > 0 && ( */}
                      <Button
                        style={{marginBottom: "12px"}}
                        type="dashed"
                        onClick={() => {
                          remove(field.name);
                          // Update state here to remove the corresponding button text
                          const updatedUrlTextValues = [...buttonUrlTextValues];
                          updatedUrlTextValues.splice(index, 1);
                          setButtonUrlTextValues(updatedUrlTextValues);
                        }}
                        block
                        icon={<MinusOutlined/>}
                      >
                        Remove Button
                      </Button>
                    {/* )} */}
                  </div>
                ))}
                {fields.length < 2 && (
                  <Button type="dashed" onClick={() => add()} block
                          icon={<PlusOutlined/>}>
                    Add Button
                  </Button>
                )}
              </>
            )}
          </Form.List>
          {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
        </Form.Item>
      </Col>
    </>
  );
};

StepThreeLTOButtonComponent.defaultProps = {
  // editItem: undefined,
  inputDirection: undefined
};


export default StepThreeLTOButtonComponent;
