const scriptExists = (url: string) => {
  const scripts = document.getElementsByTagName('script');
  for (let i = scripts.length - 1; i >= 0; i -= 1) {
    if (scripts[i].src === url) return true;
  }
  return false;
};

const analytics = (w: Window, d: Document, s: string, l: string, i: string) => {
  const windowObj = w as any;
  windowObj.dataLayer = windowObj.dataLayer || [];
  windowObj.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const dl = l !== 'dataLayer' ? `&l=${l}` : '';
  const scr = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;

  /*
      To avoid Multiple installations of google tag manager detected warning
  */
  if (!scriptExists(scr)) {
    const f = d.getElementsByTagName(s)[0];
    const j: HTMLScriptElement = d.createElement("script");
    j.async = true;
    j.src = scr;
    f?.parentNode?.insertBefore(j, f);
  }
};

export { analytics, scriptExists };
