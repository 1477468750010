import React from 'react';
import { useSelector } from 'react-redux';
import { NAV_TYPE_TOP } from 'constants/ThemeConstant';
import utils from 'utils';
import { RootState } from 'App';
import MenuContent from './MenuContent';

export const TopNav = (props: { localization?: boolean, routeInfo?: any }) => {
	const { localization, routeInfo } = props;
	const { topNavColor } = useSelector((state: RootState) => state.theme);
	return (
		<div className={`top-nav ${utils.getColorContrast(topNavColor)}`} style={{ backgroundColor: topNavColor }}>
			<div className="top-nav-wrapper">
				<MenuContent type={NAV_TYPE_TOP} localization={localization} routeInfo={routeInfo} />
			</div>
		</div>
	);
};
TopNav.defaultProps = {
	routeInfo: undefined,

	localization: false
};
export default TopNav;
