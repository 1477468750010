import React, { useEffect, useState } from "react";
import { Card, notification } from "antd";
import moment from 'moment';
import { WhatsappApiReportModel } from "models";
import ApiReportList from "components/whatsapp/ApiReport";
import {WhatsappApiReportFilterRequest} from "models/requests/WhatsappApiReportFilterRequest";
import {WhatsappApiReportExportRequest} from "models/requests/WhatsappApiReportExportRequest";
import {
  getWhatsappApiSearchResultService,
  getWhatsappApiFilterResultService,
  getWhatsappApiReportExportService
} from "services/dashboard/WhatsappService";
import { useNavigate } from "react-router-dom";

const ApiReport = () => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<WhatsappApiReportModel[]>([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: "",
    };
    notification.error(notificationParam);
  };

  const fetchAllApiReports = async (data?: WhatsappApiReportFilterRequest, page: number = 1, size: number = 10) => {
    // const  starDate = moment().subtract(1, 'month');
    // const endDate = moment();
    let response;
    try {
      setLoading(true);
      if (data){
        response = await getWhatsappApiFilterResultService(data);
      }
      else {
        response = await getWhatsappApiFilterResultService({
          page,
          size,
          start_date: moment().subtract(1, 'month').format("YYYY-MM-DD"),
          end_date: moment().endOf('month').format("YYYY-MM-DD")
        });
      }
      setReports(response.items);
      setTotal(response.total);
    } catch (error: any) {
      showErrorNotification(error.message);
      setReports([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const fetchBySearch = async (key: string,data: WhatsappApiReportFilterRequest) => {

    try {
      const response = await getWhatsappApiSearchResultService(key,data);

      if (response.items) {
        setReports(response.items);
        setTotal(response.total);
      }
    } catch (error: any) {
      showErrorNotification(error.message);
      setReports([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const fetchByFilter = async (data: WhatsappApiReportFilterRequest) => {
    try {
      setLoading(true);
      const response = await getWhatsappApiFilterResultService(data);
      if (response.items) {
        setReports(response.items);
        setTotal(response.total);
      }
    } catch (error: any) {
      showErrorNotification(error.message);
      setReports([]);
      setTotal(0);
    }
    setLoading(false);
  };



  const onPaginate = async (
    page: number,
    newSize: number,
    data: WhatsappApiReportFilterRequest,

    searchText?: string,
  ) => {
    const paginatedData = {
      start_date: data.start_date,
      end_date: data.end_date,
      page,
      size: newSize,
      ...(data.whatsapp_status && {viber_status: data.whatsapp_status.toLowerCase()}),
      ...(data.country && {country: data.country}),
    };
    setLoading(true);
    if (searchText) {
      fetchBySearch(searchText,paginatedData);
    } else {
      fetchAllApiReports(paginatedData, page, newSize);
    }
  };


  const createExportFile = async (data: WhatsappApiReportExportRequest) => {
    try {
      const response = await getWhatsappApiReportExportService(data);
      if (response) {
        navigate('/importexport');
      }
    } catch (error: any) {
      showErrorNotification(error.message);
    }
    setLoading(false);
  };

  const onExportClick=async (data: WhatsappApiReportExportRequest)=>{
    createExportFile(data);
  };


  useEffect(() => {
    fetchAllApiReports();
  }, []);

  return (
    <Card className="mt-4">
      <ApiReportList
        reports={reports}
        loading={loading}
        total={total}
        onPaginate={onPaginate}
        fetchByFilter={fetchByFilter}
        onExportLog={onExportClick}
      />
    </Card>
  );
};

export default ApiReport;
