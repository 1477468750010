import {
  WhatsappCampaignCreateRequest,
  WhatsappCampaignUpdateRequest,
  WhatsappCampaignManageGetRequest,
  WhatsappCampaignProcessingGetRequest,
  VerifyRequestSummaryGetRequest
} from 'models/requests';

import {
  WhatsappCampaignCreateResponse,
  WhatsappCampaignListResponse,
  WhatsappCampaignResponse,
  WhatsappCampaignUpdateResponse,
  WhatsappCampaignProcessingResponse,
  WhatsappCampaignStopResponse,
  WhatsappCampaignVerifyRequestSummaryResponse,
  WhatsappAnalyticsResponse,
} from 'models/responses';

import DashboardApi from ".";

const createWhatsappCampaign = async (data: WhatsappCampaignCreateRequest) => DashboardApi.post<WhatsappCampaignCreateResponse, WhatsappCampaignCreateRequest>('whatsapp_campaign/send', data);
const editWhatsappCampaign = async (data: WhatsappCampaignUpdateRequest, id: string) => DashboardApi.put<WhatsappCampaignUpdateResponse, WhatsappCampaignUpdateRequest>(`whatsapp_campaign/${id}`, data);
const getAllWhatsappCampaigns = async (data: WhatsappCampaignManageGetRequest) => DashboardApi.get<WhatsappCampaignListResponse, WhatsappCampaignManageGetRequest>(`whatsapp_campaign`, data);
const getVerifyRequestSummary = async (data: VerifyRequestSummaryGetRequest) => DashboardApi.post<WhatsappCampaignVerifyRequestSummaryResponse, VerifyRequestSummaryGetRequest>(`whatsapp_campaign/whatsapp-recipient-summary`, data);
const cancelWhatsappCampaign = async (id: string) => DashboardApi.post<WhatsappCampaignStopResponse>(`whatsapp_campaign/${id}/cancel`);
const getWhatsappCampaign = async (id: string) => DashboardApi.get<WhatsappCampaignResponse>(`whatsapp_campaign/${id}`);

const getProcessingWhatsappCampaign = (data: WhatsappCampaignProcessingGetRequest) => DashboardApi.get<WhatsappCampaignProcessingResponse, WhatsappCampaignProcessingGetRequest>('whatsapp_campaign/processing', data);

const manageWhatsappCampaignSearch = (key: string) => DashboardApi.get<WhatsappCampaignProcessingResponse>(`whatsapp_campaign/processing/search/${key}`);
const whatsappCampaignReportSearch = (key: string) => DashboardApi.get<WhatsappCampaignProcessingResponse>(`whatsapp_campaign/search/${key}`);
const getWhatsappCampaignMsgLog = async (id: string) => DashboardApi.get<WhatsappCampaignResponse>(`whatsapp_campaign/whatsapp-msg-log/${id}`);

const getWhastappAnalytics = async (id: string) => DashboardApi.get<WhatsappAnalyticsResponse>(`whatsapp_campaign/analytics/${id}`);
const getWhatsappCampaignMsgByStatusLog = async (id: string, status: string) => DashboardApi.get(`whatsapp_campaign/whatsapp-msg-log/${status}/${id}`) ;
export {
  createWhatsappCampaign,
  getAllWhatsappCampaigns,
  editWhatsappCampaign,
  getVerifyRequestSummary,
  cancelWhatsappCampaign,
  getWhatsappCampaign,
  getProcessingWhatsappCampaign,
  manageWhatsappCampaignSearch,
  whatsappCampaignReportSearch,
  getWhatsappCampaignMsgLog,
  getWhastappAnalytics,
  getWhatsappCampaignMsgByStatusLog
};
