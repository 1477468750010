import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, Form, Input, Row, Select} from 'antd';
import {WhatsappCampaign} from "../../../../models";
import {
  listMarketingWhatsappTemplateService, listWhatsappContactService
} from "../../../../services/dashboard/WhatsappService";
import ChatPreviewPage from "../../WhatsappTemplate/WhatsappTemplatePreview";
import {WhatsappCampaignMessageFormModel} from "../../../../models/forms";


export interface WhatsappCampaignMessageTabProps {
  whatsappCampaign?: WhatsappCampaign;
  onClickNext: (WhatsappMessageModel: WhatsappCampaignMessageFormModel) => void;
  onSelectedTemplate?: any;
  onButtonTexts?: any;
}

const WhatsappCampaignMessageTab = (props: WhatsappCampaignMessageTabProps) => {
    const {whatsappCampaign, onClickNext, onSelectedTemplate, onButtonTexts} = props;
    const [messageForm] = Form.useForm();
    const [templates, setTemplates] = useState([{
      template_name: '',
      template_id: '',
      buttons: {
        buttons_type: '',
        button_quick_reply: [{button_text_quick_reply: ''}],
        button_phone_number: [{button_text_phone_number: '', button_phone_number: ''}],
        button_url: [{button_text_url: '', button_url: '', example_url_values: ''}],
      },
    },]);
    const [selectedTemplate, setSelectedTemplate] = useState({
      body_text: '', example_body_text: [], header_text: '', header_format: '',
      footer_text: '', example_header_text: [], media_url: '', buttons: {
        buttons_type: '',
        button_quick_reply: [{button_text_quick_reply: ''}],
        button_phone_number: [{button_text_phone_number: '', button_phone_number: ''}],
        button_url: [{button_text_url: '', button_url: '', example_url_values: ''}]
      }
    });

    const [bodyParameters, setBodyParameters] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);
    const [buttonTextValues, setButtonTextValues] = useState<string[]>([]);
    const [buttonPhoneTextValues, setButtonPhoneTextValues] = useState<string[]>([]);
    const [buttonUrlTextValues, setButtonUrlTextValues] = useState<string[]>([]);
    const [buttonType, setButtonType] = useState('');
    const [actionType, setActionType] = useState('');
    const [personalizeMenuVisibility, setPersonalizeMenuVisibility] = useState({});
    const [parameterValue, setParameterValue] = useState('');
    const [showPersonalizationMenu, setShowPersonalizationMenu] = useState(false);
    const [originatoNnumber, setOriginatorNumber] = useState([{number: '', name: ''}]);


    const PERSONALIZATION_FIELDS = [
      {text: 'First Name', value: '{first_name}'},
      {text: 'Last Name', value: '{last_name}'},
      {text: 'Email', value: '{email}'},
      {text: 'Number', value: '{number}'},
      {text: 'Var 1', value: '{var1}'},
      {text: 'Var 2', value: '{var2}'},
      {text: 'Var 3', value: '{var3}'},
      {text: 'Var 4', value: '{var4}'},
      {text: 'Var 5', value: '{var5}'},
      {text: 'Country', value: '{country}'},
      {text: 'Blacklist', value: '{blacklist}'}];

    const handleParameterChange = (value: any) => {
      setParameterValue(value);
    };

  const fetchPhoneNumber = async () => {
    setLoading(true);
    try {
      const fetchedPhoneNumbers: any = await listWhatsappContactService();
      setOriginatorNumber(fetchedPhoneNumbers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // console.error('Error fetching templates:', error);
    }
  };

    const handlePersonalizeChangeHeader = (value: any) => {
      const newValue = `{${value}}`;
      const currentValue = parameterValue || '';
      const updatedValuex = `${currentValue} ${newValue}`.trim();
      setParameterValue(updatedValuex);
      setShowPersonalizationMenu(false);
      messageForm.setFieldsValue({content: {media: {text_header_title: updatedValuex}}});
    };

    const personalizeMenu = (
      <div className="nav-profile nav-dropdown p-3" style={{height: 250, width: 220}}>
        <h5 className="mb-2">Personalize</h5>
        <Select
          showSearch
          style={{width: '100%'}}
          placeholder="Search"
          optionFilterProp="children"
          onChange={(value) => handlePersonalizeChangeHeader(value)}
          filterOption={(input: any, option: any) =>
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
            option.title.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {PERSONALIZATION_FIELDS.map((e) => (
            <Select.Option key={e.value} value={e.value} title={e.text}>
              {e.text}
            </Select.Option>
          ))}
        </Select>
      </div>
    );


    const handleBodyParameterChange = (key: any, value: any) => {
      setBodyParameters({
        ...bodyParameters,
        [key]: value,
      });
    };

    const handlePersonalizeMenuVisibilityChange = (paramKey: any, visible: any) => {
      setPersonalizeMenuVisibility((prevVisibility) => ({...prevVisibility, [paramKey]: visible}));
    };

    const handlePersonalizeChange = (paramKey: any, value: any) => {
      const newValue = `{${value}}`;
      const updatedValue = bodyParameters[paramKey] ? `${bodyParameters[paramKey]} ${newValue}` : newValue;
      const updatedBodyParameters = {...bodyParameters, [paramKey]: updatedValue};
      setBodyParameters(updatedBodyParameters);
      handlePersonalizeMenuVisibilityChange(paramKey, false);

      // Set the form field value as well
      const currentValue =
        messageForm.getFieldValue(['content', 'body_parameter_values', paramKey]) || '';
      const updatedValuex = `${currentValue} ${newValue}`.trim();
      messageForm.setFieldsValue({
        content: {
          body_parameter_values: {...bodyParameters, [paramKey]: updatedValuex},
        },
      });
    };

    const createPersonalizeMenu = (paramKey: any) => (
      <div className="nav-profile nav-dropdown p-3" style={{height: 250, width: 220}}>
        <h5 className="mb-2">Personalize</h5>
        <Select
          showSearch
          style={{width: '100%'}}
          placeholder="Search"
          optionFilterProp="children"
          onChange={(value) => handlePersonalizeChange(paramKey, value)}
          filterOption={(input: any, option: any) =>
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
            option.title.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {PERSONALIZATION_FIELDS.map((e) => (
            <Select.Option key={e.value} value={e.value} title={e.text}>
              {e.text}
            </Select.Option>
          ))}
        </Select>
      </div>
    );


    // @ts-ignore
    const isPersonalizeMenuVisible = (paramKey: any) => personalizeMenuVisibility[paramKey] || false;


    const chatContainerStyle: React.CSSProperties = {
      margin: '20px auto',
      padding: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      background: `url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png')`, // Replace with your image URL
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
    };

    const handleTemplateChange = (templateId: any) => {
      setButtonType('');
      setActionType('');
      setBodyParameters({});
      const selected = templates.find((template) => template.template_id === templateId);

      if (selected) {
        // @ts-ignore
        setSelectedTemplate(selected);
        if (onSelectedTemplate) {
          onSelectedTemplate(selected);
        }
        const quickReplyButtons = selected?.buttons?.button_quick_reply || [];
        const phoneButtons = selected?.buttons?.button_phone_number || [];
        const urlButtons = selected?.buttons?.button_url || [];

        const quickReplyTextValues = quickReplyButtons.map((button) => button.button_text_quick_reply || '');
        const phoneTextValues = phoneButtons.map((button) => button.button_text_phone_number || '');
        const urlTextValues = urlButtons.map((button) => button.button_text_url || '');

        setButtonTextValues(quickReplyTextValues);
        setButtonPhoneTextValues(phoneTextValues);
        setButtonUrlTextValues(urlTextValues);

        if  (onButtonTexts) {
          onButtonTexts({quickReplyTextValues, phoneTextValues, urlTextValues});
        }

        if (selected?.buttons?.buttons_type.includes("QUICK_REPLY")) {
          setButtonType('QUICK_REPLY');
        } else if (selected?.buttons?.buttons_type.length === 0) {
          setButtonType('');
        } else if (selected?.buttons?.buttons_type.includes("PHONE_NUMBER")) {
          setActionType('PHONE_NUMBER');
          setButtonType('CALL_TO_ACTION');
        } else if (selected?.buttons?.buttons_type.includes("URL")) {
          setActionType('URL');
          setButtonType('CALL_TO_ACTION');
        } else if (selected?.buttons?.buttons_type.includes("PHONE_NUMBER_AND_URL")) {
          setActionType('PHONE_NUMBER_AND_URL');
          setButtonType('CALL_TO_ACTION');
        }
      }
    };

    const onSubmitMessageForm = (values: WhatsappCampaignMessageFormModel) => {

      const WhatsappContent = {
        campaign_name: values.campaign_name || '',
        originator: values.originator || '',
        content: {
          template_id: values.content?.template_id || '',
          body_parameter_values: bodyParameters || selectedTemplate.example_body_text,
          // eslint-disable-next-line no-nested-ternary
          media: selectedTemplate.header_format
            ? (selectedTemplate.header_format === 'TEXT' && selectedTemplate.example_header_text === [])
              ? undefined // Set media to null if TEXT format and no example_header_text
              : {
                media_type: values.content?.media?.media_type || null,
                media_url: values.content?.media?.media_url || selectedTemplate.media_url,
                text_header_title:
                  selectedTemplate.header_format === 'TEXT'
                    ? values.content?.media?.text_header_title || null
                    : '',
                media_caption: values.content?.media?.media_caption || '',
                location: selectedTemplate.header_format === 'LOCATION'
                  ? {
                    longitude: values.content?.media?.location?.longitude || '',
                    latitude: values.content?.media?.location?.latitude || '',
                    name: values.content?.media?.location?.name || '',
                    address: values.content?.media?.location?.address || ''
                  } : undefined
              }
            : undefined,
          buttons: values.content?.buttons,
        },
      };

      let buttonIndex: number = -1;
      selectedTemplate?.buttons?.button_url?.forEach((button, index) => {
        if (button && button.example_url_values) {
          buttonIndex += 1;
          if (values.content?.buttons?.actions[index]?.action_payload) {
            WhatsappContent.content.buttons!.actions[index] = {
              action_index: String(buttonIndex),
              action_type: 'URL',
              action_payload: values.content?.buttons?.actions[index]?.action_payload
            };
          }
        } else {
          buttonIndex += 1;
        }
      });

      // Check the header format and set media_type accordingly
      if (selectedTemplate.header_format === 'IMAGE') {
        WhatsappContent.content.media!.media_type = 'image';
      } else if (selectedTemplate.header_format === 'VIDEO') {
        WhatsappContent.content.media!.media_type = 'video';
      } else if (selectedTemplate.header_format === 'DOCUMENT') {
        WhatsappContent.content.media!.media_type = 'document';
      } else if (selectedTemplate.header_format === 'AUDIO') {
        WhatsappContent.content.media!.media_type = 'audio';
      } else if (selectedTemplate.header_format === 'LOCATION') {
        WhatsappContent.content.media!.media_type = 'location';
      } else if (
        selectedTemplate.header_format === 'TEXT' &&
        Object.keys(selectedTemplate.example_header_text).length === 0
      ) {
        WhatsappContent.content.media = undefined;
      } else if (selectedTemplate.header_format === 'TEXT') {
        WhatsappContent.content.media!.media_type = 'text';
        WhatsappContent.content.media!.text_header_title =
          selectedTemplate.example_header_text &&
          values.content?.media?.text_header_title
            ? values.content?.media?.text_header_title
            : null;
      }
      // @ts-ignore
      onClickNext(WhatsappContent);
    };


    const fetchTemplates = async () => {
      setLoading(true);
      try {
        const fetchedTemplates: any = await listMarketingWhatsappTemplateService();
        if (fetchedTemplates){
          setTemplates(fetchedTemplates);
        }
        else{
          setTemplates([]);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.error('Error fetching templates:', error);
      }
    };

    useEffect(() => {
      if (whatsappCampaign) {
        messageForm.setFieldsValue({
          content: {
            template_id: whatsappCampaign.content?.template_id || '',
            body_parameter_values: bodyParameters || '',
            media: {
              media_type: whatsappCampaign.content?.media?.media_type || '',
              media_url: whatsappCampaign.content?.media?.media_url || '',
              text_header_title: whatsappCampaign.content.media?.text_header_title || '',
              media_caption: whatsappCampaign.content?.media?.media_caption || '',
              location: {
                longitude: whatsappCampaign.content?.media?.location?.longitude || '',
                latitude: whatsappCampaign.content?.media?.location?.latitude || '',
                name: whatsappCampaign.content?.media?.location?.name || '',
                address: whatsappCampaign.content?.media?.location?.address || ''
              }
            },
          }
        });
      }
      fetchTemplates();
      fetchPhoneNumber();
    }, [whatsappCampaign]);


    return (

      <Form
        layout="vertical"
        size="small"
        form={messageForm}
        onFinish={onSubmitMessageForm}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={1} lg={1}/>
          <Col xs={24} sm={24} md={15} lg={15}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label="Whatsapp Campaign Name"
                  name="campaign_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter campaign name!',
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item label="Originator" name='originator' rules={[
                  {
                    required: true,
                    message: 'Please select your originator!',
                  },
                ]} labelCol={{span: 7}}>
                  <Select loading={loading} placeholder="Choose your Originator" showSearch filterOption={(input: any, option: any) =>
                    (option?.key && option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
                    (option?.title && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  }
                          // onChange={(value: string) => fetchTemplates(value)}
                  >
                    {originatoNnumber.map((originatorNumber, index) => (
                      <Select.Option key={originatorNumber.number} value={originatorNumber.number}>
                        {originatorNumber.number}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  className="text-left">

                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label="Choose your template" name={['content', 'template_id']} labelCol={{span: 7}} rules={[
                      {
                        required: true,
                        message: 'Please select your template!',
                      },
                    ]}>
                      <Select placeholder="Choose your template" onChange={handleTemplateChange} showSearch
                              filterOption={(input: any, option: any) =>
                                (option?.key && option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
                                (option?.title && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                              }>
                        {templates.length === 0 ? (
                          <Select.Option value="addTemplate"><a href="/whatsapp/whatsapp-template">Create New
                            template</a></Select.Option>
                        ) : (
                          templates.map((template, index) => (
                            <Select.Option key={template.template_id} value={template.template_id}>
                              {template.template_name}
                            </Select.Option>

                          ))
                        )}
                      </Select>
                    </Form.Item>
                  </Col>

                  {selectedTemplate && (
                    <div>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {selectedTemplate.header_format && selectedTemplate.header_format !== 'TEXT' && selectedTemplate.header_format !== 'LOCATION' && (
                          <Form.Item label="Media URL" name={['content', 'media', 'media_url']} labelCol={{span: 7}} rules={[
                            {
                              required: true,
                              message: 'Please enter media url!',
                            },
                          ]}>
                            <Input/>
                          </Form.Item>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {selectedTemplate.header_format && selectedTemplate.header_format === 'TEXT' && Object.keys(selectedTemplate.example_header_text).length > 0 && (
                          <Form.Item
                            label="Header Parameters"
                            name={['content', 'media', 'text_header_title']}
                            labelCol={{span: 7}}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Header text!',
                              },
                            ]}
                          >
                            <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                              <Input
                                value={parameterValue}
                                onChange={(e) => handleParameterChange(e.target.value)}
                              />
                              <div className="text-right">
                                <Dropdown
                                  overlay={personalizeMenu}
                                  onVisibleChange={() => setShowPersonalizationMenu(!showPersonalizationMenu)}
                                  visible={showPersonalizationMenu}
                                  placement="topRight"
                                >
                                  <Button type="link">
                                    Personalize
                                  </Button>
                                </Dropdown>
                              </div>
                            </div>
                          </Form.Item>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {selectedTemplate.header_format === 'LOCATION' && (
                          <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Form.Item label="Longitude" name={['content', 'media', 'location', 'longitude']}
                                         labelCol={{span: 7}} rules={[
                                {
                                  required: true,
                                  message: 'Please enter longitude!',
                                },
                              ]}>
                                <Input/>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Form.Item label="Latitude" name={['content', 'media', 'location', 'latitude']}
                                         labelCol={{span: 7}} rules={[
                                {
                                  required: true,
                                  message: 'Please enter latitude!',
                                },
                              ]}>
                                <Input/>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Form.Item label="Name" name={['content', 'media', 'location', 'name']}
                                         labelCol={{span: 7}}>
                                <Input/>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Form.Item label="Address" name={['content', 'media', 'location', 'address']}
                                         labelCol={{span: 7}}>
                                <Input/>
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {selectedTemplate.body_text && Object.keys(selectedTemplate.example_body_text).length > 0 && (
                          <Form.Item
                            label="Body Parameters"
                            name={['content', 'body_parameter_values']}
                            labelCol={{span: 7}}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter body parameter values!',
                              },
                            ]}
                          >
                            <div>
                              {Object.keys(selectedTemplate.example_body_text).map((key, index) => (
                                <div key={key} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                                  <span style={{marginRight: '10px'}}>{`${key}:`}</span>
                                  <Input
                                    value={bodyParameters[key] || ''}
                                    onChange={(e) => handleBodyParameterChange(key, e.target.value)}
                                  />
                                  <div className="text-right">
                                    <Dropdown
                                      overlay={createPersonalizeMenu(key)}
                                      onVisibleChange={(visible) => handlePersonalizeMenuVisibilityChange(key, visible)}
                                      visible={isPersonalizeMenuVisible(key)}
                                      placement="topRight"
                                    >
                                      <Button type="link">
                                        Personalize
                                      </Button>
                                    </Dropdown>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Form.Item>
                        )}
                      </Col>
                      {selectedTemplate.buttons ? (
                          <Col xs={24} sm={24} md={24} lg={24}>
                            {(selectedTemplate.buttons?.buttons_type?.length > 0) && (selectedTemplate.buttons?.buttons_type?.includes('URL') && selectedTemplate.buttons?.button_url
                            ) && (
                              <Form.Item /* eslint-disable react/no-array-index-key */>
                                {selectedTemplate.buttons?.button_url?.map((button, index) => (
                                  <div key={index}>
                                    {button.example_url_values && (
                                      <Form.Item label="Button Dynamic URL"
                                                 name={['content', 'buttons', 'actions', index, 'action_payload']}
                                                 labelCol={{span: 7}}
                                                 rules={[
                                                   {
                                                     required: true,
                                                     message: 'Please enter Buttons Dynamic URL!',
                                                   },
                                                 ]}>
                                        <Input/>
                                      </Form.Item>
                                    )}
                                  </div>
                                ))}
                              </Form.Item>
                            )}
                          </Col>)
                        : []}

                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>

          </Col>
          <Col lg={1}/>
          <Col xs={8} sm={6} md={6} style={chatContainerStyle}>
            <ChatPreviewPage headerText={selectedTemplate.header_text} bodyText={selectedTemplate.body_text}
                             footerText={selectedTemplate.footer_text}
                             selectedMediaType={selectedTemplate.header_format}
                             buttonType={buttonType}
                             actionType={actionType}
                             actionButtonsQuickReply={buttonTextValues}
                             actionButtonsPhoneNumber={buttonPhoneTextValues}
                             actionButtonsUrl={buttonUrlTextValues}/>
          </Col>
          <Col xs={24} sm={24} md={1} lg={1}/>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={23}>
            <div className="text-right"
                 style={{marginTop: '4px', ...(window.innerWidth >= 400 && {marginTop: '100px'})} as any}>
              <Button type="primary" className="ml-2" htmlType="submit" loading={loading}>
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Form>)
      ;
  }
;

WhatsappCampaignMessageTab.defaultProps = {
  whatsappCampaign: undefined,
  onSelectedTemplate: undefined,
  onButtonTexts: undefined,
};

export default WhatsappCampaignMessageTab;

