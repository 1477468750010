import React, { useEffect, useState } from 'react';
import { Card, notification } from 'antd';
import {WhatsappMetaWabaLists, WhatsappTemplateDetails} from "models";
import {
  listWhatsappTemplateService,
  deleteWhatsappMetaTemplateService, listWhatsappMetaWabaIdService, listWhatsappMetaTemplateService
} from 'services/dashboard/WhatsappService';
import WhatsappTemplateList from 'components/whatsapp-meta/WhatsappTemplate/WhatsappTemplateList';
import WhatsappMetaTemplateForm from "../../../components/whatsapp-meta/WhatsappTemplate/WhatsappTemplateForm";

const WhatsappTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [whatsapptemplate, setWhatsappTemplateList] = useState<WhatsappTemplateDetails[]>([]);
  const [showWhatsappTemplateForm, setShowWhatsappTemplateForm] = useState(false);
  const [whatsappTemplateToEdit, setWhatsappTemplateToEdit] = useState<WhatsappTemplateDetails | undefined>();
  const [wabaIds, setWabaIds] = useState<WhatsappMetaWabaLists>();
  const fetchWhatsappTemplateList = async (wabaId: string|undefined) => {
    setLoading(true);
    try {
      let response;
      if(wabaId){
         response = await listWhatsappMetaTemplateService(wabaId);
      }
      else{
         response = await listWhatsappTemplateService();
      }
      setWhatsappTemplateList(response);
      setLoading(false);
    } catch (error) {
      setWhatsappTemplateList([]);
      setLoading(false);
    }
  };

  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };
  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };

  const openWhatsappTemplateForm = (_isEdit?: boolean, template?: WhatsappTemplateDetails) => {
    setWhatsappTemplateToEdit(template);
    setShowWhatsappTemplateForm(true);
  };
  const onCloseForm = async () => {
    setShowWhatsappTemplateForm(false);
    setWhatsappTemplateToEdit(undefined);
    await fetchWhatsappTemplateList(undefined);
  };

  const onSuccessDeleteWhatsappTemplate = (id: number) => {
    showSuccessNotification('Whatsapp Template deleted successfully');
    setLoading(false);
    const newWhatsappTemplate = whatsapptemplate.filter(c => c.id !== id);
    setWhatsappTemplateList(newWhatsappTemplate);
  };

  const onErrorDeleteWhatsappTemplate = (showNotification?: boolean) => {
    if (showNotification) {
      showErrorNotification('Failed to delete Whatsapp Template');
    }
    setLoading(false);
  };

  const deleteWhatsappTemplate = async (tempId: number) => {
    setLoading(true);
    try {
      const response = await deleteWhatsappMetaTemplateService({ id: tempId });
      if (!response.deleted) {
        onErrorDeleteWhatsappTemplate(true);
      } else {
        onSuccessDeleteWhatsappTemplate(tempId);
      }
    } catch (error) {
      onErrorDeleteWhatsappTemplate(true);
    }
  };

  const onClickDeleteWhatsappTemplate = async (id: number) => {
    await deleteWhatsappTemplate(id);
  };

  const fetchWabaId = async () => {
    setLoading(true);
    try {
      const fetchedWabaIds: any = await listWhatsappMetaWabaIdService();
      setWabaIds(fetchedWabaIds);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // console.error('Error fetching waba id:', error);
    }
  };

  useEffect(() => {
    fetchWhatsappTemplateList(undefined);
    fetchWabaId();
  }, []);

  return (
    <Card className="mt-4">
      <WhatsappTemplateList
        loading={loading}
        whatsapptemplate={whatsapptemplate}
        openWhatsappTemplateForm={openWhatsappTemplateForm}
        onClickDeleteWhatsappTemplate={onClickDeleteWhatsappTemplate}
        onClickRefresh={fetchWhatsappTemplateList}
        wabaIds={wabaIds}
      />
      <WhatsappMetaTemplateForm loadingTemplate={loading} showWhatsappTemplateForm={showWhatsappTemplateForm} editItem={whatsappTemplateToEdit} onClose={onCloseForm} fetchWabaId={fetchWabaId} wabaIds={wabaIds}/>
    </Card>
  );
};

export default WhatsappTemplate;
