/* eslint-disable no-param-reassign */
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { notification } from 'antd';
import { ApiErrorResponse, ErrorResponse, ValidationErrorResponse } from 'models/responses';

const ExternalMessageClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Config

// API Request interceptor
ExternalMessageClient.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }

  return config;
}, error => {
  // Do something with request error here
  notification.error({
    message: 'Error'
  });
  Promise.reject(error);
});

// API respone interceptor
ExternalMessageClient.interceptors.response.use((response) => response.data, (error) => {
  let validationError: ValidationErrorResponse | undefined;
  let commonError: ErrorResponse | undefined;
  let message = 'Failed to send message';
  const handledError = false;
  let description = '';
  commonError = error.response.data;
  if (error?.response?.data) {
    message = error.response.data.detail?.message ?? error.response.data.detail;
  }
  if (error.response.status === 400) {
    message = message ?? 'Bad Request';
  }
  if (error.response.status === 404) {
    message = message ?? 'Not Found';
  }
  if (error.response.status === 422) {
    validationError = error.response.data;
    commonError = undefined;
    message = 'Validation Error';
    description = validationError?.detail?.map(x => x.msg)?.join("/n") ?? '';
  }
  if (error.response.status === 402) {
    validationError = error.response.data;
    commonError = undefined;
    message = validationError?.detail?.map(x => x.message)?.join("/n") ?? '';
  }
  if (error.response.status === 500) {
    message = message ?? 'Internal Server Error';
  }
  if (error.response.status === 508 || error.response.status === 504) {
    message = 'Time Out';
  }


  const response: ApiErrorResponse = {
    status: error.response.status,
    code: commonError?.detail?.code,
    error: commonError,
    message,
    handledError,
    validationError,
    description
  };

  return Promise.reject(response);
});

export default ExternalMessageClient;
