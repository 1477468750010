import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const GettingStarted = lazy(() => import("./GettingStarted"));
const Settings = lazy(() => import("./Settings"));
const ApiReport = lazy(() => import("./ApiReport"));
const ViberPricing = lazy (() => import("./Pricing"));

const ViberPages = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/viber/getting-started" replace />} />
    <Route path='getting-started' element={<GettingStarted />} />
    <Route path='settings' element={<Settings />} />
    <Route path='api-report' element={<ApiReport />} />
    <Route path='pricing' element={<ViberPricing />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default ViberPages;
