import React, {useState} from 'react';
import {DownloadOutlined, ReloadOutlined} from "@ant-design/icons";
import {ColumnsType} from 'antd/es/table';
import {
  WhatsappCampaignExportModel,
  WhatsappCampaignListngModel, WhatsappMediaTemplate
} from "models";
import TableList from 'components/shared/TableList';

export interface WhatsappCampaignReportProps {
  whatsappCampaigns: WhatsappCampaignListngModel[];
  loading?: boolean;
  onError: (msg: string) => void;
  onPaginate?: (page: number, pageSize: number, searchText?: string) => void;
  onExportLog?: (id: string) => void;
  total: number;
  onRefreshLog?: () => void;
}

const WhatsappCampaignReportList = (props: WhatsappCampaignReportProps) => {
  const {whatsappCampaigns, loading, onError, onPaginate, total, onExportLog, onRefreshLog} = props;
  // const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const downloadReport = async (item: WhatsappCampaignListngModel) => {
    if (item.campaign_name) {
      onExportLog?.(item.id); // TODO : Handle here
    } else {
      onError('Failed to download invoice.');
    }
  };

  const searchRefresh = () => {
    onRefreshLog?.(); // TODO : Handle here

  };
  const actions = [
    {
      renderToolTip: (item: WhatsappCampaignListngModel) => 'Download',
      renderIcon: (item: WhatsappCampaignListngModel) => item.status === 'sent' ? <DownloadOutlined/> : "",
      onClick: async (item: WhatsappCampaignListngModel) => {
        await downloadReport(item);
      }
    }
  ];

  const searchPredicate = (item: WhatsappCampaignListngModel, text: string) =>
    item.campaign_name.toLowerCase().includes(text)
    || item.id.toString().toLowerCase().includes(text)
    || `${item.status}`.toLowerCase().includes(text);


  const tableColumns: ColumnsType<WhatsappCampaignListngModel> = [
    {
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Campaign date(UTC)',
      dataIndex: 'created_at',
      render: (_v, record) => {
        const campaignProcessedDatetime = (record.scheduled_at) ? record.scheduled_at : record.created_at;
        const arr = (campaignProcessedDatetime).toString().split(/-|\s|\+|:|\./);// split string and create array.
        const dateStr = `${arr[0]}${"-"}${arr[1]}${"-"}${arr[2]}${":"}${arr[3]}${":"}${arr[4]}`;
        return dateStr.replace("T", "  ");
      },
    },
    {
      title: 'Name',
      dataIndex: 'campaign_name',
    },
    {
      title: 'Total Recipients',
      dataIndex: 'total_recipients_count',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      render: (content: WhatsappMediaTemplate) => {
        if (content) {
          /* eslint-disable camelcase */
          const {template_id, body_parameter_values, media} = content;
          const templateId = template_id || 'N/A';
          let bodyParams;
          let mediaType;
          if (body_parameter_values) {
            bodyParams = body_parameter_values
              ? Object.entries(body_parameter_values)
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ')
              : 'N/A';

          }
          if (media) {
            mediaType = media.media_type || 'N/A';
          }

          return (
            <div>
              <div>Template ID: {templateId}</div>
              {bodyParams && (
                <div>Body Parameters: {bodyParams}</div>)}
              {mediaType && (
                <div>Media Type: {mediaType}</div>)}
            </div>
          );
        }
        return 'N/A';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    // {
    //   title: 'Log',
    //   dataIndex: 'log',
    // }
  ];

  const WHATSAPP_CAMPAIGN_CSV_HEADERS = ["id", "campaign_name", "scheduled_at", "status", "total_recipients_count", "template_id", "media_type", "body_parameter_values"];
  const exportMapper = (contact: WhatsappCampaignListngModel): WhatsappCampaignExportModel => {
    const formattedBodyParameterValues = Object.entries(contact.content?.body_parameter_values || {})
      .map(([key, value]) => `${key}:${value}`)
      .join(', ');

    return {
      id: contact.id,
      campaign_name: contact.campaign_name,
      scheduled_at: contact.scheduled_at,
      status: contact.status,
      total_recipients_count: contact.total_recipients_count,
      template_id: contact.content?.template_id || "",
      media_type: contact.content?.media?.media_type || "",
      body_parameter_values: formattedBodyParameterValues
    };
  };

  const controls = [

    {
      title: 'Refresh',
      icon: <ReloadOutlined/>,
      onClick: () => {

        searchRefresh();

      }
    },
    {
      title: 'Download All Reports',
      icon: <DownloadOutlined/>,
      onClick: () => {
      },
      isExport: true,
      exportFileName: 'Whatsapp Campaign Reports',
      exportMapper,
      headers: WHATSAPP_CAMPAIGN_CSV_HEADERS
    }


  ];

  const onSearch = (value: string) => {
    setSearchText(value);
    onPaginate?.(1, pageSize, value);
  };
  const onChangePage = (newPage: number, newSize: number) => {
    if (pageSize !== newSize) {
      setPageSize(newSize);
      setPage(1);
      onPaginate?.(1, newSize, searchText);
    } else {
      setPage(newPage);
      onPaginate?.(newPage, newSize, searchText);
    }
  };

  const getPaginationOption = () => ({
    onChangePage,
    pageSize,
    currentPage: page,
    total
  });

  return (
    <div>
      <TableList<WhatsappCampaignListngModel> items={whatsappCampaigns} columns={tableColumns} loading={loading}
                                              rowKey={(record: WhatsappCampaignListngModel) => record.id} showSearch
                                              controls={controls} onSearch={onSearch}
                                              searchPredicate={searchPredicate} hideRowSelection actions={actions}
                                              serverSidePaginationOption={getPaginationOption()}/>
    </div>
  );
};

WhatsappCampaignReportList.defaultProps = {
  loading: false,
  onPaginate: undefined,
  onExportLog: undefined,
  onRefreshLog: undefined
};

export default WhatsappCampaignReportList;
