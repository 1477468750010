import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  Edge,
  MiniMap,
  ReactFlowInstance,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow
} from 'reactflow';
import 'reactflow/dist/style.css';
import {Button, Dropdown, Menu, notification} from "antd";
import {PlayCircleTwoTone, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import Tooltip from 'antd/es/tooltip';
import TextMessageNode from "./TextMessageNode";
import TextAndButtonNode from "./TextAndButtonNode";
import ButtonEdge from "./ButtonEdge";
import EntryNode from "./EntryNode";
import {
  deployChatFlowService,
  getChatflowService,
  saveChatflowService
} from "../../services/dashboard/ChatbotService";
import useFormErrors from "../../hooks/useFormErrors";


const nodeTypes = {entryMessage: EntryNode, TEXT: TextMessageNode, INTERACTIVE_BUTTON: TextAndButtonNode};
const edgeTypes = {buttonEdge: ButtonEdge};
const getNodeId = () => `${+new Date()}`;

const Canvas = () => {
  const {name, id} = useParams<{ name: string, id: string }>();
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef<string>('');
  const connectingSourceHandle = useRef<null | string>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>([]);
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance | null>(null);
  const {setViewport, screenToFlowPosition} = useReactFlow();
  const [visible, setVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({x: 0, y: 0});
  const [isEntryNodeConnected, setIsEntryNodeConnected] = useState(true);
  const [shouldFitFlow, setShouldFitFlow] = useState<boolean | undefined>(true);
  const {formErrors} = useFormErrors();
  let hasErrors = false;


  const onSuccess = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };

  const onError = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };

   const addEntryNode = ()=> {
     const entryNode = {
      id: "1",
      type: 'entryMessage',
      data: {msg_type: 'entryMessage'},
      position: screenToFlowPosition({
        x: (window.innerWidth / 2 - 800),
        y: (window.innerHeight / 2 - 300),
      }),
      // origin: [0.5, 0.0],
    };
      // @ts-ignore
    setNodes((nds) => nds.concat(entryNode));
  };

  const getChatFlowForUser = async () => {
    try {
      if (id) {
        const flow = await getChatflowService(id);
        if (flow !== null) {
          if ((flow.graph.nodes).length === 0) {
            setIsEntryNodeConnected(false);
            setShouldFitFlow(false);
            addEntryNode();
          } else if ((flow.graph.nodes).length < 5) {
            setShouldFitFlow(false);
          }
          if (flow) {
            const {x = 0, y = 0, zoom = 1} = flow.graph.viewport;
            setNodes(flow.graph.nodes || []);
            setEdges(flow.graph.edges || []);
            setViewport({x, y, zoom});
          } else {
            setIsEntryNodeConnected(false);
            setShouldFitFlow(false);
          }
        }
      }
    } catch (error: any) {
      if (error.code !== "CHATBOT_NOT_EXISTS") {
        onError(error.message);
      } else {
        setIsEntryNodeConnected(false);
        setShouldFitFlow(false);
        addEntryNode();
      }
    }
  };

  useEffect(() => {
    getChatFlowForUser();
  }, []);

  const onConnect = useCallback(
    (params) => {
      const newEdge = {
        ...params,
        type: 'buttonEdge',
        id: params.sourceHandle ? `${params.sourceHandle}-${params.target}` : `${params.source}-${params.target}`
      };
      connectingNodeId.current = '';
      connectingSourceHandle.current = null;
      setEdges((eds) => addEdge(newEdge, eds));
    },
    [setEdges],
  );
  const addInitialNode = (type: string) => {
    const newNodeId = getNodeId();
    const newNode = {
      id: newNodeId,
      type,
      data: {msg_type: type},
      position: screenToFlowPosition({
        x: (window.innerWidth / 2 - 150),
        y: (window.innerHeight / 2 - 200),
      }),
      origin: [0.5, 0.0],
    };
    // @ts-ignore
    setNodes((nds) => nds.concat(newNode));
    // @ts-ignore
    setEdges((eds) => eds.concat({id: `1-${newNodeId}`, source: "1", target: newNodeId, type: 'buttonEdge'}));
  };
    useEffect(() => {
      if(edges.find(e =>e.source==="1")){
       setIsEntryNodeConnected(true);
      }
      else{
        setIsEntryNodeConnected(false);
      }
    }, [nodes, edges]);
  const addNode = (type: string, xPos = null, yPos = null) => {
    const newNodeId = getNodeId();
    // @ts-ignore
    const newNode = {
      id: newNodeId,
      type,
      data: {msg_type: type},
      position: screenToFlowPosition({
          x: xPos ? xPos + 150 : (window.innerWidth - 300),
          y: yPos ? yPos - 100 : (window.innerHeight / 2),
        }
      ),
      origin: [0.5, 0.0],
    };
    // @ts-ignore
    setNodes((nds) => nds.concat(newNode));
    return newNodeId;

  };
  const handleDroppableMenuClick = ({key}: any) => {
    // @ts-ignore
    const newNodeID = addNode(key, menuPosition.x, menuPosition.y);
    const source = connectingNodeId.current;
    const sourceHandle = connectingSourceHandle.current;
    const target = newNodeID;
    const edgeId = sourceHandle ? `${sourceHandle}-${target}` : `${source}-${target}`;

    const newEdge: Edge = {
      id: edgeId,
      type: 'buttonEdge',
      source,
      target,
    };
    if (sourceHandle) {
      newEdge.sourceHandle = sourceHandle;
    }

// @ts-ignore
    setEdges((eds) => eds.concat(newEdge));
    setMenuVisible(false);
  };
  const onConnectStart = useCallback((_, {nodeId, handleId}) => {
    connectingNodeId.current = nodeId;
    connectingSourceHandle.current = handleId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;
      const targetIsPane = event.target.classList.contains('react-flow__pane');
      if (targetIsPane) {
        setMenuPosition({x: event.clientX, y: event.clientY});
        setMenuVisible(true);
      }
    },
    [screenToFlowPosition],
  );

  const validateFlow = (flow: any) => {
    let errorMessage = '';
    if( flow.nodes.length === 1){
      return 'Please add some nodes to your flow';
    }
    if(!isEntryNodeConnected){
      return 'Please connect the whatsapp node to one of your nodes to mark the start of the flow!';
    }

    formErrors.forEach((error: boolean, nodeId: string) => {
      if (error) {
        hasErrors = true;
      }
    });
    if (hasErrors) {
      errorMessage = 'Please fill in all mandatory fields!';
    }
    const sourceHandles: string[]  = [];
    flow.nodes.forEach((node:any) =>{
      if(node.type === 'INTERACTIVE_BUTTON'){
        node.data.payload_info.buttons?.forEach((button:any)=>{
          sourceHandles.push(button.id);
        });
      }
    });
    if (sourceHandles.length > 0) {
      flow.edges.forEach((edge: any) => {
        if (Object.hasOwn(edge, 'sourceHandle') && edge.sourceHandle) {
          const handleIndex = sourceHandles.indexOf(edge.sourceHandle);
          if (handleIndex !== -1) {
            sourceHandles.splice(handleIndex, 1);
          }
        }
      });
    }
     if (sourceHandles.length > 0) {
        if (hasErrors) {
          errorMessage = 'Please fill in all mandatory fields and connect created buttons to some target!';
        } else {
          errorMessage = 'Please connect created buttons to some target!';
        }
      }
    return errorMessage;
  };

  const onSave = async () => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      const errorMessage = validateFlow(flow);
      const jsonFlow = JSON.stringify(flow);
      if (errorMessage !== '') {
        onError(errorMessage);
      } else if (id) {
        try {
          const response = await saveChatflowService(id, jsonFlow);
          if (response) {
            onSuccess('Chatflow has been saved!');
          } else {
            onError('Failed to save chatflow!');
          }
        } catch (error: any) {
          onError(error.message);
        }
      }
    }
  };

const onDeploy = async () => {
  try {
    // @ts-ignore
    const response = await deployChatFlowService(id);
    if (response) {
      onSuccess('Successfully deployed the chatflow!');
    }
  } catch (error: any) {
    onError(error.message);
  }
};


const handleMenuClick = (e: { key: string; }) => {
  if (!isEntryNodeConnected) {
    addInitialNode(e.key);
  } else {
    addNode(e.key);
  }
  setVisible(false);
};

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="TEXT">Text Message</Menu.Item>
    <Menu.Item key="INTERACTIVE_BUTTON">Interactive Button Message</Menu.Item>
  </Menu>
);

// @ts-ignore
return (
  <>
    <div style={{padding: '5px', marginBottom: '5px'}}>
      <h3 className="mb-0 mr-3 font-weight-semibold">{name}</h3>
    </div>
    <div className="wrapper" ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onInit={setRfInstance}
        fitView={shouldFitFlow}
        fitViewOptions={{padding: (shouldFitFlow ? 0 : 2)}}
        nodeOrigin={[0.5, 0]}
      >
        <div className="flow-custom-controls">
          <Tooltip placement="left" title="Add a node">
            <Dropdown overlay={menu} trigger={['click']} visible={visible} onVisibleChange={setVisible}>
              <Button type="primary" shape="circle" style={{marginBottom: 5}} icon={<PlusOutlined/>}
                      onClick={() => setVisible(!visible)}/>
            </Dropdown>
          </Tooltip>
          <Tooltip placement="left" title="Save the flow">
            <Button type="primary" shape="circle" style={{marginBottom: 5}} icon={<SaveOutlined/>} onClick={onSave}/>
          </Tooltip>
          <Tooltip placement="left" title="Deploy the flow">
            <Button type="primary" shape="circle" icon={<PlayCircleTwoTone/>} onClick={onDeploy}/>
          </Tooltip>
        </div>
        {menuVisible && (
          <Menu
            style={{position: 'fixed', top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, zIndex: 6}}
            onClick={handleDroppableMenuClick}
          >
            <Menu.Item key="TEXT">Text Message</Menu.Item>
            <Menu.Item key="INTERACTIVE_BUTTON">Interactive Button Message</Menu.Item>
          </Menu>
        )}
        <div className="flow-controls">
          <Controls position='top-right'/>
        </div>
        <MiniMap/>
        <Background gap={12} size={1}/>
      </ReactFlow>
    </div>
  </>
);
}
;

const WhatsappConversationFlow = () => (

  <div style={{width: '100%', height: '100vh'}}>

    <ReactFlowProvider>
      < Canvas/>
    </ReactFlowProvider>


  </div>
);

export default WhatsappConversationFlow;
