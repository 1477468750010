import React, {useState} from 'react';
import { Card, Col, Row, Tabs} from 'antd';
import CampaignCarouselCard from "../CampaignCarouselCard";
import {CarouselCard, WhatsappCarousel} from "../../../../models";


export interface CampaignDynamicCarouselComponentProps {
  template: any;
  onFinishedCard: (carouselComponent: WhatsappCarousel, isAllSaved: boolean) => void;
  inputDirection?: any;
  selectedOriginator: any;
}

const CampaignDynamicCarouselComponent = (props: CampaignDynamicCarouselComponentProps) => {

  const {template, onFinishedCard, inputDirection, selectedOriginator} = props;
  const [activeKey, setActiveKey] = useState<string>('0');
  const [components, setComponents] = useState<any>([]);
  // const [isAllSaved, setIsAllSaved] = useState<boolean>(false);

  const handleCardDataChange = (newCardData: CarouselCard) => {
    components[parseInt(activeKey, 10)]=newCardData;
    setComponents(components);
    setActiveKey(parseInt(activeKey, 10)+1===template.carousel?.components?.length? activeKey:String(parseInt(activeKey, 10)+1));
  };

  const mergeCards = ()=>{
    onFinishedCard({cards:components}, false);
    // setIsAllSaved(true);
  };

  const onClickPrev = () => {
    setActiveKey(String(parseInt(activeKey, 10)-1));
  };

  const tabPanes = Array.from({length: template.carousel?.components?.length}, (_, index) => (
    <Tabs.TabPane tab={`Card ${index + 1}`} key={index.toString()} >
      <Card>
        <CampaignCarouselCard templateCardComponent={template.carousel?.components[index]} onFinishCard={handleCardDataChange} onClickPrev={onClickPrev}
                              mergeCards={mergeCards} activeKey={parseInt(activeKey, 10)}
                              lastcard={parseInt(activeKey, 10)+1===template.carousel?.components?.length} inputDirection={inputDirection}
                              selectedOriginator={selectedOriginator}/>
      </Card>
    </Tabs.TabPane>
  ));


  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24}>
        <Tabs
          activeKey={activeKey}
          // onChange={(key) => setActiveKey(key)}
          type="card"
        >
          {tabPanes}
        </Tabs>
      </Col>
    </Row>
  );
};


CampaignDynamicCarouselComponent.defaultProps = {
  inputDirection: undefined,
};

export default CampaignDynamicCarouselComponent;
