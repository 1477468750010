/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Drawer } from "antd";
import { NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { Scrollbars } from "react-custom-scrollbars";
import { onMobileNavToggle } from "redux/actions/Theme";
import Flex from "components/shared/Flex";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from 'App';
import Logo from "./Logo";
import MenuContent from "./MenuContent";

const MobileNav = (props: {
  routeInfo?: any,
  hideGroupTitle?: boolean,
  localization?: boolean
}) => {
  const { routeInfo, hideGroupTitle, localization } = props;
  const dispatch = useDispatch();

  const { mobileNav } = useSelector((state: RootState) => state.theme);
  const onClose = () => {
    dispatch(onMobileNavToggle(false));
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent type={NAV_TYPE_SIDE} routeInfo={routeInfo} hideGroupTitle={hideGroupTitle} localization={localization} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};
MobileNav.defaultProps = {
  routeInfo: undefined,
  hideGroupTitle: false,
  localization: false
};
export default MobileNav;
