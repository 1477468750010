import React, {useEffect, useState} from 'react';
import { Alert, Button, Form, Select, Table, Tooltip, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {CampaignImportFormModel} from 'models/forms';

export interface FieldMapping {
  name: string;
}
export interface FieldMappingFormProps extends CampaignImportFormModel {
  headerlist: string[]
  onClickSubmit: (mapping: { [key: string]: string }) => void;
  onClickCloseMappingModal:()=>void;
  visible: boolean;
}
const CampaignFieldMappingForm = (props: FieldMappingFormProps) => {
  const { headerlist, onClickSubmit, onClickCloseMappingModal, visible } = props;
  const [active, setActive] = useState(true);
  const [mappings, setMappings] = useState<{ [key: string]: string }>({});
  const [showWarnings, setShowWarnings] = useState(false);
  const [mappingForm] = Form.useForm();

  const fields =
    [ { text: 'Number (Mandatory)', value: 'number' },
      { text: 'First Name', value: 'first_name' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'Email', value: 'email' },
      { text: 'Var 1', value: 'var1' },
      { text: 'Var 2', value: 'var2' },
      { text: 'Var 3', value: 'var3' },
      { text: 'Var 4', value: 'var4' },
      { text: 'Var 5', value: 'var5' },
      { text: 'Country', value: 'country' }];

  const onChangeMapping = (key: string, value: string) => {
    if (key && key !== 'undefined') {
      mappings[key] = value;
      setMappings(mappings);
      if (key && key === "number") {
        setActive(false);
      }
    }
  };
  const tableHeadWithToolTip = () => (
    <div>
      Fields in file
      <Tooltip title="Column headers in file">
        &nbsp;
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );
  const tableColumns: ColumnsType<FieldMapping> = [
    {
      title: () => "Mapping Fields",
      dataIndex: "name",
      render: (text, record) => (
        <>
          {text}
          {record.name === 'number' && (
            <>
              <Tooltip title="Number fields are mandatory and should be a not none value.">
                <InfoCircleOutlined style={{marginLeft: 4}}/>
              </Tooltip>
              <span style={{color: 'red', marginLeft: 4}}>*</span>

            </>
          )}
        </>
      ),
    },
    {
      title: () => tableHeadWithToolTip(),
      width: 300,
      render: (_value, record) => (
        <Form.Item
          name={record.name}
          labelCol={{ span: 4 }}
          labelAlign='left'
        >
          <Select
            placeholder="Select field"
            className="mt-2 w-100"
            showSearch
            onChange={(value) => { onChangeMapping(record.name, `${value}`); }}
            value={mappings[record.name]} // Set the selected value here
          >
            {headerlist
              .filter(f => !Object.values(mappings).includes(f)) // Filter out already mapped fields
              .map((f, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Select.Option key={index} value={f}>
                  {f}
                </Select.Option>
              ))}
          </Select>
          {/* <Select placeholder="Select field" className="mt-2 w-100" */}
          {/*         showSearch onChange={(value) => { onChangeMapping(record.name, `${value}`); }}> */}
          {/*   /!* eslint-disable-next-line react/no-array-index-key *!/ */}
          {/*   {headerlist.map((f, index) => <Select.Option key={index} value={f}>{f}</Select.Option>)} */}
          {/* </Select> */}

        </Form.Item>
      ),
    }
  ];

  const validateMapping = (values: [key: string]) => {
    const index = Object.values(values).findIndex(x => x !== undefined);

    return index >= 0;
  };


  const onSubmit = async (values: [key: string]) => {
    if (validateMapping(values)) {
      onClickSubmit?.(mappings);
    } else {
      setShowWarnings(true);
    }
  };

  const onCloseModal=()=>{
    setActive(true);
    onClickCloseMappingModal?.();
    mappingForm.resetFields();
  };

  useEffect(() => {
    mappingForm.resetFields();
  },[headerlist]);

  return (
    <Modal
      onCancel={onCloseModal}
      visible={visible}
      footer={null}
      width={600}
    >
    <Form
      form={mappingForm}
    name="fieldMappingForm"
    layout="horizontal"
    size="small"
    className="mt-4"
    onFinish={onSubmit}
  >
    <div>
      {showWarnings ? <Alert
        message="Please map fields"
        onClose={() => { setShowWarnings(false); }}
        banner
        closable
      /> : null}
      <Table<FieldMapping>
        columns={tableColumns}
        dataSource={fields.map(fh => ({ name: fh.value }))}
        rowKey="name"
        size="small"
        pagination={false}
      />
      <div className="mt-4 text-right">
        <Button
          htmlType="submit"
          type="primary"
          className="ml-2"
          disabled={active}
        >
          Map and Next
        </Button>
      </div>

    </div>

  </Form>
    </Modal>);
};

export default CampaignFieldMappingForm;
