import React from 'react';
import {Handle, Position, useReactFlow} from 'reactflow';
import {Card} from "antd";
import {WhatsAppOutlined} from "@ant-design/icons";

const EntryNode = (selectedNode:any) => {
  const {deleteElements} = useReactFlow();
  const {id: selectedNodeId} = selectedNode;

  // eslint-disable-next-line no-unused-vars
  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteElements({nodes: [{id: selectedNodeId}]});
  };
  return(
    <div style={{position: 'relative'}}>

        <Handle type="source" position={Position.Right} className='edgebutton-handle' />
        <div style={{position: 'relative', display: 'inline-block'}}>
          <Card >
            <WhatsAppOutlined />
          </Card>
        </div>
    </div>

  );
};

export default EntryNode;
