import React,{useState,useEffect } from 'react';
import {ClearOutlined, DownloadOutlined, SnippetsOutlined, CopyOutlined, ReloadOutlined} from '@ant-design/icons';
import moment from 'moment';
import en from 'world_countries_lists/data/countries/en/world.json';
import {ColumnsType} from 'antd/es/table';
import {AuthState, NumberLookupApiReport} from "models";
import TableListReport from 'components/shared/TableListReport';
import {Col, DatePicker, Form, Input, Modal, Row, Select, notification} from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import {useSelector} from "react-redux";
import {
  NumLookupApiReportExportRequest,
  NumLookupApiReportFilterRequest
} from "../../../models/requests";
import {RootState} from "../../../App";

export interface NumLookupApiReportProps {
  reports: NumberLookupApiReport[];
  loading?: boolean;
  // onError: (msg: string) => void;
  onPaginate?: (page: number, pageSize: number,data:NumLookupApiReportFilterRequest, searchText?: string) => void
  fetchByFilter: (data: NumLookupApiReportFilterRequest) => void;
  onExportLog?:(data: NumLookupApiReportExportRequest) => void;
  total: number;
}

const NumLookupApiReportList = (props: NumLookupApiReportProps) => {
  const {reports, loading, total, fetchByFilter,onExportLog,onPaginate} = props;
  const { localCurrency } = useSelector((state: RootState): AuthState => state.auth);
  const lastMonth = moment().subtract(1, 'month');
  const [startDate, setStartDate] = useState(lastMonth);
  const [endDate, setEndDate] = useState(moment());
  const [searchData, setSearchData] = useState<NumLookupApiReportFilterRequest>();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const {RangePicker} = DatePicker;
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [showLogModal, setShowLogModal] = useState(false);
  const [logForm] = Form.useForm();
  const [msgInfo, setMsgInfo] = useState<string>("hlo");
  const status =['SUCCESS', 'INVALID_NUMBER', 'NOT_ALLOWED_COUNTRY', 'UNKNOWN_SUBSCRIBER', 'NETWORK_NOT_REACHED',
                  'NETWORK_CANNOT_REACH_NUMBER', 'NUMBER_LOCATION_NOT_KNOWN_TO_NETWORK', 'NUMBER_NOT_KNOWN_TO_MSC', 'NUMBER_ABSENT_FOR_SM', 'ROAMING_NOT_ALLOWED',
                  'ILLEGAL_SUBSCRIBER', 'BEARER_SERVICE_NOT_PROVISIONED', 'TELE_SERVICE_NOT_PROVISIONED', 'ILLEGAL_EQUIPMENT', 'CALL_BARRED', 'FACILITY_NOT_SUPPORTED',
                  'PHONE_SWITCHED_OFF', 'INCOMPATIBLE_TERMINAL', 'SUBSCRIBER_BUSY', 'SM_DELIVERY_FAILED', 'CONGESTION_OCCURRED', 'SYSTEM_FAILURE', 'MISSING_DATA',
                  'DATA_ERROR', 'UNSUPPORTED_MOBILE_NETWORK_WITH_PORT_CORRECTION', 'UNSUPPORTED_MOBILE_NETWORK_WITHOUT_PORT_CORRECTION', 'LANDLINE_NETWORK'];

  const downloadReport = async (data: NumLookupApiReportExportRequest) => {

    if (data){
      onExportLog?.(data);
    }
  };

  const searchRefresh = async (data: NumLookupApiReportFilterRequest) => {

    if (data){
      fetchByFilter?.(data);
    }
  };

  const onSearch = async (value: string) => {

    setSearchText(value);
    const data = {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      page,
      size: pageSize,
      ...(selectedStatus && {lookup_status: selectedStatus}),
      ...(selectedCountry && {country: selectedCountry}),
    };


    onPaginate?.(1, pageSize,data,value);



  };


  const onClickLog = (item: NumberLookupApiReport) => {
    let ported: string;
    if (item.ported == null){
      ported = "";
    }
    else if(item.ported){
      ported = "YES";
    }
    else{
      ported = "NO";
    }
    logForm.resetFields();
    logForm.setFieldsValue({
      'msg_id': item.msg_id,
      'date': new Date(item.created_at).toDateString().toString(),
      'recipient': `${item.recipient} - ${item.country ?? ''}`,
      'network': item.network,
      'cic':item.cic,
      'imsi':item.imsi,
      'ocn':item.ocn,
      'ported':ported,
      'present':item.present?.toUpperCase(),
      'mcc':item.mcc,
      'mnc':item.mnc,
      'gw_status': item.gw_status,
      'numlookup_cost': item.rate,
      'recipient_type': item.recipient_type,
      'status_code': item.error,
      'status': item.error_message,
      'req_id': item.req_id,
    });
    setMsgInfo(`
    Request ID:  ${item.req_id} \n
    Message ID: ${item.msg_id} \n
    Date: ${new Date(item.created_at).toDateString().toString()} \n
    Recipient: ${item.recipient} - ${item.country ?? ''} \n
    Network: ${item.network} \n
    Ported: ${item.ported} \n
    Present: ${item.present} \n
    MCC': ${item.mcc} \n
    MNC: ${item.mnc} \n
    CIC: ${item.cic} \n
    IMSI: ${item.imsi} \n
    OCN: ${item.ocn} \n
    Number lookup Rate: ${item.rate} \n
    Recipient Type: ${item.recipient_type} \n
    Status Code: ${item.error} \n
    Status: ${item.error_message}`
    );
    setShowLogModal(true);
  };

  const onCloseLogModal = () => {
    setShowLogModal(false);
    logForm.resetFields();
  };
  const searchPredicate = (item: NumberLookupApiReport, text: string) =>
    item.mcc?.toString().toLowerCase().includes(text)
    || item.mnc?.toString().toLowerCase().includes(text)
    || item.msg_id.toString().toLowerCase().includes(text)
    || item.recipient.toString().toLowerCase().includes(text)
    || item.country.toString().toLowerCase().includes(text)
    || `${item.status}`.toLowerCase().includes(text)
    || `${item.network}`.toLowerCase().includes(text);



  const tableColumns: ColumnsType<NumberLookupApiReport> = [
    {
      title: 'Request Id',
      dataIndex: 'req_id'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_v, record) => {
        const date = new Date(record.created_at).toDateString();
        return date.toString();
      },
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      render: (_v, record) =>
        <Col xl={24} lg={24} md={24}>
          <Row gutter={10}>
            <Col xl={20} lg={20} md={20}>
              {record.recipient}
            </Col>
            <Col xl={4} lg={4} md={4} className='text-left'>
              <span className={`flag:${record.country?.toUpperCase()}`} style={{height: 10, width: 15}}/>
            </Col>
          </Row>
        </Col>
    },
    {
      title: 'Network',
      dataIndex: 'network',
    },
    {
      title: 'MCC',
      dataIndex: 'mcc',
    },
    {
      title: 'MNC',
      dataIndex: 'mnc',
    },
    {
      title: 'Status',
      dataIndex: 'error_message',
    },
    {
      title: 'Log',
      dataIndex: 'log',
      render: (_v, record) => <SnippetsOutlined onClick={e => onClickLog(record)}/>
    }
  ];


  const filterReportData = () => {
    const data = {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      page,
      size: pageSize,
      ...(selectedStatus && {lookup_status: selectedStatus}),
      ...(selectedCountry && {country: selectedCountry}),
    };
    setSearchData(data);
    fetchByFilter?.(data);
  };


  const onChangeDate = (e: any) => {
    if (e) {
      const [sDate, eDate] = e;
      setStartDate(sDate);
      setEndDate(eDate);
    }
  };

  const onChangeSelectedCountry = (e: string) => {
    if(e){
      setSelectedCountry(e);
    }
  };

  const onChangeSelectedStatus = (e: string) => {
    if(e){
      setSelectedStatus(e);
    }
  };

  useEffect(() => {
    filterReportData();
  }, [selectedCountry,selectedStatus, startDate, endDate]);






  const onFilterClear = () => {
    setSelectedCountry(undefined);
    // setSelectedDate(undefined);
    setSelectedStatus(undefined);
  };


  const disabledDate = (current: any) =>  (current && current > moment().endOf('day')) || (current && current < lastMonth);


  const controls = [
    {    title: 'Refresh',
      icon: <ReloadOutlined/>,
      isIconOnly:true,
      onClick: () => {
        if (searchData)
          searchRefresh(searchData);
      }
    },
    {
      title: '',
      buttonNotNeeded: true,
      icon: <RangePicker id='date_filter' key='country_filter' // value={selectedDate}
                         className="w-100"
                         ranges={{
                           Today: [moment(), moment()],
                           'This Month': [moment().startOf('month'), moment().endOf('month')],
                         }}
                         format="YYYY/MM/DD"
                         value={[startDate, endDate]}
                         defaultValue={[startDate, endDate]}
                         disabledDate={disabledDate}
                         onChange={e => onChangeDate(e)}
      />,
    },
    {
      title: '',
      buttonNotNeeded: true,
      icon: <Select id='country_filter' key='country_filter' showSearch className="w-100"
                    filterOption={(input: any, option: any) =>
                      option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } placeholder='Choose Country' value={selectedCountry} onChange={e => {
        if (e) {
          onChangeSelectedCountry(e);
        }
      }}>
        {en.map(e => <Select.Option key={e.alpha2} value={e.alpha2.toUpperCase()} title={e.name}><span
          className={`flag:${e.alpha2.toUpperCase()} mr-2`}/>{e.name}</Select.Option>)}
      </Select>
    },
    {
      title: '',
      buttonNotNeeded: true,
      icon: <Select id='status_filter' key='status_filter'
                    style={{width: '300px'}}
                    placeholder="Choose Number Lookup Status" showSearch
                    onChange={e => {
                      if (e) {
                        onChangeSelectedStatus(e);
                      }
                    }} value={selectedStatus}>
        {status.map(e=><Select.Option key={e} value={e}>{e}</Select.Option>)}
      </Select>
    },
    {
      title: 'Clear',
      icon: <ClearOutlined/>,
      onClick: () => {
        onFilterClear();
      }
    },
    {
      title: 'Export',
      icon: <DownloadOutlined/>,
      isIconOnly:true,
      onClick: () => {
        if (searchData)
          downloadReport(searchData);
      },
    }

  ];


  const onChangePage = (newPage: number, newSize: number) => {
    if (pageSize !== newSize) {
      setPageSize(newSize);
      setPage(1);
      if (searchData!==undefined)
        onPaginate?.(1, newSize,searchData,searchText);
    } else {
      setPage(newPage);
      if (searchData!==undefined)
        onPaginate?.(newPage, newSize,searchData,searchText);
    }
  };

  const getPaginationOption = () => ({
    onChangePage,
    pageSize,
    currentPage: page,
    total
  });

  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };

  return (
    <div>

      <TableListReport<NumberLookupApiReport> items={reports} columns={tableColumns} loading={loading}
                                            showSearch onSearch={onSearch}
                                            rowKey={(record: NumberLookupApiReport) => record.msg_id} controls={controls}
                                            searchPredicate={searchPredicate} hideRowSelection
                                            serverSidePaginationOption={getPaginationOption()}/>

      <Modal
        visible={showLogModal}
        onCancel={onCloseLogModal}
        footer={null}
        width={550}
      >
        <Form form={logForm} className='w-100'>
          <Col xl={24} lg={24} md={24}>
            <CopyToClipboard text={msgInfo}
                             onCopy={(e) => showSuccessNotification('Log Copied to clipboard')}>
              <span className="cursor-pointer"><CopyOutlined/></span>
            </CopyToClipboard>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Request ID" name="req_id" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Message ID" name="msg_id" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Date" name="date" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Receipient" name="recipient" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Network" name="network" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="MCC" name="mcc" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="MNC" name="mnc" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="CIC" name="cic" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="IMSI" name="imsi" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="OCN" name="ocn" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Ported" name="ported" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Reachable" name="present" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Recipient Type" name="recipient_type" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Status Code" name="status_code" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false}  readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Status" name="status" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Number Lookup Rate" name="numlookup_cost" labelAlign="left" labelCol={{span: 10}}>
                {/* eslint-disable-next-line no-nested-ternary */}
                <Input bordered={false} prefix={(localCurrency?(localCurrency==="EURO"? "€": (localCurrency==="AED"?"د.إ":(localCurrency==="INR"?"₹":"$"))):"$")} readOnly/>
              </Form.Item>
            </Col>
          </Col>
        </Form>
      </Modal>
    </div>
  );
};

NumLookupApiReportList.defaultProps = {
  loading: false,
  onPaginate: undefined,
  onExportLog:undefined
};

export default NumLookupApiReportList;
