import { LoadingOutlined } from "@ant-design/icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Image, Progress } from "antd";
import React from "react";

interface WhatsappImageAttachmentProps {
  id?: string | null;
  imageUrl: string | null;
  mediaSrc: string | null;
  handleDownload: () => void;
  isDownloading: boolean;
  isUploading?: boolean;
  downloadProgress: number;
}

const WhatsappImageAttachment: React.FC<WhatsappImageAttachmentProps> = ({
  id,
  imageUrl,
  mediaSrc,
  handleDownload,
  isDownloading,
  isUploading,
  downloadProgress,
}) => {

  const placeholderContainerStyle: React.CSSProperties = {
    position: "relative",
    maxWidth: "400px",
    width: "250px",
    height: "200px",
    backgroundColor: "#f0f0f0",
    borderRadius: "4px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const placeholderImageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    filter: (imageUrl === null && id === null) ? "none" : "blur(5px)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  };

  const containerStyle: React.CSSProperties = {
    position: "relative",
    maxWidth: "400px",
    width: "250px",
    // height: "200px",
    borderRadius: "4px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const imageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const buttonStyle: React.CSSProperties = {
    zIndex: 2,
    fontSize: "16px",
    color: "white",
  };

  const progressContainerStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 3,
  };

  const imageDefaultPlaceholder =
    "https://placehold.co/600x400?text=Image+failed+to+load";

  if (!imageUrl) {
    return (
      <div style={placeholderContainerStyle}>
        <img
          src="https://placehold.co/600x400?text=Image+Unavailable!\nAsk+your+customer+to+resend\nthe+image."
          alt="Placeholder"
          style={placeholderImageStyle}
        />
      </div>
    );
  }

  return mediaSrc ? (
    <div style={containerStyle}>
      <Image
        src={mediaSrc}
        placeholder={<Image preview={false} src={mediaSrc} width={400} />}
        fallback={imageDefaultPlaceholder}
        style={imageStyle}
      />
    </div>
  ) : (
    <div style={placeholderContainerStyle}  onClick={handleDownload} onKeyDown={() => handleDownload} role="button" tabIndex={0}>
      <img
        src="https://placehold.co/600x400?text=Image+Loading...\nPlease+wait"
        alt="Placeholder"
        style={placeholderImageStyle}
      />
    
      {!isDownloading && !isUploading ? (
        <Button
          type="primary"
          shape="circle"
          icon={<FontAwesomeIcon icon={faDownload} />}
          style={buttonStyle}
        />
      ) : (
        <div style={progressContainerStyle}>
          {isDownloading && <Progress
            type="circle"
            percent={downloadProgress}
            strokeWidth={12}
            width={50}
            strokeColor="green"
          />}

          {isUploading && <LoadingOutlined style={{ fontSize: 24, strokeWidth: 2 }} spin />}
        </div>
      )}

      
    </div>
  );
};

WhatsappImageAttachment.defaultProps = {
  id: undefined,
  isUploading: undefined,
};
export default WhatsappImageAttachment;
