import React, {useEffect, useState} from 'react';
import {Handle, Position, useReactFlow, useStoreApi} from 'reactflow';
import {Button, Card, Col, Form, Input, Row, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {buttonInfo, TextAndButtonNodeProps} from "../../models/chatbot";
import useFormErrors from "../../hooks/useFormErrors";

const TextAndButtonNode = (selectedNode: TextAndButtonNodeProps) => {
  const {setNodes, getEdges, setEdges} = useReactFlow();
  const store = useStoreApi();
  const [buttonNames, setButtonNames] = useState<buttonInfo[]>([]);
  const {deleteElements} = useReactFlow();
  const [form] = Form.useForm();
  const {id: selectedNodeId} = selectedNode;
  const [buttonForm] = Form.useForm();
  const {updateFormErrors, deleteFormError} = useFormErrors();


  useEffect(() => {
    const {data: {payload_info: payloadInfo}} = selectedNode;
    if (payloadInfo) {

      form.setFieldsValue({header: payloadInfo.header, body: payloadInfo.body, footer: payloadInfo.footer});

      if (payloadInfo.buttons) {
        setButtonNames(payloadInfo.buttons);
      }
    }
  }, []);

  useEffect(() => {
    const {nodeInternals} = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === selectedNodeId) {
          // eslint-disable-next-line no-param-reassign
          node.data = {
            ...node.data,
            payload_info: {
              ...node.data.payload_info,
              buttons: buttonNames.map((button) => ({
                id: button.id,
                name: button.name,
              })),
            }
          };
        }
        return node;
      })
    );
  }, [buttonNames]);

  const addInput = () => {
    const uniqueId = `${+new Date()}`;
    setButtonNames([...buttonNames, {id: uniqueId, name: ''}]);
  };

  const handleInputChange = (id: string, value: any) => {
    const updatedButtons = buttonNames.map((button) => {
      if (button.id === id) {
        return {...button, name: value};
      }
      return button;
    });
    setButtonNames(updatedButtons);
  };
  const deleteInput = (id: string) => {
    const connectedEdge = getEdges().find(
      (edge) => edge.sourceHandle === id
    );
    if (connectedEdge) {
      setEdges((eds) => eds.filter((e) => e.id !== connectedEdge.id));
    }
    const updatedButtons = buttonNames.filter((button) => button.id !== id);
    setButtonNames(updatedButtons);
  };

  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteFormError(selectedNodeId);
    deleteElements({nodes: [{id: selectedNodeId}]});
  };
  const handleMouseLeave = () => {
    form
      .validateFields()
      .then((values: any) => {
        const updatedPayload = {
          header: values.header,
          body: values.body,
          footer: values.footer,
          buttons: buttonNames.map((button) => ({
            id: button.id,
            name: button.name,
          })),
        };
        const {nodeInternals} = store.getState();
        setNodes(
          Array.from(nodeInternals.values()).map((node) => {
            if (node.id === selectedNodeId) {
              return {
                ...node,
                data: {
                  ...node.data,
                  payload_info: updatedPayload,
                },
              };
            }
            return node;
          })
        );
      })
      .catch((errorInfo: any) => {
        updateFormErrors(selectedNodeId, true);
      });
    buttonForm.validateFields().then((values) => {
      updateFormErrors(selectedNodeId, false);
    }).catch((error) => {
      updateFormErrors(selectedNodeId, true);
    });
  };

  return (
    <div style={{position: 'relative'}}>
      <Tooltip
        color='white'
        title={
          <div
            style={{
              background: 'transparent',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Button
              title='Delete'
              onClick={(event) => onDeleteNode(event)}
              icon={<DeleteOutlined/>}
              style={{height: '35px', width: '35px'}}
            />
          </div>
        }
        placement='rightTop'
      >
        <Handle type="target" position={Position.Left} className='edgebutton-handle'/>
        <div style={{position: 'relative', display: 'inline-block'}}>
          <Card title="Interactive Button Message" style={{width: '350px', border: "1px solid #173409FF"}}
                onMouseLeave={handleMouseLeave}>
            <Form form={form} name="node_form" layout="vertical">
              <Form.Item name="header">
                <Input placeholder="Header"/>
              </Form.Item>
              <span style={{color: 'red', fontSize: '16px'}}>*</span>
              <Form.Item name="body" rules={[{required: true, message: 'Body is required'}]}>
                <Input.TextArea placeholder="Body" autoSize={{minRows: 3}}/>
              </Form.Item>
              <Form.Item name="footer">
                <Input placeholder="Footer"/>
              </Form.Item>
            </Form>
            <Form
              form={buttonForm}
              name="button_form"
            >
              {buttonNames.map((button) => (
                <>
                  <span style={{color: 'red', fontSize: '16px'}}>*</span>
                  <Form.Item key={button.id}
                             name={button.id}
                             initialValue={button.name}
                             rules={[
                               {validator: (_, value) => value && value.trim() !== '' ? Promise.resolve() : Promise.reject(new Error('Should be a valid name'))}
                             ]}
                             style={{
                               display: 'inline-block',
                               marginRight: '8px',
                               width: '100%'
                             }}
                  >
                    <Row gutter={4}>
                      <Col md={20}>
                        <Input
                          style={{
                            border: '1px solid #939ea8',
                            borderRadius: '2px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                          }}
                          value={button.name || ''}
                          placeholder='Enter button name'
                          onChange={(e) => handleInputChange(button.id, e.target.value)}
                        />
                      </Col>
                      <Col md={4}>
                        <Handle type="source" position={Position.Right} id={button.id} className='edgebutton-handle'/>
                        <Button type="link" onClick={() => deleteInput(button.id)}
                                icon={<DeleteOutlined/>}/>

                      </Col>
                    </Row>
                  </Form.Item>
                </>
              ))}
            </Form>
            <Button type="dashed" onClick={addInput} disabled={buttonNames.length > 2} block>
              Add Button
            </Button>

          </Card>
        </div>
      </Tooltip>
    </div>
  );
};

export default TextAndButtonNode;
