import React from 'react';
import Title from "antd/lib/typography/Title";
import { Message } from './Conversation';
import WhatsappAttachment from './WhatsappAttachment';
import WhatsappLocationMessage from './WhatsappLocationMessage';

export const replaceTemplatePlaceholders = (
  bodyText: string,
  exampleBodyText: { [key: string]: string | undefined }
): string => {
  if (!exampleBodyText || !bodyText) {
    return bodyText;
  }

  // Convert exampleBodyText to an array of its values
  const values = Object.values(exampleBodyText);

  return bodyText?.replace(/{{(\d+)}}/g, (match, number) => {
    const index = parseInt(number, 10) - 1; // Convert to zero-based index
    return values[index] !== undefined ? values[index]! : match;
  });
};

const WhatsappTemplateMessage = ({msg, formatWhatsappText}: {msg: Message, formatWhatsappText: any}) => {

    const formattedTemplateHeaderText = replaceTemplatePlaceholders(
        msg.content?.template_details?.header_text,
        msg.content?.header_parameter_values
      );
      const formattedTemplateBodyText = replaceTemplatePlaceholders(
        msg.content?.template_details?.body_text,
        msg.content?.body_parameter_values
      );
  return (
    <div className="template-msg">
    {msg.content?.template_details ? (
      <div>
        <div
          className={`template-msg__header ${
            msg.content?.template_details?.header_format === "TEXT"
              ? "header-without-media"
              : "header-with-media"
          }`}
        >
          {msg.content?.template_details?.header_format &&
          msg.content?.template_details?.header_format === "LOCATION" && (
            <WhatsappLocationMessage latitude={msg.content?.location_latitude} longitude={msg.content?.location_longitude} name={msg.content?.location_name} address={msg.content?.location_address} />
          )}
            {msg.content?.template_details?.header_format && msg.content?.template_details?.header_format !== "TEXT" && msg.content?.template_details?.header_format !== "LOCATION" &&(
               <WhatsappAttachment
               attachmentUrl={msg.content?.media_url}
                 attachmentType={msg.content?.template_details?.header_format}
                 attachmentFileName={msg.content?.document_file_name}
                 attachmentCaption={(msg.content?.template_details?.header_text === null && msg.content?.template_details?.footer_text === null && msg.content?.template_details?.body_text === null) ? " " : undefined}   
                 attachmentMimeType={msg.content?.media_mime_type}
                 formatWhatsappText={formatWhatsappText}
               />
            )}
          {msg.content?.template_details?.header_text && (
            <Title level={4} className="header-text">
              {formatWhatsappText(formattedTemplateHeaderText)}
            </Title>
          )}
        </div>
        {msg.content?.template_details?.body_text && (
          <div
            className={`template-msg__body ${
              msg.content?.template_details?.header_format === "TEXT"
                ? "header-without-media"
                : ""
            }`}
          >
            <div>{formatWhatsappText(formattedTemplateBodyText)}</div>
          </div>
        )}
        {msg.content?.template_details?.footer_text && (
          <div className="template-msg__footer mt-1">
            <p className="mb-0">
              {formatWhatsappText(
                msg.content?.template_details?.footer_text
              )}
            </p>
          </div>
        )}
      </div>
    ) : (
      <div className="template-msg__body">
        <div
          className="mb-0"
          style={{ fontStyle: "italic" }}
        >{`TEMPLATE message: ${msg.content?.template_id}`}</div>
      </div>
    )}
  </div>
  );
};

export default WhatsappTemplateMessage;