import React from 'react';
import AppBreadcrumb from 'components/layout/AppBreadcrumb';
import IntlMessage from '../utils/IntlMessage';

export const PageHeader = (props: { title: string, display: boolean, showBreadCrumb: boolean }) => {
	const { title, display, showBreadCrumb } = props;
	return (
		display ? (
			<div className="app-page-header">
				<h3 className="mb-0 mr-3 font-weight-semibold">
					<IntlMessage id={title || 'home'} />
				</h3>
				{showBreadCrumb ? <AppBreadcrumb /> : null}
			</div>
		)
			: null
	);
};

export default PageHeader;