import React, {useState} from 'react';
import {Modal, Select, Tag} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { WhatsappMetaWabaLists, WhatsappTemplateDetails} from "models";
import TableList from 'components/shared/TableList';


export interface WhatsappTemplateListProps {
  whatsapptemplate: WhatsappTemplateDetails[];
  loading?: boolean;
  openWhatsappTemplateForm?: (isEdit?: boolean, item?: WhatsappTemplateDetails) => void;
  onClickDeleteWhatsappTemplate?: (id: number) => void;
  onClickRefresh:(wabaId: string|undefined)=>void;
  wabaIds?: WhatsappMetaWabaLists;
}

const WhatsappTemplateList = (props: WhatsappTemplateListProps) => {
  const { whatsapptemplate, loading, openWhatsappTemplateForm, onClickDeleteWhatsappTemplate, onClickRefresh, wabaIds } = props;
  const [editError, setEditError] = useState<string | null>(null);
  const [selectedWaba, setSelectedWaba] = useState<string>();
  const onConfirmDelete = (item: WhatsappTemplateDetails) => {
    onClickDeleteWhatsappTemplate?.(item.id);
  };

  const openEditForm = (item: WhatsappTemplateDetails) => {
    if (item.last_update_at) {
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setDate(twentyFourHoursAgo.getDate() - 1);

      const createdAtDate = new Date(item.created_at);
      const updatedAtDate = new Date(item.last_update_at);
      createdAtDate.setMilliseconds(0);
      updatedAtDate.setMilliseconds(0);
      if (createdAtDate.toISOString() !== updatedAtDate.toISOString()) {
        if (updatedAtDate >= twentyFourHoursAgo) {
          setEditError("You can only edit this template once every 24 hours.");
          Modal.error({
            title: 'Warning',
            content: 'You can only edit this template once every 24 hours.',
          });
        } else {
          openWhatsappTemplateForm?.(true, item);
        }
      } else {
        openWhatsappTemplateForm?.(true, item);
      }
    } else {
      openWhatsappTemplateForm?.(true, item);
    }
  };

  const onFilterWaba = (e: string) => {
    if (e) {
      setSelectedWaba(e);
      onClickRefresh?.(e);
    }
  };

  const actions = [
    {
      toolTip: 'Edit',
      icon: <EditOutlined />,
      getDisabled: (item: WhatsappTemplateDetails) => !!item.carousel?.components || item.status === 'PENDING',
      onClick: openEditForm,
    },
    {
      toolTip: 'Delete',
      icon: <DeleteOutlined />,
      popConfirm: {
        title: 'Are you sure you want to delete this Whatsapp Template?', onConfirm: onConfirmDelete
      },
    }
  ];
  const controls = [
    {
      title: 'Refresh',
      icon: <ReloadOutlined/>,
      isIconOnly:true,
      onClick: () => {
        onClickRefresh?.(undefined);
      }
    },
    {
      title: '',
      buttonNotNeeded: true,
      icon: <Select id='waba_filter' key='waba_filter' placeholder="Select WABA" showSearch className="w-100"
                    onChange={e => {
                      if (e) {
                        onFilterWaba(e);
                      }
                    }} value={selectedWaba}>
        {wabaIds?.map(e => <Select.Option key={e.waba_id} value={e.waba_id} title={e.name}><span/>{e.name}</Select.Option>)}
      </Select>
    },
    {
      title: 'Create  Whatsapp Template',
      icon: <PlusOutlined />,
      onClick: () => { openWhatsappTemplateForm?.(); }
    },
  ];

  const searchPredicate = (item: WhatsappTemplateDetails, text: string) =>
    item.template_name.toLowerCase().includes(text);

  const tableColumns: ColumnsType<WhatsappTemplateDetails> = [
    // {
    //   title: 'Template ID',
    //   dataIndex: 'template_id'
    // },
    {
      title: 'Template ID',
      dataIndex: 'template_name',
    },
    {
      title: 'Language',
      dataIndex: 'template_language',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Body Text',
      dataIndex: 'body_text',
    },
    {
      title: 'Last updated',
      dataIndex: 'updated_at',
      render: (_v, record) => {
        const date = new Date(record.updated_at).toDateString();
        return date.toString();
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => {
        let color = '';

        if (status === 'APPROVED') {
          color = 'green';
        } else if (status === 'PENDING') {
          color = 'orange';
        } else if (status === 'REJECTED') {
          color = 'red';
        }

        return <Tag color={color}>{status}</Tag>;
      },
    }
  ];

  return (
    <div>
      {editError && <p hidden style={{ color: 'red' }}>{editError}</p>}
      <TableList<WhatsappTemplateDetails> items={whatsapptemplate} columns={tableColumns} loading={loading}
                                          rowKey={(record: WhatsappTemplateDetails) => record.id} showSearch actions={actions} controls={controls}
                                          searchPredicate={searchPredicate} hideRowSelection />
    </div>
  );
};

WhatsappTemplateList.defaultProps = {
  loading: false,
  openWhatsappTemplateForm: undefined,
  onClickDeleteWhatsappTemplate: undefined,
  wabaIds: undefined,
};

export default WhatsappTemplateList;
