import DashboardApi from "api/dashboard";
import {
  UserAddressCreateRequest,
  UserAddressUpdateRequest,
  VerifyOtpRequest,
  ForgotPasswordTokenRequest,
  VerifyForgotPasswordRequest,
  ChangePasswordRequest,
  ProfileUpdateRequest,
  EmailRequestOTPRequest,
  EmailVerifyOTPRequest,
  PhoneVerifyOTPRequest,
  SubAccountAddRequest,
  SubAccountEditRequest,
  SubAccountPriceCreateRequest,
  SubAccountPriceEditRequest,
  SubUserCreateRequest,
  SubUserUpdateRequest,
  UserSetPasswordRequest,
  RequestOTPWithPhoneRequest,
  AllocateUpdateRequest
} from 'models/requests';
import {
  RequestOtpResponse,
  UserAddressCreateResponse,
  UserAddressListResponse,
  UserAddressUpdateResponse,
  UserResponse,
  VerifyOtpResponse,
  VerifyForgotPasswordResponse,
  ForgotPasswordTokenResponse,
  ChangePasswordResponse,
  UserProfileResponse,
  UpdateProfileResponse,
  EmailVerifyOTPResponse,
  EmailRequestOTPResponse,
  PhoneVerifyOTPResponse,
  PhoneRequestOTPResponse,
  SubAccountsResponse,
  DeleteSubAccountResponse,
  SubAccountPriceResponse,
  AddSubAccountsResponse,
  EditSubAccountsResponse,
  CreateSubAccountPriceResponse,
  EditSubAccountPriceResponse,
  DeleteSubAccountPriceResponse,
  SubUserListResponse,
  SubUserCreateResponse,
  SubUserUpdateResponse,
  SubUserDeleteResponse,
  SubAccountByIdResponse,
  AuthListResponse,
  UserSetPasswordResponse,
  BuyingCostResponse,
  AllocateUpdateResponse
} from 'models/responses';
import AuthApi from "./index";

const getUserAddressList = async () => AuthApi.get<UserAddressListResponse>('users/address');
const createUserAddress = async (data: UserAddressCreateRequest) => AuthApi.post<UserAddressCreateResponse, UserAddressCreateRequest>('users/address', data);
const updateUserAddress = async (data: UserAddressUpdateRequest, addressId: string) => AuthApi.put<UserAddressUpdateResponse, UserAddressUpdateRequest>(`users/address/${addressId}`, data);
const deleteUserAddress = async (addressId: string) => AuthApi.delete<UserAddressUpdateResponse>(`users/address/${addressId}`);
const getUser = async () => AuthApi.get<UserResponse>('users/me');
const requestOtp = async () => AuthApi.post<RequestOtpResponse>('users/request-otp');
const verifyOtp = async (data: VerifyOtpRequest) => AuthApi.post<VerifyOtpResponse, VerifyOtpRequest>('users/verify-otp', data);
const requestForgotPasswordToken = (data: ForgotPasswordTokenRequest) => AuthApi.post<ForgotPasswordTokenResponse, ForgotPasswordTokenRequest>('users/forgot-password', data, true);
const verifyForgotPassword = (data: VerifyForgotPasswordRequest) => AuthApi.post<VerifyForgotPasswordResponse, VerifyForgotPasswordRequest>('users/reset-password', data, true);
const changePassword = (data: ChangePasswordRequest) => AuthApi.post<ChangePasswordResponse, ChangePasswordRequest>('users/change-password', data);
const getUserProfile = () => AuthApi.get<UserProfileResponse>('users/profile');
const updateUserProfile = (data: ProfileUpdateRequest) => AuthApi.put<UpdateProfileResponse, ProfileUpdateRequest>('users/profile', data);

const emailRequestOTP = (data: EmailRequestOTPRequest) => AuthApi.post<EmailRequestOTPResponse, EmailRequestOTPRequest>('users/email-request-otp', data);
const emailVerifyOTP = (data: EmailVerifyOTPRequest) => AuthApi.post<EmailVerifyOTPResponse, EmailVerifyOTPRequest>('users/email-verify-otp', data);


const phoneRequestOTP = () => AuthApi.post<PhoneRequestOTPResponse>('users/request-otp');
const phoneVerifyOTP = (data: PhoneVerifyOTPRequest) => AuthApi.post<PhoneVerifyOTPResponse, PhoneVerifyOTPRequest>('users/verify-otp', data);

const getAllSubAccounts = () => AuthApi.get<SubAccountsResponse>('users/sub-account');
const getSubAccount = (id: string) => DashboardApi.get<SubAccountByIdResponse>(`sub-account/${id}`);
const addSubAccount = (data: SubAccountAddRequest) => AuthApi.post<AddSubAccountsResponse, SubAccountAddRequest>('users/sub-account', data);
const editSubAccount = (id: string, data: SubAccountEditRequest) => DashboardApi.put<EditSubAccountsResponse, SubAccountEditRequest>(`sub-account/${id}`, data);
const deleteSubAccount = (id: string) => AuthApi.delete<DeleteSubAccountResponse>(`users/sub-account/${id}`);
const getSubAccountPrice = (id: string) => DashboardApi.get<SubAccountPriceResponse>(`sub-account/price-config/${id}`);
const createSubAccountPrice = (id: string, data: SubAccountPriceCreateRequest) => DashboardApi.post<CreateSubAccountPriceResponse, SubAccountPriceCreateRequest>(`sub-account/price-config/${id}`, data);
const editSubAccountPrice = (id: number, data: SubAccountPriceEditRequest) => DashboardApi.put<EditSubAccountPriceResponse, SubAccountPriceEditRequest>(`sub-account/price-config/${id}`, data);
const deleteSubAccountPrice = (id: number) => DashboardApi.delete<DeleteSubAccountPriceResponse>(`sub-account/price-config/${id}`);

const getSubUsers = () => AuthApi.get<SubUserListResponse>('users/sub-user');
const createSubUser = (data: SubUserCreateRequest) => AuthApi.post<SubUserCreateResponse, SubUserCreateRequest>('users/sub-user', data);
const updateSubUser = (id: string, data: SubUserUpdateRequest) => AuthApi.put<SubUserUpdateResponse, SubUserUpdateRequest>(`users/sub-user/${id}`, data);
const deleteSubUser = (id: string) => AuthApi.delete<SubUserDeleteResponse>(`users/sub-user/${id}`);


const getAccessList = () => AuthApi.get<AuthListResponse>('authorization/list');



const userSetPassword = (data: UserSetPasswordRequest) => AuthApi.post<UserSetPasswordResponse, UserSetPasswordRequest>('users/set-password', data, true);

const getBuyingCost = (country: string) => DashboardApi.get<BuyingCostResponse>(`sub-account/price-config/buying-cost/${country}`);

const requestOTPWithPhone = (data: RequestOTPWithPhoneRequest) => AuthApi.post<PhoneRequestOTPResponse, RequestOTPWithPhoneRequest>('users/request-otp', data);

const editAllocateCredit = (subAccountId: string, data: AllocateUpdateRequest) => DashboardApi.post<AllocateUpdateResponse, AllocateUpdateRequest>(`sub-account/allocate-credit/${subAccountId}`, data);
export {
  getUserAddressList,
  createUserAddress,
  updateUserAddress,
  deleteUserAddress,
  getUser,
  requestOtp,
  verifyOtp,
  requestForgotPasswordToken,
  verifyForgotPassword,
  changePassword,
  getUserProfile,
  updateUserProfile,
  emailRequestOTP,
  emailVerifyOTP,
  phoneRequestOTP,
  phoneVerifyOTP,
  getAllSubAccounts,
  getSubAccount,
  deleteSubAccount,
  getSubAccountPrice,
  addSubAccount,
  editSubAccount,
  createSubAccountPrice,
  editSubAccountPrice,
  deleteSubAccountPrice,
  getSubUsers,
  createSubUser,
  updateSubUser,
  deleteSubUser,
  getAccessList,
  userSetPassword,
  getBuyingCost,
  requestOTPWithPhone,
  editAllocateCredit
};
