import React, { useEffect} from 'react';
import {Input, Button, Form, Row, Col, notification, Modal} from 'antd';
import {InfoCircleOutlined} from "@ant-design/icons";
import {WhatsappSettings} from "models";
// import en from 'world_countries_lists/data/countries/en/world.json';
import { updateWhatsappSettingsService} from "../../../../services/dashboard/WhatsappService";

export interface WhatsappSettingsProps {
  whatsappSettings: WhatsappSettings;
  reset: (settings: WhatsappSettings) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const WhatsappSettingsPage = (props: WhatsappSettingsProps) => {
  const [form] = Form.useForm();
  const {whatsappSettings, reset, loading, setLoading} = props;
  // const [Contacts, setContacts] = useState([]);



  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };
  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };

  const onSuccessCreateWhatsappSettings = () => {
    showSuccessNotification(`Whatsapp Settings Updated successfully`);
    setLoading(false);
  };
  const onErrorCreateWhatsappSettings = (showNotification?: boolean) => {
    if (showNotification) {
      showErrorNotification("Whatsapp Settings Update Failed");
    }
    setLoading(false);
  };

  const onSubmitForm = async (values: {
    call_back_url: string,
    webhook_secret: string
  }) => {
    setLoading(true);
    try {
      const request = {
        call_back_url: (values.call_back_url === '') ? null : values.call_back_url,
        webhook_secret: (values.webhook_secret === '') ? null : values.webhook_secret
      };
      const response = await updateWhatsappSettingsService(request);
      if (response !== undefined) {
        onSuccessCreateWhatsappSettings();
        reset(response);
      } else {
        onErrorCreateWhatsappSettings(true);
      }
    } catch (error) {
      onErrorCreateWhatsappSettings(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    form.setFieldsValue({
      call_back_url: whatsappSettings.call_back_url,
      webhook_secret: whatsappSettings.webhook_secret
    });
    setLoading(false);


    // const fetchPhoneNumber = async () => {
    //     try {
    //         const fetchedPhoneNumbers: any = await listWhatsappContactService();
    //         if (fetchedPhoneNumbers && fetchedPhoneNumbers.length > 0) {
    //             const phoneNumbers = fetchedPhoneNumbers.map((contact: any) => contact.number); // Extract phone numbers
    //             setContacts(phoneNumbers); // Call setContacts() with the array of phone numbers
    //         } else {
    //             console.log("No contact data fetched.");
    //         }
    //     } catch (error) {
    //         // console.error('Error fetching templates:', error);
    //     }
    // };
    // fetchPhoneNumber();
  }, [whatsappSettings]);

  return (
    <Form
      onFinish={onSubmitForm}
      form={form}
      name="basicInformation"
      size="small">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Webhook URL"
              name="call_back_url"
              labelCol={{span: 5}}
              labelAlign="left"
              initialValue={whatsappSettings?.call_back_url}
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label={<span>
                          Webhook Secret
                            <InfoCircleOutlined
                              style={{fontSize: '18px', marginLeft: '8px'}}
                              onClick={() => {
                                Modal.info({
                                  title: "Webhook secret Info",
                                  content: (
                                    <div>
                                      If setup, we will pass this webhook secret in the header as X-Webhook-Secret in Whatsapp message callback.
                                    </div>
                                  ),
                                });
                              }}
                            />
                      </span>}
                    name="webhook_secret"
                    labelCol={{span: 5}}
                    labelAlign="left"
                    initialValue={whatsappSettings?.webhook_secret}
                  >
                    <Input/>
                  </Form.Item>
                </Col>
              </Row>
                <div className="mt-4 text-right">
                    <Button
                        name='submit'
                        htmlType="submit"
                        type="primary"
                        className="ml-2"
                        loading={loading}
                    >
                        Save
                    </Button>
                </div>
            </Col>
        </Form>
    );
};


export default WhatsappSettingsPage;
