import { NumLookupApiReportFilterRequest,NumLookupApiReportExportRequest} from 'models/requests';
import { NumLookupApiReportListResponse, NumberLookupApiReportExportResponse} from 'models/responses';
import NumLookupApi from ".";

const getNumLookupApiSearchResult = (key: string,data: NumLookupApiReportFilterRequest) => NumLookupApi.get<NumLookupApiReportListResponse,NumLookupApiReportFilterRequest>(`report/search/${key}`,data);
const getNumLookupApiFilterResult = (data: NumLookupApiReportFilterRequest ) => NumLookupApi.get<NumLookupApiReportListResponse, NumLookupApiReportFilterRequest>(`report/filter`, data);

const getNumLookupApiReportExport = (data: NumLookupApiReportExportRequest ) => NumLookupApi.get<NumberLookupApiReportExportResponse, NumLookupApiReportExportRequest>(`report/export`,data);

export {
  getNumLookupApiSearchResult,
  getNumLookupApiFilterResult,
  getNumLookupApiReportExport
};
