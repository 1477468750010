import {
  ContactBlackListRequest,
  ContactBulkCreateRequest,
  ContactCreateRequest,
  ContactDeleteRequest,
  ContactImportRequest,
  ContactListGetRequest,
  ContactRemoveFromBlackListRequest,
  ContactRemoveMultiBlackListRequest,
  ContactsAddToGroupRequest,
  ContactsMultiDeleteRequest,
  ContactsRemoveFromBlackListRequest,
  ContactsSearchRequest,
  ContactUpdateRequest,
  FileHeadersGetRequest, HubspotCrmCallBackRequest
} from 'models/requests';

import {
  ContactBlackListResponse,
  ContactBulkCreateResponse,
  ContactCreateResponse,
  ContactDeleteResponse,
  ContactImportResponse,
  ContactListResponse,
  ContactRemoveFromBlackListResponse,
  ContactsAddToGroupResponse, ContactsAllDeleteResponse,
  ContactsMultiDeleteResponse,
  ContactsRemoveFromBlackListResponse,
  ContactsSearchResponse,
  ContactUpdateResponse,
  FileHeadersResponse,
  FileUploadResponse, HubspotCrmCallBackResponse
} from 'models/responses';

import DashboardApi from ".";

const createSingleContact = async (data: ContactCreateRequest) => DashboardApi.post<ContactCreateResponse, ContactCreateRequest>('contacts/contact', data);
const listAllContact = async () => DashboardApi.get<ContactCreateResponse[]>('contacts/contact');
const updateContact = async (data: ContactUpdateRequest) => DashboardApi.put<ContactUpdateResponse, ContactUpdateRequest>(`contacts/contact/${data.id}`, data);
const deleteContact = async (data: ContactDeleteRequest) => DashboardApi.delete<ContactDeleteResponse, ContactDeleteRequest>(`contacts/contact/${data.contact_id}`);
const blackListSingleContact = async (data: ContactBlackListRequest) => DashboardApi.post<ContactBlackListResponse, ContactBlackListRequest>(`contacts/contact/add-blacklist/${data.contact_id}`);
const removeContactFromBlackList = async (data: ContactRemoveFromBlackListRequest) => DashboardApi.post<ContactRemoveFromBlackListResponse, ContactRemoveFromBlackListRequest>(`contacts/contact/remove-blacklist/${data.contact_id}`);
const uploadFile = async (data: any) => DashboardApi.post<FileUploadResponse>('contacts/upload', data, false, {
  'Content-Type': 'multipart/form-data'
});
const getFileHeaders = async (data: any) => DashboardApi.postWithQueryParams<FileHeadersResponse, FileHeadersGetRequest>('contacts/upload/get-headers', data);
const importContact = async (data: ContactImportRequest) => DashboardApi.post<ContactImportResponse, ContactImportRequest>('contacts/contact/contact-import', data);
const addMultipleContactsToGroup = async (data: ContactsAddToGroupRequest) => DashboardApi.post<ContactsAddToGroupResponse, ContactsAddToGroupRequest>('contacts/contact/add-multiple-contacts-to-group', data);
const deleteMultipleContacts = async (data: ContactsMultiDeleteRequest) => DashboardApi.post<ContactsMultiDeleteResponse, ContactsMultiDeleteRequest>('contacts/contact/multi-delete', data);

const createBulkContact = async (data: ContactBulkCreateRequest) => DashboardApi.post<ContactBulkCreateResponse, ContactBulkCreateRequest>('contacts/contact/bulk', data);
const removeContactsFromBlackList = async (data: ContactsRemoveFromBlackListRequest) => DashboardApi.post<ContactsRemoveFromBlackListResponse, ContactsRemoveFromBlackListRequest>('contacts/contact/multi-add-blacklist', data);
const removeMultiBlackListContact = async (data: ContactRemoveMultiBlackListRequest) => DashboardApi.post<ContactsRemoveFromBlackListResponse, ContactRemoveMultiBlackListRequest>('contacts/contact/remove-multi-blacklist', data);
const listContact = async (data: ContactListGetRequest) => DashboardApi.get<ContactListResponse, ContactListGetRequest>('contacts/contact/with-pagination', data);
const searchContacts = async (data: ContactsSearchRequest) => DashboardApi.get<ContactsSearchResponse, ContactsSearchRequest>(`contacts/contact/search/${data.searchkey}`, data);
const getHubspotCrmCallback = async (data: HubspotCrmCallBackRequest) => DashboardApi.post<HubspotCrmCallBackResponse, HubspotCrmCallBackRequest>('contacts/contact/hubspot-callback', data);
const getZohoCrmCallback = async (data: HubspotCrmCallBackRequest) => DashboardApi.post<HubspotCrmCallBackResponse, HubspotCrmCallBackRequest>('contacts/contact/zoho-callback', data);
const deleteAllContacts = async () => DashboardApi.post<ContactsAllDeleteResponse>('contacts/contact/all-delete');

export {
  createSingleContact,
  updateContact,
  createBulkContact,
  deleteContact,
  deleteMultipleContacts,
  blackListSingleContact,
  removeContactsFromBlackList,
  removeContactFromBlackList,
  removeMultiBlackListContact,
  addMultipleContactsToGroup,
  importContact,
  listContact,
  searchContacts,
  uploadFile,
  getFileHeaders,
  getHubspotCrmCallback,
  getZohoCrmCallback,
  listAllContact,
  deleteAllContacts
};
