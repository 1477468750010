// import { LoginRequest } from 'models/requests';
import { LoginResponse } from 'models/responses';
import AuthApi from "./index";


const login = async (data:any) => AuthApi.post<LoginResponse>(`login/user`, data, true, {
  'Content-Type': 'application/x-www-form-urlencoded'
});
const getTestToken = async () => AuthApi.get<LoginResponse>('login/get-test-token');
const refreshToken = (token: string) => AuthApi.post<LoginResponse>('login/user/refresh', undefined, true, {
  'Authorization': `Bearer ${token}`
});

export {
  login,
  refreshToken,
  getTestToken
};