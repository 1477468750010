import React from 'react';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import { useSelector } from "react-redux";
import utils from 'utils';
import { Grid } from 'antd';
import { RootState } from 'App';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props: { mobileLogo: boolean, isMobile: boolean, navCollapsed: boolean, navType: string }) => {
  const { mobileLogo, navCollapsed, navType, isMobile } = props;

  const isNavTop = navType === NAV_TYPE_TOP;
  if (isMobile && !mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return 'auto';
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  }
  return `${SIDE_NAV_WIDTH}px`;
};

const getLogo = (props: { mobileLogo?: boolean; navCollapsed?: boolean; logoType?: string; }) => {
  const { navCollapsed, logoType } = props;
  if (logoType === 'light') {
    if (navCollapsed) {
      return '/img/logo-sm-white.png';
    }
    return '/img/logo-white.png';
  }

  if (navCollapsed) {
    return '/img/logo-sm.png';
  }
  return '/img/logo.png';
};

const getLogoDisplay = (isMobile: boolean, mobileLogo: boolean) => {
  if (isMobile && !mobileLogo) {
    return 'd-none';
  }
  return 'logo';
};

export const Logo = (props: { mobileLogo?: boolean, logoType?: string }) => {
  const { navCollapsed, navType } = useSelector((state: RootState) => state.theme);
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const { mobileLogo = false, logoType } = props;
  return (
    <div
      className={getLogoDisplay(isMobile, mobileLogo)}
      style={{ width: `${getLogoWidthGutter({ navCollapsed, navType, isMobile, mobileLogo })}` }}>
      <img src={getLogo({ navCollapsed, mobileLogo, logoType })} alt={`${APP_NAME} logo`} />
    </div>
  );
};

Logo.defaultProps = {
  logoType: 'light',
  mobileLogo: false
};
export default Logo;
