import React, { useState, useRef, useEffect } from "react";
import { Button, notification, Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faDownload,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";

interface WhatsappVideoAttachmentProps {
  id?: string | null;
  videoUrl: string | null;
  mediaSrc: string | null;
  handleDownload: () => void;
  isDownloading: boolean;
  downloadProgress: number;
}

const WhatsappVideoAttachment: React.FC<WhatsappVideoAttachmentProps> = ({
  id,
  videoUrl,
  mediaSrc,
  handleDownload,
  isDownloading,
  downloadProgress,
}) => {
  const containerStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "white",
    borderRadius: "4px",
    border: "1px solid #d9d9d9",
    overflow: "hidden",
  };

  const videoContainerStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    height: "auto",
  };

  const playPauseButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    display: "block",
    fontSize: "17px",
  };

  const controlsContainerStyle: React.CSSProperties = {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    color: "white",
    padding: "5px 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: 0,
    transition: "opacity 0.3s",
    zIndex: 2,
  };

  const actionButtonStyle: React.CSSProperties = {
    fontSize: "14px",
  };

  const progressContainerStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 3,
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  const timeStyle: React.CSSProperties = {
    color: "#fff",
    fontSize: "12px",
    textAlign: "left",
    marginTop: "2px",
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  };

  const placeholderContainerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#888",
    width: "250px",
    height: "150px",
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
    position: "relative",
    maxWidth: "400px",
    overflow: "hidden",
  };

  const placeholderImageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    filter: (videoUrl === null && id === null) ? "none" : "blur(5px)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  };

  const buttonStyle: React.CSSProperties = {
    zIndex: 2,
    fontSize: "16px",
    color: "white",
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const video = videoRef.current;

    if (!video) return () => {};

    const setVideoData = () => {
      setDuration(video.duration);
      setCurrentTime(video.currentTime);
    };

    const setVideoTime = () => {
      if (video.duration) {
        setCurrentTime(video.currentTime);
        setProgress((video.currentTime / video.duration) * 100);
      }
    };

    const handleEnd = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      setProgress(0);
    };

    video.addEventListener("loadedmetadata", setVideoData);
    video.addEventListener("timeupdate", setVideoTime);
    video.addEventListener("ended", handleEnd);

    return () => {
      if (video) {
        video.removeEventListener("loadedmetadata", setVideoData);
        video.removeEventListener("timeupdate", setVideoTime);
        video.removeEventListener("ended", handleEnd);
      }
    };
  }, [mediaSrc]);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play().catch((error) =>{
          //  console.error("Error playing video:", error);
           notification.error({
            message: "Error playing video",
            description: error.message,
           });
          });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const downloadVideo = () => {
    if (mediaSrc) {
      const link = document.createElement("a");
      link.href = mediaSrc;
      link.download = "video.mp4";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFullScreen = () => {
    const video = videoRef.current;
    if (video) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if ((video as any).mozRequestFullScreen) {
        (video as any).mozRequestFullScreen();
      } else if ((video as any).webkitRequestFullscreen) {
        (video as any).webkitRequestFullscreen();
      } else if ((video as any).msRequestFullscreen) {
        (video as any).msRequestFullscreen();
      }
    }
  };

  const handleProgressClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const video = videoRef.current;
    if (video) {
      const progressBar = event.currentTarget;
      const clickPosition =
        event.clientX - progressBar.getBoundingClientRect().left;
      const newTime =
        (clickPosition / progressBar.offsetWidth) * video.duration;
      video.currentTime = newTime;
      setCurrentTime(newTime);
      setProgress((newTime / video.duration) * 100);
    }
  };

  return mediaSrc ? (
    <div
      style={containerStyle}
      onMouseEnter={() => {
        const controls = document.getElementById("video-controls");
        const playPauseButton = document.getElementById("play-pause-button");
        if (controls) controls.style.opacity = "1";
        if (playPauseButton) playPauseButton.style.display = "block";
      }}
      onMouseLeave={() => {
        const controls = document.getElementById("video-controls");
        const playPauseButton = document.getElementById("play-pause-button");
        if (controls) controls.style.opacity = "0";
        if (playPauseButton && !isPlaying)
          playPauseButton.style.display = "block";
      }}
    >
      <div style={videoContainerStyle}>
        <video
          ref={videoRef}
          src={mediaSrc}
          preload="auto"
          controls={false}
          style={{
            width: "100%",
            display: "block",
            maxHeight: "440px",
          }}
        >
          <track kind="captions" />
        </video>
        <Button
          id="play-pause-button"
          type="primary"
          shape="circle"
          icon={<FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />}
          onClick={togglePlayPause}
          style={playPauseButtonStyle}
        />
        <div id="video-controls" style={controlsContainerStyle}>
          <div style={progressContainerStyle} onClick={handleProgressClick} onKeyDown={() => handleProgressClick} role="button" tabIndex={0}>
            <Progress
              percent={progress}
              showInfo={false}
              strokeColor="#25d366"
              style={progressStyle}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              zIndex: 2,
            }}
          >
            <div style={timeStyle}>
              {formatTime(currentTime)} / {formatTime(duration)}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Button
                type="text"
                icon={<FontAwesomeIcon icon={faDownload} />}
                onClick={downloadVideo}
                style={actionButtonStyle}
              />
              <Button
                type="text"
                icon={<FontAwesomeIcon icon={faExpand} />}
                onClick={handleFullScreen}
                style={actionButtonStyle}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={placeholderContainerStyle} onClick={handleDownload} onKeyDown={() => handleDownload} role="button" tabIndex={0}>
      <img
        src="https://placehold.co/600x400?text=Video+Unavailable!\nAsk+your+customer+to+resend\nthe+video."
        alt="Placeholder"
        style={placeholderImageStyle}
      />
      {!isDownloading && !mediaSrc && videoUrl && (
        <Button
          type="primary"
          shape="circle"
          icon={<FontAwesomeIcon icon={faDownload} />}
          style={buttonStyle}
        />
      )}
      {isDownloading && videoUrl && (
        <div style={progressContainerStyle}>
          <Progress
            type="circle"
            percent={downloadProgress}
            strokeWidth={12}
            width={50}
            strokeColor="green"
          />
        </div>
      )}
    </div>
  );
};

WhatsappVideoAttachment.defaultProps = {
  id: undefined
};
export default WhatsappVideoAttachment;
