import React from 'react';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

const ConversationEmpty: React.FC = () => (
  <div className="chat-content-empty">
    <div className="text-center">
      <img src="/img/others/entry-image-light.webp" alt="whatsapp" />
      <Title>WhatsApp Inbox</Title>
      <Text>View and reply to all your Whatsapp messages.</Text>
    </div>
  </div>
);

export default ConversationEmpty;

