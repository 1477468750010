import {
  BlockedRegisterRequest,
  OpenRegisterRequest,
  ResendNewAccountEmailRequest,
  SignupEmailValidateRequest,
  OpenRegisterQueryRequest
} from 'models/requests';
import {
  BlockedRegisterResponse,
  OpenRegisterResponse,
  NewAccountEmailResendResponse,
  SignupEmailValidateResponse
} from 'models/responses';
import AuthApi from ".";

const validateSignupEmail = async (data: SignupEmailValidateRequest) => AuthApi.post<SignupEmailValidateResponse, SignupEmailValidateRequest>('register/validate-signup-email', data, true);
const openRegister = async (data: OpenRegisterRequest, queryData:OpenRegisterQueryRequest) => AuthApi.postWithQueryParams<OpenRegisterResponse, OpenRegisterQueryRequest, OpenRegisterRequest>('register/open', queryData, data, true);
const blockedRegister = async (data: BlockedRegisterRequest) => AuthApi.post<BlockedRegisterResponse, BlockedRegisterRequest>('register/blocked-registrations', data, true);
const resendNewAccountEmail = async (data: ResendNewAccountEmailRequest) => AuthApi.post<NewAccountEmailResendResponse, ResendNewAccountEmailRequest>('register/resend-new-account-email', data, true);

export {
  validateSignupEmail,
  openRegister,
  blockedRegister,
  resendNewAccountEmail
};
