import React from 'react';
import {BaseEdge, EdgeLabelRenderer, EdgeProps, useReactFlow, getBezierPath} from 'reactflow';
import './index.css';

const ButtonEdge  = ({
                        id,
                        sourceX,
                        sourceY,
                        targetX,
                        targetY,
                        sourcePosition,
                        targetPosition,
                        style = {},
                        markerEnd,
                    }: EdgeProps) => {

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
  const {setEdges} = useReactFlow();
    const onEdgeClick = (evt: any) => {
        evt.stopPropagation();
        setEdges((eds) => eds.filter((e) => e.id !== id));

    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style}/>
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button className="edgebutton" onClick={(event) => onEdgeClick(event)}>
                        ×
                    </button>
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export default ButtonEdge;
