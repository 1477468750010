import React, {useEffect} from 'react';
import {
  Col,
  Form,
  Input,
  Modal, Switch, Tooltip
} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {WhatsappTemplateDetails} from "../../../../../../models";


export interface StepTwoLTOComponentProps {
  editItem?: WhatsappTemplateDetails;
  isTemplateTypeChange?: boolean;
  isCategoryTypeChange?: boolean;
  form?: any;
  inputDirection?: any;
  currentStep: number;
}

const StepTwoLTOComponent = (props: StepTwoLTOComponentProps) => {
  const {currentStep, form, inputDirection, editItem, isTemplateTypeChange, isCategoryTypeChange} = props;

  useEffect(() => {
    if (editItem) {
      // setFooterText(editItem.footer_text || '');
    }

    if (isTemplateTypeChange){
      form.setFieldsValue({ limited_time_offer: {limited_time_offer_text: undefined }});
    }
    if (isCategoryTypeChange){
      form.setFieldsValue({ limited_time_offer: {limited_time_offer_text: undefined }});
    }
  }, [editItem, currentStep]);


  return (
    <>
      <Col xs={24} sm={12} md={12}>
        <Form.Item
          label={
            <span>
      Offer details text
      <Tooltip title="Heading of delivered message with offer expiration details.">
        <InfoCircleOutlined
          style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
        />
      </Tooltip>
    </span>
          }
          name={['limited_time_offer', 'limited_time_offer_text']}
          labelCol={{span: 8}}
          labelAlign='left'
          extra="Maximum length is 16 chars"
          rules={[
            {
              required: true,
              message: 'Please enter Offer details text',
            },
            {
              max: 16,
              message: 'Offer details text cannot exceed 16 characters',
            }
          ]}

        >
          <Input dir={inputDirection}/>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={12}>
        <Form.Item
          label={
            <span>
              Has Expiration
              <InfoCircleOutlined
                style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                onClick={() => {
                  Modal.info({
                    title: "Has Expiration",
                    content: (
                      <div>
                        <p>Set to true to have the offer expiration details appear in the delivered
                          message.</p>
                        <p>If set to true, the copy code button component must be included in the buttons
                          array.</p>
                        <p>If set to false, offer expiration details will not appear in the delivered
                          message and the copy code button component is optional</p>
                      </div>
                    ),
                  });
                }}
              />
          </span>
          }
          name={['limited_time_offer', 'has_expiration']}
          labelCol={{span: 7}}
          labelAlign='left'
          valuePropName="checked"
          getValueProps={(value) => ({checked: value})}
          getValueFromEvent={(checked) => checked}
        >
          <Switch/>
        </Form.Item>
      </Col>
    </>
  );
};
StepTwoLTOComponent.defaultProps = {
  inputDirection: undefined,
  editItem: undefined,
  isCategoryTypeChange: undefined,
  isTemplateTypeChange: undefined,
  form: undefined,
};


export default StepTwoLTOComponent;
