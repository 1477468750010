export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE';
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE';
export const SHOW_LOADING = 'SHOW_LOADING';
export const AUTH_TOKEN = 'auth_token';
export const REFRESH_TOKEN = 'refresh_token';
export const FETCH_USER = 'FETCH_USER';

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';


export const USER_ACL_LIST = 'USER_ACL_LIST';
export const FETCH_ACL_SUCCESS = 'FETCH_ACL_SUCCESS';

export const NAVIGATE_TO_HOME = 'NAVIGATE_TO_HOME';

export const PRELOAD_DATA = 'PRELOAD_DATA';
export const PRELOAD_DATA_SUCCESS = 'PRELOAD_DATA_SUCCESS';

export const SET_USER = 'SET_USER';
export const SET_USER_ACL = 'SET_USER_ACL';
export const SET_USER_WALLET = 'SET_USER_WALLET';
export const SET_USER_TEST_CREDIT = 'SET_USER_TEST_CREDIT';

export const SET_PERMISSION_MAPS = 'SET_PERMISSION_MAPS';

export const FETCH_USER_WALLET = 'FETCH_USER_WALLET';

export const SET_LOCAL_CURRENCY = 'SET_LOCAL_CURRENCY';
