import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import reducers from "../reducers";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const configureStore = (preloadedState = {}) => {
  const composeEnhancers = compose;
  const store = createStore(reducers, preloadedState, composeEnhancers(
    applyMiddleware(...middlewares)
  ));
  sagaMiddleware.run(rootSaga);

  return store;
};

const store = configureStore();

export default store;


