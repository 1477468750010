import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
// import PageHeader from 'components/layout/PageHeader';
import { WhatsappCampaign} from "models";
import WhatsappCampaignForm from "../../../components/whatsapp/WhatsappCampaign/WhatsappCampaignForm";
import {getWhatsappCampaignService} from "../../../services/dashboard/WhatsappCampaignService";


const WhatsappCampaignFormPage = () => {
  const { id } = useParams();
  const [whatsappCampaign, setCampaign] = useState<WhatsappCampaign | undefined>();

  const fetchWhatsappCampaign = async () => {
    try {
      if (id !== undefined) {
        const response = await getWhatsappCampaignService(id);
        setCampaign(response);
      }
    } catch (error) {
      setCampaign(undefined);
    }
  };

  useEffect(() => {

    Promise.all([fetchWhatsappCampaign()]);
  }, [id]);

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          {/* <PageHeader display title="page.whatsapp.whatsappCampaign" showBreadCrumb={false} /> */}
            <WhatsappCampaignForm whatsappCampaign={whatsappCampaign} />
        </Col>
      </Row>
    </div>
  );
};

export default WhatsappCampaignFormPage;
