import {
  ContactListGetRequest,
  GroupCreateRequest,
  GroupDeleteRequest,
  GroupUpdateRequest
} from 'models/requests';
import {
  ContactsByGroupResponse,
  GroupCreateResponse,
  GroupDeleteResponse,
  GroupListResponse,
  GroupUpdateResponse
} from 'models/responses';

import DashboardApi from ".";
import {ContactGroup} from "../../models";

const createGroup = async (data: GroupCreateRequest) => DashboardApi.post<GroupCreateResponse, GroupCreateRequest>('contacts/group', data);
const listGroup = async () => DashboardApi.get<GroupListResponse>(`contacts/group`);
const deleteGroup = async (data: GroupDeleteRequest) => DashboardApi.delete<GroupDeleteResponse, GroupDeleteRequest>(`contacts/group/${data.group_id}`);
const updateGroup = async (data: GroupUpdateRequest) => DashboardApi.put<GroupUpdateResponse, GroupUpdateRequest>(`contacts/group/${data.group_id}`, data);
const getContactsByGroup = async (groupId: number) => DashboardApi.get<ContactsByGroupResponse>(`contacts/group/contacts-under-group/${groupId}`);
const getContactsByGroupPaginate = async (groupId: number, data: ContactListGetRequest) => DashboardApi.get<ContactsByGroupResponse>(`contacts/group/contacts-under-group-paginate/${groupId}`, data);
const syncContactsIntegrations = async (groupId: number, integrationId: any) => DashboardApi.post<ContactsByGroupResponse>(`integrations/sync-contact-integrations/${integrationId}/${groupId}`);
const getMappingFromGroup = async (groupId: number) => DashboardApi.get<ContactGroup>(`contacts/group/group-mapping/${groupId}`);

export {
  createGroup,
  updateGroup,
  deleteGroup,
  listGroup,
  getContactsByGroup,
  syncContactsIntegrations,
  getMappingFromGroup, getContactsByGroupPaginate
};
