import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import UserPreference from "./UserPreference";

const AccountPreference = lazy(() => import("./AccountPreference"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const SubUsers = lazy(() => import("./SubUsers"));
const SubAccounts = lazy(() => import("./SubAccounts"));

const UserPages = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/users/preference" replace />} />
    <Route path='preference/*' element={<UserPreference />}>
      <Route path="account" element={<AccountPreference />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="sub-users" element={<SubUsers />} />
      <Route path="sub-accounts" element={<SubAccounts />} />
      <Route path="*" element={<Navigate to="/error/404" replace />} />
    </Route>
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default UserPages;