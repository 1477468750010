
const formErrors = new Map<string, boolean>();
const useFormErrors = () => {
  const updateFormErrors = (id: string, hasError: boolean) => {
    formErrors.set(id,hasError);
  };
  const deleteFormError = (id:string) =>{
    formErrors.delete(id);
  };
  return { formErrors, updateFormErrors, deleteFormError };
};

export default useFormErrors;
