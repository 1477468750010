import { listApplication, createApplication, updateApplication, deleteApplication, getApplicationToken, getApplicationTokenById, createApplicationAndGetToken } from 'api/auth/ApplicationApi';
import {
  ApplicationWithTokenCreateRequest,
  // ApplicationTokenGetRequest,
  ApplicationDeleteRequest,
  ApplicationCreateRequest,
  ApplicationRequest
} from 'models/requests';


const listApplicationService = async () => listApplication();
const createApplicationService = async (data: ApplicationCreateRequest) => createApplication(data);
const updateApplicationService = async (data: ApplicationRequest, appId: number) => updateApplication(data, appId);
const deleteApplicationService = async (data: ApplicationDeleteRequest) => deleteApplication(data);
const getApplicationTokenService = async (data: any) => getApplicationToken(data);
const getApplicationTokenByIdService = async (appId: number) => getApplicationTokenById(appId);
const createApplicationAndGetTokenService = async (req: ApplicationWithTokenCreateRequest) => createApplicationAndGetToken(req);
export {
  listApplicationService,
  createApplicationService,
  updateApplicationService,
  deleteApplicationService,
  getApplicationTokenService,
  getApplicationTokenByIdService,
  createApplicationAndGetTokenService
};
