import React,{useState,useEffect } from 'react';
import {ClearOutlined, DownloadOutlined, SnippetsOutlined, CopyOutlined, ReloadOutlined, UserOutlined} from '@ant-design/icons';
import moment from 'moment';
import en from 'world_countries_lists/data/countries/en/world.json';
import {ColumnsType} from 'antd/es/table';
import { WhatsappApiReportModel} from "models";
import TableListReport from 'components/shared/TableListReport';
import {Col, DatePicker, Form, Input, Modal, Row, Select, notification, Tooltip} from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import {WhatsappApiReportFilterRequest} from "models/requests/WhatsappApiReportFilterRequest";
import TextArea from 'antd/lib/input/TextArea';
import { WhatsappApiReportExportRequest } from "../../../models/requests";

export interface ApiReportProps {
  reports: WhatsappApiReportModel[];
  loading?: boolean;
  // onError: (msg: string) => void;
  onPaginate?: (page: number, pageSize: number,data:WhatsappApiReportFilterRequest, searchText?: string) => void
  fetchByFilter: (data: WhatsappApiReportFilterRequest) => void;
  onExportLog?:(data: WhatsappApiReportExportRequest) => void;
  total: number;
}

const ApiReportList = (props: ApiReportProps) => {
  const {reports, loading, total, fetchByFilter,onExportLog,onPaginate} = props;
  const lastMonth = moment().subtract(1, 'month');
  const [startDate, setStartDate] = useState(lastMonth);
  const [endDate, setEndDate] = useState(moment());
  const [searchData, setSearchData] = useState<WhatsappApiReportFilterRequest>();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const {RangePicker} = DatePicker;
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [messageType, setMessageType] = useState<string>();
  const [interactiveType, setInteractiveType] = useState<string>();
  const [eventType, setEventType] = useState<string>();
  const [mediaType, setMediaType] = useState<string>();
  const [reason, setReason] = useState<string>();
  const [showLogModal, setShowLogModal] = useState(false);
  const [logForm] = Form.useForm();
  const [msgInfo, setMsgInfo] = useState<string>("hlo");


  const downloadReport = async (data: WhatsappApiReportExportRequest) => {

    if (data){
      onExportLog?.(data);
    }
  };

  const searchRefresh = async (data: WhatsappApiReportFilterRequest) => {

    if (data){
      fetchByFilter?.(data);
    }
  };

  const onSearch = async (value: string) => {

    setSearchText(value);
    const data = {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      page,
      size: pageSize,
      ...(selectedStatus && {whatsapp_status: selectedStatus.toLowerCase()}),
      ...(selectedCountry && {country: selectedCountry}),
    };
    onPaginate?.(1, pageSize,data,value);
  };


  const onClickLog = (item: WhatsappApiReportModel) => {
    setMessageType((item.media_type || item.quick_replies || item.actions || item.text_header_title)? "MEDIA_TEMPLATE":item.message_type);
    setReason(item.gw_status);
    logForm.resetFields();
    logForm.setFieldsValue({
      'msg_id': item.msg_id,
      'date': new Date(item.created_at).toDateString().toString(),
      'originator': item.originator,
      'recipients': `${item.recipient} - ${item.country ?? ''}`,
      'conversation_id': item.waConvId,
      'message_type': item.message_type,
      'status': item.status,
      'gw_status': item.gw_status,
      'mid': item.mid,
      'rate': item.rate,
      'req_id': item.req_id
    });
    let bodyParam = '';
    if (Object.keys(item.body_parameter_values).length > 0){
      Object.entries(item.body_parameter_values).forEach(([key, value], index) => {
        bodyParam += `${key} - ${value}  `;
      });
    }
    if (item.media_type || item.quick_replies || item.actions || item.text_header_title){
      setMediaType(item.media_type);
      logForm.setFieldsValue({
        'template_id': item.template_id,
        'body_parameter_values': bodyParam,
        'media_type': item.media_type,
        'media_url': item.media_url,
        'media_caption': item.media_caption,
        'document_file_name': item.document_file_name,
        'text_header_title': item.text_header_title
      });
      setMsgInfo(`
            Request ID:  ${item.req_id} \n
            Message ID: ${item.msg_id} \n
            Date: ${new Date(item.created_at).toDateString().toString()} \n
            Originator: ${item.originator} \n
            Recipient: ${item.recipient} - ${item.country ?? ''} \n
            Conversational ID: ${item.waConvId} \n
            Message Type: ${item.message_type} \n
            Template ID: ${item.template_id} \n
            Parameter Values: ${bodyParam} \n
            Media URL: ${item.media_url} \n
            Media Caption: ${item.media_caption} \n
            Doc File Name: ${item.document_file_name} \n
            Text Header: ${item.text_header_title} \n
            Status: ${item.status} \n
            Reason: ${item.gw_status} \n
            Cost: ${item.rate}`
      );
      if (item.media_type==="location"){
        logForm.setFieldsValue({
          'template_id': item.template_id,
          'body_parameter_values': bodyParam,
          'media_type': item.media_type,
          'location_longitude': item.location_longitude,
          'location_latitude': item.location_latitude,
          'location_name': item.location_name,
          'location_address': item.location_address
        });
        setMsgInfo(`
            Request ID:  ${item.req_id} \n
            Message ID: ${item.msg_id} \n
            Date: ${new Date(item.created_at).toDateString().toString()} \n
            Originator: ${item.originator} \n
            Recipient: ${item.recipient} - ${item.country ?? ''} \n
            Conversational ID: ${item.waConvId} \n
            Message Type: ${item.message_type} \n
            Template ID: ${item.template_id} \n
            Parameter Values: ${bodyParam} \n
            Longitude of Location: ${item.location_longitude} \n
            Latitude of Location: ${item.location_latitude} \n
            Location Name: ${item.location_name} \n
            Location Address: ${item.location_address} \n
            Status: ${item.status} \n
            Reason: ${item.gw_status} \n
            Cost: ${item.rate}`
        );
      }
      if (item.quick_replies?.quick_replies.length >0){
        let replyButton='';
        item.quick_replies?.quick_replies.map(element=>{
          replyButton += `index: ${element.index}, payload: ${element.payload} \n`;
          return replyButton;
        });
        logForm.setFieldsValue({
          'quick_replies': replyButton
        });
        setMsgInfo(`
            Request ID:  ${item.req_id} \n
            Message ID: ${item.msg_id} \n
            Date: ${new Date(item.created_at).toDateString().toString()} \n
            Originator: ${item.originator} \n
            Recipient: ${item.recipient} - ${item.country ?? ''} \n
            Conversational ID: ${item.waConvId} \n
            Message Type: ${item.message_type} \n
            Template ID: ${item.template_id} \n
            Parameter Values: ${bodyParam} \n
            Media URL: ${item.media_url} \n
            Media Caption: ${item.media_caption} \n
            Doc File Name: ${item.document_file_name} \n
            Text Header: ${item.text_header_title} \n
            Quick Reply Button: ${replyButton} \n
            Status: ${item.status} \n
            Reason: ${item.gw_status} \n
            Cost: ${item.rate}`
        );
      }

      if (item.actions?.actions.length >0){
        let actionButton='';
        item.actions?.actions.map(element=>{
          actionButton += `type: ${element.type}, index: ${element.index}, payload: ${element.payload} \n`;
          return actionButton;
        });
        logForm.setFieldsValue({
          'actions': actionButton
        });
        setMsgInfo(`
            Request ID:  ${item.req_id} \n
            Message ID: ${item.msg_id} \n
            Date: ${new Date(item.created_at).toDateString().toString()} \n
            Originator: ${item.originator} \n
            Recipient: ${item.recipient} - ${item.country ?? ''} \n
            Conversational ID: ${item.waConvId} \n
            Message Type: ${item.message_type} \n
            Template ID: ${item.template_id} \n
            Parameter Values: ${bodyParam} \n
            Media URL: ${item.media_url} \n
            Media Caption: ${item.media_caption} \n
            Doc File Name: ${item.document_file_name} \n
            Text Header: ${item.text_header_title} \n
            Call To Action Button: ${actionButton} \n
            Status: ${item.status} \n
            Reason: ${item.gw_status} \n
            Cost: ${item.rate}`
        );
      }
    }
    else if (item.message_type === "TEMPLATE"){
      logForm.setFieldsValue({
        'template_id': item.template_id,
        'body_parameter_values': bodyParam
      });
      setMsgInfo(`
            Request ID:  ${item.req_id} \n
            Message ID: ${item.msg_id} \n
            Date: ${new Date(item.created_at).toDateString().toString()} \n
            Originator: ${item.originator} \n
            Recipient: ${item.recipient} - ${item.country ?? ''} \n
            Conversational ID: ${item.waConvId} \n
            Message Type: ${item.message_type} \n
            Template ID: ${item.template_id} \n
            Template Parameter Values: ${bodyParam} \n
            Status: ${item.status} \n
            Reason: ${item.gw_status} \n
            Cost: ${item.rate}`
      );
    }
    else if (item.message_type === "AUTO_TEMPLATE" || item.message_type==="TEXT"){
      logForm.setFieldsValue({
        'message_text': item.message_text,
      });
      setMsgInfo(`
            Request ID:  ${item.req_id} \n
            Message ID: ${item.msg_id} \n
            Date: ${new Date(item.created_at).toDateString().toString()} \n
            Originator: ${item.originator} \n
            Recipient: ${item.recipient} - ${item.country ?? ''} \n
            Conversational ID: ${item.waConvId} \n
            Message Type: ${item.message_type} \n
            Message Text: ${item.message_text} \n
            Status: ${item.status} \n
            Reason: ${item.gw_status} \n
            Cost: ${item.rate}`
      );
    }
    else if (item.message_type === "ATTACHMENT"){
      logForm.setFieldsValue({
        'attachment_type': item.attachment_type,
        'attachment_mime_type': item.attachment_mime_type,
        'attachment_caption': item.attachment_caption,
        'attachment_data': item.attachment_data,
        'attachment_url': item.attachment_url
      });
      setMsgInfo(`
        Request ID:  ${item.req_id} \n
        Message ID: ${item.msg_id} \n
        Date: ${new Date(item.created_at).toDateString().toString()} \n
        Originator: ${item.originator} \n
        Recipients: ${item.recipient} - ${item.country ?? ''} \n
        Conversational ID: ${item.waConvId} \n
        Message Type: ${item.message_type} \n
        Attachment Type: ${item.attachment_type} \n
        Attachment MIME Type: ${item.attachment_mime_type} \n
        Attachment Caption: ${item.attachment_caption} \n
        Attachment Data: ${item.attachment_data} \n
        Attachment URL: ${item.attachment_url} \n
        Status: ${item.status} \n
        Reason: ${item.gw_status} \n
        Cost: ${item.rate}`
      );
    }
    else if (item.message_type === "LOCATION"){
      logForm.setFieldsValue({
        'location_longitude': item.location_longitude,
        'location_latitude': item.location_latitude,
        'location_name': item.location_name,
        'location_address': item.location_address
      });
      setMsgInfo(`
        Request ID:  ${item.req_id} \n
        Message ID: ${item.msg_id} \n
        Date: ${new Date(item.created_at).toDateString().toString()} \n
        Originator: ${item.originator} \n
        Recipients: ${item.recipient} - ${item.country ?? ''} \n
        Conversational ID: ${item.waConvId} \n
        Message Type: ${item.message_type} \n
        Longitude of Location: ${item.location_longitude} \n
        Latitude of Location: ${item.location_latitude} \n
        Location Name: ${item.location_name} \n
        Location Address: ${item.location_address} \n
        Status: ${item.status} \n
        Reason: ${item.gw_status} \n
        Cost: ${item.rate}`
      );
    }
    else if (item.message_type === "CONTACTS"){
      if (item.event_type !== "USER_INITIATED"){
          logForm.setFieldsValue({
            'contact_first_name': item.contact?.first_name,
            'contact_last_name': item.contact?.last_name,
            'contact_display_name': item.contact?.display_name,
            'contact_phone': item.contact?.phone,
            'contact_email': item.contact?.email,
            'contact_url': item.contact?.url
          });
          setMsgInfo(`
          Request ID:  ${item.req_id} \n
          Message ID: ${item.msg_id} \n
          Date: ${new Date(item.created_at).toDateString().toString()} \n
          Originator: ${item.originator} \n
          Recipients: ${item.recipient} - ${item.country ?? ''} \n
          Conversational ID: ${item.waConvId} \n
          Message Type: ${item.message_type} \n
          First Name: ${item.contact?.first_name} \n
          Last Name: ${item.contact?.last_name} \n
          Display Name: ${item.contact?.display_name} \n
          Phone: ${item.contact?.phone} \n
          Email: ${item.contact?.email} \n
          Url: ${item.contact?.url} \n
          Status: ${item.status} \n
          Reason: ${item.gw_status} \n
          Cost: ${item.rate}`
          );
      }
      else{
        const contact = item.contact?.contacts?.length>0?item.contact.contacts[0]:null;
        const contactsLen = item.contact?.contacts?.length;
        if (contact){
          logForm.setFieldsValue({
            'contact_first_name': contact.name.first_name,
            'contact_last_name': contact.name.last_name,
            'contact_display_name':  contact.name.formatted_name,
            'contact_phone':  contact.phones?.length>0?contact.phones[0]?.phone:'',
            'contact_email': contact.emails?.length>0?contact.emails[0]?.email:'',
            'contact_url': contact.urls?.length>0?contact.urls[0]?.url:''
          });
          if (contactsLen>1){
            logForm.setFieldsValue({
              'message_type': `${item.message_type} - ${contactsLen} Contacts Included.`
            });
          }
          setMsgInfo(`
          Request ID:  ${item.req_id} \n
          Message ID: ${item.msg_id} \n
          Date: ${new Date(item.created_at).toDateString().toString()} \n
          Originator: ${item.originator} \n
          Recipients: ${item.recipient} - ${item.country ?? ''} \n
          Conversational ID: ${item.waConvId} \n
          Message Type: ${item.message_type} \n
          First Name: ${contact.name.first_name} \n
          Last Name: ${contact.name.last_name} \n
          Display Name: ${contact.name.formatted_name} \n
          Phone: ${contact.phones?.length>0?contact.phones[0]?.phone:''} \n
          Email: ${contact.emails?.length>0?contact.emails[0]?.email:''} \n
          Url: ${contact.urls?.length>0?contact.urls[0]?.url:''} \n
          Status: ${item.status} \n
          Reason: ${item.gw_status} \n
          Cost: ${item.rate}`
          );
        }

      }
    }
    else if(item.message_type === "INTERACTIVE"){
      if (item.event_type !== "USER_INITIATED") {
        setInteractiveType(item.interactive_type);
        if (item.interactive_header_type === "text") {
          logForm.setFieldsValue({'header_text': item.attachment_caption});
        } else {
          logForm.setFieldsValue({'header_text': item.attachment_url});
        }
        logForm.setFieldsValue({
          'interactive_type': item.interactive_type,
          'header_type': item.interactive_header_type,
          'body_text': item.message_text,
          'footer_text': item.interactive_footer_text
        });
        let listAction = '';
        let buttonAction = '';
        if (item.interactive_type === "list") {
          item.interactive_list_action.sections.map(element => {
            listAction += `Section Title: ${element.title} \n`;
            element.rows.map(p => {
              listAction += `Id: ${p.id}, Title: ${p.title}, Description: ${p.description} \n`;
              return listAction;
            });
            return listAction;
          });
          logForm.setFieldsValue({
            'button': item.interactive_list_action.button,
            'sections': listAction
          });
        }
        if (item.interactive_type === "button") {
          item.interactive_reply_button_action.buttons.map(element => {
            buttonAction += `Id: ${element.reply.id}, Title: ${element.reply.title} \n`;
            return buttonAction;
          });
          logForm.setFieldsValue({
            'buttons': buttonAction
          });
        }
        setMsgInfo(`
        Request ID:  ${item.req_id} \n
        Message ID: ${item.msg_id} \n
        Date: ${new Date(item.created_at).toDateString().toString()} \n
        Originator: ${item.originator} \n
        Recipients: ${item.recipient} - ${item.country ?? ''} \n
        Conversational ID: ${item.waConvId} \n
        Message Type: ${item.message_type} \n
        Interactive Type: ${item.interactive_type} \n
        Header Type: ${item.interactive_header_type} \n
        Header Value: ${item.attachment_caption} ${item.attachment_url} \n
        Body Text: ${item.message_text} \n
        Footer Text: ${item.interactive_footer_text} \n
        Status: ${item.status} \n
        Reason: ${item.gw_status} \n
        Cost: ${item.rate} \n
        button: ${item.interactive_list_action?.button} \n
        sections: ${listAction} \n
        buttons: ${buttonAction}`
        );
      }
      else {
        setEventType(item.event_type);
        if (item.interactive_list_action?.list_reply) {
          setInteractiveType("list");
          const listAction = `Id: ${item.interactive_list_action?.list_reply.id}\nTitle: ${item.interactive_list_action?.list_reply?.title}\nDescription: ${item.interactive_list_action?.list_reply?.description}`;
          logForm.setFieldsValue({
            'sections': listAction
          });
        }
        if (item.interactive_reply_button_action?.button_reply) {
          setInteractiveType("button");
          const buttonAction = `Id: ${item.interactive_reply_button_action?.button_reply.id}\nTitle: ${item.interactive_reply_button_action?.button_reply?.title}\n`;
          logForm.setFieldsValue({
            'buttons': buttonAction
          });
        }
        setMsgInfo(`
        Request ID:  ${item.req_id} \n
        Message ID: ${item.msg_id} \n
        Date: ${new Date(item.created_at).toDateString().toString()} \n
        Originator: ${item.originator} \n
        Recipients: ${item.recipient} - ${item.country ?? ''} \n
        Conversational ID: ${item.waConvId} \n
        Message Type: ${item.message_type} \n
        Status: ${item.status} \n
        Reason: ${item.gw_status} \n
        Cost: ${item.rate} \n
        button: ${item.interactive_list_action?.button} \n
        sections: ${`Id: ${item.interactive_list_action?.list_reply.id}\nTitle: ${item.interactive_list_action?.list_reply?.title}\nDescription: ${item.interactive_list_action?.list_reply?.description}`} \n
        buttons: ${`Id: ${item.interactive_reply_button_action?.button_reply.id}\nTitle: ${item.interactive_reply_button_action?.button_reply?.title}\n`}`
        );
      }
    }
    setShowLogModal(true);
  };

  const onCloseLogModal = () => {
    setShowLogModal(false);
    logForm.resetFields();
  };
  const searchPredicate = (item: WhatsappApiReportModel, text: string) =>
    item.originator.toLowerCase().includes(text)
    || item.msg_id.toString().toLowerCase().includes(text)
    || item.recipient.toString().toLowerCase().includes(text)
    || item.country?.toString().toLowerCase().includes(text)
    || `${item.status}`.toLowerCase().includes(text)
    || `${item.message_type}`.toLowerCase().includes(text);



  const tableColumns: ColumnsType<WhatsappApiReportModel> = [
    {
      title: 'Message Id',
      dataIndex: 'msg_id',
      render: (_v, record) =>
        <Col xl={24} lg={24} md={24}>
          <Row gutter={10}>
            <Col xl={20} lg={20} md={20}>
              {record.msg_id}
            </Col>
            <Col xl={4} lg={4} md={4} className='text-left'>
              {record.event_type==="USER_INITIATED"?<Tooltip title="User Initiated Message"><UserOutlined/></Tooltip>:null}
            </Col>
          </Row>
        </Col>
    },
    {
      title: 'Date(UTC)',
      dataIndex: 'date',
      render: (_v, record) => {
        const ProcessedDatetime=(record.created_at)?record.created_at:record.created_at;
        const arr = (ProcessedDatetime).toString().split(/-|\s|\+|:|\./);// split string and create array.
        const dateStr =`${arr[0]}${"-"}${arr[1]}${"-"}${arr[2]}${":"}${arr[3]}${":"}${arr[4]}`;
        return dateStr.replace("T","  ");
      },
    },
    {
      title: 'Originator',
      dataIndex: 'originator',
    },
    {
      title: 'Message Type',
      dataIndex: 'message_type',
    },
    {
      title: 'Recipients',
      dataIndex: 'recipients',
      render: (_v, record) =>
        <Col xl={24} lg={24} md={24}>
          <Row gutter={10}>
            <Col xl={20} lg={20} md={20}>
              {record.recipient}
            </Col>
            <Col xl={4} lg={4} md={4} className='text-left'>
              <span className={`flag:${record.country?.toUpperCase()}`} style={{height: 10, width: 15}}/>
            </Col>
          </Row>
        </Col>
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Log',
      dataIndex: 'log',
      render: (_v, record) => <SnippetsOutlined onClick={e => onClickLog(record)}/>
    }
  ];


  const filterReportData = () => {
    const data = {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      page,
      size: pageSize,
      ...(selectedStatus && {whatsapp_status: selectedStatus.toLowerCase()}),
      ...(selectedCountry && {country: selectedCountry}),
    };
    setSearchData(data);
    fetchByFilter?.(data);
  };


  const onChangeDate = (e: any) => {
    if (e) {
      const [sDate, eDate] = e;
      setStartDate(sDate);
      setEndDate(eDate);
    }
  };

  const onChangeSelectedCountry = (e: string) => {
    if(e){
      setSelectedCountry(e);
    }
  };

  const onChangeSelectedStatus = (e: string) => {
    if(e){
      setSelectedStatus(e);
    }
  };

  useEffect(() => {
    filterReportData();
  }, [selectedCountry,selectedStatus, startDate, endDate]);






  const onFilterClear = () => {
    setSelectedCountry(undefined);
    // setSelectedDate(undefined);
    setSelectedStatus(undefined);
  };


  const disabledDate = (current: any) =>  (current && current > moment().endOf('day')) || (current && current < lastMonth);


  const controls = [
    {    title: 'Refresh',
      icon: <ReloadOutlined/>,
      isIconOnly:true,
      onClick: () => {
        if (searchData)
          searchRefresh(searchData);
      }
    },
    {
      title: '',
      buttonNotNeeded: true,
      icon: <RangePicker id='date_filter' key='country_filter' // value={selectedDate}
                         className="w-100"
                         ranges={{
                           Today: [moment(), moment()],
                           'This Month': [moment().startOf('month'), moment().endOf('month')],
                         }}
                         format="YYYY/MM/DD"
                         value={[startDate, endDate]}
                         defaultValue={[startDate, endDate]}
                         disabledDate={disabledDate}
                         onChange={e => onChangeDate(e)}
      />,
    },
    {
      title: '',
      buttonNotNeeded: true,
      icon: <Select id='country_filter' key='country_filter' showSearch className="w-100"
                    filterOption={(input: any, option: any) =>
                      option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } placeholder='Choose Country' value={selectedCountry} onChange={e => {
        if (e) {
          onChangeSelectedCountry(e);
        }
      }}>
        {en.map(e => <Select.Option key={e.alpha2} value={e.alpha2.toUpperCase()} title={e.name}><span
          className={`flag:${e.alpha2.toUpperCase()} mr-2`}/>{e.name}</Select.Option>)}
      </Select>
    },
    {
      title: '',
      buttonNotNeeded: true,
      icon: <Select id='status_filter' key='status_filter' className="w-100" placeholder="Choose Status" showSearch
                    onChange={e => {
                      if (e) {
                        onChangeSelectedStatus(e);
                      }
                    }} value={selectedStatus}>
        <Select.Option key='delivered' value="delivered">Delivered</Select.Option>
        <Select.Option key='un_delivered' value="un_delivered">Un-Delivered</Select.Option>
        <Select.Option key='sent' value="sent">Sent</Select.Option>
        <Select.Option key='processed' value="processed">Processed</Select.Option>
        <Select.Option key='scheduled' value="scheduled">Scheduled</Select.Option>
        <Select.Option key='rejected' value="rejected">Rejected</Select.Option>
        <Select.Option key='read' value="read">Read</Select.Option>
      </Select>
    },
    {
      title: 'Clear',
      icon: <ClearOutlined/>,
      onClick: () => {
        onFilterClear();
      }
    },
    {
      title: 'Export',
      icon: <DownloadOutlined/>,
      isIconOnly:true,
      onClick: () => {
        if (searchData)
          downloadReport(searchData);
      },
    }

  ];


  const onChangePage = (newPage: number, newSize: number) => {
    if (pageSize !== newSize) {
      setPageSize(newSize);
      setPage(1);
      if (searchData!==undefined)
        onPaginate?.(1, newSize,searchData,searchText);
    } else {
      setPage(newPage);
      if (searchData!==undefined)
        onPaginate?.(newPage, newSize,searchData,searchText);
    }
  };

  const getPaginationOption = () => ({
    onChangePage,
    pageSize,
    currentPage: page,
    total
  });

  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };

  return (
    <div>

      <TableListReport<WhatsappApiReportModel> items={reports} columns={tableColumns} loading={loading}
                                            showSearch onSearch={onSearch}
                                    rowKey={(record: WhatsappApiReportModel) => record.id} controls={controls}
                                    searchPredicate={searchPredicate} hideRowSelection
                                    serverSidePaginationOption={getPaginationOption()}/>

      <Modal
        visible={showLogModal}
        onCancel={onCloseLogModal}
        footer={null}
        width={550}
      >
        <Form form={logForm} className='w-100'>
          <Col xl={24} lg={24} md={24}>
            <CopyToClipboard text={msgInfo}
                             onCopy={(e) => showSuccessNotification('Log Copied to clipboard')}>
              <span className="cursor-pointer"><CopyOutlined/></span>
            </CopyToClipboard>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Request ID" name="req_id" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Message ID" name="msg_id" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Date" name="date" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Originator" name="originator" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Recipient" name="recipients" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Message Type" name="message_type" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            {(messageType==="TEXT" || messageType==="AUTO_TEMPLATE")&&
              <Col xl={24} lg={24} md={24}>
                <Form.Item label="Message Text" name="message_text" labelAlign="left" labelCol={{span: 10}}>
                  <TextArea rows={4} bordered={false} readOnly/>
                </Form.Item>
              </Col>
            }
            {(messageType==="ATTACHMENT")&&
              <div>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Attachment Type" name="attachment_type" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Attachment Mime Type" name="attachment_mime_type" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Attachment Caption" name="attachment_caption" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Attachment Data" name="attachment_data" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Attachment URL" name="attachment_url" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </div>
            }
            {(messageType==="INTERACTIVE" && eventType!=="USER_INITIATED")&&
              <div>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Interactive Type" name="interactive_type" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Header Type" name="header_type" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Header Text/Link" name="header_text" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Body Text" name="body_text" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Footer Text" name="footer_text" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </div>
            }
            {(interactiveType==="list")&&
              <div>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="List Title" name="button" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="List Options" name="sections" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </div>
            }
            {(interactiveType==="button")&&
              <div>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Reply Buttons" name="buttons" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </div>
            }
            {(messageType==="LOCATION" || mediaType==="location")&&
              <div>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Longitude of Location" name="location_longitude" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Latitude of Location" name="location_latitude" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Location Name" name="location_name" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Location Address" name="location_address" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </div>
            }
            {(mediaType==="location")&&
              <div>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Media Type" name="media_type" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Longitude of Location" name="location_longitude" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Latitude of Location" name="location_latitude" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Location Name" name="location_name" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Location Address" name="location_address" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Quick Reply Button" name="quick_replies" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Call To Action Button" name="actions" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </div>
            }
            {(messageType==="CONTACTS")&&
              <div>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="First Name" name="contact_first_name" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Last Name" name="contact_last_name" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Display Name" name="contact_display_name" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Phone Number" name="contact_phone" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Contact Email" name="contact_email" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Contact URL" name="contact_url" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </div>
            }
            {(messageType==="TEMPLATE" || messageType==="MEDIA_TEMPLATE")&&
              <div>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Template ID" name="template_id" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Template Parameter Values" name="body_parameter_values" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </div>
            }
            {(messageType==="MEDIA_TEMPLATE" && mediaType!=="location")&&
              <div>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Media Type" name="media_type" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Media URL" name="media_url" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col><Col xl={24} lg={24} md={24}>
                <Form.Item label="Media CaptionID" name="media_caption" labelAlign="left" labelCol={{span: 10}}>
                  <Input bordered={false} readOnly/>
                </Form.Item>
              </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Text Header Title" name="text_header_title" labelAlign="left" labelCol={{span: 10}}>
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Quick Reply Button" name="quick_replies" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24}>
                  <Form.Item label="Call To Action Button" name="actions" labelAlign="left" labelCol={{span: 10}}>
                    <TextArea rows={4} bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </div>
            }

            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Status" name="status" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            {reason && <Col xl={24} lg={24} md={24}>
              <Form.Item label="Reason" name="gw_status" labelAlign="left" labelCol={{span: 10}}>
                <TextArea rows={2} bordered={false} readOnly/>
              </Form.Item>
            </Col>}
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Cost" name="rate" labelAlign="left" labelCol={{span: 10}}>
                <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24}>
              <Form.Item label="Conversational ID" name="conversation_id" labelAlign="left" labelCol={{span: 10}}>
                <TextArea rows={2} bordered={false} readOnly/>
              </Form.Item>
            </Col>
          </Col>
        </Form>
      </Modal>
    </div>
  );
};

ApiReportList.defaultProps = {
  loading: false,
  onPaginate: undefined,
  onExportLog:undefined
};

export default ApiReportList;
