import { Breakpoint } from "antd/es/_util/responsiveObserve";

class Utils {
	static searchRoute = (tree: any[], value: any, key = 'key', reverse = false) => {
		const stack = [tree[0]];
		while (stack.length) {
			const node = stack[reverse ? 'pop' : 'shift']();
			if (node[key] === value) return node;
			if (node.submenu) {
				stack.push(...node.submenu);
			}
		}
		return null;
	};

	/**
	 * Get current path related object from Navigation Tree
	 * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
	 * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
	 * @return {Object} object that contained the path string
	 */
	static getRouteInfo = (navTree: any, path: string): any => Utils.searchRoute(navTree, path, 'key');

	/**
	 * Get Breakpoint
	 * @param {Object} screens - Grid.useBreakpoint() from antd
	 * @return {Array} array of breakpoint size
	 */
	static getBreakPoint(screens: Partial<Record<Breakpoint, boolean>>) {
		const breakpoints = Object.entries(screens).filter((s) => s[1] === true).map(s => s[0]);
		return breakpoints;
	}

	/**
	 * Get accessible color contrast
	 * @param {String} hex - Hex color code e.g '#3e82f7'
	 * @return {String} 'dark' or 'light'
	 */
	static getColorContrast(hex: string) {
		if (!hex) {
			return 'dark';
		}
		const cutHex = (h: string) => (h.charAt(0) === '#') ? h.substring(1, 7) : h;
		const hexToR = (h: string) => parseInt((cutHex(h)).substring(0, 2), 16);
		const hexToG = (h: string) => parseInt((cutHex(h)).substring(2, 4), 16);
		const hexToB = (h: string) => parseInt((cutHex(h)).substring(4, 6), 16);


		const threshold = 130;
		const hRed = hexToR(hex);
		const hGreen = hexToG(hex);
		const hBlue = hexToB(hex);

		const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
		if (cBrightness > threshold) {
			return 'dark';
		}
		return 'light';

	}

}
export default Utils;