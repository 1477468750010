import React, { Component } from 'react';
import { Menu } from 'antd';
import { Link, Route, Navigate, useLocation, Routes } from 'react-router-dom';
import {
  BookOutlined,
  CarryOutOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import InnerAppPageLayout from 'layouts/innerApp';
import WhatsappCampaign from "../WhatsappCampaign";
import WhatsappCampaignReport from "../WhatsappCampaignReport";
import ManageWhatsappCampaign from "../ManageWhatsappCampaign";



const SettingOption: React.FC = () => {
  const location = useLocation();
  // const locationPath = location.pathname.split('/');
  // const currentpath = locationPath[locationPath.length - 1];

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[`/whatsapp`]}
      selectedKeys={[location.pathname]}
    >
      <Menu.Item key="/whatsapp/whatsapp-campaign">
        <CarryOutOutlined />
        <span>Campaign Sender</span>
        <Link to="/whatsapp/whatsapp-campaign" />
      </Menu.Item>
      <Menu.Item key="/whatsapp/whatsapp-campaign/whatsapp-campaign-report">
        <BookOutlined />
        <span>Campaign Report</span>
        <Link to="whatsapp-campaign-report" />
      </Menu.Item>
      <Menu.Item key="/whatsapp/whatsapp-campaign/manage-whatsapp-campaign" >
        <ScheduleOutlined />
        <span>Manage Campaign</span>
        <Link to="manage-whatsapp-campaign" />
      </Menu.Item>
    </Menu>
  );
};

const SettingContent: React.FC = () => (
  <Routes>
    <Route path="" element={<WhatsappCampaign />} />
    <Route path="whatsapp-campaign-report" element={<WhatsappCampaignReport />} />
    <Route path="manage-whatsapp-campaign" element={<ManageWhatsappCampaign />} />
    <Route path="*" element={<Navigate to="whatsapp-campaign/*" replace />} />
  </Routes>
);

// eslint-disable-next-line react/prefer-stateless-function
class WhatsappCampaignTab extends Component {
  render() {
    return (
      <InnerAppPageLayout sideContentWidth={320} sideContent={<SettingOption />} mainContent={<SettingContent />} />
    );
  }
}

export default WhatsappCampaignTab;
