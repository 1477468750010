import React, {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

const Chatbot = lazy(() => import("."));
const WhatsappConversationFlow = lazy(() => import("../../components/WhatsappConversationFlow"));
const ChatbotPages = () => (
  <Routes>
    <Route path='/' element={<Chatbot/>}/>
    <Route path=":name/:id" element={<WhatsappConversationFlow/>}/>
    <Route path="*" element={<Navigate to="/error/404" replace/>}/>
  </Routes>

);

export default ChatbotPages;
