import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ApiReport from "./ApiReport";
import Pricing from "./Pricing";

const GettingStarted = lazy(() => import("./GettingStarted"));

const ViberPages = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/numlookup/getting-started" replace />} />
    <Route path='getting-started' element={<GettingStarted />} />
    <Route path='api-report' element={<ApiReport />} />
    <Route path='pricing' element={<Pricing />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default ViberPages;
