import {
  WhatsappOriginatorRegisterRequest,
  WhatsappSettingsUpdateRequest,
  WhatsappTemplateCreateRequest,
  WhatsappTemplateDeleteRequest,
  WhatsappTemplateUpdateRequest,
} from "../../models/requests";
import WhatsappApi from "./index";
import {
  WhatsappSettingsUpdateResponse,
  WhatsappResponse,
  WhatsappTemplateListResponse,
  WhatsappTemplateDeleteResponse,
  WhatsappTemplateCreateResponse,
  WhatsappOnboardingResponse,
  WhatsappContactListResponse,
  WhatsappTemplateUpdateResponse,
  WhatsappPricingResponse,
  WhatsappSubscriptionResponse
} from "../../models/responses";


const getWhatsappSettings = async () => WhatsappApi.get<WhatsappResponse>('setting');
const updateWhatsappSettings = async (data: WhatsappSettingsUpdateRequest) => WhatsappApi.put<WhatsappSettingsUpdateResponse, WhatsappSettingsUpdateRequest>('setting', data);

const createWhatsappTemplate = async (data: WhatsappTemplateCreateRequest) => WhatsappApi.post<WhatsappTemplateCreateResponse, WhatsappTemplateCreateRequest>('template/create', data);
const updateWhatsappTemplate = async (data: WhatsappTemplateUpdateRequest, templateId: number) => WhatsappApi.put<WhatsappTemplateUpdateResponse, WhatsappTemplateUpdateRequest>(`template/update/${templateId}`, data);
const listWhatsappTemplate = async () => WhatsappApi.get<WhatsappTemplateListResponse>('template');
const listMarketingWhatsappTemplate = async () => WhatsappApi.get<WhatsappTemplateListResponse>('template/marketing_template');
const listWhatsappTemplateGettingStarted = async () => WhatsappApi.get<WhatsappTemplateListResponse>('template/getting-started');

const getWhatsappTemplate = async (templateName:string) => WhatsappApi.get<boolean>(`template/exist/${templateName}`);
const deleteWhatsappTemplate = async (data: WhatsappTemplateDeleteRequest) => WhatsappApi.delete<WhatsappTemplateDeleteResponse>(`template/delete/${data.id}`);

const sendWhatsappOriginatorRegisterRequest = async (data: WhatsappOriginatorRegisterRequest) => WhatsappApi.post<string, WhatsappOriginatorRegisterRequest>('setting/register-originator', data);
const sendWhatsappShowInterestRequest = async () => WhatsappApi.post<string>('show-interest');

const getOnboardingDetails = async () => WhatsappApi.get<WhatsappOnboardingResponse>('onboarding');

const listWhatsappContact = async () => WhatsappApi.get<WhatsappContactListResponse>('numbers');

const getWhatsappPricing = async ()=> WhatsappApi.get<WhatsappPricingResponse>('pricing/user');

const getWhatsappSubscription = async ()=> WhatsappApi.get<WhatsappSubscriptionResponse>('subscription/user');

const saveWhatsappToken = async (data: {"token":string}) => WhatsappApi.post<string, {"token":string}>('save-token', data);

export {
  getWhatsappSettings,
  updateWhatsappSettings,
  createWhatsappTemplate,
  updateWhatsappTemplate,
  listWhatsappTemplate,
  listMarketingWhatsappTemplate,
  deleteWhatsappTemplate,
  sendWhatsappOriginatorRegisterRequest,
  sendWhatsappShowInterestRequest,
  getOnboardingDetails,
  listWhatsappContact,
  listWhatsappTemplateGettingStarted,
  getWhatsappPricing,
  getWhatsappTemplate,
  getWhatsappSubscription,
  saveWhatsappToken,
};
