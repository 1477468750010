import React, {useState} from 'react';
import {
  Col,
  Form,
  Input,
  Modal, Switch
} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";


const StepTwoAuthenticationComponent = () => {

  const [preventToggle, setPreventToggle] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);

  return (
    <>
      <Col xs={24} sm={12} md={12}>
        <Form.Item
          name="message_send_ttl_seconds"
          labelCol={{span: 10}}
          label={
            <span>
                  Time-to-live value (s)
                  <InfoCircleOutlined
                    style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                    onClick={() => {
                      Modal.info({
                        title: "Time-to-live value (s)",
                        content: (
                          <div>
                            <p>If we are unable to deliver a message to a WhatsApp user, we will continue
                              attempting to deliver the message for a period of time known as a
                              time-to-live.</p>
                            <p>By default, messages have a time-to-live of 24 hours, but newly created
                              authentication templates have a default time-to-live of 10 minutes. So set value
                              between <b>60 and 600 seconds</b></p>
                          </div>
                        ),
                      });
                    }}
                  />
              </span>
          }
          rules={[
            ({getFieldValue}) => ({
              validator: (_, value) => {
                if (!value || (value >= 60 && value <= 600)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Please enter a number between 60 and 600')
                );
              },
            }),
          ]}
        >
          <Input type="number"/>
        </Form.Item>
      </Col>
      <Col xs={24} sm={2} md={2}/>
      <Col xs={24} sm={10} md={10}>
        <Form.Item
          label={
            <span>
                  Security Recommendation
                  <InfoCircleOutlined
                    style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                    onClick={(event) => {
                      event.stopPropagation();
                      setPreventToggle(true);
                      Modal.info({
                        title: "Security Recommendation",
                        content: (
                          <div>
                            Set to true if you want the template to include the string, For your security, do
                            not share this code. Set to false to exclude the string.
                          </div>
                        ),
                        onOk: () => setPreventToggle(false)
                      });
                    }}
                  />
              </span>
          }
          name="body_add_security_recommendation"
          labelCol={{span: 12}}
          valuePropName="checked"
          getValueProps={(value) => ({checked: value})}
          getValueFromEvent={(checked) => {
            if (preventToggle) {
              return switchChecked; // Return the current switch state if preventToggle is true
            }
            return checked; // Otherwise, return the new switch state
          }}
        >
          <Switch
            checked={switchChecked} // Set the checked state of the switch
            onChange={(checked) => setSwitchChecked(checked)} // Update the switch state
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={12}>
        <Form.Item
          label={
            <span>
                          Code Expiration Minutes
                          <InfoCircleOutlined
                            style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                            onClick={() => {
                              Modal.info({
                                title: "Code Expiration Minutes",
                                content: (
                                  <div>
                                    <p>Set to an integer if you want the code expiration footer string included in the
                                      response.</p>
                                    <p>Value indicates number of minutes until code expires.</p>
                                  </div>
                                ),
                              });
                            }}
                          />
                      </span>
          }
          name="code_expiration_minutes"
          labelCol={{span: 10}}
          rules={[
            ({getFieldValue}) => ({
              validator: (_, value) => {
                if (!value || (value >= 1 && value <= 90)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Please enter a number between 1 and 90')
                );
              },
            }),
          ]}
        >
          <Input type="number"/>
        </Form.Item>
      </Col>
    </>
  );
};

export default StepTwoAuthenticationComponent;
