

import React from 'react';
import { Form, FormInstance, FormItemProps } from 'antd';
import CountryPhoneInput, {
  ConfigProvider, CountryPhoneInputValue
} from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { findCountryCode, findCountryDialCode } from 'utils/helper';

export interface FormItemPhoneInputProps<Values> extends FormItemProps<Values> {
  onCountrySelectionChange?: (value: CountryPhoneInputValue) => void;
  onValidDialCode?: (value: CountryPhoneInputValue) => void;
  form?: FormInstance;
  disableValidation?: boolean;
  addonAfter?: React.ReactNode;
}
const FormItemPhoneInput = <Values = any>(props: FormItemPhoneInputProps<Values>) => {

  const { labelCol, labelAlign, label, name, initialValue, rules, onCountrySelectionChange, onValidDialCode, form, disableValidation, addonAfter, className } = props;
  // const [phoneFieldValue, setPhoneFieldValue] = useState<CountryPhoneInputValue>();

  const areaMapper = (area: any) => ({
    ...area,
    emoji: <span className={`flag:${area.short}`} />,
  });
  const onKeyUpPhoneInput = (e: any): void => {
    const phoneNumber = e.target.value ?? '';
    if (phoneNumber.startsWith('+')) {
      const cId = phoneNumber.substring(0, 4);
      const countryMap = findCountryCode(cId);
      if (countryMap != null) {
        const data = en.find(x => x.alpha2.toUpperCase() === countryMap.toUpperCase());
        if (data != null) {
          const value = { short: data.alpha2.toUpperCase() };
          if (name) {
            form?.setFieldsValue({ [`${name}`]: value });
          }
          onValidDialCode?.(value);
        }
      }
    }

  };
  const onChangePhoneCode = (value: string, option: any) => {
    const dialCode = findCountryDialCode(value);
    if (dialCode) {
      const phone = { phone: dialCode, short: value };
      if (name) {
        form?.setFieldsValue({ [`${name}`]: phone });
      }
      onCountrySelectionChange?.(phone);
    }
  };

  return (<ConfigProvider locale={en} areaMapper={areaMapper}>
    <Form.Item
      className={className}
      labelCol={labelCol}
      labelAlign={labelAlign}
      label={label}
      name={name}
      initialValue={initialValue}
      rules={rules ?? [
        {
          required: !disableValidation,
          message: 'Please enter valid mobile number',
        },
        {
          validator: (_ruleObject, value) => {
            if (disableValidation) {
              return Promise.resolve();
            }
            const patternRegex = /^[+]{0,1}[- ()0-9]{4,20}$/im;
            if (value.phone && patternRegex.test(value.phone)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Please enter valid mobile number. Only +, (, ), - and space are allowed.'));
          },
          message: 'Please enter valid mobile number. Only +, (, ), - and space are allowed.',
        }
      ]} >
      <CountryPhoneInput size="small" style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
        onKeyUp={onKeyUpPhoneInput} selectProps={{ onSelect: onChangePhoneCode }}
        addonAfter={addonAfter} className="d7-select" />
    </Form.Item>
  </ConfigProvider>);
};
FormItemPhoneInput.defaultProps = {
  onCountrySelectionChange: undefined,
  onValidDialCode: undefined,
  form: undefined,
  disableValidation: false,
  addonAfter: undefined
};
export default FormItemPhoneInput;
