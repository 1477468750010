import React from 'react';


const Flex = (props: { children?: React.ReactNode, className?: string, alignItems?: string, justifyContent?: string, mobileFlex?: boolean, flexDirection?: string }) => {
	const { children, className, alignItems, justifyContent, mobileFlex, flexDirection } = props;
	const getFlexResponsive = () => mobileFlex ? 'd-flex' : 'd-md-flex';
	return (
		<div className={`${getFlexResponsive()} ${className} ${flexDirection ? (`flex-${flexDirection}`) : ''} ${alignItems ? (`align-items-${alignItems}`) : ''} ${justifyContent ? (`justify-content-${justifyContent}`) : ''}`}>
			{children}
		</div>
	);
};

Flex.defaultProps = {
	mobileFlex: true,
	flexDirection: 'row',
	className: '',
	alignItems: undefined,
	justifyContent: undefined,
	children: undefined
};


export default Flex;
