import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { WhatsappContactList} from "models";
import TableList from 'components/shared/TableList';


export interface WhatsappContactListProps {
  whatsappcontact: WhatsappContactList[];
}

const WhatsappContactNumberList = (props: WhatsappContactListProps) => {
  const { whatsappcontact } = props;

  const tableColumns: ColumnsType<WhatsappContactList> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Number',
      dataIndex: 'number',
    }
  ];

  return (
    <div>
      <TableList<WhatsappContactList> items={whatsappcontact} columns={tableColumns}
        rowKey={(record: WhatsappContactList) => record.user_id} hideRowSelection />
    </div>
  );
};


export default WhatsappContactNumberList;
