import React, {useState} from "react";
import {
  Modal
} from "antd";
import { ColumnsType } from "antd/es/table";
import { ContactGroup } from "models";
import TableList from "components/shared/TableList";


export interface CampaignGroupPickerProps {
  contactGroups: ContactGroup[];
  visible: boolean;
  onClose?: () => void;
  loading?: boolean;
  selectedContactGroups?: ContactGroup[];
  onOk?: (records: ContactGroup[]) => void;

}



const CampaignGroupPicker = (props: CampaignGroupPickerProps) => {
  const { contactGroups, visible, onClose, loading, onOk, selectedContactGroups } = props;
  // const [loading, setLoading] = useState(false);
  const [contactGroupData, setContactGroupData] = useState<ContactGroup[]>([]);
  const tableColumns: ColumnsType<ContactGroup> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
  ];
  const onCloseModal = () => {
    onClose?.();
  };
  const searchPredicate = (item: ContactGroup, text: string) =>
    item.id.toString().toLowerCase().includes(text)
    || item.name.toLowerCase().includes(text);

  const onChangeSelection = (keys: React.Key[], records: ContactGroup[]) => {
    setContactGroupData(records);
  };

  const onOok=()=>{
    onOk?.(contactGroupData);
  };
  return (
    <Modal
      visible={visible}
      onOk={onOok}
      okText="Add"
      onCancel={onCloseModal}
      // footer={null}
      width={500}
    >
      <TableList<ContactGroup>
        items={contactGroups}
        columns={tableColumns}
        loading={loading}
        rowKey={(record: ContactGroup) => record.id}
        showSearch
        searchPredicate={searchPredicate}
        scroll={{ y: 400 }}
        onChangeSelection={onChangeSelection}
        persistedSelectedRowKeys={selectedContactGroups?.map(x => x.id)}
      />
    </Modal>
  );
};

CampaignGroupPicker.defaultProps = {
  onClose: undefined,
  loading: false,
  selectedContactGroups: undefined,
  onOk: undefined
};

export default CampaignGroupPicker;
