import React from 'react';
import { Dropdown, Menu, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

interface EllipsisDropdownProps {
  trigger?: 'click' | 'hover' | 'contextMenu';
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  menu: React.ReactElement<MenuProps>;
}

const EllipsisDropdown: React.FC<EllipsisDropdownProps> = ({
  trigger = 'click',
  placement = 'bottomRight',
  menu = <Menu />,
}) => (
    <Dropdown overlay={menu} placement={placement} trigger={[trigger]}>
      <div className="ellipsis-dropdown">
        <EllipsisOutlined />
      </div>
    </Dropdown>
  );

EllipsisDropdown.defaultProps = {
  trigger: 'click',
  placement: 'bottomRight',
};

export default EllipsisDropdown;
