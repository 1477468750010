import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { WhatsappCampaignListngModel} from "models";
// import ManageCampaignList from 'components/sms/ManageCampaign';
import {
  getProcessingWhatsappCampaignService, manageWhatsappCampaignSearchService,
  stopWhatsappCampaignService
} from "../../../services/dashboard/WhatsappCampaignService";
import ManageWhatsappCampaignList from "../../../components/whatsapp/ManageWhatsappCampaign";

const ManageWhatsappCampaign = () => {
  const [list, setCampaigns] = useState<WhatsappCampaignListngModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };
  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };


  const fetchAllCampaign = async (page: number = 1, size: number = 10) => {
    try {
      setLoading(true);
      const response = await getProcessingWhatsappCampaignService({ page, size });
      setCampaigns(response.items);
      setTotal(response.total);
    } catch (error: any) {
      showErrorNotification(error.message);
      setCampaigns([]);
    }
    setLoading(false);
  };

  const onSuccessStopCampaign = () => {
    showSuccessNotification(`Whatsapp Campaign stopped successfully`);
    setLoading(false);
  };

  const onErrorStopCampaign = (showNotification?: boolean) => {
    if (showNotification) {
      showErrorNotification(`Failed to stop Whatsapp Campaign`);
    }
    setLoading(false);
  };

  const stopWhatsappCampaign = async (campaignId: string) => {
    try {
      setLoading(true);
      const response = await stopWhatsappCampaignService(campaignId);
      if (response.id) {
        onSuccessStopCampaign();
      } else {
        onErrorStopCampaign(true);
      }
      const newApps = list.map(c =>
        (c.id === campaignId ? Object.assign(c, { status: response.status }) : c)
      );
      setCampaigns(newApps);
    } catch (error: any) {
      showErrorNotification(error.message);
    }
  };

  const fetchBySearch = async (key: string) => {
    try {
      const response = await manageWhatsappCampaignSearchService(key);
      if (response.items) {
        setCampaigns(response.items);
        setTotal(response.total);
      }
    } catch (error: any) {
      showErrorNotification(error.message);
      setCampaigns([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const onPaginate = async (page: number, size: number, searchText?: string) => {
    setLoading(true);
    if (searchText) {
      fetchBySearch(searchText);
    } else {
      fetchAllCampaign(page, size);
    }
  };

  useEffect(() => {
    fetchAllCampaign();
  }, []);


  return (
      <ManageWhatsappCampaignList whatsappCampaigns={list} loading={loading} onClickCancelCampaign={stopWhatsappCampaign} total={total} onPaginate={onPaginate} />
  );
};

export default ManageWhatsappCampaign;

