import React, { useEffect, useState } from 'react';
import {Card, Row, Col, Form, Input, Button, Upload, message as antdMessage, Select, Image, Tooltip} from 'antd';
import { CloudUploadOutlined, DeleteOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { showErrorNotification, showSuccessNotification } from "../../../../utils/helper";
import { WhatsappMetaBusinessProfile } from "../../../../models";
import {
  updateWhatsappMetaBusinessProfileService, uploadResumableFileService
} from "../../../../services/dashboard/WhatsappService";

const { Option } = Select;

export interface WhatsappBusinessProfileFormProps {
  businessProfile?: WhatsappMetaBusinessProfile;
  numberId?: string;
  ContactNumber?: string;
  fetchBusinessProfile?: () => void;
}

const WhatsappBusinessProfileForm = (props: WhatsappBusinessProfileFormProps) => {
  const { businessProfile, numberId, ContactNumber,fetchBusinessProfile } = props;
  const [form] = Form.useForm();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [pictureHandle, setPictureHandle] = useState();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const verticalOptions = [
    "UNDEFINED", "OTHER", "AUTO", "BEAUTY", "APPAREL", "EDU", "ENTERTAIN", "EVENT_PLAN",
    "FINANCE", "GROCERY", "GOVT", "HOTEL", "HEALTH", "NONPROFIT", "PROF_SERVICES",
    "RETAIL", "TRAVEL", "RESTAURANT", "NOT_A_BIZ"
  ];

  const onSubmitForm = async (values: any) => {
    const { websites } = values;
    const formData = {
      about: values.about,
      address: values.address,
      description: values.description,
      email: values.email,
      vertical: values.vertical,
      profile_picture_handle: pictureHandle || businessProfile?.profile_picture_handle,
      websites: websites?.map((site: { url: any; }) => site.url)
    };
    try {
      setLoadingSubmit(true);
      if (numberId) {
        const response = await updateWhatsappMetaBusinessProfileService(ContactNumber, formData);
        if (response.id) {
          setLoadingSubmit(false);
          showSuccessNotification("Business Profile Updated Successfully");
          if (fetchBusinessProfile) {
            fetchBusinessProfile();  // Fetch data again after successful save
          }

        } else {
          setLoadingSubmit(false);
          showErrorNotification('Failed to update Business Profile');
        }
      }
    } catch (error: any) {
      setLoadingSubmit(false);
      showErrorNotification(error.message);
    }
    // console.log('Form Data: ', formData);
  };

  const fileUploadCustomRequest = (options: any) => {
    const isMedia = options.file.type;
    if (isMedia === "image/jpeg" || isMedia === "image/png") {
      setUploadingFile(true);
      const data = new FormData();
      data.append('file', options.file);
      uploadResumableFileService(options.file)
        .then(response => {
          if (response.header_handle !== "None") {
            options.onSuccess(response, options.file);
          } else {
            antdMessage.error(`${options.file} file type is not supported.`);
          }
        })
        .catch(error => {
          showErrorNotification(error.message);
        })
        .finally(() => setUploadingFile(false));
    } else {
      antdMessage.error(`${options.file} file type is not supported.`);
    }
  };

  const onChangeFile = (info: any) => {
    const { status, response } = info.file;
    if (status === "done") {
      antdMessage.success(`${info.file.name} file uploaded successfully.`);
      form.setFieldsValue({ profile_picture_handle: response.header_handle });
      setPictureHandle(response.header_handle);
    } else if (status === "error") {
      form.setFieldsValue({ profile_picture_handle: '' });
      antdMessage.error(`${info.file.name} file upload failed.`);
    } else if (status === "removed") {
      form.setFieldsValue({ profile_picture_handle: '' });
    }
  };

  useEffect(() => {
    if (businessProfile) {
      form.setFieldsValue({
        display_name: businessProfile?.display_name,
        messaging_product: businessProfile?.messaging_product,
        about: businessProfile?.about,
        address: businessProfile?.address,
        description: businessProfile?.description,
        email: businessProfile?.email,
        profile_picture_handle: businessProfile?.profile_picture_handle || businessProfile?.profile_picture_url,
        vertical: businessProfile?.vertical,
        websites: businessProfile.websites ? businessProfile.websites.map((url: any) => ({ url })) : []
      });
    } else {
      form.setFieldsValue({
        websites: [{ url: '' }],
      });
    }
  }, [businessProfile]);

  return (
    <div>
      <Card loading={loadingSubmit}>
        <h3>Business Profile</h3>
        <br />
        <Form
          name="applicationForm"
          layout="horizontal"
          size="small"
          onFinish={onSubmitForm}
          form={form}
        >
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12}>
              <Form.Item
                label="About"
                name="about"
                labelCol={{ span: 4 }}
                labelAlign='left'
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Form.Item
                label="Address"
                name="address"
                labelCol={{ span: 4 }}
                labelAlign='left'
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Form.Item
                label="Description"
                name="description"
                labelCol={{ span: 4 }}
                labelAlign='left'
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Form.Item
                label="Email"
                name="email"
                labelCol={{ span: 4 }}
                labelAlign='left'
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={<span>
                                  Profile picture{' '}
                <Tooltip className="mx-1"
                         title="Please upload JPG image of resolution greater than 192 pixel * 192 pixel">
                                      <InfoCircleOutlined/>
                                  </Tooltip>
                              </span>}
              name="profile_picture"
              labelCol={{ span: 4 }}
              labelAlign='left'
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Upload
                  name="file"
                  accept=".jpeg, .png"
                  maxCount={1}
                  customRequest={fileUploadCustomRequest}
                  onChange={onChangeFile}
                  showUploadList={{
                    showRemoveIcon: true,
                    removeIcon: <DeleteOutlined />,
                  }}
                >
                  <Button type='default' className="bg-gray-lightest mx-2 px-5 w-100"><CloudUploadOutlined />Upload</Button>
                </Upload>
                {businessProfile?.profile_picture_handle || businessProfile?.profile_picture_url ? (
                  <Image
                    src={businessProfile?.profile_picture_handle || businessProfile?.profile_picture_url}
                    style={{ width: "50px", marginLeft: "25px" }}
                  />
                ) : null}
              </div>
            </Form.Item>
          </Col>
            <Col xs={12} sm={12} md={12}>
              <Form.Item
                label="Vertical"
                name="vertical"
                labelCol={{ span: 4 }}
                labelAlign='left'
              >
                <Select>
                  {verticalOptions.map(option => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Form.List name="websites">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Col xs={12} sm={12} md={12} key={field.key}>
                      <Form.Item
                        name={[field.name, 'url']}
                        fieldKey={[field.fieldKey, 'url']}
                        label={index === 0 ? "Websites" : ""}
                        labelCol={{ span: 4 }}
                        labelAlign='left'
                        rules={[{ type: 'url', warningOnly: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  ))}
                  {fields.length < 2 && (
                  <Col xs={12} sm={12} md={12}>
                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                      Add Website
                    </Button>
                  </Col>)}
                </>
              )}
            </Form.List>
          </Row>
          <Form.Item>
            <div className="mt-4 text-right">
              <Button htmlType="submit" type="primary" disabled={uploadingFile} loading={loadingSubmit}>
                Update
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

WhatsappBusinessProfileForm.defaultProps = {
  businessProfile: undefined,
  numberId: undefined,
  ContactNumber: undefined,
  fetchBusinessProfile: undefined,
};

export default WhatsappBusinessProfileForm;