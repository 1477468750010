import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// const Contacts = lazy(() => import("./Contacts"));
const AllContacts = lazy(() => import("./AllContacts"));


const ContactPages = () => (
  <Routes>
    <Route path="/*" element={<AllContacts />} />
    {/* <Route path="/" element={<Contacts />} /> */}
    {/* <Route path='groups' element={<Groups />} /> */}
    {/* <Route path='/all-contacts/:groupId' element={<Groups />} /> */}
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default ContactPages;
