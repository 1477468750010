import AxiosService from "api";

const ChatBotApi = {
  baseUrl: '/chatbot/v1/',
  getUrl: (url: string) => `${ChatBotApi.baseUrl}${url}`,
  post: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.post<R, D>(ChatBotApi.getUrl(url), data, isPublic, headers),
  postWithQueryParams: <R = any, P = any, D = any>(url: string, queryData?: P, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.postWithQueryParams<R, P, D>(ChatBotApi.getUrl(url), queryData, data, isPublic, headers),
  put: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.put<R, D>(ChatBotApi.getUrl(url), data, isPublic, headers),
  get: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.get<R, D>(ChatBotApi.getUrl(url), data, isPublic, headers),
  delete: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.delete<R, D>(ChatBotApi.getUrl(url), data, isPublic, headers),
};
export default ChatBotApi;
