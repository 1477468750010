import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {notification, Tabs} from 'antd';
import {WhatsappCampaignSummary, WhatsappCampaign, WhatsappMetaContactList} from "models";
import {
  WhatsappCampaignMessageFormModel,
  WhatsappCampaignScheduleFormModel
} from 'models/forms';
import {findCountry} from 'utils/helper';
// import WhatsappCampaignSenderTab from "../WhatsappCampaignSenderTab";
import WhatsappCampaignMessageTab from "../WhatsappCampaignMessageTab";
import {
  createWhatsappCampaignService, editWhatsappCampaignService,
  getWhatsappCampaignVerifyRequestSummaryService
} from "../../../../services/dashboard/WhatsappCampaignService";
import {WhatsappCampaignUpdateRequest} from "../../../../models/requests";
import {ApiErrorResponse} from "../../../../models/responses";
import WhatsappCampaignScheduleTab from "../WhatsappCampaignScheduleTab";
import WhatsappCampaignVerifyTab from "../WhatsappCampaignVerifyTab";
import {getOriginatorDetailsService} from "../../../../services/dashboard/WhatsappService";


const {TabPane} = Tabs;

export interface WhatsappCampaignFormProps {
  whatsappCampaign?: WhatsappCampaign;
};


const WhatsappCampaignForm = (props: WhatsappCampaignFormProps) => {
  const {whatsappCampaign} = props;

  const [activeTab, setActiveTab] = useState("1");
  const [messageFormModel, setMessageFormModel] = useState<WhatsappCampaignMessageFormModel>();
  const [scheduleFormModel, setScheduleFormModel] = useState<WhatsappCampaignScheduleFormModel>();
  const [loading, setLoading] = useState(false);
  // const [schedule, setSchedule] = useState(false);
  const [campaignVerificationList, setWhatsappCampaignSummaryList] = useState<WhatsappCampaignSummary[]>([]);
  const [countryWiseCode, setCountryWiseCode] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({
    body_text: '', example_body_text: [], header_text: '', header_format: '',
    footer_text: '', example_header_text: [], media_url: '', buttons: {
      buttons_type: '',
      button_quick_reply: [{button_text_quick_reply: ''}],
      button_phone_number: [{button_text_phone_number: '', button_phone_number: ''}],
      button_url: [{button_text_url: '', button_url: '', example_url_values: ''}],
      button_copy_code: [{button_text_copy_code: ''}]
    },
    limited_time_offer: {has_expiration: false, limited_time_offer_text: ''},
    carousel: {
      components: [{
        media_url: '', body_text: '', example_body_text: [], buttons: {
          button_url: [{button_text_url: '', button_url: '', example_url_values: ''}]
        }
      }]
    }

  });
  const [buttonTextValues, setButtonTextValues] = useState<string[]>([]);
  const [buttonPhoneTextValues, setButtonPhoneTextValues] = useState<string[]>([]);
  const [buttonUrlTextValues, setButtonUrlTextValues] = useState<string[]>([]);
  const [buttonCopyCodeTextValues, setButtonCopyCodeTextValues] = useState<string[]>([]);
  const [originator, setOriginator] = useState<WhatsappMetaContactList>();
  const [inputDirection, setInputDirection] = useState('ltr');
  const navigate = useNavigate();

  const onTabClick = (activeKey: string, e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const showErrorNotification = (messageText: string) => {
    const notificationParam = {
      message: messageText,
      description: ''
    };
    notification.error(notificationParam);
  };
  const showSuccessNotification = (messageText: string) => {
    const notificationParam = {
      message: messageText,
      description: ''
    };
    notification.success(notificationParam);
  };

  // const onClickSenderFormNext = (formModel: WhatsappCampaignMessageFormModel) => {
  //   // setMessageFormModel(formModel);
  //   // Update the form data
  //   // Assign values to messageFormModel directly
  //   setMessageFormModel((prevModel: any) => ({
  //     ...prevModel,
  //     campaign_name: formModel.campaign_name || '',
  //     originator: formModel.originator || '',
  //   }));
  //
  //   setActiveTab("2");
  // };

  const onClickMessageFormNext = async (formModel: WhatsappCampaignMessageFormModel) => {
    // setMessageFormModel(formModel);

    try {
      const response = await getOriginatorDetailsService(formModel?.originator);
      setOriginator(response);
    } catch (error) {
      setOriginator(undefined);
    }

    // Assign values to messageFormModel directly
    setMessageFormModel((prevModel: any) => ({
      ...prevModel,
      campaign_name: formModel.campaign_name || '',
      originator: formModel.originator || '',
      content: {
        template_id: formModel.content?.template_id || '',
        language: formModel.content?.language || '',
        body_parameter_values: formModel.content?.body_parameter_values || '',
        media: formModel.content?.media ? {
          media_type: formModel.content?.media?.media_type || '',
          media_url: formModel.content?.media?.media_url || '',
          text_header_title: formModel.content?.media?.text_header_title,
          document_file_name: formModel.content?.media?.document_file_name,
          media_caption: formModel.content?.media?.media_caption || '',
          location: formModel.content?.media?.media_type === 'location'
            ? {
              longitude: formModel.content?.media?.location?.longitude || '',
              latitude: formModel.content?.media?.location?.latitude || '',
              name: formModel.content?.media?.location?.name || '',
              address: formModel.content?.media?.location?.address || ''
            } : undefined
        } : undefined,
        limited_time_offer: formModel.content?.limited_time_offer,
        carousel: formModel.content?.carousel,
        buttons: formModel.content?.buttons
      }
    }));

    setActiveTab("2");
  };

  const fetchVerificationResult = async (formModel: WhatsappCampaignScheduleFormModel) => {
    setLoading(true);
    try {
      const request = {
        contact_groups: formModel.groups ?? [],
        contacts: formModel.contacts ?? [],
        recipients: formModel.recipients ?? [],
        recipients_file: formModel.filepath ?? '',
        file_mapping: formModel.file_mapping ?? [],
        filtering: formModel?.filtering ?? [],
        originator: messageFormModel?.originator ?? ''
      };
      const response = await getWhatsappCampaignVerifyRequestSummaryService(request);
      if (response) {
        const data = Object.entries(response.country_count_dict).map(([key, value], i) => ({
          id: i,
          country: findCountry(key),
          totalRecipients: value
        }));
        const total = Object.values(response.country_count_dict).reduce((acc: number, val: string) => acc + parseInt(val, 10), 0);
        setCountryWiseCode(total);
        setWhatsappCampaignSummaryList(data);
        // Check the remaining message limit reached the limit
        if (data.length === 0) {
          showErrorNotification('Please enter a valid recipient phone number.');
        } else {
          const remainingLimit = response.remaining_messaging_limit ?? 0;

          if (remainingLimit === 0) {
            showErrorNotification('Your daily message limit already reached.');
          } else {
            const remain = remainingLimit - total;

            if (remain < 0) {
              showErrorNotification(`You can only send messages to ${remainingLimit} recipients numbers. Because Your remaining daily message limit is ${remainingLimit}`);
            } else {
              setActiveTab("3");
            }
          }
        }
      } else {
        setWhatsappCampaignSummaryList([]);
        // setSchedule(true);
      }
    } catch (error) {
      showErrorNotification('Failed to fetch verify request');
    } finally {
      setLoading(false);
    }
    ;

  };
  const onSuccessSchedule = () => {
    const type = whatsappCampaign ? 'edited' : 'created';
    showSuccessNotification(`Campaign ${type} successfully`);
    setLoading(false);
    navigate('/whatsapp-meta/whatsapp-campaign/whatsapp-campaign-report');
  };

  const onErroSchedule = (showNotification?: boolean) => {
    if (showNotification) {
      const type = whatsappCampaign ? 'edit' : 'create';
      showErrorNotification(`Failed to ${type} Campaign`);
    }
    setLoading(false);
  };
  const createCampaign = async (values: WhatsappCampaignUpdateRequest) => {
    setLoading(true);
    try {
      const response = await createWhatsappCampaignService(values);
      if (response.id) {
        onSuccessSchedule();
      } else {
        onErroSchedule(true);
      }
    } catch (error: any) {
      const newError = error as ApiErrorResponse;
      let {message} = newError;
      if (newError.status === 422) {
        message = newError.validationError?.detail?.map(x => {
          if (x.msg?.indexOf('invalid_schedule_time: Schedule time must be > current time') >= 0) {
            return 'The schedule time has already passed. Please correct it to proceed';
          }
          return x.msg;
        })?.join("/n") ?? '';
      }

      onErroSchedule(false);
      showErrorNotification(message);
    }
  };

  const updateCampaign = async (values: WhatsappCampaignUpdateRequest, campId: string) => {
    try {
      const response = await editWhatsappCampaignService(values, campId);
      if (response.id) {
        onSuccessSchedule();
      } else {
        onErroSchedule(true);
      }
    } catch (error) {
      onErroSchedule(true);
    }
  };

  const onClickSchedule = async () => {
    setLoading(true);
    const request = {
      campaign_name: messageFormModel?.campaign_name ?? '',
      originator: messageFormModel?.originator ?? '',
      content: messageFormModel?.content,
      recipients: scheduleFormModel?.recipients ?? [],
      recipients_file: scheduleFormModel?.filepath,
      contact_groups: scheduleFormModel?.groups ?? [],
      contacts: scheduleFormModel?.contacts ?? [],
      scheduled_at: scheduleFormModel?.scheduled_at,
      scheduled_tz: scheduleFormModel?.scheduled_tz,
      file_mapping:scheduleFormModel?.file_mapping,
      allow_duplicate: scheduleFormModel?.allow_duplicate,
      filtering: scheduleFormModel?.filtering,
      total_recipients_count: countryWiseCode,
    };
    if (whatsappCampaign) {
      await updateCampaign(request, whatsappCampaign.id);
    } else {
      await createCampaign(request);
    }
  };

  const onClickScheduleFormNext = async (formModel: WhatsappCampaignScheduleFormModel) => {
    setScheduleFormModel(formModel);
    if (formModel.groups?.length === 0 && formModel.contacts?.length === 0 && formModel.recipients?.length === 0 && !formModel.filepath) {
      showErrorNotification("Please add a recipient phone number");
    } else {
      await fetchVerificationResult(formModel);
    }
  };

  const handleOnSelectedTemplate = (selected: any) => {
    setSelectedTemplate(selected);
  };

  const handleOnButtonTexts = (value: any) => {
    setButtonTextValues(value.quickReplyTextValues);
    setButtonPhoneTextValues(value.phoneTextValues);
    setButtonUrlTextValues(value.urlTextValues);
    setButtonCopyCodeTextValues(value.copyCodeTextValues);
  };

  const handleOnInputDirection = (value: any) => {
    setInputDirection(value);
  };

  useEffect(() => {
  }, [whatsappCampaign]);

  return (<Tabs size="middle"
                tabBarGutter={50}
                tabBarStyle={{ width: '100%', ...(window.innerWidth >= 600 && window.innerWidth <= 767 && { width: '91%', marginLeft:"52px", marginBottom:"30px" }), ...(window.innerWidth >= 768 && window.innerWidth <= 1440 && { width: '91%', marginLeft:"39px", marginBottom:"30px" }), ...(window.innerWidth > 1441 && window.innerWidth <= 2560 && { width: '91%', marginLeft:"57px", marginBottom:"30px" }) } as any}
                activeKey={activeTab}
                onTabClick={onTabClick}
                className="d7-tabs" centered>
    {/* <TabPane tab="Sender" key="1" style={{overflowY: 'auto', overflowX: 'hidden', height: '100%', ...(window.innerWidth >= 992 && { height: '650px' }) } as any}> */}
    {/*   <WhatsappCampaignSenderTab whatsappCampaign={whatsappCampaign} onClickNext={onClickSenderFormNext}/> */}
    {/* </TabPane> */}
    <TabPane tab="Message" key="1" style={{overflowY: 'auto', overflowX: 'hidden', height: '100%', ...(window.innerWidth >= 992 && { height: '650px' }) } as any}>
      <WhatsappCampaignMessageTab onClickNext={onClickMessageFormNext}
                                  originator={messageFormModel?.originator ?? ''} onSelectedTemplate={handleOnSelectedTemplate} onButtonTexts={handleOnButtonTexts} onInputDirection={handleOnInputDirection}/>
    </TabPane>
    <TabPane tab="Contacts & Schedule" key="2" style={{overflowY: 'auto', overflowX: 'hidden', height: '100%', ...(window.innerWidth >= 992 && { height: '650px' }) } as any}>
      <WhatsappCampaignScheduleTab
        whatsappCampaign={whatsappCampaign}
        onClickPrev={() => setActiveTab("1")}
        onClickNext={onClickScheduleFormNext}
        messageFormModel={messageFormModel}
        selectedTemplate={selectedTemplate}
        buttonTextValues={buttonTextValues}
        buttonPhoneTextValues={buttonPhoneTextValues}
        buttonUrlTextValues={buttonUrlTextValues}
        buttonCopyCodeTextValues={buttonCopyCodeTextValues}
        inputDirection={inputDirection}
        originator={originator}
      />
    </TabPane>
    <TabPane tab="Summary" key="3" style={{overflowY: 'auto', overflowX: 'hidden', height: '100%', ...(window.innerWidth >= 992 && { height: '650px' }) } as any}>
      <WhatsappCampaignVerifyTab
        campaignVerificationList={campaignVerificationList}
        messageFormModel={messageFormModel}
        scheduleFormModel={scheduleFormModel}
        loading={loading}
        // schedule={schedule}
        onClickPrev={() => setActiveTab("2")}
        onSubmitForm={onClickSchedule}
        selectedTemplate={selectedTemplate}
        buttonTextValues={buttonTextValues}
        buttonPhoneTextValues={buttonPhoneTextValues}
        buttonUrlTextValues={buttonUrlTextValues}
        buttonCopyCodeTextValues={buttonCopyCodeTextValues}
        inputDirection={inputDirection}
      />
    </TabPane>
  </Tabs>);
};

WhatsappCampaignForm.defaultProps = {
  whatsappCampaign: undefined,
};

export default WhatsappCampaignForm;
