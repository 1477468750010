import {
  OpenRegisterRequest,
  SignupEmailValidateRequest,
  BlockedRegisterRequest,
  ResendNewAccountEmailRequest, OpenRegisterQueryRequest
} from 'models/requests';
import { validateSignupEmail, openRegister, blockedRegister, resendNewAccountEmail } from 'api/auth/RegisterApi';

const validateSignupEmailService = async (data: SignupEmailValidateRequest) => validateSignupEmail(data);
const openRegisterService = async (data: OpenRegisterRequest, queryData: OpenRegisterQueryRequest) => openRegister(data, queryData);
const blockedRegisterService = async (data: BlockedRegisterRequest) => blockedRegister(data);
const resendNewAccountEmailService = async (data: ResendNewAccountEmailRequest) => resendNewAccountEmail(data);


export {
  validateSignupEmailService,
  openRegisterService,
  blockedRegisterService,
  resendNewAccountEmailService
};
