import React from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerIconShadow,
  iconAnchor: [12, 41], 
});

L.Marker.prototype.options.icon = DefaultIcon;

interface WhatsappLocationMessageProps {
  latitude?: number;
  longitude?: number;
  name?: string;
  address?: string;
}

const OpenMapOnClick: React.FC<{ lat: number; lng: number }> = ({ lat, lng }) => {
  const map = useMap();
  map.on('click', () => {
    const url = `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lng}#map=18/${lat}/${lng}`;
    window.open(url, '_blank');
  });
  return null;
};

const WhatsappLocationMessage: React.FC<WhatsappLocationMessageProps> = ({ latitude, longitude, name, address }) => {
  if (latitude === undefined || longitude === undefined) {
    return <div>Invalid location coordinates.</div>;
  }

  const mapCenter: LatLngTuple = [latitude, longitude];
  const mapStyle = {
    height: '150px',
    width: '246px',
    borderRadius: '4px',
    overflow: 'hidden',
    cursor: 'pointer', 
  };

  return (
    <div>
    <div style={{ position: 'relative' }}>
      <MapContainer
        center={mapCenter}
        zoom={15}
        style={mapStyle}
        zoomControl={false}
        attributionControl={false}
        dragging={false}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={mapCenter} />
        <OpenMapOnClick lat={latitude} lng={longitude} />
      </MapContainer>
    </div>
    {name && address && <div style={{padding: "8px", backgroundColor: "gainsboro", borderRadius: "4px", borderTopRightRadius: "0px", borderTopLeftRadius: "0px"}}>
      <Title level={4} style={{marginBottom: "0px", fontSize: "14px"}}>{name}</Title>
      <Text style={{fontSize: "12px"}}>{address}</Text>
    </div>}
    </div>
  );
};

WhatsappLocationMessage.defaultProps = {
  latitude: undefined,
  longitude: undefined,
  name: undefined,
  address: undefined,
};

export default WhatsappLocationMessage;
