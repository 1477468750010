import React, {useEffect, useState} from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal, Switch
} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";


export interface StepThreeOTPButtonComponentProps {
  onOtpTypeChange: any;
  form: any;
  inputDirection?: any;
}

const StepThreeOTPButtonComponent = (props: StepThreeOTPButtonComponentProps) => {

  const {onOtpTypeChange, form, inputDirection} = props;
  const [otpButtonType, setOtpButtonType] = useState<string>();


  const ButtonTypeChange = (type: string) => {
    setOtpButtonType(type);
    onOtpTypeChange(type);
    // console.log(form.getFieldValue(['buttons', 'button_Otp', 0, 'zero_tap_terms_accepted']));
    form.setFieldsValue({
      buttons: {
        button_Otp: [{
          otp_type: type,
          button_text_otp: form.getFieldValue(['buttons', 'button_Otp', 0, 'button_text_otp']) || '',
          otp_autofill_text: form.getFieldValue(['buttons', 'button_Otp', 0, 'otp_autofill_text']) || '',
          otp_package_name: form.getFieldValue(['buttons', 'button_Otp', 0, 'otp_package_name']) || '',
          otp_signature_hash: form.getFieldValue(['buttons', 'button_Otp', 0, 'otp_signature_hash']) || '',
          zero_tap_terms_accepted: true ? type === 'zero_tap' : null
        }],
      },
    });
  };

  useEffect(() => {
    const otpType = form.getFieldValue(['buttons', 'button_Otp', 0, 'otp_type']);
    if (otpType) {
      ButtonTypeChange(otpType);
      if (onOtpTypeChange){
        onOtpTypeChange(otpType);
      }
    }
  }, [form, ButtonTypeChange]);

  return (
    <>
      <Col xs={24} sm={24} md={24}>
        <p style={{textAlign: 'left'}}>
          Message templates in the AUTHENTICATION category must have exactly one button.
        </p>
      </Col>
      <Col xs={24} sm={24} md={24}>
        <Form.Item name={['buttons', 'button_Otp', 0, 'otp_type']} labelAlign='left' labelCol={{span: 3}}
                   style={{textAlign: 'center'}}>
          <Button.Group>
            <Button
              type={otpButtonType === 'one_tap' ? 'primary' : 'default'}
              onClick={() => ButtonTypeChange('one_tap')}
            >
              One Tap
            </Button>
            <Button
              type={otpButtonType === 'zero_tap' ? 'primary' : 'default'}
              onClick={() => ButtonTypeChange('zero_tap')}
            >
              Zero Tap
            </Button>
            <Button
              type={otpButtonType === 'copy_code' ? 'primary' : 'default'}
              onClick={() => ButtonTypeChange('copy_code')}
            >
              Copy Code
            </Button>
          </Button.Group>
        </Form.Item>
      </Col>

      {otpButtonType && (
        <>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              labelAlign='left'
              labelCol={{span: 3}}
              label="Button Text"
              name={['buttons', 'button_Otp', 0, 'button_text_otp']}
              rules={[
                {
                  max: 25,
                  message: 'Button Text cannot exceed 25 characters',
                }
              ]}
            >
              <Input dir={inputDirection}/>

            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
          {otpButtonType !== 'copy_code' && (
            <><Form.Item
              labelAlign='left'
              labelCol={{span: 3}}
              label="Autofill Text"
              name={['buttons', 'button_Otp', 0, 'otp_autofill_text']}
              rules={[
                {
                  max: 25,
                  message: 'Autofill Text cannot exceed 25 characters',
                }
              ]}
            >
              <Input dir={inputDirection}/>
            </Form.Item><Form.Item
              labelAlign='left'
              labelCol={{span: 3}}
              label="Package Name"
              name={['buttons', 'button_Otp', 0, 'otp_package_name']}
              rules={[
                {
                  required: true,
                  message: 'Please enter Your Android app\'s package name',
                },
                {
                  max: 25,
                  message: 'Package Name cannot exceed 25 characters',
                }
              ]}
            >
              <Input/>
            </Form.Item><Form.Item
              labelAlign='left'
              labelCol={{span: 3}}
              label="Signature Hash"
              name={['buttons', 'button_Otp', 0, 'otp_signature_hash']}
              rules={[
                {
                  required: true,
                  message: 'Please enter Your app signing key hash',
                },
              ]}
            >
              <Input/>
            </Form.Item></>
          )}
        </Col>
          <Col xs={24} sm={24} md={24}>
            {otpButtonType === 'zero_tap' && (
              <Form.Item
                label={
                  <span>
          Zero Tap Terms Accepted
          <InfoCircleOutlined
            style={{ fontSize: '16px', marginLeft: '8px', cursor: 'pointer' }}
            onClick={() => {
              Modal.info({
                title: "Terms Accepted",
                content: (
                  <div>
                    <p>Set to true to indicate that you understand that your use of zero-tap
                      authentication is subject to the WhatsApp Business Terms of Service, and
                      that it&apos;s your responsibility to ensure your customers expect that the
                      code will be automatically filled in on their behalf when they choose to receive the
                      zero-tap code through WhatsApp.</p>
                    <p>If set to false, the template will not be created as you need to accept
                      zero-tap terms before creating zero-tap enabled message templates.</p>
                  </div>
                ),
              });
            }}
          />
        </span>
                }
                name={['buttons', 'button_Otp', 0, 'zero_tap_terms_accepted']}
                labelCol={{ span: 5 }}
                labelAlign='left'
                // valuePropName="checked"
                // getValueProps={(value) => ({ checked: value })}
                // getValueFromEvent={(checked) => checked}
                initialValue
              >
                <Switch defaultChecked disabled />
              </Form.Item>
            )}
          </Col>
        </>

      )}

    </>
  );
};

StepThreeOTPButtonComponent.defaultProps = {
  // form: undefined,
  inputDirection: undefined
};


export default StepThreeOTPButtonComponent;
