import React, {useState} from 'react';
import {Modal, Tag} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { WhatsappTemplateDetails} from "models";
import TableList from 'components/shared/TableList';


export interface WhatsappTemplateListProps {
  whatsapptemplate: WhatsappTemplateDetails[];
  loading?: boolean;
  openWhatsappTemplateForm?: (isEdit?: boolean, item?: WhatsappTemplateDetails) => void;
  onClickDeleteWhatsappTemplate?: (id: number) => void;
  onClickRefresh:(wabaId: string|undefined)=>void;
}

const WhatsappTemplateList = (props: WhatsappTemplateListProps) => {
  const { whatsapptemplate, loading, openWhatsappTemplateForm, onClickDeleteWhatsappTemplate, onClickRefresh} = props;
  const [editError, setEditError] = useState<string | null>(null);
  const onConfirmDelete = (item: WhatsappTemplateDetails) => {
    onClickDeleteWhatsappTemplate?.(item.id);
  };

  const openEditForm = (item: WhatsappTemplateDetails) => {
    if (item.last_update_at) {
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setDate(twentyFourHoursAgo.getDate() - 1);

      const createdAtDate = new Date(item.created_at);
      const updatedAtDate = new Date(item.last_update_at);
      createdAtDate.setMilliseconds(0);
      updatedAtDate.setMilliseconds(0);
      if (createdAtDate.toISOString() !== updatedAtDate.toISOString()) {
        if (updatedAtDate >= twentyFourHoursAgo) {
          setEditError("You can only edit this template once every 24 hours.");
          Modal.error({
            title: 'Warning',
            content: 'You can only edit this template once every 24 hours.',
          });
        } else {
          openWhatsappTemplateForm?.(true, item);
        }
      } else {
        openWhatsappTemplateForm?.(true, item);
      }
    }
  };

  // const disableEdit = (item: WhatsappTemplateDetails)=> {
  //   if (item.carousel) {
  //     setEditDisabled(true);
  //   } else {
  //     setEditDisabled(false);
  //   }
  // };



  const actions = [
    {
      toolTip: 'Edit',
      icon: <EditOutlined />,
      getDisabled: (item: WhatsappTemplateDetails) => !!item.carousel,
      onClick: openEditForm,
      // onClick: (item: WhatsappTemplateDetails) => {
      //   openWhatsappTemplateForm?.(true, item);
      // }
    },
    {
      toolTip: 'Delete',
      icon: <DeleteOutlined />,
      popConfirm: {
        title: 'Are you sure you want to delete this Whatsapp Template?', onConfirm: onConfirmDelete
      },
    }
  ];
  const controls = [
    {
      title: 'Refresh',
      icon: <ReloadOutlined/>,
      isIconOnly:true,
      onClick: () => {
        onClickRefresh?.(undefined);
      }
    },
    {
      title: 'Create  Whatsapp Template',
      icon: <PlusOutlined />,
      onClick: () => { openWhatsappTemplateForm?.(); }
    }
  ];

  const searchPredicate = (item: WhatsappTemplateDetails, text: string) =>
    item.template_name.toLowerCase().includes(text);

  const tableColumns: ColumnsType<WhatsappTemplateDetails> = [
    // {
    //   title: 'Template ID',
    //   dataIndex: 'template_id'
    // },
    {
      title: 'Template ID',
      dataIndex: 'template_name',
    },
    {
      title: 'Template Language',
      dataIndex: 'template_language',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Header Text',
      dataIndex: 'header_text',
    },
    {
      title: 'Header Format',
      dataIndex: 'header_format',
    },
    // {
    //   title: 'Example Header Text',
    //   dataIndex: 'example_header_text',
    // },
    {
      title: 'Body Text',
      dataIndex: 'body_text',
    },
    // {
    //   title: 'Example Body Text',
    //   dataIndex: 'example_body_text',
    // },
    {
      title: 'Footer Text',
      dataIndex: 'footer_text',
    },
    // {
    //   title: 'Buttons',
    //   dataIndex: 'buttons',
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => {
        let color = '';

        if (status === 'APPROVED') {
          color = 'green';
        } else if (status === 'PENDING') {
          color = 'orange';
        } else if (status === 'REJECTED') {
          color = 'red';
        }

        return <Tag color={color}>{status}</Tag>;
      },
    }
  ];

  return (
    <div>
      {editError && <p hidden style={{ color: 'red' }}>{editError}</p>}
      <TableList<WhatsappTemplateDetails> items={whatsapptemplate} columns={tableColumns} loading={loading}
                                          rowKey={(record: WhatsappTemplateDetails) => record.id} showSearch actions={actions} controls={controls}
                                          searchPredicate={searchPredicate} hideRowSelection />
    </div>
  );
};

WhatsappTemplateList.defaultProps = {
  loading: false,
  openWhatsappTemplateForm: undefined,
  onClickDeleteWhatsappTemplate: undefined
};

export default WhatsappTemplateList;
