import React, {useEffect, useState} from 'react';
import {
  Col,
  Form,
  Input,
} from "antd";
import {WhatsappTemplateDetails} from "../../../../../../models";


export interface StepTwoFooterComponentProps {
  onFooterTextChanged?: any;
  inputDirection?: any;
  editItem?: WhatsappTemplateDetails;
  isTemplateTypeChange?: boolean;
  isCategoryTypeChange?: boolean;
  form?: any;
  currentStep: number;
}

const StepTwoFooterComponent = (props: StepTwoFooterComponentProps) => {

  const {currentStep, form, onFooterTextChanged, inputDirection, editItem, isTemplateTypeChange, isCategoryTypeChange} = props;

  const [footerText, setFooterText] = useState<string | undefined>(undefined);

  const handleFooterTextChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setFooterText(value);
    if (onFooterTextChanged) {
      onFooterTextChanged(value);
    }
  };


  useEffect(() => {
    if (editItem) {
      setFooterText(editItem.footer_text || '');
    }

    if (isTemplateTypeChange){
      setFooterText(undefined);
      form.setFieldsValue({ footer_text: undefined });
    }
    if (isCategoryTypeChange){
      setFooterText(undefined);
      form.setFieldsValue({ footer_text: undefined });
    }
  }, [editItem, currentStep]);

  return (
          <><Col xs={24} sm={24} md={24}>
            <h4>
              Footer <small style={{color: 'gray', fontSize: '12px'}}> (Optional)</small>
            </h4>
          </Col><Col xs={24} sm={21} md={21}>
            <Form.Item
              name="footer_text"
              labelAlign='left'
              labelCol={{span: 3}}
              extra="Maximum length is 60 chars"
              rules={[
                {
                  max: 60,
                  message: 'Footer text cannot exceed 60 characters',
                },
              ]}
            >
              <Input value={footerText}
                     onChange={handleFooterTextChange}
                     dir={inputDirection}
              />
            </Form.Item>
          </Col></>
  );
};

StepTwoFooterComponent.defaultProps = {
  onFooterTextChanged: undefined,
  inputDirection: undefined,
  editItem: undefined,
  isCategoryTypeChange: undefined,
  isTemplateTypeChange: undefined,
  form: undefined,
};


export default StepTwoFooterComponent;
