import React, {useEffect, useState} from 'react';
import {Input, Button, Modal, Form, Row, Col, Select, notification} from 'antd';
import {useNavigate} from 'react-router-dom';
import {WhatsappContactList} from "models";
import {Chatbot} from "../../../../models/chatbot";
import {createChatbotService, updateChatbotService} from "../../../../services/dashboard/ChatbotService";

export interface ChatbotAddingFormProps {
  showChatbotForm: boolean
  onSave?: (response: Chatbot, isNew: boolean) => void,
  onClose?: () => void,
  editItem?: Chatbot
  contacts: WhatsappContactList[]
}

const ChatbotAddingForm = (props: ChatbotAddingFormProps) => {
  const [form] = Form.useForm();
  const {showChatbotForm, onClose, onSave, editItem, contacts} = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const resetAndCloseForm = () => {
    form.resetFields();
    onClose?.();
  };
  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };
  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };
  const onOkModel = () => {
    if (!loading) {
      resetAndCloseForm();
    }
  };

  const onCancelModel = () => {
    if (!loading) {
      resetAndCloseForm();
    }
  };

  const createChatbot = async (values: any) => {
    setLoading(true);
    try {
      const isNew = true;
      await createChatbotService(values).then(resp => {
        showSuccessNotification('Successfully created the chatbot!');
        setLoading(false);
        form.resetFields();
        onSave?.(resp, isNew);
        navigate(`${values.name}/${resp}`);

      }).catch(error => {
        showErrorNotification('Failed to create chatbot');
      });
    } catch (error) {
      showErrorNotification('Failed to create chatbot');
    }
    setLoading(false);
  };
  const updateChatbot = async (chatbotId: string, values: any) => {
    setLoading(true);
    try {
      const isNew = false;
      await updateChatbotService(chatbotId, values).then(resp => {
        // showSuccessNotification('Successfully updated the chatbot!');
        form.resetFields();
        onSave?.(resp, isNew);
        navigate(`${values.name}/${chatbotId}`);
      }).catch(error => {
        showErrorNotification('Failed to update chatbot');
      });
    } catch (error) {
      showErrorNotification('Failed to update chatbot');
    }
    setLoading(false);
  };

  const onSubmitForm = async (values: any) => {
    if (editItem) {
      await updateChatbot(editItem?.id ?? '', values);

    } else {
      await createChatbot(values);
    }
  };

  useEffect(() => {
    if (editItem) {
      if (editItem.number) {
        const cleanedChatbotNumber = editItem.number.replace(/\+/, '');
        form.setFieldsValue({name: editItem.name, number: cleanedChatbotNumber});
      } else {
        form.setFieldsValue({name: editItem.name, number: undefined});
      }
    }
  }, [editItem, contacts]);
  return (
    <Modal
      title={editItem ? "Edit Chatbot" : "Add Chatbot"}
      visible={showChatbotForm}
      onOk={onOkModel}
      onCancel={onCancelModel}
      footer={null}
    > <Form
      name="chatbotForm"
      layout="horizontal"
      size="small"
      form={form}
      onFinish={onSubmitForm}
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24}>
          <Form.Item
            label="Chatbot Name"
            name="name"
            labelAlign='left'
            labelCol={{span: 8}}
            rules={[
              {
                required: true,
                message: 'Please enter chatbot name',
              }
            ]}
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24}>
          <Form.Item
            label="Phone number"
            name="number"
            labelAlign='left'
            labelCol={{span: 8}}
          >

            <Select showSearch placeholder="select Phonenumber" >
              {contacts?.map((e) => (
                <Select.Option key={e.number} value={e.number}>
                  {e.name?`${e.number} (${e.name})`:`${e.number}`}
                </Select.Option>
              ))}

            </Select>

          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <div className="text-right">
          <Button htmlType="submit" type="primary" loading={loading}>
            Next
          </Button>
        </div>
      </Form.Item>
    </Form>
    </Modal>
  );
};
ChatbotAddingForm.defaultProps = {
  onSave: undefined,
  onClose: undefined,
  editItem: undefined
};

export default ChatbotAddingForm;
