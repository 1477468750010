import React from 'react';
import {Card, Col, Row } from 'antd';
import { ImportOutlined, PhoneFilled } from '@ant-design/icons';
import ChatMessageComponent from '../WhatsappMessagePreviewComponent';

interface MediaIconUrls {
  [key: string]: string;
}

const ChatPreviewPage = ({
                           headerText,
                           bodyText,
                           footerText,
                           selectedMediaType,
                           buttonType,
                           actionType,
                           actionButtonsQuickReply,
                           actionButtonsPhoneNumber,
                           actionButtonsUrl,
                         }: {
  headerText: string;
  bodyText: string;
  footerText: string;
  selectedMediaType: string;
  buttonType: string;
  actionType: string;
  actionButtonsQuickReply: string[];
  actionButtonsPhoneNumber: string[];
  actionButtonsUrl: string[];
}) => {
  const mediaIconUrls: MediaIconUrls = {
    IMAGE:
      'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png',
    VIDEO:
      'https://www.pngmart.com/files/1/Video-Icon-PNG-HD.png',
    DOCUMENT:
      'https://www.freeiconspng.com/thumbs/document-icon/document-icon-19.png',
    LOCATION:
      'https://www.freeiconspng.com/thumbs/location-icon-png/location-icon-map-png--1.png',
  };

  const messages = [
    {
      content: headerText,
      isBold: true,
      isMedia:
        selectedMediaType === 'IMAGE' ||
        selectedMediaType === 'VIDEO' ||
        selectedMediaType === 'DOCUMENT' ||
        selectedMediaType === 'LOCATION',
      mediaIconUrl: mediaIconUrls[selectedMediaType],
    },
    { content: bodyText.replace(/\*(.*?)\*/g, '<b>$1</b>')
        .replace(/_(.*?)_/g, '<i>$1</i>')
        .replace(/```(.*?)```/g, '<code>$1</code>'), },
    { content: footerText, isOpacity: true },
  ];

  const chatContainerStyle = {
    maxWidth: '400px',
    margin: '20px auto',
    padding: '10px',
    background: 'white',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div>
      <Row style={chatContainerStyle}>
        <Col xs={24} sm={24} md={24} /* eslint-disable react/no-array-index-key */>
          {messages.map((message, index) => (
            <ChatMessageComponent
              key={index}
              content={message.content}
              isBold={message.isBold}
              isOpacity={message.isOpacity}
              isMedia={message.isMedia}
              mediaIconUrl={message.mediaIconUrl}
            />
          ))}
        </Col>
      </Row>
       {buttonType !== 'None' && (
         <>
           <Row>
           {actionButtonsQuickReply.map((value, index) => (
             <Col xs={12} sm={12} md={12} key={index}>
               {buttonType === 'QUICK_REPLY' && (
                 <Card style={{marginTop: '-15px', padding: '-20px'}}>
                   {value}
                 </Card>
               )}
             </Col>
           ))}
         </Row>
           {buttonType === 'CALL_TO_ACTION' && (
             <><Row>
               {actionButtonsPhoneNumber.map((value, index) => (
                 <Col xs={12} sm={12} md={12} key={index}>
                   {actionType === 'PHONE_NUMBER' && (
                     <Card style={{marginTop: '-15px', padding: '-20px'}}>
                       <PhoneFilled/>
                       {value}
                     </Card>
                   )}
                 </Col>
               ))}
             </Row><Row>
               {actionButtonsUrl.map((value, index) => (
                 <Col xs={12} sm={12} md={12} key={index}>
                   {actionType === 'URL' && (
                     <Card style={{marginTop: '-15px', padding: '-20px'}}>
                       <ImportOutlined/>
                       {value}
                     </Card>
                   )}
                 </Col>
               ))}
             </Row><Row>
               {actionType === 'PHONE_NUMBER_AND_URL' && (
                 <><Col xs={12} sm={12} md={12}>
                   <Card style={{marginTop: '-15px', padding: '-20px'}}>
                     <ImportOutlined/>
                     {actionButtonsUrl}
                   </Card>
                 </Col><Col xs={12} sm={12} md={12}>
                   <Card style={{marginTop: '-15px', padding: '-20px'}}>
                     <PhoneFilled/>
                     {actionButtonsPhoneNumber}
                   </Card>
                 </Col></>
               )}
             </Row>
             </>
           )}
         </>
       )}
    </div>
  );
};

export default ChatPreviewPage;
