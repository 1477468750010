import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import en from 'world_countries_lists/data/countries/en/world.json';
import { ColumnsType } from 'antd/es/table';
import {AuthState, NumLookupPricing, NumLookupPricingExport} from "models";
import TableList from 'components/shared/TableList';
import { Col, Row, Select } from 'antd';
import {useSelector} from "react-redux";
import {RootState} from "../../../App";

export interface NumLookupPricingProps {
  pricing: NumLookupPricing[];
  loading?: boolean;
  onSearch?: (searchText?: string) => void;
}

const NumLookupPricingList = (props: NumLookupPricingProps) => {
  const { pricing, loading, onSearch } = props;
  const [selecteCountry, setSelectedCountry] = useState<string>();
  const { localCurrency } = useSelector((state: RootState): AuthState => state.auth);

  const tableColumns: ColumnsType<NumLookupPricing> = [
    {
      title: 'Country',
      dataIndex: 'country',
      render: (_v, record) =>
        <Col xl={24} lg={24} md={24}>
          <Row gutter={10}>
            <Col xl={1} className='text-left'>
              <span className={`flag:${record.country?.toUpperCase()}`} style={{ height: 15, width: 20 }} />
            </Col>
            <Col xl={20} lg={20} md={20}>
              {record.country_name}
            </Col>
          </Row>
        </Col>
    },
    {
      // eslint-disable-next-line no-nested-ternary
      title: `Price ${(localCurrency?(localCurrency==="EURO"? "€": (localCurrency==="AED"?"د.إ":(localCurrency==="INR"?"₹":"$"))):"$")}`,
      dataIndex: 'price'
    }
  ];

  const API_CSV_HEADERS = ['country_name', 'price'];
  const exportMapper = (item: NumLookupPricingExport): NumLookupPricingExport => (
    {
      user_id: item.user_id,
      country: item.country,
      country_name:item.country_name,
      price: item.price,
    }
  );

  const onSearching = (value: string) => {
    setSelectedCountry(value);
    onSearch?.(value);
  };

  const controls = [
    {
      title: '',
      buttonNotNeeded: true,
      icon: <Select id='country_filter' key='country_filter' showSearch style={{ width: 300 }} filterOption={(input: any, option: any) =>
        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } placeholder='Country' value={selecteCountry} allowClear onChange={e =>  { onSearching(e); }}>
        {en.map(e => <Select.Option key={e.alpha2} value={e.alpha2.toUpperCase()} title={e.name}><span className={`flag:${e.alpha2.toUpperCase()} mr-2`} />{e.name}</Select.Option>)}
      </Select>
    },
    {
      title: 'Export',
      icon: <DownloadOutlined />,
      onClick: () => { },
      isExport: true,
      exportFileName: 'Number Lookup Pricing',
      exportMapper,
      headers: API_CSV_HEADERS
    }
  ];

  return (
    <div>
      <TableList<NumLookupPricing> items={pricing} columns={tableColumns} loading={loading}
                               rowKey={(record: NumLookupPricing) => record.id} controls={controls} hideRowSelection />
    </div>
  );
};

NumLookupPricingList.defaultProps = {
  loading: false,
  onSearch: undefined
};

export default NumLookupPricingList;
