import {
  NumberLookupPriceRequest, NumLookupApiReportExportRequest,
  NumLookupApiReportFilterRequest
} from "../../models/requests";
import {getNumLookupPrice, getNumLookupUserPriceList} from "../../api/numberLookup/NumLookupPricingApi";
import {
  getNumLookupApiFilterResult,
  getNumLookupApiReportExport,
  getNumLookupApiSearchResult
} from "../../api/numberLookup/NumLookupReportApi";

const numberLookupPriceService = async (data: NumberLookupPriceRequest) => getNumLookupPrice(data);
const getNumberLookupUserPriceListService = async ()=> getNumLookupUserPriceList();
const getNumLookupApiSearchResultService = async (key: string,data: NumLookupApiReportFilterRequest) => getNumLookupApiSearchResult(key,data);
const getNumLookupApiFilterResultService = async (data: NumLookupApiReportFilterRequest) => getNumLookupApiFilterResult(data);

const getNumLookupApiReportExportService = async (data: NumLookupApiReportExportRequest) => getNumLookupApiReportExport(data);

export {
  numberLookupPriceService,
  getNumberLookupUserPriceListService,
  getNumLookupApiFilterResultService,
  getNumLookupApiSearchResultService,
  getNumLookupApiReportExportService
};
