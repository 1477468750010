import { WhatsappApiReportFilterRequest,WhatsappApiReportExportRequest} from 'models/requests';
import { WhatsappApiReportListResponse, WhatsappApiReportExportResponse} from 'models/responses';
import WhatsappApi from ".";

const getWhatsappApiSearchResult = (key: string,data: WhatsappApiReportFilterRequest) => WhatsappApi.get<WhatsappApiReportListResponse,WhatsappApiReportFilterRequest>(`report/search/${key}`,data);
const getWhatsappApiFilterResult = (data: WhatsappApiReportFilterRequest ) => WhatsappApi.get<WhatsappApiReportListResponse, WhatsappApiReportFilterRequest>(`report/filter`, data);

const getWhatsappApiReportExport = (data: WhatsappApiReportExportRequest ) => WhatsappApi.get<WhatsappApiReportExportResponse, WhatsappApiReportExportRequest>(`report/export`,data);

export {
  getWhatsappApiSearchResult,
  getWhatsappApiFilterResult,
  getWhatsappApiReportExport
};
