/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector } from "react-redux";
import {
	CloseOutlined,
} from '@ant-design/icons';
import utils from 'utils';
import { RootState } from 'App';
import { Grid } from 'antd';
import SearchInput from './SearchInput';

export const NavSearch = (props: { active: boolean, close: () => void }) => {
	const { active, close } = props;
	const { headerNavColor } = useSelector((state: RootState) => state.theme);
	const mode = utils.getColorContrast(headerNavColor);
	const { useBreakpoint } = Grid;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
	return (
		<div className={`nav-search ${active ? 'nav-search-active' : ''} ${mode}`} style={{ backgroundColor: headerNavColor }}>
			<div className="d-flex align-items-center w-100">
				<SearchInput close={close} active={active} mode={mode} isMobile={isMobile} />
			</div>
			<div className="nav-close" onClick={close}>
				<CloseOutlined />
			</div>
		</div>
	);
};

export default NavSearch;
