/* eslint-disable no-use-before-define */
import { ExportOutlined } from "@ant-design/icons";
import {
  faArrowUpRightFromSquare,
  faCopy,
  faListUl,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message as antdMessage, Button, Dropdown, Menu, notification, Spin, Tooltip } from "antd";
import type { FormInstance } from "antd/es/form";
import { UploadFile } from "antd/es/upload/interface";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import EllipsisDropdown from "components/shared/EllipsisDropdown";
import IconSvg from "components/utils/IconSvg";
import { AblyInstanceContext } from "hooks/useAbly";
import useAblyChannel from "hooks/useAblyChannel";
import { capitalize } from "lodash";
import moment from "moment-timezone";
import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { Scrollbars } from "react-custom-scrollbars-2";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  getWhatsappChatExportService,
  InterveneChatService,
  listWhatsappChatMessagesService, resumeChatbotInChatService,
  uploadMediaFileService
} from "services/dashboard/WhatsappService";
import { sendMetaWhatsappMessageService } from "services/integration/IntegrationTestService";
import Utils from "utils";
import { showErrorNotification, timestampToTime } from "utils/helper";
import ChatInput from "./ChatInput";
import { Originator } from "./ChatMenu";
import WhatsappAttachment from "./WhatsappAttachment";
import WhatsappContactMessage from "./WhatsappContactsMessage";
import WhatsappLocationMessage from "./WhatsappLocationMessage";
import WhatsappTemplateMessage, { replaceTemplatePlaceholders } from "./WhatsappTemplateMessage";



export interface Message {
  id: string;
  from?: string;
  time: string;
  type: string;
  text?: string | null;
  info?: {
    body: string;
  };
  image?: {
    url: string;
  };
  attachment?: {
    type: string;
    url: string;
    id: string;
    caption: string;
    filename: string;
  };
  content?: any;
  status?: string;
  waMsgId?: string;
  reaction?: any;
}

export interface ChatItem {
  id: string;
  name?: string;
  avatar?: string;
  unread: number;
  phone: string;
  lastMessage: Message;
  expiration_timestamp?: number;
}

interface LocationState {
  chatInfo: ChatItem;
  originatorInfo: Originator;
  isChatbotConnected: boolean;
  chatIntervened: boolean;
}

interface Params {
  [key: string]: string | undefined;
}

const Conversation = (props: any) => {
  const { token } = useOutletContext<{ token?: string }>();

  const abortControllerRef = useRef<AbortController | null>(null);
  const formRef = useRef<FormInstance>(null);
  const chatBodyRef = useRef<Scrollbars>(null);
  const params = useParams<Params>();
  const location = useLocation();
  const navigate = useNavigate();

  const [chatInfo, setChatInfo] = useState<ChatItem | null>(null);
  const [originatorInfo, setOriginatorInfo] = useState<Originator>();
  const [chatbotConnected, setChatbotConnected] = useState(false);
  const [chatInterevened, setChatInterevened] = useState(false);
  const [msgList, setMsgList] = useState<Message[]>([]);
  const [showEmojis, setShowEmojis] = useState(false);
  const [loadIntervene, setLoadIntervene] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  // const [loading, setLoading] = useState(false);
  const [hasWarningMsgPushed, setHasWarningMsgPushed] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [isFileUploading, setIsFileUploading] = useState(false);


  const [loadingStates, setLoadingStates] = useState<{ [chatId: string]: boolean }>({});

  const setLoading = (chatId: string | undefined, isLoading: boolean) => {
    if (!chatId) return;

    setLoadingStates(prevState => {
      if (isLoading) {
        return { ...prevState, [chatId]: isLoading };
      }

      // Remove the chatId key from prevState
      const newState = Object.fromEntries(
        Object.entries(prevState).filter(([key]) => key !== chatId)
      );

      return newState;
    });
  };


  const isLoading = (chatId: string | undefined) => {
    if (!chatId) return false;
    return !!loadingStates[chatId];
  };

  const [customerWindowTimer, setCustomerWindowTimer] = useState(0);
  const [lastConversationWindowTimer, setLastConversationWindowTimer] = useState(0);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const { id } = params;
  const isMobile = Utils.getBreakPoint(useBreakpoint()).includes("xs");

  useEffect(() => {
    if (location.state) {
      const data = location.state as LocationState;
      setChatInfo(data.chatInfo as ChatItem);
      setOriginatorInfo(data.originatorInfo as Originator);
      setChatbotConnected(data.isChatbotConnected);
      setChatInterevened(data.chatIntervened);
    }
  }, [location]);

  useEffect(() => {
    setMsgList([]); // Clear the current messages
    setHasWarningMsgPushed(false);
    setLastConversationWindowTimer(0);
    setCustomerWindowTimer(0);
    setPage(1); // Reset page number when chat info changes
    setHasMore(false);
    getConversation(1);
  }, [chatInfo?.phone, originatorInfo?.number]);

  const inboxAblyClient = useContext(AblyInstanceContext);
  const incomingMessage = useAblyChannel(
    inboxAblyClient,
    `channel_${originatorInfo?.number}`,
    Boolean(originatorInfo?.number)
  );

  const showErrorMessage = (messageText: string) => {
    const notificationParam = {
      message: messageText,
      description: ''
    };
    notification.error(notificationParam);
  };

  const canUpdateStatus = (currentStatus: string, newStatus: string) => {
    const statusOrder = ["processed", "sent", "rejected", "delivered", "read"];
    const currentIndex = statusOrder.indexOf(currentStatus);
    const newIndex = statusOrder.indexOf(newStatus);
    return newIndex > currentIndex;
  };

  useEffect(() => {
    if (
      incomingMessage?.name === chatInfo?.phone?.substring(1) &&
      incomingMessage?.data
    ) {
      const msgParsed =
        typeof incomingMessage.data === "string"
          ? JSON.parse(incomingMessage.data)
          : incomingMessage.data;
      const whatsappMessages = msgParsed.whatsapp_messages?.[0]?.message;

      // if profile_name has some value, then set the chatInfo name
      if (
        whatsappMessages?.[0]?.content?.profile_name &&
        chatInfo?.name === null
      ) {
        setChatInfo((prevChatInfo: ChatItem | any) => ({
          ...prevChatInfo,
          name: whatsappMessages?.[0]?.content?.profile_name,
        }));
      }

      const newMessageDate = moment
        .utc(
          whatsappMessages?.[0]?.msg_timestamp
            ? whatsappMessages[0].msg_timestamp * 1000
            : whatsappMessages?.[0]?.created_at
        )
        .local()
        .format("YYYY-MM-DD");

      const lastMessageDate = moment
        .utc(msgList?.[msgList.length - 1]?.time)
        .local()
        .format("YYYY-MM-DD");

      let showDate;
      if (msgList.length === 0) showDate = true;
      else if (newMessageDate === lastMessageDate) showDate = false;
      else showDate = true;

      const normalizedIncomingMessages: Message[] = normalizeMessages(
        whatsappMessages,
        showDate
      );
      const msgListCopy = [...msgList];
      if (normalizedIncomingMessages?.length > 0) {
        normalizedIncomingMessages.forEach((item: any) => {
          const normalizedIncomingMessage = item;
          if (normalizedIncomingMessage?.type === "date") {
            msgListCopy.push(normalizedIncomingMessage);
          } else {
            if (normalizedIncomingMessage?.content?.template_details) {
              normalizedIncomingMessage.content.template_details =
                typeof normalizedIncomingMessage.content.template_details ===
                "string"
                  ? JSON.parse(
                      normalizedIncomingMessage.content.template_details
                    )
                  : normalizedIncomingMessage.content.template_details;
            }

            // delete normalizedIncomingMessage.content.template_details if it is empty or null or undefined
            if (
              normalizedIncomingMessage?.content?.template_details === null ||
              normalizedIncomingMessage?.content?.template_details ===
                undefined ||
              normalizedIncomingMessage?.content?.template_details === ""
            ) {
              delete normalizedIncomingMessage?.content?.template_details;
            }

            const messageIndex = msgList?.findIndex(
              (msg) => msg.id === normalizedIncomingMessage.id
            );
            if (messageIndex !== -1) {
              //  update existing message
              msgListCopy[messageIndex] = {
                ...msgListCopy[messageIndex],
                ...normalizedIncomingMessage,
                content: {
                  ...msgListCopy[messageIndex]?.content,
                  ...normalizedIncomingMessage?.content,
                  // status should be updated only in the order of processed, sent/rejected, delivered, read

                  status: canUpdateStatus(
                    msgListCopy[messageIndex]?.content?.status,
                    normalizedIncomingMessage?.content?.status
                  )
                    ? normalizedIncomingMessage?.content?.status
                    : msgListCopy[messageIndex]?.content?.status,
                },
              };

              const [element] = msgListCopy.splice(messageIndex, 1);
              msgListCopy.push(element);
              // scrollToBottom();
            } else {

              const sendMessageIndex = msgList?.findIndex(
                (msg) => msg.id === whatsappMessages?.[0].req_id
              );

              if (sendMessageIndex !== -1) {
                const [element] = msgListCopy.splice(sendMessageIndex, 1);
                if(element?.content?.isNewAttachment) {
                  normalizedIncomingMessage.content.isNewAttachment = element.content.isNewAttachment;
                }
                if(element?.content?.attachment_uploading !== undefined) {
                  normalizedIncomingMessage.content.attachment_uploading = element.content.attachment_uploading;
                }
              }

              msgListCopy.push(normalizedIncomingMessage);
            }
          }
        });
        setMsgList(msgListCopy);

        const currentTime = Date.now();

        // @ts-ignore
        const lastCustomerMessage: any = normalizedIncomingMessages?.findLast(
          (item: Message) =>
            item.content?.event_type === "USER_INITIATED" &&
          item.content?.msg_timestamp
        );

        if (lastCustomerMessage) {
          const messageTime = new Date(lastCustomerMessage.time).getTime();
          const timeDifferenceInSeconds = (currentTime - messageTime) / 1000;

          if (timeDifferenceInSeconds > 86400) {
            setCustomerWindowTimer(0);
          } else {
            setCustomerWindowTimer(Math.floor(86400 - timeDifferenceInSeconds));
          }
        }

        const incomingBusinessMessages: any = normalizedIncomingMessages?.filter(
          (item: Message) =>
            item.content?.event_type !== "USER_INITIATED" &&
            item.content?.expiration_timestamp
        );

        if (incomingBusinessMessages?.length > 0) {
          incomingBusinessMessages.forEach((item: any) => {
            const expirationTimestamp = item.content?.expiration_timestamp || 0;
            const timeDifferenceInSeconds = ((expirationTimestamp * 1000) - currentTime) / 1000;

            if (timeDifferenceInSeconds > lastConversationWindowTimer) {
              setLastConversationWindowTimer(Math.round(timeDifferenceInSeconds));
            }

          });
        }
      }
    }
  }, [incomingMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [msgList]);

  // write a useEffect to decrement the timer for the last conversation window every second
  useEffect(() => {
    let intervalId: any;
    if (lastConversationWindowTimer > 0) {
     intervalId = setInterval(() => {
      setLastConversationWindowTimer((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000); // Update every second
  }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [lastConversationWindowTimer]);


  useEffect(() => {

    if (
      lastConversationWindowTimer === 0 &&
      !hasWarningMsgPushed &&
      customerWindowTimer !== 0 &&
      msgList.length !== 0
    ) {

      setMsgList((prevArray) => [...prevArray, {
        id: "warning-msg",
        type: "info",
        info: {body: "Sending a new message will incur charges as your last conversation session has expired."},
        time: new Date().toISOString(),
      }]);
      setHasWarningMsgPushed(true);
    }
  }, [lastConversationWindowTimer, hasWarningMsgPushed, msgList, customerWindowTimer]);

  useEffect(() => {
    let intervalId: any;
    if (customerWindowTimer > 0) {
      intervalId = setInterval(() => {
        setCustomerWindowTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [customerWindowTimer]);

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    const formattedDate = `${dd}/${mm}/${yyyy}`;

    if (date.toDateString() === today.toDateString()) {
      return "TODAY";
    }
    if (date.toDateString() === yesterday.toDateString()) {
      return "YESTERDAY";
    }
    return formattedDate;
  };


  const normalizeMessages = (
    messages: any[],
    isDateRequired: boolean = true
  ) => {
    const normalizedChatMessages: any[] = [];
    let currentDate: any = null;

    const brandNumber = originatorInfo?.number?.startsWith("+")
      ? originatorInfo?.number?.substring(1)
      : originatorInfo?.number;

    messages?.forEach((item: any, index: number) => {
      const messageDate = moment
        .utc(
          item?.content?.msg_timestamp
            ? item.content.msg_timestamp * 1000
            : item?.created_at
        )
        .local()
        .format("YYYY-MM-DD");
      const messageTime = moment
        .utc(
          item?.content?.msg_timestamp
            ? item.content.msg_timestamp * 1000
            : item?.created_at
        )
        .local()
        .valueOf();

      if (messageDate !== currentDate && isDateRequired) {
        // Add date type object at the start of a new date
        normalizedChatMessages.push({
          id: `date-${messageDate}_index-${index}`,
          type: "date",
          time: messageTime,
        });
        currentDate = messageDate;
      }

      if (item.message_type === "REACTION") {
        // If it's a reaction, find the corresponding message and add/replace the reaction
        const relatedMessage = normalizedChatMessages.find(
          (msg) => msg.waMsgId === item.reaction.message_id
        );
        if (relatedMessage) {
          relatedMessage.reaction = item.reaction; // Replace the existing reaction
        }
      } else {
        // Add new message
        const newMessageObject = {
          id: item.msg_id,
          from:
            item.originator === `+${brandNumber}` ||
            item.originator === brandNumber
              ? "me"
              : "opposite",
          time: messageTime,
          type: item.message_type,
          text: item.message_text,
          status: item.status,
          content: item,
          waMsgId: item?.event_type === 'USER_INITIATED' ? item?.waConvId : item?.mid, // Store waConvId for reaction lookup
          reaction: {}, // Initialize reaction object
        };
        normalizedChatMessages.push(newMessageObject);
      }
    });

    return normalizedChatMessages;
  };

  const handleChatExport = async (
    brandNumber: string | undefined,
    customerNumber: string | undefined
  ) => {
    if (!brandNumber || !customerNumber) return;

    const normalizedBrandNumber = brandNumber?.startsWith("+")
      ? brandNumber?.substring(1)
      : brandNumber;
    const normalizedCustomerNumber = customerNumber?.startsWith("+")
      ? customerNumber?.substring(1)
      : customerNumber;
    setLoading(`export-${brandNumber}-${customerNumber}`, true);

    try {
      const response = await getWhatsappChatExportService(
        normalizedBrandNumber,
        normalizedCustomerNumber
      );
      if (response) {
        navigate("/importexport");
      }
    } catch (error: any) {
      showErrorNotification(error.message);
    }
    setLoading(`export-${normalizedBrandNumber}-${normalizedCustomerNumber}`, false);
  };

  const menu = () => (
    <Menu>
      <Menu.Item key="0" onClick={() => handleChatExport(chatInfo?.phone, originatorInfo?.number)}>
        <ExportOutlined />
        <span className="ml-2">Export Chat</span>
      </Menu.Item>
    </Menu>
  );


  const getConversation = async (pageNumber: number) => {
    setLoading(id,true);
    let fetchedChatMessages = [];
    try {
      if (originatorInfo?.number && chatInfo?.phone) {
        const brandNumber = originatorInfo?.number?.startsWith("+")
          ? originatorInfo?.number?.substring(1)
          : originatorInfo?.number;
        const customerNumber = chatInfo?.phone?.startsWith("+")
          ? chatInfo?.phone?.substring(1)
          : chatInfo?.phone;

        // Abort any ongoing request
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        // Create a new AbortController for the new request
        abortControllerRef.current = new AbortController();

        const result = await listWhatsappChatMessagesService(
          brandNumber,
          customerNumber,
          pageNumber,
          abortControllerRef?.current?.signal
        );
        fetchedChatMessages = result?.messages || [];

        const lastCustomerMessage: any = result?.last_user_initiated_message;
        const currentTime = Date.now();
        if (lastCustomerMessage) {
          const messageTime = new Date(lastCustomerMessage.created_at).getTime();
          const timeDifferenceInSeconds = (currentTime - messageTime) / 1000;

          if (timeDifferenceInSeconds > 86400) {
            setCustomerWindowTimer(0);
          } else {
            setCustomerWindowTimer(Math.floor(86400 - timeDifferenceInSeconds));
          }
        } else {
          setCustomerWindowTimer(0);
        }

        const lastConversationWindowExpirationTimestamp = result?.recent_conversation_window_expiration_timestamp;

        if (lastConversationWindowExpirationTimestamp > 0) {
          const timeDifferenceInSeconds =
            ((lastConversationWindowExpirationTimestamp*1000) - currentTime) / 1000;

          if (timeDifferenceInSeconds > 86400) {
            setLastConversationWindowTimer(0);
          } else {
            setLastConversationWindowTimer(Math.round(timeDifferenceInSeconds));
          }
        } else {
          setLastConversationWindowTimer(0);
        }

        if (fetchedChatMessages && fetchedChatMessages?.length > 0) {
          const normalizedChatMessages = normalizeMessages(fetchedChatMessages);
          if (pageNumber === 1) {
            setMsgList([...normalizedChatMessages]);
          } else {
            setMsgList((prevMsgList) => [
              ...normalizedChatMessages,
              ...prevMsgList,
            ]);
          }
          // scrollToBottom();
          setHasMore(true);
        } else {
          setHasMore(false); // No more messages to load
        }
      }
    } catch (error) {
      // console.error(error);
      setLoading(id, false);
    }
    setLoading(id, false);
  };

  const fetchMoreData = () => {
    getConversation(page + 1);
    setPage(page + 1);
  };

  const formatWhatsappText = (text: string, showLink: boolean = true): React.ReactNode => {
    if (!text) return null;
    const parts = text.split(
      /(\*[^*]+\*|_[^_]+_|~[^~]+~|```[^```]+```|`[^`]+`|\b(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)|\n)/g
    );

    return parts.map((part, index) => {
      const key = `${part}-${index}`;

      if (part.startsWith("*") && part.endsWith("*")) {
        return <strong key={key}>{part.slice(1, -1)}</strong>;
      }
      if (part.startsWith("_") && part.endsWith("_")) {
        return <em key={key}>{part.slice(1, -1)}</em>;
      }
      if (part.startsWith("~") && part.endsWith("~")) {
        return <del key={key}>{part.slice(1, -1)}</del>;
      }
      if (part.startsWith("```") && part.endsWith("```")) {
        return (
          <pre key={key}>
            <code>{part.slice(3, -3)}</code>
          </pre>
        );
      }
      if (part.startsWith("`") && part.endsWith("`")) {
        return <code key={key}>{part.slice(1, -1)}</code>;
      }
      if (
        part.match(
          /\b(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)/
        ) && showLink
      ) {
        const url = part.startsWith("http") ? part : `http://${part}`;
        return (
          <a
            href={url}
            key={key}
            target="_blank"
            rel="noopener noreferrer"
            style={{ wordBreak: "break-all" }}
          >
            {part}
          </a>
        );
      }
      if (part === "\n") {
        return <br key={key} />;
      }
      return <span key={key}>{part}</span>;
    });
  };

  const formatButtonMessageText = (text: string): string => {    
    if(text.indexOf('TEXT:=') === 0 && text.indexOf('CAMPAIGNID:=') > 0){
        return `↳${text.substring(text.indexOf('TEXT:=') + 6, text.indexOf('CAMPAIGNID:=') - 1)}` ;
    }
    return `↳${text}` ; 
  } ;

  const getMsgBasedOnType = (msg: Message) => {
    switch (msg.type) {
      case "TEXT":
        return msg?.text ? (
          <span>{formatWhatsappText(msg.text)}</span>
        ) : (
          <span style={{ fontStyle: "italic", color: "gray" }}>
            🚫 This message cannot be shown.
          </span>
        );
      case "INTERACTIVE":
        return (
          <div className="interactive-msg">
            <div className="interactive-msg__header">
              {msg.content?.interactive_header_type === "text" ? (
                <Title level={4} className="header-text">
                  {formatWhatsappText(
                    msg.content?.text_header_title ??
                      msg.content?.attachment_caption
                  )}
                </Title>
              ) : null}
            </div>
            <div className="interactive-msg__body">
              {msg.content?.message_text && (
                <Text>{formatWhatsappText(msg.content?.message_text)}</Text>
              )}
              {msg.content?.interactive_reply_button_action?.button_reply && (
                <Text>
                  ↳
                  {
                    msg.content?.interactive_reply_button_action?.button_reply
                      ?.title
                  }
                </Text>
              )}
            </div>
            <div className="interactive-msg__footer mt-1">
              <p className="mb-0">
                {formatWhatsappText(msg.content?.interactive_footer_text)}
              </p>
            </div>
          </div>
        );

      case "ATTACHMENT": {
        const attachmentId = msg?.content?.attachment_id;
        const attachmentUrl = msg?.content?.attachment_url;
        const attachmentType = msg?.content?.attachment_type;
        const attachmentCaption = msg?.content?.attachment_caption;
        const attachmentMimeType = msg?.content?.attachment_mime_type;
        const attachmentFileName = msg?.content?.attachment_filename;

        return (
          <WhatsappAttachment
            attachmentId={attachmentId}
            attachmentUrl={attachmentUrl}
            attachmentType={attachmentType}
            attachmentFileName={attachmentFileName}
            attachmentCaption={attachmentCaption}
            attachmentMimeType={attachmentMimeType}
            formatWhatsappText={formatWhatsappText}
            isUploading={msg?.content?.attachment_uploading}
            isNewAttachment={msg?.content?.isNewAttachment}
          />
        );
      }

      case "TEMPLATE":
        return (
          <WhatsappTemplateMessage
            msg={msg}
            formatWhatsappText={formatWhatsappText}
          />
        );

      case "BUTTON":
        return (
          <div className="button-msg">{formatButtonMessageText(msg.content?.message_text)}</div>
        );

      case "LOCATION":
        return (
          <WhatsappLocationMessage
            latitude={msg.content?.location_latitude}
            longitude={msg.content?.location_longitude}
            name={msg.content?.location_name}
            address={msg.content?.location_address}
          />
        );

      case "CONTACTS":
        return (
          <WhatsappContactMessage
            contact={msg.content?.contact}
          />
        );
      default:
        return <span>{`${msg.type} message.`}</span>;
    }
  };

  const getType = (type: string | undefined) => {
    if (!type) return "";
    if (type.includes("webp")) {
      return "sticker";
    }
    if (type.includes("jpeg") || type.includes("png")) {
      return "image";
    }
    if (type.startsWith("video")) {
      return "video";
    }
    if(type.startsWith("audio")) {
      return "audio";
    }

    if(type.startsWith("application") || type.startsWith("text")) {
      return "document";
    }
    return "file";
  };

  const submitNewMessage = async () => {
    const onSuccess = (message: string) => {
      // const notificationParam = {
      //   message,
      //   description: "",
      // };
      // notification.success(notificationParam);
      setNewMessage("");
      setFileList([]);
    };

    const values = formRef.current?.getFieldsValue();

    formRef.current?.setFieldsValue({
      newMsg: "",
    });

    let mediaId;
    let newMsgData: Message | null = null;
    if (fileList?.length > 0) {
      newMsgData = {
        id: `msg-${new Date().getTime()}`,
        from: "me",
        type: "ATTACHMENT",
        text: null,
        time: `${moment.utc(new Date()).local()}`,
        status: "media_uploading",
        content: {
          attachment_url: mediaId,
          attachment_type: getType(fileList[0].type),
          attachment_caption: values.newMsg || null,
          attachment_mime_type: fileList[0].type,
          attachment_data: fileList[0]?.originFileObj,
          ...(getType(fileList[0].type) === "document" && {attachment_filename: fileList[0].name}),
          attachment_uploading: true,
          isNewAttachment: true,
        },
      };



      setMsgList([...msgList, newMsgData]);
      // scrollToBottom();
      onSuccess("Message Send Successfully");
      mediaId = await uploadFile(fileList[0]?.originFileObj);
    }

    if (values && token) {

      if(fileList?.length > 0 && (mediaId === undefined || mediaId === null)) {
        setMsgList([...msgList]);
        return;
      }

      try {
        const WhatsappTextCondtent = {
          message_type: "TEXT",
          text: {
            preview_url: true,
            body: values.newMsg || "",
          },
        };

        const WhatsappAttachmentContent = {
          message_type: "ATTACHMENT",
          attachment: {
            type: getType(fileList[0]?.type),
            caption: values.newMsg || null,
            id: mediaId,
            ...(getType(fileList[0]?.type) === "document" && {filename: fileList[0]?.name}),
            mime_type: fileList[0]?.type,
          },
        };

        const WhatsappRecipients = [
          {
            recipient: chatInfo?.phone,
            recipient_type: "individual",
          },
        ];

        const response = await sendMetaWhatsappMessageService(
          {
            messages: [
              {
                channel: "whatsapp",
                originator: originatorInfo?.number,
                originator_name: originatorInfo?.name,
                content:
                  fileList?.length > 0
                    ? WhatsappAttachmentContent
                    : WhatsappTextCondtent,
                recipients: WhatsappRecipients,
                feature_ref: "inbox",
              },
            ],
          },
          token
        );
        if (response) {
          if (fileList?.length > 0 && newMsgData) {
            newMsgData = {
              ...newMsgData,
              id: response.request_id?.replaceAll("-", ""),
              time: `${moment.utc(response.created_at).local()}`,
              status: "processed",
              content: {
                ...newMsgData?.content,
                attachment_uploading: false,
              },
            };
          } else {
            newMsgData = {
              id: response.request_id?.replaceAll("-", ""),
              from: "me",
              type: "TEXT",
              text: values.newMsg || "",
              time: `${moment.utc(response.created_at).local()}`,
              status: "processed",
            };
          }

          setMsgList([...msgList, newMsgData as Message]);

          // scrollToBottom();
          onSuccess("Message Send Successfully");
        }
      } catch (error: any) {
        // console.log(error);
        notification.error({
          message: 'Message Send Failed',
          description: "Please try again.",
        });
        setMsgList([...msgList]);
      }
    }
  };

  const scrollToBottom = () => {
    chatBodyRef.current?.scrollToBottom();
  };


  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        // show success toast
      notification.success({
        message: 'Copied',
        description: "Code successfully copied to clipboard",
      });
      })
      .catch((err) => {
        notification.error({
          message: 'Copy Failed',
          description: "Failed to copy code to clipboard",
        });
      });
  };

  const uploadFile = async (file: any) => {
    setIsFileUploading(true);

    let mediaId = null;
    const fileType = file.type;
    const fileSize = file.size / 1024/ 1024;
    const doc = [ "application/pdf", "text/plain", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.ms-excel"];
    const image = ["image/jpeg", "image/png"];
    const video = ["video/3gp", "video/mp4"];
    if ((doc.includes(fileType) && fileSize > 100) || (image.includes(fileType) && fileSize > 5) || (video.includes(fileType) && fileSize > 16)) {
      showErrorMessage("File Too Large: The file you uploaded is too large.");
      setIsFileUploading(false);
    }
    else {

      await uploadMediaFileService(originatorInfo?.number || "", file)
        .then((response) => {
          if (response.id) {
            mediaId = response.id;
          } else {
            antdMessage.error(`${file} file type is not supported.`);
          }
        })
        .catch((error) => {
          showErrorMessage(error.message);
        })
        .finally(() => setIsFileUploading(false));
    }
    return mediaId;
  };

  const chatContentHeader = (
    name: string | undefined,
    phone: string,
    avatar?: string
  ) => {
    let badgeLabel;
    const badgeTooltip =
      "Whatsapp allows you to send freeform messages(i.e. any non-template message) only for 24 hours after the customer's last message.";
    if (!(customerWindowTimer <= 0)) {
      badgeLabel = isMobile
        ? `${Math.floor(customerWindowTimer / 3600)}hrs ${Math.floor(
            (customerWindowTimer % 3600) / 60
          )}mins left`
        : `Freeform messaging expires in ${Math.floor(
            customerWindowTimer / 3600
          )}hrs ${Math.floor((customerWindowTimer % 3600) / 60)}mins.`;
    } else {
      badgeLabel = isMobile ? "Expired" : "Freeform messaging expired";
    }

    return (
      <div className="chat-content-header">
        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <Avatar
            name={name ?? phone}
            src={avatar}
            size="40"
            textSizeRatio={1.75}
            round
          />
          <div className="chat-content-header-container">
            <span className="chat-content-title-container">
              <h4 className="mb-0" style={{ whiteSpace: "nowrap" }}>
                {name ?? phone}
              </h4>
              {(!isLoading(id) || page !== 1) && (
                <Tooltip title={badgeTooltip}>
                  <span
                    className={`timer-badge timer-badge-${
                      customerWindowTimer > 0 ? "active" : `inactive`
                    }`}
                  >
                    <span>{badgeLabel}</span>
                  </span>
                </Tooltip>
              )}
            </span>
            {name && phone && (
              <p className="mb-0 chat-content-subtitle">{phone}</p>
            )}
          </div>
        </div>

        <div>
        <EllipsisDropdown menu={menu()} />
      </div>
      </div>
    );
  };


  const chatContentBody = (
    messages: Message[],
    chatId?: string | undefined
  ) => (
    <div
      className={`chat-content-body ${fileList.length > 0 ? "chat-content-body-with-file-list" : ""}`}
      id="scrollableConvDiv"
      style={{
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      {/* <Scrollbars ref={chatBodyRef} autoHide> */}
      <InfiniteScroll
        dataLength={messages.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Spin size="small" spinning={page !== 1} />
          </div>
        }
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          overflow: "hidden",
        }}
        inverse // To make it scroll in reverse direction (top to bottom)
        scrollableTarget="scrollableConvDiv"
      >
        <div
          style={{
            padding: "15px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {messages?.map((msg, i) => {
            let fromClass = "";
            if (msg.from === "opposite") fromClass = "msg-recipient";
            else if (msg.from === "me") fromClass = "msg-sent";
            const templateAttachmentHeaderClass = msg.content?.message_type === "TEMPLATE" && msg.content?.template_details?.header_format ? `msg-template-attachment-header` : "";

            let iconId;
            if (msg?.status === "read" || msg?.status === "delivered") {
              iconId = "doubleTick";
            } else if (msg?.status === "sent") {
              iconId = "singleTick";
            } else if (msg?.status === "rejected") {
              iconId = "rejected";
            } else if (msg?.status === "processed" || msg?.status === "media_uploading") {
              iconId = "processed";
            } else {
              iconId = "unknown";
            }

            const interactiveListItems = (
              <Menu
                style={{
                  maxHeight: "500px",
                  overflowY: "auto",
                  paddingRight: "4px",
                }}
              >
                {msg.content?.interactive_list_action?.sections?.map(
                  (item: any) => (
                    <div
                      style={{
                        borderBottom: "1px solid #e8e8e8",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                      key={item.title}
                    >
                      <Menu.ItemGroup title={item.title}>
                        {item.rows.map((subitem: any) => (
                          <Menu.Item key={subitem.title}>
                            <span>
                              <Title
                                level={4}
                                className="interactive-list-item-title"
                                style={{ marginBottom: "2px" }}
                              >
                                {subitem.title}
                              </Title>

                              <Text
                                className="interactive-list-item-description"
                                style={{ color: "#72849a", fontSize: "14px" }}
                              >
                                {subitem.description}
                              </Text>
                            </span>
                          </Menu.Item>
                        ))}
                      </Menu.ItemGroup>
                    </div>
                  )
                )}
              </Menu>
            );

            let msgBubbleMaxWidth = isMobile ? "80%" : "65%";
            if(msg.type === "ATTACHMENT") {
              if(msg.content?.attachment_type === "image" || msg.content?.attachment_type === "video") {
                msgBubbleMaxWidth = "250px";
              }else{
                msgBubbleMaxWidth = "unset";
              }
            }
            else if(msg.type === "LOCATION") {
              msgBubbleMaxWidth = "250px";
            }
            else if(msg.type === "CONTACTS") {
              msgBubbleMaxWidth = "300px";
            }
            else if(msg.type === "TEMPLATE") {
              if(msg.content?.template_details?.header_format !== null){
                if(msg.content?.template_details?.header_format !== "TEXT")
                  if(msg.content?.template_details?.header_format === "IMAGE" || msg.content?.template_details?.header_format === "VIDEO")
                    msgBubbleMaxWidth = "250px";
                  else if(msg.content?.template_details?.header_format === "LOCATION")
                    msgBubbleMaxWidth = "250px";
                  else
                    msgBubbleMaxWidth = "unset";
              }
              else if(msg.content?.template_details?.buttons?.button_Otp !== null || msg.content?.template_details?.buttons?.button_copy_code !== null ||
                msg.content?.template_details?.buttons?.button_quick_reply !== null || msg.content?.template_details?.buttons?.button_phone_number !== null ||
                msg.content?.template_details?.buttons?.button_url !== null
              ) {
                msgBubbleMaxWidth = isMobile ? "80%" : "250px";
              }
            }
            else if(msg.type === "INTERACTIVE") {
              if(msg.content?.interactive_type === "button") {
                if(msg.content?.interactive_reply_button_action?.buttons?.length > 2) {
                  msgBubbleMaxWidth = isMobile ? "80%" : "250px";
                }
              }
            }

            return (
              <div
                // @ts-ignore
                key={`msg-${chatId}-${msg.waMsgId || msg.id}`}
                className={`msg ${
                  msg.type === "date" || msg.type === "info"
                    ? "chat__info-container"
                    : `msg-${msg.type}`
                } ${fromClass} ${templateAttachmentHeaderClass}`}
              >
                {msg.type !== "date" && msg.type !== "info" ? (
                  <div
                    style={{
                      maxWidth: msgBubbleMaxWidth,
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      position: "relative",
                    }}
                  >
                    <div className="bubble">
                      <div className="bubble-wrapper">
                        {getMsgBasedOnType(msg)}
                      </div>
                      <span
                        className="chat__msg-filler"
                        style={{
                          width:
                            msg.content?.feature_ref === "chatbot" ||
                            msg.content?.feature_ref === "campaign"
                              ? "92px"
                              : "75px",
                        }}
                      >
                        {" "}
                      </span>
                      <span className="chat__msg-footer">
                        {msg.content?.feature_ref === "chatbot" && (
                          <Tooltip title="Chatbot response">
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <IconSvg
                                id="bot"
                                aria-label={msg?.status}
                                className="chat__msg-status-icon--feature-ref"
                              />
                            </span>
                          </Tooltip>
                        )}
                        {msg.content?.feature_ref === "campaign" && (
                          <Tooltip title="Campaign message">
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <IconSvg
                                id="megaphone"
                                aria-label={msg?.status}
                                className="chat__msg-status-icon--feature-ref"
                              />
                            </span>
                          </Tooltip>
                        )}

                        <span> {timestampToTime(msg.time)} </span>
                        {msg.from === "me" && (
                            <Tooltip title={msg.content?.gw_status ? capitalize(msg.content?.gw_status) : null} popupVisible={Boolean(msg.content?.gw_status)}>
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                          <IconSvg
                            id={iconId}
                            aria-label={msg?.status}
                            className={`chat__msg-status-icon ${
                              msg?.status === "read"
                                ? "chat__msg-status-icon--blue"
                                : ""
                            }`}
                          />
                          </span>
                          </Tooltip>
                        )}
                      </span>
                    </div>
                    {msg.type === "INTERACTIVE" && (
                      <div className="interactive-reply-container">
                        {msg.content?.interactive_type === "button"
                          ? msg.content?.interactive_reply_button_action?.buttons?.map(
                              (item: any) =>
                                item?.type === "reply" ? (
                                  <div
                                    className={`bubble interactive-reply-btn ${msg.content?.interactive_reply_button_action?.buttons?.length > 2 ? "interactive-reply-btn--full" : ""}`}
                                    key={item?.reply?.title}
                                  >
                                    <Button type="link">
                                      <strong>{item?.reply?.title}</strong>
                                    </Button>
                                  </div>
                                ) : null
                            )
                          : null}
                        {msg.content?.interactive_type === "cta_url"
                          ? msg.content?.interactive_cta_url_action
                              ?.parameters && (
                              <div
                                className="bubble interactive-reply-btn"
                                key={
                                  msg.content?.interactive_cta_url_action
                                    ?.parameters?.display_text
                                }
                              >
                                <Button
                                  type="link"
                                  href={
                                    msg.content?.interactive_cta_url_action
                                      ?.parameters?.url
                                  }
                                  target="_blank"
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faArrowUpRightFromSquare}
                                      style={{ marginRight: "8px" }}
                                    />
                                  }
                                >
                                  <strong>{
                                    msg.content?.interactive_cta_url_action
                                      ?.parameters?.display_text
                                  }</strong>
                                </Button>
                              </div>
                            )
                          : null}
                        {msg.content?.interactive_type === "list"
                          ? msg.content?.interactive_list_action?.button && (
                              <div
                                className="bubble interactive-reply-btn"
                                key={
                                  msg.content?.interactive_list_action?.button
                                }
                              >
                                <Dropdown
                                  overlay={interactiveListItems}
                                  trigger={["click"]}
                                >
                                  <Button
                                    type="link"
                                    icon={
                                      <FontAwesomeIcon
                                        icon={faListUl}
                                        style={{ marginRight: "8px" }}
                                      />
                                    }
                                  >
                                    <strong>{
                                      msg.content?.interactive_list_action
                                        ?.button
                                    }</strong>
                                  </Button>
                                </Dropdown>
                              </div>
                            )
                          : null}
                        {/* {msg.content?.interactive_type === "button"
                          ? msg.content?.interactive_reply_button_action?.buttons?.slice(0, 1)?.map(
                              (item: any) =>
                                item?.type === "reply" ? (
                                  <div
                                  className="bubble interactive-reply-btn"
                                  key={item?.reply?.title}
                                >
                                   <Dropdown overlay={interactiveListItems} trigger={["click"]}>
                                 <Button type="link" icon={<FontAwesomeIcon icon={faListUl} style={{marginRight:"8px"}} />}>
                                 {item?.reply?.title}
                                    </Button>
                                    </Dropdown>
                                </div>
                                ) : null
                            )
                          : null} */}
                      </div>
                    )}
                    {msg.type === "TEMPLATE" && (
                      <div className="interactive-reply-container">
                        {/* quick reply */}
                        {msg.content?.template_details?.buttons?.button_quick_reply?.map(
                          (item: any) => (
                            <div className="bubble interactive-reply-btn interactive-reply-btn--full" key={item?.button_text_quick_reply}>
                              <Button type="link">
                                <strong><span style={{display:"inline-block", transform: "scaleY(-1)"}}>&#x21B5;</span> {item?.button_text_quick_reply}</strong>
                              </Button>
                            </div>
                          )
                        )}
                        {/* coupon code */}
                        {msg.content?.coupon_code?.coupon_code?.map(
                          (item: any) => (
                            <div className="bubble interactive-reply-btn" key={item?.coupon_code}>
                              <Button onClick={()=>handleCopy(item?.coupon_code)} type="link" icon={<FontAwesomeIcon icon={faCopy} style={{ marginRight: "8px" }} />}>
                                <strong>Copy offer code</strong>
                              </Button>
                            </div>
                          )
                        )}
                        {/* phone number */}
                        {msg.content?.template_details?.buttons?.button_phone_number?.map(
                          (item: any) => (
                            <div className="bubble interactive-reply-btn interactive-reply-btn--full" key={item?.button_text_phone_number}>
                              <Button type="link" target="_blank" href={`tel:${item?.button_phone_number}`} icon={<FontAwesomeIcon icon={faPhone} style={{ marginRight: "8px" }} />}>
                                <strong>{item?.button_text_phone_number}</strong>
                              </Button>
                            </div>
                          ))}
                        {/* button url */}
                        {msg.content?.template_details?.buttons?.button_url?.map(
                          (item: any) => {
                            const actionUrl = replaceTemplatePlaceholders(item?.button_url, msg.content?.actions?.actions?.filter((action: any) => action?.type === "url")?.map((action: any) => (action?.payload)));
                            return(
                            <div className="bubble interactive-reply-btn interactive-reply-btn--full" key={item?.button_text_url}>
                              <Button type="link" target="_blank" href={actionUrl || undefined} icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: "8px" }} />}>
                                <strong>{item?.button_text_url}</strong>
                              </Button>
                            </div>
                          );}
                        )}
                      </div>
                    )}
                    {msg.reaction && Object.keys(msg.reaction).length > 0 && (
        <div className="reaction-wrapper" style={{marginLeft: msg.from === "me" ? "0" : "8px", marginRight: msg.from === "me" ? "8px" : "0", alignSelf: msg.from === "me" ? "flex-end" : "flex-start"}}>
          <span className="reaction-emoji">{msg.reaction.emoji}</span>
        </div>
      )}
                  </div>
                ) : null}

                {msg.type === "date" ? (
                  <div className="chat__date-wrapper">
                    <span className="chat__date">
                      {" "}
                      {formatDate(new Date(msg.time).getTime())}
                    </span>
                  </div>
                ) : null}
                {msg.type === "info" && !!msg.info!.body ? (
                  <p className="chat__info-msg">
                    {/* <IconSvg id="info" className="chat__info-icon" /> */}
                    {msg.info!.body}
                  </p>
                ) : null}
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
      {/* </Scrollbars> */}
    </div>
  );


  const chatContentFooter = () => (
    <footer className="chat-content-footer">
      <ChatInput
        showEmojis={showEmojis}
        setShowEmojis={setShowEmojis}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        submitNewMessage={submitNewMessage}
        formRef={formRef}
        isSessionExpired={customerWindowTimer <= 0}
        fileList={fileList}
        setFileList={setFileList}
        isFileUploading={isFileUploading}
      />
    </footer>
  );

  const onClickIntervene = async () => {
    setLoadIntervene(true);
    try {
      if (originatorInfo?.number && chatInfo?.phone) {
        const data = {
          customer_number: chatInfo?.phone,
          registered_number: originatorInfo?.number
        };
        const result = await InterveneChatService(data);
        if (result) {
          setLoadIntervene(false);
          setChatInterevened(true);
        } else {
          setLoadIntervene(false);
          showErrorMessage("Failed to Intervene Chat");
        }
      } else {
        setLoadIntervene(false);
        showErrorMessage("Failed to Intervene Chat");
      }
    }catch (error: any) {
      setLoadIntervene(false);
      showErrorMessage("Failed to Intervene Chat");
    }

  };

  const onClickResumeChatbot = async () => {
    setLoadIntervene(true);
    try{
    if (originatorInfo?.number && chatInfo?.phone){
      const result = await resumeChatbotInChatService(originatorInfo?.number, chatInfo?.phone);
      if (result){
        setLoadIntervene(false);
        setChatInterevened(false);
      }
      else{
        setLoadIntervene(false);
        showErrorMessage("Failed to Resume Chatbot");
      }
    }
    else{
      setLoadIntervene(false);
      showErrorMessage("Failed to Resume Chatbot");
    }
    }catch (error: any) {
      setLoadIntervene(false);
      showErrorMessage("Failed to Resume Chatbot");
    }
  };

  const showEndButton = () => (
    <footer className="chat-content-footer">
      <div style={{paddingTop: "10px"}}>
        <Button className="intervene__button" shape="round" type="primary" loading={loadIntervene}
                onClick={onClickResumeChatbot}>Resume Chatbot</Button>
      </div>
        <ChatInput
          showEmojis={showEmojis}
          setShowEmojis={setShowEmojis}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          submitNewMessage={submitNewMessage}
          formRef={formRef}
          isSessionExpired={customerWindowTimer <= 0}
          fileList={fileList}
          setFileList={setFileList}
          isFileUploading={isFileUploading}
        />
    </footer>
);

const showInterveneButton = () => (
    <footer className="chat-content-footer">
      <div style={{paddingTop: "10px", paddingBottom: "10px"}}>
      <Button className="intervene__button" shape="round" type="primary" loading={loadIntervene} onClick={onClickIntervene}>Intervene</Button>
      </div>
    </footer>
      );

      return (
      <Spin spinning={page === 1 && isLoading(id)} size="large" tip="Please wait...">
        <div className="chat-content">
          <div className="chat-bg"/>
          {chatInfo &&
            chatContentHeader(chatInfo?.name, chatInfo.phone, chatInfo?.avatar)}
          {chatContentBody(msgList, id)}
          {/* eslint-disable-next-line no-nested-ternary */}
          {chatbotConnected ? (chatInterevened ? showEndButton() : showInterveneButton()) : chatContentFooter()}
        </div>
      </Spin>
      );
      };

      Conversation.defaultProps = {
      token: undefined,
    };

export default Conversation;
