/* eslint-disable func-names */
/* eslint-disable func-style */
import { AuthListResponse, LoginResponse, UserResponse, UserWalletResponse } from 'models/responses';
import { all, takeEvery, fork, call, put } from 'redux-saga/effects';
import {authenticated, showAuthMessage, signOutSuccess} from 'redux/actions';
import { SignInRequestAction, UserACLFetchRequestAction, UserACLFetchSuccessAction, UserFetchRequestAction } from 'redux/types';
import { loginService } from 'services/auth/LoginService';
import { getUserService, getAccessListService } from 'services/auth/UserService';
import { getUserWalletService } from 'services/dashboard/PaymentService';
import { fetchACLSuccess, fetchUser, fetchUserACLs, fetchUserSuccess, navigateToHome, setUserTestCredit, setUserWallet, setLocalCurrency } from '../actions/Auth';
import {
  AUTH_TOKEN,
  FETCH_ACL_SUCCESS,
  FETCH_USER,
  FETCH_USER_WALLET,
  REFRESH_TOKEN,
  SIGNIN,
  SIGNOUT,
  USER_ACL_LIST
} from '../constants/Auth';


export function* signIn(action: SignInRequestAction) {
  try {
    const response: LoginResponse = yield call(loginService, action.payload);
    localStorage.setItem(AUTH_TOKEN, response.access_token);
    localStorage.setItem(REFRESH_TOKEN, response.refresh_token);

    yield put(authenticated(response.access_token));
    yield put(fetchUser());
    yield put(fetchUserACLs(true));
  } catch (error: any) {
    let message = 'Internal Server Error';
    if (error) {
      message = error.message ?? message;
    }
    yield put(showAuthMessage(message));
  }
};
export function* getUser(action: UserFetchRequestAction) {
  try {
    const response: UserResponse = yield call(getUserService);
    yield put(fetchUserSuccess(response));
  } catch (error: any) {
    let message = 'Internal Server Error';
    if (error) {
      message = error.message ?? message;
    }
    yield put(showAuthMessage(message));
  }
};

export function* getUserACL(action: UserACLFetchRequestAction) {
  try {
    const response: AuthListResponse = yield call(getAccessListService);
    yield put(fetchACLSuccess(response, action.redirect));
  } catch (error: any) {
    let message = 'Internal Server Error';
    if (error) {
      message = error.message ?? message;
    }
    yield put(showAuthMessage(message));
  }
};


export function* onSuccessUserACL(action: UserACLFetchSuccessAction) {
  if (action.redirect) {
    yield put(navigateToHome());
  }
};

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, signIn);
}
export function* fetchUserDetails() {
  yield takeEvery(FETCH_USER, getUser);
}
export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      yield put(signOutSuccess());
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
};

export function* fetchUserACLDetails() {
  yield takeEvery(USER_ACL_LIST, getUserACL);
};


export function* onSuccessUserACLDetails() {
  yield takeEvery(FETCH_ACL_SUCCESS, onSuccessUserACL);
};


export function* getUserWallet() {
  try {
    const response: UserWalletResponse = yield call(getUserWalletService);
    yield put(setUserWallet(response.credit));
    yield put(setUserTestCredit(response.test_credit));
    yield put(setLocalCurrency(response.local_currency));
  } catch (error: any) {
    let message = 'Internal Server Error';
    if (error) {
      message = error.message ?? message;
    }
    yield put(showAuthMessage(message));
  }
};

export function* fetchUserWallet() {
  yield takeEvery(FETCH_USER_WALLET, getUserWallet);
}

export default function* rootSaga() {
  yield all([
    fork(signInWithEmail),
    fork(signOut),
    fork(fetchUserDetails),
    fork(fetchUserACLDetails),
    fork(onSuccessUserACLDetails),
    fork(fetchUserWallet)
  ]);
}



