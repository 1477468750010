import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import navigationConfig from "configs/NavigationConfig";
import IntlMessage from 'components/utils/IntlMessage';


const breadcrumbData: { [k: string]: any } = {
	'/': <IntlMessage id="home" />
};

navigationConfig.forEach((elm) => {
	// const assignBreadcrumb = (obj) => breadcrumbData[obj.path] = <IntlMessage id={obj.title} />;
	const assignBreadcrumb = (obj: { path: string, title: string }) => {
		breadcrumbData[obj.path] = <IntlMessage id={obj.title} />;
	};

	assignBreadcrumb(elm);
	if (elm.submenu) {
		elm.submenu.forEach(elm1 => {
			assignBreadcrumb(elm1);
			if (elm1.submenu) {
				elm1.submenu.forEach(elm2 => {
					assignBreadcrumb(elm2);
				});
			}
		});
	}
});

const BreadcrumbRoute = () => {
	const location = useLocation();
	const pathSnippets = location.pathname.split('/').filter(i => i);
	const buildBreadcrumb = pathSnippets.map((_, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
		return (
			<Breadcrumb.Item key={url}>
				<Link to={url}>{breadcrumbData[url]}</Link>
			</Breadcrumb.Item>
		);
	});

	return (
		<Breadcrumb>
			{buildBreadcrumb}
		</Breadcrumb>
	);
};
const AppBreadcrumb = () => <BreadcrumbRoute />;

export default AppBreadcrumb;
