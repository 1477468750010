import {
  HubspotCrmCallBackRequest
} from 'models/requests';

import {
  HubspotCrmCallBackResponse
} from 'models/responses';

import DashboardApi from ".";

const listInstalledIntegrations = async () => DashboardApi.get('integrations/installed_integrations');
const listHubspotIntegrations = async () => DashboardApi.get('integrations/hubspot');
const listIntegrationsPlatform = async () => DashboardApi.get('integrations');
const listHeaderFieldsIntegrations = async (integrationId: any, moduleType: any) => DashboardApi.get(`integrations/contacts_fields/${integrationId}/${moduleType}`);
const listZohoIntegrations = async () => DashboardApi.get('integrations/zoho');
const getHubspotCrmCallback = async (data: HubspotCrmCallBackRequest) => DashboardApi.post<HubspotCrmCallBackResponse, HubspotCrmCallBackRequest>('integrations/hubspot-callback', data);
const getZohoCrmCallback = async (data: HubspotCrmCallBackRequest) => DashboardApi.post<HubspotCrmCallBackResponse, HubspotCrmCallBackRequest>('integrations/zoho-callback', data);
const createCrmContact = async (data: any) => DashboardApi.post('integrations/import-contact-crm', data);

const deleteIntegration = async (data: any) => DashboardApi.delete(`integrations/${data.platform_integration_id}`);
export {
  listInstalledIntegrations,
  listHubspotIntegrations,
  listIntegrationsPlatform,
  listZohoIntegrations,
  getHubspotCrmCallback,
  getZohoCrmCallback,
  createCrmContact,
  deleteIntegration,
  listHeaderFieldsIntegrations
};
