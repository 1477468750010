import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'antd';
import PageHeader from 'components/layout/PageHeader';
import { WhatsappSettings } from "models";
import WhatsappSettingsPage from 'components/whatsapp/WhatsappSettings/WhatsappSettingsUpdateForm';
import {getWhatsappSettingsService, listWhatsappContactService} from "services/dashboard/WhatsappService";
import WhatsappContactNumberList from "components/whatsapp/WhatsappSettings/WhatsappNumberList";


const Settings = () => {
  const [whatsappSettings, setWhatsappSettings] = useState<WhatsappSettings>({
    call_back_url: "", webhook_secret: ""
  });
  const [loading, setLoading] = useState(false);
  const [Contacts, setContacts] = useState([]);


  const fetchWhatsappSettings = async () => {
    setLoading(true);
    try {
      const response = await getWhatsappSettingsService();
      if (response !== undefined) {
        setWhatsappSettings(response);
      }
    } catch (error) {
      // setSMSSettings(undefined);
    }
    setLoading(false);
  };

  const fetchPhoneNumber = async () => {
    try {
      const fetchedPhoneNumbers: any = await listWhatsappContactService();
      if (fetchedPhoneNumbers && fetchedPhoneNumbers.length > 0) {
        setContacts(fetchedPhoneNumbers);
      }
    } catch (error) {
      // console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    Promise.all([fetchWhatsappSettings(), fetchPhoneNumber()]);
  }, []);

  const resetWhatsappSettings = (settings: WhatsappSettings) => {
    setWhatsappSettings(settings);
  };

  return (
    <><Row gutter={24}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
        <PageHeader display title="page.whatsapp.settings" showBreadCrumb={false}/>
        <Card className="mt-4" loading={loading}>
          <WhatsappSettingsPage whatsappSettings={whatsappSettings} reset={resetWhatsappSettings} loading={loading}
                                setLoading={setLoading}/>
        </Card>
      </Col>
    </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
          <h3>Registered Whatsapp Numbers</h3>
          <Card className="mt-4" loading={loading}>
            <WhatsappContactNumberList whatsappcontact={Contacts}/>
          </Card>
        </Col>
      </Row></>

  );
};

export default Settings;
