import DashboardApi from "api/dashboard";
import {
  addMultipleContactsToGroup,
  removeContactsFromBlackList,
  blackListSingleContact,
  createBulkContact,
  createSingleContact,
  importContact,
  listContact,
  removeMultiBlackListContact,
  deleteMultipleContacts,
  removeContactFromBlackList,
  deleteContact,
  searchContacts,
  uploadFile,
  getFileHeaders,
  updateContact, listAllContact, deleteAllContacts
} from "api/dashboard/ContactApi";
import { API_BASE_URL } from "configs/AppConfig";
import {
  ContactBlackListRequest,
  ContactBulkCreateRequest,
  ContactCreateRequest,
  ContactDeleteRequest,
  ContactImportRequest,
  ContactListGetRequest,
  ContactRemoveFromBlackListRequest,
  ContactRemoveMultiBlackListRequest,
  ContactsAddToGroupRequest,
  ContactsMultiDeleteRequest,
  ContactsRemoveFromBlackListRequest,
  ContactsSearchRequest,
  ContactUpdateRequest,
  FileHeadersGetRequest
} from 'models/requests';

import { AUTH_TOKEN } from "redux/constants/Auth";

const createSingleContactService = async (data: ContactCreateRequest) => createSingleContact(data);
const listAllContactService = async () => listAllContact();
const updateContactService = async (data: ContactUpdateRequest) => updateContact(data);

const deleteContactService = async (data: ContactDeleteRequest) => deleteContact(data);
const blackListSingleContactService = async (data: ContactBlackListRequest) => blackListSingleContact(data);
const removeContactFromBlackListService = async (data: ContactRemoveFromBlackListRequest) => removeContactFromBlackList(data);
const removeContactsFromBlackListService = async (data: ContactsRemoveFromBlackListRequest) => removeContactsFromBlackList(data);
const uploadFileService = async (file: any) => {
  const data = new FormData();
  data.append('file', file);
  return uploadFile(data);
};
const getFileHeadersService = async (data: FileHeadersGetRequest) => getFileHeaders(data);
const addMultipleContactsToGroupService = async (data: ContactsAddToGroupRequest) => addMultipleContactsToGroup(data);
const deleteMultipleContactsService = async (data: ContactsMultiDeleteRequest) => deleteMultipleContacts(data);

const createBulkContactService = async (data: ContactBulkCreateRequest) => createBulkContact(data);

const removeMultiBlackListContactService = async (data: ContactRemoveMultiBlackListRequest) => removeMultiBlackListContact(data);
const importContactService = async (data: ContactImportRequest) => importContact(data);
const listContactService = async (data: ContactListGetRequest) => listContact(data);
const searchContactsService = async (data: ContactsSearchRequest) => searchContacts(data);
const deleteAllContactsService = async () => deleteAllContacts();

const getFileUploadConfig = () => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN);
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${jwtToken}`
  };
  const action = `${API_BASE_URL}${DashboardApi.baseUrl}contacts/upload`;
  return { action, headers };
};

export {
  createSingleContactService,
  updateContactService,
  createBulkContactService,
  deleteContactService,
  deleteMultipleContactsService,
  blackListSingleContactService,
  removeContactsFromBlackListService,
  removeContactFromBlackListService,
  removeMultiBlackListContactService,
  addMultipleContactsToGroupService,
  importContactService,
  listContactService,
  searchContactsService,
  getFileUploadConfig,
  uploadFileService,
  getFileHeadersService,
  listAllContactService,
  deleteAllContactsService
};
