import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Invoice = lazy(() => import("./Invoice"));
const Payment = lazy(() => import("./Payment"));
const PaymentSuccess = lazy(() => import("./Payment/Success"));
const PaymentFailure = lazy(() => import("./Payment/Failure"));
const AutoRechargeForm = lazy(() => import("./AutoRecharge"));

const BillingPages = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/billing/invoice" replace />} />
    <Route path='invoice' element={<Invoice />} />
    <Route path='payment/*'>
      <Route path="" element={<Payment />} />
      <Route path='success' element={<PaymentSuccess />} />
      <Route path='failure' element={<PaymentFailure />} />
      <Route path="*" element={<Navigate to="/error/404" replace />} />
    </Route>
    <Route path='auto-recharge' element={<AutoRechargeForm />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>
);

export default BillingPages;
