import React, { useState} from 'react';
import { Button, Form, Input, Select } from 'antd';
import { CountryPhoneInputValue } from 'antd-country-phone-input';
import { useNavigate } from "react-router-dom";
import en from 'world_countries_lists/data/countries/en/world.json';
import { openRegisterService } from 'services/auth/RegisterService';
import FormItemPhoneInput from 'components/shared/FormItemPhoneInput';
import { handleApiError } from 'utils/helper';
import { ApiErrorResponse } from 'models/responses';



const RegistrationForm = (props: { validatedEmail?: string }) => {
  const organizationSizeList = ['1-10 Employees', '10-50 Employees', '100-500 Employees', '500+ Employees'];
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [spamAlert, setSpamAlert] = useState(false);
  const [entryAlert, setEntryAlert] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const [domain, setDomain] = useState<string>();
  const { validatedEmail } = props;
  const urlParams = new URLSearchParams(window.location.search);
  let tag = urlParams.get('tag');

  const onSuccessRegister = () => {
    setLoading(false);
    navigate('/signup/verify-email');
  };
  const onErrorRegister = (error: unknown) => {
    const errorResponse = error as ApiErrorResponse;
    if ((errorResponse.code==="SPAM_SIGNUP") || (errorResponse.message==="Email is already added in Signup Check table and blocked")){
      setLoading(false);
      setSpamAlert(true);
      setMobileAlert(false);
      setEntryAlert(false);
      setDomain(validatedEmail?.split("@")[1]);
    }
    else if (errorResponse.code==="SPAM_MOBILE_SIGNUP"){
      setLoading(false);
      setMobileAlert(true);
      setSpamAlert(false);
      setEntryAlert(false);
    }
    else if(errorResponse.code==="INVALID_REQUEST"){
      setLoading(false);
      setEntryAlert(true);
      setMobileAlert(false);
      setSpamAlert(false);
    }
    else{
      setLoading(false);
      handleApiError(error);
    }
  };


  const getPhoneNumber = (phoneField: any) => {
    if (phoneField?.phone) {
      // eslint-disable-next-line no-nested-ternary
      const phone = phoneField.phone?.startsWith('+') ?
        phoneField.phone : phoneField.code? `+${phoneField.code}${phoneField.phone}`: `+${phoneField.phone}`;
      return phone.trim()
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('{', '')
        .replaceAll('}', '')
        .replaceAll('[', '')
        .replaceAll(']', '');
    }
    return undefined;
  };


  const onSubmitForm = async (values: {
    email: string,
    company: string,
    orgSize: string,
    mobile: CountryPhoneInputValue,
    country: string,
  }) => {
    setLoading(true);
    try {
      const {
        email,
        company,
        orgSize,
        mobile,
        country,
      } = values;
      if (!tag){
        tag = '';
      }
      const queryParam = {"tag": tag};
      const phone = getPhoneNumber(mobile);
      const response = await openRegisterService({
        email
        , company,
        org_size: orgSize, mobile: phone,
        country
      }, queryParam);
      if (response) {
        onSuccessRegister();
      } else {
        onErrorRegister({ message: 'Failed to register', status: 0 });
      }
    } catch (error:any) {
        onErrorRegister(error);
    }
  };


  return (<Form
    layout="vertical"
    name="registrationForm"
    form={form}
    onFinish={onSubmitForm}>
    <Form.Item
      label="Email"
      name="email"
      initialValue={validatedEmail}
      rules={[
        {
          required: true,
          message: 'Please enter your email',
        },
        {
          type: 'email',
          message: 'Please enter a valid email!'
        }
      ]} >
      <Input type="email" readOnly />
    </Form.Item>
    <FormItemPhoneInput
      label="Mobile Number"
      name="mobile"
      initialValue={{ short: 'AE' }}
      form={form}
      onCountrySelectionChange={(phone) => { form.setFieldsValue({ country: phone.short }); }}
      onValidDialCode={(phone) => { form.setFieldsValue({ country: phone.short }); }}
    />

    <Form.Item
      label="Country"
      initialValue='AE'
      name="country">
      <Select className='w-100' showSearch filterOption={(input: any, option: any) =>
        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
        {en.map(e => <Select.Option key={e.alpha2} value={e.alpha2.toUpperCase()} title={e.name}><span className={`flag:${e.alpha2.toUpperCase()} mr-2`} />{e.name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Company"
      name="company">
      <Input />
    </Form.Item>
    <Form.Item
      label="Company Size"
      name="orgSize">
      <Select className='w-100'>
        {organizationSizeList.map(size => <Select.Option value={size} key={size}>{size}</Select.Option>)}
      </Select>
    </Form.Item>
    {spamAlert && <span className='font-weight-semibold ant-form-item-explain-error'>We restrict signups from <a href={domain}>{domain}</a> for security reasons.
    </span>}
    {entryAlert && <span className='font-weight-semibold ant-form-item-explain-error'>Registration is temporarily blocked. Please try again later.
    </span>}
    {mobileAlert && <span className='font-weight-semibold ant-form-item-explain-error'>We restrict signups from this mobile number. Please fill this form <a href="https://share-eu1.hsforms.com/16ajIMlggQIWGaTaHczEebQf50ta">here</a> to create account.
    </span>}
    <Form.Item>
      <div className="text-right mt-4">
        <Button type="primary" htmlType="submit" loading={loading}>
          Register
        </Button>
      </div>
    </Form.Item>
  </Form>);
};
RegistrationForm.defaultProps = {
  validatedEmail: undefined
};
export default RegistrationForm;
