import {ChatbotSaveResponse, ChatbotGraph, ChatbotList, ChatbotDeleteResponse} from "../../models/responses";
import {ChatbotCreate, ChatbotUpdate} from "../../models/requests";
import ChatBotApi from ".";

const postChatbotSaveRequest = async (chatBotId:string,data:any) => ChatBotApi.post<ChatbotSaveResponse>(`graph/${chatBotId}`,data);
const postChatbotDeployRequest = async (chatBotId:string) => ChatBotApi.post(`graph/deploy/${chatBotId}`);
const getChatbotRequest = async (chatbotId:string)=> ChatBotApi.get<ChatbotGraph>(`graph/${chatbotId}`);
const createChatbotRequest = async (data:ChatbotCreate)=> ChatBotApi.post<any,ChatbotCreate>('graph',data);
const listChatbotRequest = async () => ChatBotApi.get<ChatbotList[]>('graph');
const updateChatbotRequest = async (chatbotId:string, data:ChatbotUpdate) => ChatBotApi.put(`graph/${chatbotId}`,data);
const deleteChatbotRequest = async (chatbotId:string)=> ChatBotApi.delete<ChatbotDeleteResponse>(`graph/${chatbotId}`);

export {
  postChatbotSaveRequest,
  postChatbotDeployRequest,
  getChatbotRequest,
  createChatbotRequest,
  listChatbotRequest,
  updateChatbotRequest,
  deleteChatbotRequest
};
