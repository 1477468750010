import React, { Suspense } from 'react';
import Loading from 'components/shared/Loading';
import { Outlet } from 'react-router-dom';

export const AuthPageLayout = () => (
  <Suspense fallback={<Loading cover='page' />}>
    <div className="auth-container">
      <Outlet />
    </div>
  </Suspense>
);

export default AuthPageLayout;
