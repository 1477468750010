import React from "react";
import { FormattedMessage } from "react-intl";


// eslint-disable-next-line react/no-unused-prop-types
const IntlMessage = (props: { id: string | undefined }) => {
  const { id } = props;
  return <FormattedMessage id={id} />;
};
export default IntlMessage;

// export default injectIntl(IntlMessage, {
//   withRef: false
// });
