import React from "react";
import {Col, Row} from "antd";

const ChatMessageComponent = ({content, isBold, isOpacity, isMedia, mediaIconUrl}: {
  content: string,
  isBold?: boolean,
  isOpacity?: boolean,
  isMedia?: boolean,
  mediaIconUrl?: string
}) => {
  const className = `message sender ${isOpacity ? 'opacity' : ''}`;
  const contentStyle:  React.CSSProperties = {
    fontWeight: isBold ? 'bold' : 'normal',
    opacity: isOpacity ? 0.5 : 1,
    whiteSpace: 'pre-wrap',
  };

  const renderContent = () => {
    if (content && content.includes("<b>") && content.includes("</b>")) {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }
      return <span>{content}</span>;

  };
  return (
      <div className={className}>
        <Row>
          <Col xs={24} sm={24} md={24} className="message-content" style={contentStyle}>
            {isMedia ?
            <Col style={{textAlign:"center"}}>
               <img width="50%" height="50%" src={mediaIconUrl} className="media-icon" alt=""/>
            </Col> : null}
            <Col>{isMedia ? null : renderContent()}</Col>
          </Col>
        </Row>
      </div>
  );
};

ChatMessageComponent.defaultProps = {
  isBold: false,
  isOpacity: false,
  isMedia: false,
  mediaIconUrl: '',
};

export default ChatMessageComponent;
