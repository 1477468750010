import React, {Component, ErrorInfo, ReactNode} from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundaryCustom extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render(): ReactNode {
    const {hasError} = this.state;
    const {children} = this.props;

    if (hasError) {
      return <div style={{color: "red"}}>Error: Unable to load the content. Please refresh the page and try again later.
      </div>;
    }

    return children;
  }
}

export default ErrorBoundaryCustom;
