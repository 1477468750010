import {
  ApplicationWithTokenCreateRequest,
  // ApplicationTokenGetRequest,
  ApplicationDeleteRequest,
  ApplicationCreateRequest,
  ApplicationRequest
} from 'models/requests';
import {
  ApplicationCreateResponse,
  ApplicationDeleteResponse,
  ApplicationListResponse,
  ApplicationTokenResponse,
  ApplicationUpdateResponse
} from 'models/responses';

import AuthApi from "./index";

const listApplication = async () => AuthApi.get<ApplicationListResponse>('application');
const createApplication = async (data: ApplicationCreateRequest) => AuthApi.post<ApplicationCreateResponse, ApplicationCreateRequest>('application', data);
const updateApplication = async (data: ApplicationRequest, appId: number) => AuthApi.put<ApplicationUpdateResponse, ApplicationRequest>(`application/${appId}`, data);
const deleteApplication = async (data: ApplicationDeleteRequest) => AuthApi.delete<ApplicationDeleteResponse>(`application/${data.application_id}`);
const getApplicationToken = async (data: any) => AuthApi.post<ApplicationTokenResponse>('login/application', data, true, { 'Content-Type': 'application/x-www-form-urlencoded' });

const getApplicationTokenById = async (applicationId: number) => AuthApi.get<ApplicationTokenResponse>(`login/application/token/${applicationId}`);

const createApplicationAndGetToken = async (req: ApplicationWithTokenCreateRequest) => AuthApi.post<ApplicationTokenResponse, ApplicationWithTokenCreateRequest>('login/application/token', req);

export {
  listApplication,
  createApplication,
  updateApplication,
  deleteApplication,
  getApplicationToken,
  getApplicationTokenById,
  createApplicationAndGetToken
};
