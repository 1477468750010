import React, {useEffect, useState} from 'react';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {ColumnsType} from 'antd/es/table';
import {Contact, ContactGroup} from "models";
import TableList from 'components/shared/TableList';
import {Alert, Button, Col, Form, Modal, notification, Row, Select} from "antd";
import {
  deleteIntegrationService, listZohoIntegrationsService
} from "../../../../services/dashboard/IntegrationsService";
import {addMultipleContactsToGroupService} from "../../../../services/dashboard/ContactService";
import {listGroupService} from "../../../../services/dashboard/ContactGroupService";
import {ZOHO_APP_BASE_URL} from "../../../../configs/AppConfig";

const ZohoPreview = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [zohoIntegrations, setZohoIntegrations] = useState([]);
  const [showWarnings, setShowWarnings] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState<number[]>([]);
  const [showGroupMappingForm, setShowGroupMappingForm] = useState(false);
  const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);

  const onClose = () => {
    setSelectedContactIds([]);
    setShowGroupMappingForm(false);
  };

  const resetAndCloseForm = () => {
    form.resetFields();
    onClose();
  };

  const onCancelModel = () => {
    if (!loading) {
      resetAndCloseForm();
    }
  };

  const onOkModel = () => {
    if (!loading) {
      resetAndCloseForm();
    }
  };

  const fetchZohoIntegrationsList = async () => {
    setLoading(true);
    try {
      const response = await listZohoIntegrationsService();
      setZohoIntegrations(response);
      setLoading(false);
    } catch (error) {
      setZohoIntegrations([]);
      setLoading(false);
    }
  };


  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };
  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };
  const onSuccessAddMultipleContactsToGroup = () => {
    showSuccessNotification('Contacts added to group');
    setLoading(false);
    resetAndCloseForm();
  };

  const onErrorAddMultipleContactsToGroup = (showNotification?: boolean) => {
    if (showNotification) {
      showErrorNotification('Failed to add contacts to group');
    }
    setLoading(false);
  };

  // const onCrmIntegrationCallback = async (integrationId: any) => {
  //   try {
  //     const response = await createCrmContactService(integrationId);
  //     setSelectedContactIds(response);
  //   } catch (error) {
  //     setSelectedContactIds([]);
  //   }
  // };
  const onSubmitForm = async (values: { groupId: number }) => {
    setLoading(true);
    onClose();
    // await onCrmIntegrationCallback(selectedIntegrationId[0]);
    if (selectedContactIds && selectedContactIds.length > 0) {
      try {
        const response = await addMultipleContactsToGroupService({
          group_id: values.groupId,
          contact_id: selectedContactIds ?? []
        });
        if (response && response.length > 0) {
          onSuccessAddMultipleContactsToGroup();
        } else {
          onErrorAddMultipleContactsToGroup(true);
        }
      } catch (error) {
        onErrorAddMultipleContactsToGroup(true);
      }
    }
  };

  const fetchContactGroups = async () => {
    try {
      const response = await listGroupService();
      setContactGroups(response);
    } catch (error) {
      setContactGroups([]);
    }
  };

  const flattenedIntegrations = zohoIntegrations.flat();

  useEffect(() => {
    Promise.all([fetchZohoIntegrationsList(), fetchContactGroups()]);
  }, [selectedContactIds]);

  const onSuccessDeleteIntegrations = (showNotification?: boolean) => {
    if (showNotification) {
      showSuccessNotification('Integration deleted successfully');
    }
    setLoading(false);
  };
  const onErrorDeleteIntegrations = (showNotification?: boolean) => {
    if (showNotification) {
      showErrorNotification('Failed to delete integration');
    }
    setLoading(false);
  };
  const deleteIntegrations = async (integrationId: number) => {
    setLoading(true);
    try {
      const response = await deleteIntegrationService({platform_integration_id: integrationId});
      if (response.id === integrationId) {
        onSuccessDeleteIntegrations(true);
      } else {
        onErrorDeleteIntegrations(true);
      }
    } catch (error) {
      onErrorDeleteIntegrations(true);
    }
    fetchZohoIntegrationsList();
  };

  const onConfirmDelete = (item: any) => {
    deleteIntegrations?.(item.id);
  };

  const controls = [
    {
      title: 'Add New Integrations',
      icon: <PlusOutlined/>,
      onClick: () => {
        window.location.href = ZOHO_APP_BASE_URL || '';
      },
    }
  ];

  const actions = [
    {
      toolTip: 'Delete',
      icon: <DeleteOutlined/>,
      popConfirm: {
        title: 'Are you sure you want to delete this integration?', onConfirm: onConfirmDelete
      },
    }
  ];

  const searchPredicate = (item: any, text: string) =>
    item.name.toLowerCase().includes(text)
    || `${item.template}`.toLowerCase().includes(text);

  const tableColumns: ColumnsType<Contact> = [
    {
      title: 'Integration ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
    }
  ];

  // const onClickAddContactsToGroup = (selectedRows?: Contact[]) => {
  //   if ((selectedRows?.length ?? 0) <= 0) {
  //     setShowWarnings(true);
  //   } else {
  //     setSelectedIntegrationId(selectedRows?.map(x => x.id) ?? []);
  //     setShowGroupMappingForm(true);
  //   }
  // };

  return (
    <>
      <div>
        {showWarnings ? <Alert className='mb-2'
                               message="Please select contacts"
                               onClose={() => {
                                 setShowWarnings(false);
                               }}
                               banner
                               closable/> : null}
        <TableList items={flattenedIntegrations} columns={tableColumns} loading={loading}
                   rowKey={(record: any) => record.id} showSearch controls={controls} actions={actions}
                   searchPredicate={searchPredicate} hideRowSelection/>
      </div>
      <Modal
        title="Add contacts to group"
        visible={showGroupMappingForm}
        onOk={onOkModel}
        onCancel={onCancelModel}
        footer={null}
      >
        <Form
          name="groupMapForm"
          layout="horizontal"
          size="small"
          className="mt-4"
          onFinish={onSubmitForm}
          form={form}
        >
          <Row gutter={16}>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label="Group"
                name="groupId"
                labelCol={{span: 4}}
                labelAlign="left"

              >
                <Select
                  placeholder="Select a group"
                  className="w-100"
                  showSearch
                  filterOption={(input: any, option: any) => option?.key.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.title.toLowerCase().indexOf(input.toLowerCase()) >=
                    0}
                >
                  {contactGroups.map((e) => (
                    <Select.Option key={e.id} value={e.id} title={e.name}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="mt-4 text-right">
            <Button
              htmlType="submit"
              type="primary"
              className="ml-2"
              loading={loading}
            >
              Add Contacts to Group
            </Button>
          </div>
        </Form>
      </Modal></>
  );
};


export default ZohoPreview;
