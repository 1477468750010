import AxiosService from "api";

const WhatsappApi = {
  baseUrl: '/whatsapp/v1/',
  getUrl: (url: string) => `${WhatsappApi.baseUrl}${url}`,
  post: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.post<R, D>(WhatsappApi.getUrl(url), data, isPublic, headers),
  postWithQueryParams: <R = any, P = any, D = any>(url: string, queryData?: P, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.postWithQueryParams<R, P, D>(WhatsappApi.getUrl(url), queryData, data, isPublic, headers),
  put: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.put<R, D>(WhatsappApi.getUrl(url), data, isPublic, headers),
  get: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.get<R, D>(WhatsappApi.getUrl(url), data, isPublic, headers),
  delete: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.delete<R, D>(WhatsappApi.getUrl(url), data, isPublic, headers),
};
export default WhatsappApi;
