import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Input, Row, notification, Select, DatePicker, Modal, Dropdown,} from "antd";
import {WhatsappCampaign} from "models";

import {FormInstance} from "antd/es/form/Form";
import TimeZone from 'configs/TimeZones.json';
import moment from 'antd/node_modules/moment';
import {
  editWhatsappCampaignService,
  getWhatsappCampaignService
} from "../../../../services/dashboard/WhatsappCampaignService";
import {listWhatsappTemplateService} from "../../../../services/dashboard/WhatsappService";

// import {WhatsappCampaignUpdateRequest} from "../../../../models/requests";

export interface EditWhatsappCampaignModalFormProps {
  showEditPopUp: boolean;
  campaignId: string;
  form: FormInstance;
  onSave: () => void;
  onCloseModal: () => void;
}

const EditWhatsappCampaignModalForm = (props: EditWhatsappCampaignModalFormProps) => {
    const {showEditPopUp, campaignId, form, onSave, onCloseModal} = props;
    const [loading, setLoading] = useState(false);
    const [whatsappCampaign, setCampaign] = useState<WhatsappCampaign>();
    const [requiredTimezone, setRequiredTimezone] = useState(false);
    const [templates, setTemplates] = useState([{template_name: '', template_id: ''}]);
    const [selectedTemplate, setSelectedTemplate] = useState({
      body_text: '',
      example_body_text: [],
      header_text: '',
      header_format: '',
      footer_text: '',
      example_header_text: [],
      media_url: '',
      buttons: {buttons_type: '', button_url: [{example_url_values: []}]}
    });
    const [bodyParameters, setBodyParameters] = useState<{ [key: string]: string }>({});
    const [mediaUrl, setMediaUrl] = useState('');
    // const [headerText, setHeaderText] = useState('');
    const [locationLongitude, setLocationLongitude] = useState('');
    const [locationLatitude, setLocationLatitude] = useState('');
    const [locationName, setLocationName] = useState('');
    const [locationAddress, setLocationAddress] = useState('');
    const [buttonDynamic, setButtonDynamic] = useState('');
    const [personalizeMenuVisibility, setPersonalizeMenuVisibility] = useState({});
    const [parameterValue, setParameterValue] = useState('');
    const [showPersonalizationMenu, setShowPersonalizationMenu] = useState(false);


    const PERSONALIZATION_FIELDS = [
      {text: 'First Name', value: '{first_name}'},
      {text: 'Last Name', value: '{last_name}'},
      {text: 'Email', value: '{email}'},
      {text: 'Number', value: '{number}'},
      {text: 'Var 1', value: '{var1}'},
      {text: 'Var 2', value: '{var2}'},
      {text: 'Var 3', value: '{var3}'},
      {text: 'Var 4', value: '{var4}'},
      {text: 'Var 5', value: '{var5}'},
      {text: 'Country', value: '{country}'},
      {text: 'Blacklist', value: '{blacklist}'}];

    const showErrorNotification = (message?: string, showNotification?: boolean) => {
      if (showNotification) {
        const notificationParam = {
          message,
          description: ''
        };
        notification.error(notificationParam);
      }
    };

    const showSuccessNotification = (message: string) => {
      const notificationParam = {
        message,
        description: ''
      };
      notification.success(notificationParam);
    };

    const getISOString = (dateValue: Date | undefined) => {
      if (dateValue) {
        const d = new Date(dateValue);
        let month = (d.getMonth() + 1).toString();
        let day = d.getDate().toString();
        const year = d.getFullYear();
        let hours = d.getHours().toString();
        let minutes = d.getMinutes().toString();
        let seconds = d.getSeconds().toString();
        month = month.length < 2 ? `0${month}` : month;
        day = day.length < 2 ? `0${day}` : day;
        hours = hours.length < 2 ? `0${hours}` : hours;
        minutes = minutes.length < 2 ? `0${minutes}` : minutes;
        seconds = seconds.length < 2 ? `0${seconds}` : seconds;
        return (`${[year, month, day].join('-')} ${[hours, minutes, seconds].join(':')}.000`);
      }
      return undefined;
    };

    const handleTemplateChange = (templateId: any) => {
      const selected = templates.find((template) => template.template_id === templateId);
      // @ts-ignore
      setSelectedTemplate(selected);
      // setBodyParameters({});
    };

    const handleParameterChange = (value: any) => {
      setParameterValue(value);
    };

    const handlePersonalizeChangeHeader = (value: any) => {
      const newValue = `{${value}}`;
      const currentValue = parameterValue || '';
      const updatedValuex = `${currentValue} ${newValue}`.trim();
      setParameterValue(updatedValuex);
      setShowPersonalizationMenu(false);
      form.setFieldsValue({content: {media: {text_header_title: updatedValuex}}});
    };

    const personalizeMenu = (
      <div className="nav-profile nav-dropdown p-3" style={{height: 250, width: 220}}>
        <h5 className="mb-2">Personalize</h5>
        <Select
          showSearch
          style={{width: '100%'}}
          placeholder="Search"
          optionFilterProp="children"
          onChange={(value) => handlePersonalizeChangeHeader(value)}
          filterOption={(input: any, option: any) =>
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
            option.title.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {PERSONALIZATION_FIELDS.map((e) => (
            <Select.Option key={e.value} value={e.value} title={e.text}>
              {e.text}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
    const handleBodyParameterChange = (key: any, value: any) => {
      setBodyParameters({
        ...bodyParameters,
        [key]: value,
      });
    };

    const handlePersonalizeMenuVisibilityChange = (paramKey: any, visible: any) => {
      setPersonalizeMenuVisibility((prevVisibility) => ({...prevVisibility, [paramKey]: visible}));
    };

    const handlePersonalizeChange = (paramKey: any, value: any) => {
      const newValue = `{${value}}`;
      const updatedValue = bodyParameters[paramKey] ? `${bodyParameters[paramKey]} ${newValue}` : newValue;
      const updatedBodyParameters = {...bodyParameters, [paramKey]: updatedValue};
      setBodyParameters(updatedBodyParameters);
      handlePersonalizeMenuVisibilityChange(paramKey, false);

      // Set the form field value as well
      const currentValue =
        form.getFieldValue(['content', 'body_parameter_values', paramKey]) || '';
      const updatedValuex = `${currentValue} ${newValue}`.trim();
      form.setFieldsValue({
        content: {
          body_parameter_values: {...bodyParameters, [paramKey]: updatedValuex},
        },
      });
    };

    const createPersonalizeMenu = (paramKey: any) => (
      <div className="nav-profile nav-dropdown p-3" style={{height: 250, width: 220}}>
        <h5 className="mb-2">Personalize</h5>
        <Select
          showSearch
          style={{width: '100%'}}
          placeholder="Search"
          optionFilterProp="children"
          onChange={(value) => handlePersonalizeChange(paramKey, value)}
          filterOption={(input: any, option: any) =>
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
            option.title.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {PERSONALIZATION_FIELDS.map((e) => (
            <Select.Option key={e.value} value={e.value} title={e.text}>
              {e.text}
            </Select.Option>
          ))}
        </Select>
      </div>
    );


    // @ts-ignore
    const isPersonalizeMenuVisible = (paramKey: any) => personalizeMenuVisibility[paramKey] || false;


    const fetchTemplates = async () => {
      try {
        const fetchedTemplates: any = await listWhatsappTemplateService();
        setTemplates(fetchedTemplates);
      } catch (error) {
        // console.error('Error fetching templates:', error);
      }
    };

    const onSubmitForm = async (values: {
      campaignName: string;
      originator: string;
      message: {
        template_id: string,
        body_parameter_values: {},
        media: {
          media_type: string,
          media_url: string,
          header_text: string,
          media_caption: string,
          location: {
            longitude: string,
            latitude: string,
            name: string,
            address: string
          }
        },
        buttons: {
          action_payload: string
        }
      };
      scheduleCampaign: Date;
      timezone: string;
    }) => {
      const {
        campaignName,
        originator,
        message,
        scheduleCampaign,
        timezone
      } = values;

      const WhatsappContent = {
          template_id: message?.template_id || whatsappCampaign?.content.template_id,
          body_parameter_values: bodyParameters || whatsappCampaign?.content.body_parameter_values,
        // eslint-disable-next-line no-nested-ternary
        media: selectedTemplate.header_format
          ? (selectedTemplate.header_format === 'TEXT' && selectedTemplate.example_header_text === [])
            ? undefined // Set media to null if TEXT format and no example_header_text
            : {
              media_type: selectedTemplate.header_format || '',
              media_url: mediaUrl || whatsappCampaign?.content.media?.media_url || '',
              text_header_title:
                selectedTemplate.header_format === 'TEXT'
                  ? parameterValue || whatsappCampaign?.content.media?.text_header_title || ''
                  : '',
              media_caption: whatsappCampaign?.content.media?.media_caption || '',
              location: selectedTemplate.header_format === 'LOCATION'
                ? {
                  longitude: locationLongitude || whatsappCampaign?.content.media?.location?.longitude || '',
                  latitude: locationLatitude || whatsappCampaign?.content.media?.location?.latitude || '',
                  name: locationName || whatsappCampaign?.content.media?.location?.name || '',
                  address: locationAddress || whatsappCampaign?.content.media?.location?.address || ''
                } : undefined
            }
            : undefined,
           buttons: undefined
        }
      ;

      // Check the header format and set media_type accordingly
      if (selectedTemplate.header_format === 'IMAGE') {
        WhatsappContent.media!.media_type = 'image';
      } else if (selectedTemplate.header_format === 'VIDEO') {
        WhatsappContent.media!.media_type = 'video';
      } else if (selectedTemplate.header_format === 'DOCUMENT') {
        WhatsappContent.media!.media_type = 'document';
      } else if (selectedTemplate.header_format === 'AUDIO') {
        WhatsappContent.media!.media_type = 'audio';
      } else if (selectedTemplate.header_format === 'LOCATION') {
        WhatsappContent.media!.media_type = 'location';
      } else if (
        selectedTemplate.header_format === 'TEXT' &&
        Object.keys(selectedTemplate.example_header_text).length === 0
      ) {
        WhatsappContent.media = undefined;
      } else if (selectedTemplate.header_format === 'TEXT') {
        WhatsappContent.media!.media_type = 'text';
        WhatsappContent.media!.text_header_title = parameterValue || selectedTemplate.example_header_text[0] || '';
      }

      if (whatsappCampaign) {
        const request = {
          campaign_name: campaignName,
          originator,
          content: WhatsappContent,
          scheduled_at: getISOString(scheduleCampaign),
          scheduled_tz: timezone,
          recipients: whatsappCampaign.recipients,
          recipients_file: whatsappCampaign.recipients_file,
          contact_groups: whatsappCampaign.contact_groups,
          contacts: whatsappCampaign.contacts
        };

        setLoading(true);
        try {
          const response = await editWhatsappCampaignService(request, whatsappCampaign.id);
          if (response.id) {
            showSuccessNotification('Campaign edited successfully');
            setLoading(false);
          } else {
            showErrorNotification();
            setLoading(false);
          }
        } catch (error: any) {
          showErrorNotification(error.message, true);
          setLoading(false);
        }
      }
      onSave();
    };

    const fetchCampaign = async () => {
      setLoading(true);
      try {
        const response = await getWhatsappCampaignService(campaignId);
        if (response) {
          setCampaign(response);
          form.setFieldsValue({
            campaign_name: response.campaign_name,
            originator: response.originator,
            content: {
              template_id: response.content.template_id,
              body_parameter_values: response.content.body_parameter_values,
              media: {
                media_type: response.content.media?.media_type,
                media_url: response.content.media?.media_url,
                text_header_title: response.content.media?.text_header_title,
                media_caption: response.content.media?.media_caption,
                longitude: response.content.media?.location?.longitude,
                latitude: response.content.media?.location?.latitude,
                name: response.content.media?.location?.name,
                address: response.content.media?.location?.address
              }
            },
            timezone: response.scheduled_tz ?? ''
          });

          if (response.scheduled_at) {
            const arr = response.scheduled_at.split(/-|\s|\+|:|\./);// split string and create array.
            const dateStr = `${arr[0]}${"-"}${arr[1]}${"-"}${arr[2]}${":"}${arr[3]}${":"}${arr[4]}`;// classic string concatenation
            form?.setFieldsValue({scheduleCampaign: moment(dateStr)});
          }
          handleTemplateChange(response.content.template_id);
          setBodyParameters(response.content.body_parameter_values as any);
          setParameterValue(response.content.media?.text_header_title as any);
          setLocationLongitude(response.content.media?.location?.longitude as any);
          setLocationLatitude(response.content.media?.location?.latitude as any);
          setLocationName(response.content.media?.location?.name as any);
          setLocationAddress(response.content.media?.location?.address as any);
          // setButtonDynamic(response.content.buttons?.actions?.action_payload as any);

        } else {
          showErrorNotification('Failed to fetch whatsapp campaign', true);
        }
      } catch (error: any) {
        showErrorNotification('Failed to fetch whatsapp campaign', true);
      }
      setLoading(false);
    };

    useEffect(() => {
      if (showEditPopUp)
        fetchCampaign();
      fetchTemplates();
    }, [showEditPopUp]);

    return (
      <Modal
        visible={showEditPopUp}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        footer={null}
        width={870}
      >
        <Form
          name="campaignEditForm1"
          layout="horizontal"
          size="small"
          className="mt-4"
          onFinish={onSubmitForm}
          form={form}
          labelAlign='left'
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label="Campaign Name"
                name="campaign_name"
                labelCol={{span: 5}}
                rules={[
                  {
                    required: true,
                    message: 'Please enter campaign name!',
                  },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label="Originator"
                name="originator"
                labelCol={{span: 5}}
                rules={[
                  {
                    required: true,
                    message: 'Please enter originator!',
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item labelCol={{span: 5}}>

                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label="Choose your template" name={['content', 'template_id']}
                             labelCol={{span: 5}}>
                    <Select placeholder="Choose your template" onChange={handleTemplateChange} disabled>
                      {templates.length === 0 ? (
                        <Select.Option value="addTemplate"><a href="/whatsapp/whatsapp-template">Create
                          New
                          template</a></Select.Option>
                      ) : (
                        templates.map((template, index) => (
                          <Select.Option key={template.template_id} value={template.template_id}>
                            {template.template_name}
                          </Select.Option>

                        ))
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                {selectedTemplate && (
                  <div>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {selectedTemplate.header_format && selectedTemplate.header_format !== 'TEXT' && selectedTemplate.header_format !== 'LOCATION' && (
                        <Form.Item label="Media URL" name={['content', 'media', 'media_url']} labelCol={{span: 5}}
                                   rules={[
                                     {
                                       required: true,
                                       message: 'Please enter media url!',
                                     },
                                   ]}>
                          <Input value={mediaUrl} onChange={(e) => setMediaUrl(e.target.value)}/>
                        </Form.Item>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {selectedTemplate.header_format && selectedTemplate.header_format === 'TEXT' && Object.keys(selectedTemplate.example_header_text).length > 0 && (
                        <Form.Item
                          label="Header Parameters"
                          name={['content', 'media', 'text_header_title']}
                          labelCol={{span: 5}}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Header text!',
                            },
                          ]}
                        >
                          <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                            <Input
                              value={parameterValue}
                              onChange={(e) => handleParameterChange(e.target.value)}
                            />
                            <div className="text-right">
                              <Dropdown
                                overlay={personalizeMenu}
                                onVisibleChange={() => setShowPersonalizationMenu(!showPersonalizationMenu)}
                                visible={showPersonalizationMenu}
                                placement="topRight"
                              >
                                <Button type="link">
                                  Personalize
                                </Button>
                              </Dropdown>
                            </div>
                          </div>
                        </Form.Item>
                      )}
                    </Col>
                    <Col>
                      {selectedTemplate.header_format === 'LOCATION' && (
                        <>
                          <Form.Item label="Longitude" labelCol={{span: 5}} rules={[
                            {
                              required: true,
                              message: 'Please enter longitude!',
                            },
                          ]}>
                            <Input value={locationLongitude} onChange={(e) => setLocationLongitude(e.target.value)}/>
                          </Form.Item>
                          <Form.Item label="Latitude" labelCol={{span: 7}} rules={[
                            {
                              required: true,
                              message: 'Please enter latitude!',
                            },
                          ]}>
                            <Input value={locationLatitude} onChange={(e) => setLocationLatitude(e.target.value)}/>
                          </Form.Item>
                          <Form.Item label="Name" labelCol={{span: 5}}>
                            <Input value={locationName} onChange={(e) => setLocationName(e.target.value)}/>
                          </Form.Item>
                          <Form.Item label="Address" labelCol={{span: 5}}>
                            <Input value={locationAddress} onChange={(e) => setLocationAddress(e.target.value)}/>
                          </Form.Item>
                        </>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {selectedTemplate.body_text && Object.keys(selectedTemplate.example_body_text).length > 0 && (
                        <Form.Item
                          label="Body Parameters"
                          name={['content', 'body_parameter_values']}
                          labelCol={{span: 5}}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter body parameter values!',
                            },
                          ]}
                        >
                          <div>
                            {Object.keys(selectedTemplate.example_body_text).map((key, index) => (
                              <div key={key} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                                <span style={{marginRight: '10px'}}>{`${key}:`}</span>
                                <Input
                                  value={bodyParameters[key] || ''}
                                  onChange={(e) => handleBodyParameterChange(key, e.target.value)}
                                />
                                <div className="text-right">
                                  <Dropdown
                                    overlay={createPersonalizeMenu(key)}
                                    onVisibleChange={(visible) => handlePersonalizeMenuVisibilityChange(key, visible)}
                                    visible={isPersonalizeMenuVisible(key)}
                                    placement="topRight"
                                  >
                                    <Button type="link">
                                      Personalize
                                    </Button>
                                  </Dropdown>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Form.Item>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {(selectedTemplate.buttons && selectedTemplate.buttons.buttons_type && selectedTemplate.buttons.buttons_type.length > 0 && selectedTemplate.buttons.buttons_type.includes('URL') && selectedTemplate.buttons.button_url
                      ) && (
                        <Form.Item /* eslint-disable react/no-array-index-key */>
                          {selectedTemplate.buttons.button_url.map((button, index) => (
                            <div key={index}>
                              {button.example_url_values && (
                                <Form.Item label="Button Dynamic URL"
                                           labelCol={{span: 5}}>
                                  <Input value={buttonDynamic} onChange={(e) => setButtonDynamic(e.target.value)}/>
                                </Form.Item>
                              )}
                            </div>
                          ))}
                        </Form.Item>
                      )}
                    </Col>
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label="Schedule Campaign"
                name="scheduleCampaign"
                labelCol={{span: 5}}
                rules={[
                  {
                    required: true,
                    message: 'Please enter message!',
                  },
                ]}>
                <DatePicker showTime onChange={e => {
                  if (e)
                    setRequiredTimezone(true);
                  else
                    setRequiredTimezone(false);
                }}
                  // onChange={e => setCampaignSchedule(e?.toISOString().replace('Z', ''))}
                            format="YYYY-MM-DD HH:mm:ss" className='w-100'/>

              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label="Timezone"
                name="timezone"
                labelCol={{span: 5}}
                rules={[
                  {
                    required: requiredTimezone,
                    message: 'Please select Timezone!',
                  },
                ]}
              >

                <Select
                  showSearch
                  style={{width: 200}}
                  placeholder="Timezone"
                  optionFilterProp="children"
                  // onChange={onChangeTimeZone}
                  filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className='w-100'
                >
                  {TimeZone.map((e) => (
                    <Select.Option key={e.name} value={e.value} title={`${e.value}`}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="mt-4 text-right">
            <Button
              htmlType="submit"
              type="primary"
              className="ml-2"
              loading={loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
;

export default EditWhatsappCampaignModalForm;
