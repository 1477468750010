import React, {useEffect, useState} from 'react';
import {Card, Row, Col, Badge, Tag, Button, Modal, Input} from 'antd';
import Icon, {EditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {

  getWhatsappMetaBusinessProfileService,
  getWhatsappMetaRegisteredNumbersService, updateWhatsappMetaDisplayNameService
} from "../../../../services/dashboard/WhatsappService";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/helper";
import {WhatsappMetaBusinessProfile, WhatsappMetaContactList} from "../../../../models";
import WhatsappBusinessProfileForm from "../WhatsappBusinessProfileForm";


const OnboardedNumberDetails = () => {
  const [loading, setLoading] = useState(false);
  const [Contacts, setContacts] = useState<WhatsappMetaContactList>();
  const [ContactNumber, setContactNumber] = useState();
  const [BusinessProfile, setBusinessProfile] = useState<WhatsappMetaBusinessProfile>();
  const {id} = useParams<{ id: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState('');

  const onErrorWhatsappProfile = (messages: string) => {
    showErrorNotification(messages);
    setLoading(false);
  };
  const onSuccessWhatsappProfile = (messages: string) => {
    showSuccessNotification(`Display Name Updated successfully`);
    setLoading(false);
  };

  const fetchBusinessProfile = async () => {
    setLoading(true);
    try {
      if (id) {
        let normalizedNumber = id.replace(/\s+/g, '');
        normalizedNumber = normalizedNumber.replace(/[-()]/g, '');
        const fetchedBusinessProfile: any = await getWhatsappMetaBusinessProfileService(normalizedNumber);
        setLoading(false);
        if (fetchedBusinessProfile) {
          setBusinessProfile(fetchedBusinessProfile.data[0]);
        }
      } else {
        onErrorWhatsappProfile("Number ID is missing");
      }
    } catch (error) {
      setLoading(false);
      onErrorWhatsappProfile("Failed to fetch onboarded whatsapp numbers!");
      // console.error('Error fetching templates:', error);
    }
  };

  const fetchPhoneNumber = async () => {
    setLoading(true);
    try {
      if (id) {
        // const numberIdInteger: number = parseInt(id, 10);
        // // eslint-disable-next-line no-restricted-globals
        // if (isNaN(numberIdInteger)) {
        //   onErrorWhatsappProfile("Invalid number ID");
        // }
        let normalizedNumber = id.replace(/\s+/g, '');
        normalizedNumber = normalizedNumber.replace(/[-()]/g, '');
        const fetchedPhoneNumber: any = await getWhatsappMetaRegisteredNumbersService(normalizedNumber);
        setLoading(false);
        if (fetchedPhoneNumber) {
          setContacts(fetchedPhoneNumber);
          setContactNumber(fetchedPhoneNumber.number);
        }
      } else {
        onErrorWhatsappProfile("Number ID is missing");
      }
    } catch (error) {
      setLoading(false);
      onErrorWhatsappProfile("Failed to fetch onboarded whatsapp numbers!");
      // console.error('Error fetching templates:', error);
    }
  };


  const showModal = () => {
    setIsModalVisible(true);
    setNewDisplayName(Contacts?.name || '');
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    setLoading(true);
    const DisplayName = {name: newDisplayName};
    try {
      const response = await updateWhatsappMetaDisplayNameService(ContactNumber, DisplayName);
      if (response.id) {
        setLoading(false);
        onSuccessWhatsappProfile(response);
        // Show success modal with message
        Modal.success({
          title: 'Update Successful',
          content: (
            <div>
              <p>Your display name will need to be approved by WhatsApp before an updated certificate becomes
                available.</p>
              <p>You will be notified when the review has been completed.</p>
            </div>
          ),
        });
      } else {
        setLoading(false);
        onErrorWhatsappProfile("Failed to update whatsapp profile display name!");
      }
    } catch (error: any) {
      setLoading(false);
      onErrorWhatsappProfile("Failed to update whatsapp profile display name!");
    }
    fetchPhoneNumber();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewDisplayName(e.target.value);
  };

  const getStatusBadge = (status: any) => {
    switch (status) {
      case "APPROVED":
        return "success";
      case "PENDING":
        return "warning";
      default:
        return "default";
    }
  };
  // eslint-disable-next-line react/no-unstable-nested-components,react/prop-types
  // @ts-ignore
  // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
  const ItemInfo = ({name, value}) => (
    <div>
      <Row className="mb-2" align="middle">
        <Col xs={12} sm={12} md={10}>
          <Icon className="text-primary font-size-md"/>
          <span className="text-muted ml-0 ">{name}:
            </span>
        </Col>
        <Col xs={12} sm={12} md={14}>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <span className="font-size-md font-weight-bold" style={{cursor: 'pointer'}} title={`Click to copy ${name}`}>
            {value}
          </span>
          </div>
        </Col>
      </Row>
    </div>
  );

  const formatMessageLimit = (text: any) => {
    let formattedText;
    if (text === 'TIER_1K') {
      formattedText = '1K Customers/24hrs';
    } else if (text === 'TIER_10K') {
      formattedText = '10K Customers/24hrs';
    } else if (text === 'TIER_100K') {
      formattedText = '100K Customers/24hrs';
    }
    return formattedText;
  };

  const formatQualityRating = (text: any) => {
    let formattedText;
    if (text === 'GREEN') {
      formattedText = <Badge color='green' text={<span className="font-size-md font-weight-bold">High</span>}/>;
    } else if (text === 'RED') {
      formattedText = <Badge color='red' text={<span className="font-size-md font-weight-bold">Low</span>}/>;
    } else if (text === 'YELLOW') {
      formattedText = <Badge color='yellow' text={<span className="font-size-md font-weight-bold">Medium</span>}/>;
    }
    return formattedText;
  };

  const formatConnctionStatus = (text: any) => {
    let formattedText;
    if (text === 'CONNECTED') {
      formattedText = <Tag color="green"><span className="font-size-md font-weight-bold rounded">Connected</span></Tag>;
    } else if (text === 'FLAGGED') {
      formattedText = <Tag color="red"><span className="font-size-md font-weight-bold rounded">Flagged</span></Tag>;
    } else if (text === 'PENDING') {
    formattedText = <Tag color="yellow"><span className="font-size-md font-weight-bold rounded">Pending</span></Tag>;
  }
    return formattedText;
  };


  useEffect(() => {
    Promise.all([fetchPhoneNumber(), fetchBusinessProfile()]);
  }, []);

  return (
    <>
      <div>
        <h2 className="mb-5 mt-md-0 mt-2 mr-3 ">
          Whatsapp Onboarded Number: {Contacts?.number}
        </h2>
        <Badge.Ribbon text={(Contacts?.status === true) ? "Active" : "Inactive"}
                      color={(Contacts?.status === true) ? "green" : "red"} >
          <Card loading={loading}>
            <h3>Number Details</h3>
            <Row justify="center">
              <Col sm={24} md={24}>
                <div className="d-md-flex">
                  <div className=" w-100">
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={7} style={{paddingTop: "4px"}}>
                        <Row className="mb-2" align="middle">
                          <Col xs={12} sm={12} md={7}>
                            <Icon className="text-primary font-size-md"/>
                            <span className="text-muted ml-0 ">Display Name:
                                <InfoCircleOutlined
                                  style={{fontSize: '14px', marginLeft: '8px'}}
                                  onClick={() => {
                                    Modal.info({
                                      title: "Display Name",
                                      content: (
                                        <div>
                                          <p>Your businesses can change the display names up to 10 times in a 30-day
                                            period.</p>
                                          <p>Once the 30-day period is up, a new 30-day period will begin and your
                                            businesses can again change the display names up to 10 times in this new
                                            30-day period.</p>
                                        </div>
                                      ),
                                    });
                                  }}
                                />
            </span>
                          </Col>
                          <Col xs={12} sm={12} md={14}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
          <span className="font-size-md font-weight-bold" style={{cursor: 'pointer'}}
                title="Click to copy Display Name">
            {Contacts?.name}
          </span>
                              <Button
                                type="link"
                                onClick={showModal}
                                icon={<EditOutlined/>}
                                style={{marginLeft: '8px', padding: 0}}
                              />
                              {Contacts?.display_name_status && (
                                <Tag
                                  color={getStatusBadge(Contacts.display_name_status)}> {Contacts.display_name_status}</Tag>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={5} style={{paddingTop: "4px"}}>
                        <ItemInfo name="Waba Id" value={Contacts?.waba_id} />
                        <ItemInfo name="Phone Number Id" value={Contacts?.phone_number_id} />
                        <ItemInfo name="Country" value={Contacts?.country_code} />
                      </Col>
                      <Col xs={24} sm={24} md={1} style={{paddingTop: "4px"}}/>
                      <Col xs={24} sm={24} md={5} style={{paddingTop: "4px"}}>
                        <ItemInfo name="Status" value={(Contacts?.status === true) ? "Active" : "Inactive"} />
                        <ItemInfo name="Connection Status" value={formatConnctionStatus(Contacts?.connection_status)}
                                  />
                        <ItemInfo name="Quality Rating" value={formatQualityRating(Contacts?.quality_rating)}
                                  />
                      </Col>
                      <Col xs={24} sm={24} md={7} style={{paddingTop: "4px"}}>
                        <ItemInfo name="Messaging Limit" value={formatMessageLimit(Contacts?.messaging_limit)}
                                  />
                        <ItemInfo name="Last Onboarded Time" value={Contacts?.last_onboarded_time} />

                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Badge.Ribbon>
      </div>
      <Modal
        title={<span>Edit Display Name <InfoCircleOutlined
          style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
          onClick={() => {
            Modal.info({
              title: "Display Name",
              content: (
                <div>
                  <p>Your businesses can change the display names up to 10 times in a 30-day period.</p>
                  <p>Once the 30-day period is up, a new 30-day period will begin and your businesses can again change
                    the display names up to 10 times in this new 30-day period.</p>
                </div>
              ),
            });
          }}
        />
      </span>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input value={newDisplayName} onChange={handleInputChange}/>
      </Modal>
      <WhatsappBusinessProfileForm businessProfile={BusinessProfile} numberId={id} ContactNumber={ContactNumber}
                                   fetchBusinessProfile={fetchBusinessProfile}/>
    </>
  );
};

export default OnboardedNumberDetails;
