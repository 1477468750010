import React, { useEffect, useState } from 'react';
import { Card, notification } from 'antd';
import { WhatsappTemplateDetails} from "models";
import {listWhatsappTemplateService, deleteWhatsappTemplateService} from 'services/dashboard/WhatsappService';
import WhatsappTemplateList from 'components/whatsapp/WhatsappTemplate/WhatsappTemplateList';
import WhatsappTemplateForm from "../../../components/whatsapp/WhatsappTemplate/WhatsappTemplateForm";

const WhatsappTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [whatsapptemplate, setWhatsappTemplateList] = useState<WhatsappTemplateDetails[]>([]);
  const [showWhatsappTemplateForm, setShowWhatsappTemplateForm] = useState(false);
  const [whatsappTemplateToEdit, setWhatsappTemplateToEdit] = useState<WhatsappTemplateDetails | undefined>();
  const fetchWhatsappTemplateList = async () => {
    setLoading(true);
    try {
      const response = await listWhatsappTemplateService();
      setWhatsappTemplateList(response);
      setLoading(false);
    } catch (error) {
      setWhatsappTemplateList([]);
      setLoading(false);
    }
  };

  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };
  const showSuccessNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };

  const openWhatsappTemplateForm = (_isEdit?: boolean, template?: WhatsappTemplateDetails) => {
    setWhatsappTemplateToEdit(template);
    setShowWhatsappTemplateForm(true);
  };
  const onCloseForm = async () => {
    setShowWhatsappTemplateForm(false);
    setWhatsappTemplateToEdit(undefined);
    await fetchWhatsappTemplateList();
  };

  const onSuccessDeleteWhatsappTemplate = (id: number) => {
    showSuccessNotification('Whatsapp Template deleted successfully');
    setLoading(false);
    const newWhatsappTemplate = whatsapptemplate.filter(c => c.id !== id);
    setWhatsappTemplateList(newWhatsappTemplate);
  };

  const onErrorDeleteWhatsappTemplate = (showNotification?: boolean) => {
    if (showNotification) {
      showErrorNotification('Failed to delete Whatsapp Template');
    }
    setLoading(false);
  };

  const deleteWhatsappTemplate = async (tempId: number) => {
    setLoading(true);
    try {
      const response = await deleteWhatsappTemplateService({ id: tempId });
      if (response.id === tempId) {
        onErrorDeleteWhatsappTemplate(true);
      } else {
        onSuccessDeleteWhatsappTemplate(tempId);
      }
    } catch (error) {
      onErrorDeleteWhatsappTemplate(true);
    }
  };

  const onClickDeleteWhatsappTemplate = async (id: number) => {
    await deleteWhatsappTemplate(id);
  };


  useEffect(() => {
    fetchWhatsappTemplateList();
  }, []);

  return (
    <Card className="mt-4">
      <WhatsappTemplateList
        loading={loading}
        whatsapptemplate={whatsapptemplate}
        openWhatsappTemplateForm={openWhatsappTemplateForm}
        onClickDeleteWhatsappTemplate={onClickDeleteWhatsappTemplate}
        onClickRefresh={fetchWhatsappTemplateList}
      />
      <WhatsappTemplateForm showWhatsappTemplateForm={showWhatsappTemplateForm} editItem={whatsappTemplateToEdit} onClose={onCloseForm} />
    </Card>
  );
};

export default WhatsappTemplate;
