import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const GettingStarted = lazy(() => import("./GettingStarted"));
const WorkspaceDetails = lazy(() => import("./WorkspaceDetails"));
const Settings = lazy(() => import("./Settings"));
const ApiReport = lazy(() => import("./ApiReport"));
const ApiLogs = lazy(() => import("./ApiLogs"));
const SlackRedirect = lazy(() => import("./SlackRedirect"));

const SlackPages = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/slack/getting-started" replace />} />
    <Route path='getting-started' element={<GettingStarted />} />
    <Route path='workspace-details' element={<WorkspaceDetails />} />
    <Route path='settings' element={<Settings />} />
    <Route path='api-report' element={<ApiReport />} />
    <Route path='api-logs' element={<ApiLogs />} />
    <Route path='slack-redirect' element={<SlackRedirect />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default SlackPages;
