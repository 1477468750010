import {Link} from "react-router-dom";
import React from "react";
import {ColumnsType} from 'antd/es/table';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,

} from "@ant-design/icons";
import TableList from "../../../shared/TableList";
import {Chatbot} from "../../../../models/chatbot";

export interface ChatbotListProps {
  chatbots: Chatbot[];
  loading?: boolean;
  openChatbotForm?: (isEdit?: boolean, item?: Chatbot) => void;
  onClickDeleteChatbot?: (chatbotId: string) => void;
}

const ChatbotListingTable = (props: ChatbotListProps) => {
  const {chatbots, loading, openChatbotForm, onClickDeleteChatbot} = props;

  const onConfirmDelete = (item: Chatbot) => {
    onClickDeleteChatbot?.(item.id);
  };
  const actions = [
    {
      toolTip: 'Edit',
      icon: <EditOutlined/>,
      onClick: (item: Chatbot) => {
        openChatbotForm?.(true, item);
      }
    },
    {
      toolTip: 'Delete',
      icon: <DeleteOutlined/>,
      popConfirm: {
        title: 'Are you sure you want to delete this chatbot?', onConfirm: onConfirmDelete
      },
    }
  ];

  const searchPredicate = (item: Chatbot, text: string) =>
    item.id.toString().toLowerCase().includes(text)
    || item.name.toLowerCase().includes(text);

  const controls = [
    {
      title: 'Add Chatbot',
      icon: <PlusOutlined/>,
      isIconOnly: true,
      onClick: () => {
        openChatbotForm?.();
      }
    }
  ];
  const tableColumns: ColumnsType<Chatbot> = [
    {
      title: 'Name',
      render: (text, record) => (
        <Link to={{pathname: `${record.name}/${record.id}`}}>{text}</Link>
      ),
      dataIndex: 'name',
    },
    {
      title: 'Phone Number',
      dataIndex: 'number',
    },
  ];

  return (
    <div className="table-responsive mb-4">
      <TableList<Chatbot> items={chatbots} columns={tableColumns} loading={loading}
                                      rowKey={(record: Chatbot) => record.id} actions={actions}
                                      hideRowSelection
                                      showSearch
                                      searchPredicate={searchPredicate}
                                      controls={controls}
      />
    </div>
  );

};

ChatbotListingTable.defaultProps = {
  loading: false,
  openChatbotForm: undefined,
  onClickDeleteChatbot: undefined
};

export default ChatbotListingTable;
