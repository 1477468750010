import {NumberLookupPriceRequest} from "../../models/requests";
import NumLookupApi from "./index";
import {
  NumLookupPricingResponse
} from "../../models/responses";

const getNumLookupPrice = async (data:NumberLookupPriceRequest) => NumLookupApi.post<number, NumberLookupPriceRequest>('pricing/price', data);

const getNumLookupUserPriceList = async () => NumLookupApi.get<NumLookupPricingResponse>('pricing');
export {
  getNumLookupPrice,
  getNumLookupUserPriceList
};
