import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Settings from "./Settings";
import WhatsappTemplate from "./WhatsappTemplate/index";
import ApiReport from "./ApiReport";
import WhatsappCampaign from "./WhatsappCampaign";
import WhatsappCampaignTab from "./WhatsappCampaignTab";


const GettingStarted = lazy(() => import("./GettingStarted"));
const WhatsappPricing = lazy (() => import("./Pricing"));
const WhatsappPages = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/whatsapp/getting-started" replace />} />
    <Route path='getting-started' element={<GettingStarted />} />
    <Route path='whatsapp-campaign' element={<WhatsappCampaignTab />}>
      <Route path=':id' element={<WhatsappCampaign />} />
    </Route>
    <Route path='whatsapp-template' element={<WhatsappTemplate />} />
    <Route path='api-report' element={<ApiReport />} />
    <Route path='pricing' element={<WhatsappPricing />} />
    <Route path='settings' element={<Settings />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default WhatsappPages;
