import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HubspotPreview from "../../components/integrations/IntegrationsPreview/HubspotPreview";
import ZohoPreview from "../../components/integrations/IntegrationsPreview/ZohoPreview";

const Integration = lazy(() => import("./IntegrationCards"));
const CrmIntegrationRedirect = lazy(() => import("./CrmIntegrationRedirect"));

const IntegrationPages = () => (
  <Routes>
    <Route path="/" element={<Integration />} />
    <Route path='crm-redirect' element={<CrmIntegrationRedirect />} />
    <Route path='hubspot' element={<HubspotPreview />} />
    <Route path='zoho' element={<ZohoPreview />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>
);

export default IntegrationPages;
