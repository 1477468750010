
import {
  HubspotCrmCallBackRequest
} from 'models/requests';
import {
  createCrmContact, deleteIntegration,
  getHubspotCrmCallback,
  getZohoCrmCallback, listHeaderFieldsIntegrations, listHubspotIntegrations,
  listInstalledIntegrations, listIntegrationsPlatform, listZohoIntegrations
} from "../../api/dashboard/IntegrationsApi";

const listInstalledIntegrationsService = async () => listInstalledIntegrations();
const listHubspotIntegrationsService = async () => listHubspotIntegrations();
const listIntegrationsPlatformService = async () => listIntegrationsPlatform();
const listHeaderFieldsIntegrationsService = async (integrationId: any, moduleType: any) => listHeaderFieldsIntegrations(integrationId, moduleType);
const listZohoIntegrationsService = async () => listZohoIntegrations();
const getHubspotCrmCallbackService = async (data: HubspotCrmCallBackRequest) => getHubspotCrmCallback(data);
const getZohoCrmCallbackService = async (data: HubspotCrmCallBackRequest) => getZohoCrmCallback(data);
const createCrmContactService = async (data: any) => createCrmContact(data);

const deleteIntegrationService = async (data: any) => deleteIntegration(data);

export {
  listInstalledIntegrationsService,
  listHubspotIntegrationsService,
  listIntegrationsPlatformService,
  listZohoIntegrationsService,
  getHubspotCrmCallbackService,
  getZohoCrmCallbackService,
  createCrmContactService,
  deleteIntegrationService,
  listHeaderFieldsIntegrationsService
};
