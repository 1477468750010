import AxiosService from "api";

const WhatsappMetaApi = {
  baseUrl: '/whatsapp/v2/',
  getUrl: (url: string) => `${WhatsappMetaApi.baseUrl}${url}`,
  post: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.post<R, D>(WhatsappMetaApi.getUrl(url), data, isPublic, headers),
  postWithQueryParams: <R = any, P = any, D = any>(url: string, queryData?: P, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.postWithQueryParams<R, P, D>(WhatsappMetaApi.getUrl(url), queryData, data, isPublic, headers),
  put: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.put<R, D>(WhatsappMetaApi.getUrl(url), data, isPublic, headers),
  get: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.get<R, D>(WhatsappMetaApi.getUrl(url), data, isPublic, headers),
  getWithSignal: <R = any, D = any>(url: string, data?: D, isPublic?: boolean, headers?: any, signal?: AbortSignal) => AxiosService.getWithSignal<R, D>(WhatsappMetaApi.getUrl(url), data, isPublic, headers, signal),
  getBlobWithProgress: async (url: string, onDownloadProgress: (progressEvent: ProgressEvent) => void, isPublic: boolean = false, headers: any = {}) => AxiosService.getBlobWithProgress(WhatsappMetaApi.getUrl(url), onDownloadProgress, isPublic, headers),
  delete: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.delete<R, D>(WhatsappMetaApi.getUrl(url), data, isPublic, headers),
};
export default WhatsappMetaApi;
