import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { fetchWhatsappMediaFileService } from 'services/dashboard/WhatsappService';
import WhatsappAudioAttachment from './WhatsappAudioAttachment';
import WhatsAppDocumentAttachment from './WhatsappDocumentAttachment';
import WhatsappImageAttachment from './WhatsappImageAttachment';
import WhatsappVideoAttachment from './WhatsappVideoAttachment';

const WhatsappAttachment = ({
  attachmentUrl,
  attachmentType: initialAttachmentType,
  attachmentFileName,
  attachmentCaption,
  attachmentMimeType,
  formatWhatsappText,
  attachmentId,
  isUploading,
  isNewAttachment,
}: {
  attachmentUrl: string;
  attachmentType: string;
  attachmentFileName?: string;
  attachmentCaption?: string;
  attachmentMimeType?: string;
  formatWhatsappText: any;
  attachmentId?: string | null;
  isUploading?: boolean;
  isNewAttachment?: boolean;
}) => {
  
  const attachmentType = initialAttachmentType?.toLowerCase();
  const [mediaSrc, setMediaSrc] = useState<string | null>(
    attachmentUrl?.startsWith("http") ? attachmentUrl : null
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const fetchMediaFile = async (mediaId: string) => {
    try {
      const blob = await fetchWhatsappMediaFileService(
        mediaId,
        (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setDownloadProgress(percentCompleted); // Update the progress state
        }
      );
      return blob;
    } catch (error) {
      throw new Error("Error fetching media file");
    }
  };

  const loadMedia = async (mediaId: string) => {
    try {
      const blob = await fetchMediaFile(mediaId);
      if (blob instanceof Blob) {
        const url = URL.createObjectURL(blob);
        setMediaSrc(url);
      } else {
        // console.log("Not a blob", blob);
        throw new Error("Response is not a Blob");
      }
    } catch (error) {
      // console.error("Error fetching media", error);
      notification.error({
        message: 'Media Download Failed',
        description: "Please try again.",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownload = async () => {
    if (attachmentUrl && !attachmentUrl?.startsWith("http") && !isDownloading) {
      setIsDownloading(true);
      loadMedia(attachmentUrl);
    }
  };

  useEffect(() => {
    if (isNewAttachment && attachmentUrl && !attachmentUrl?.startsWith("http") && attachmentType !== "document" && attachmentType !== "audio") {
      setIsDownloading(true);
      loadMedia(attachmentUrl);
    }
  }, [isNewAttachment]);

  return (
    <div className="attachment-msg">
      {/* if attachment type is image, show the image */}
      {attachmentType === "image" && (
        <div className="attachment-image">
          <WhatsappImageAttachment
            id={attachmentId}
            imageUrl={attachmentUrl}
            mediaSrc={mediaSrc}
            handleDownload={handleDownload}
            isDownloading={isDownloading}
            isUploading={isUploading}
            downloadProgress={downloadProgress}
          />
        </div>
      )}

      {/* if attachment type is video, show the video like whatsapp */}
      {attachmentType === "video" && (
        <div className="attachment-video">
          <WhatsappVideoAttachment
            id={attachmentId}
            videoUrl={attachmentUrl}
            mediaSrc={mediaSrc}
            handleDownload={handleDownload}
            isDownloading={isDownloading}
            downloadProgress={downloadProgress}
          />
        </div>
      )}
      {/* if attachment type is audio, show the audio like whatsapp */}
      {attachmentType === "audio" && (
        <div className="attachment-audio">
          <WhatsappAudioAttachment
            audioUrl={attachmentUrl}
            mediaSrc={mediaSrc}
            handleDownload={handleDownload}
            isDownloading={isDownloading}
            downloadProgress={downloadProgress}
          />
        </div>
      )}
      {/* if attachment type is document, show the document like whatsapp */}
      {attachmentType === "document" && (
        <div className="attachment-document">
          <WhatsAppDocumentAttachment
            fileName={attachmentFileName}
            downloadUrl={attachmentUrl}
            mediaSrc={mediaSrc}
            handleDownload={handleDownload}
            isDownloading={isDownloading}
            downloadProgress={downloadProgress}
            fileMimeType={attachmentMimeType}
          />
        </div>
      )}
      {/* if attachment type is sticker, show the sticker like whatsapp */}
      {attachmentType === "sticker" && (
        <div className="attachment-sticker">
          <WhatsappImageAttachment
            imageUrl={attachmentUrl}
            mediaSrc={mediaSrc}
            handleDownload={handleDownload}
            isDownloading={isDownloading}
            isUploading={isUploading || false}
            downloadProgress={downloadProgress}
          />
        </div>
      )}
      {/* if attachment type is not any of the above, show the attachment type */}
      {attachmentType !== "image" &&
        attachmentType !== "sticker" &&
        attachmentType !== "video" &&
        attachmentType !== "audio" &&
        attachmentType !== "document" && (
          <div className="attachment-placeholder pb-2">
            {capitalize(attachmentType)}
          </div>
        )}

      {attachmentCaption && (
        <div className="attachment-body">
          <div>
            {attachmentType !== "document"
              ? formatWhatsappText(attachmentCaption)
              : attachmentCaption}
          </div>
        </div>
      )}
       {(!attachmentCaption && attachmentType === "document") && (
        <div className="attachment-body">
          <div style={{ marginBottom: "8px" }} />
        </div>
      )}
    </div>
  );
};

WhatsappAttachment.defaultProps = {
  attachmentFileName: undefined,
  attachmentCaption: undefined,
  attachmentMimeType: undefined,
  attachmentId: undefined,
  isUploading: false,
  isNewAttachment: false,
};

export default WhatsappAttachment;