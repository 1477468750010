import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const ImportExports = lazy(() => import("."));

const ImportExportPages = () => (
  <Routes>
    <Route path="/" element={<ImportExports />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>
);

export default ImportExportPages;