
import cmap from "configs/CountryCodeShortMapping.json";
import { Buffer as buffer } from 'buffer';
import { notification } from "antd";
import { ApiErrorResponse } from 'models/responses';

const findCountryCode = (dialCode: string) => {
  const data = cmap.find((x) => dialCode.includes(x.dial_code) === true);
  return data?.code;
};
const findCountryDialCode = (code: string) => {
  const data = cmap.find((x) => code.toUpperCase() === x.code);
  return data?.dial_code;
};
const findCountry = (code: string) => {
  const data = cmap.find((x) => code.toUpperCase() === x.code);
  return data?.name ?? code;
};
const decode = (str: string): string => buffer.from(str, 'base64').toString('binary');

const showErrorNotification = (message: string, description?: string) => {
  const notificationParam = {
    message,
    description
  };
  notification.error(notificationParam);
};

const showSuccessNotification = (message: string, description?: string) => {
  const notificationParam = {
    message,
    description
  };
  notification.success(notificationParam);
};
const showApiErrorNotification = (response?: ApiErrorResponse) => {
  if (response) {
    const notificationParam = {
      message: response.message,
      description: response.description
    };
    notification.error(notificationParam);
  }
};
const handleApiError = (error: unknown) => {
  const errorResponse = error as ApiErrorResponse;
  showApiErrorNotification(errorResponse);
};

const timestampToTime = (timestamp: string): string => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const strHours = hours.toString().padStart(2, '0');
  return `${strHours}:${minutes} ${ampm}`;
};

const timestampToDate = (timestamp: string): string => {
  const date = new Date(timestamp);
  const now = new Date();

  const year = date.getFullYear();
  const currentYear = now.getFullYear();

  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();

  const oneDay = 24 * 60 * 60 * 1000;
  const yesterday = new Date(now.getTime() - oneDay);

  if (date.getFullYear() === yesterday.getFullYear() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getDate() === yesterday.getDate()) {
    return 'Yesterday';
  }

  const dayOfWeek = date.toLocaleDateString('default', { weekday: 'long' });

  const daysDifference = Math.floor((now.getTime() - date.getTime()) / oneDay);

  if (daysDifference < 7 && daysDifference >= 1) {
    return dayOfWeek;
  }

  if (year === currentYear) {
    return `${month} ${day}`;
  } 
    const monthNumber = (date.getMonth() + 1).toString().padStart(2, '0');
    const dayNumber = day.toString().padStart(2, '0');
    return `${monthNumber}/${dayNumber}/${year}`;
  
};

export {
  findCountryCode,
  findCountryDialCode,
  findCountry,
  decode,
  showErrorNotification,
  showSuccessNotification,
  showApiErrorNotification,
  handleApiError,
  timestampToTime,
  timestampToDate
};