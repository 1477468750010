import {
  TOGGLE_COLLAPSED_NAV,
  SIDE_NAV_STYLE_CHANGE,
  CHANGE_LOCALE,
  NAV_TYPE_CHANGE,
  TOP_NAV_COLOR_CHANGE,
  HEADER_NAV_COLOR_CHANGE,
  TOGGLE_MOBILE_NAV,
  SWITCH_THEME,
  DIRECTION_CHANGE
} from '../constants/Theme';

export const toggleCollapsedNav = (navCollapsed: any) => ({
  type: TOGGLE_COLLAPSED_NAV,
  navCollapsed
});

export const onNavStyleChange = (sideNavTheme: any) => ({
  type: SIDE_NAV_STYLE_CHANGE,
  sideNavTheme
});

export const onLocaleChange = (locale: any) => ({
  type: CHANGE_LOCALE,
  locale
});

export const onNavTypeChange = (navType: any) => ({
  type: NAV_TYPE_CHANGE,
  navType
});

export const onTopNavColorChange = (topNavColor: any) => ({
  type: TOP_NAV_COLOR_CHANGE,
  topNavColor
});

export const onHeaderNavColorChange = (headerNavColor: any) => ({
  type: HEADER_NAV_COLOR_CHANGE,
  headerNavColor
});

export const onMobileNavToggle = (mobileNav: any) => ({
  type: TOGGLE_MOBILE_NAV,
  mobileNav
});

export const onSwitchTheme = (currentTheme: any) => ({
  type: SWITCH_THEME,
  currentTheme
});

export const onDirectionChange = (direction: any) => ({
  type: DIRECTION_CHANGE,
  direction
});