import React, {useEffect, useState} from "react";
import { Button, Form, Input } from "antd";
import {analytics} from "utils/google_tag_manager_script";

export interface SetPasswordFormProps {
  onSubmit: (password: string) => void;
}
const SetPasswordForm = (props: SetPasswordFormProps) => {
  const { onSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();



  const onVerify = async (value: { newPassword: string, confirmPassword: string }) => {
    setLoading(true);
    onSubmit(value.newPassword);
    setLoading(false);
    form.resetFields();
  };

  useEffect(() => {
    analytics(window, document, 'script', 'dataLayer', 'GTM-N4P6V8W');
  },[]);

  return (
    <Form
      name="changePasswordForm"
      layout="vertical"
      form={form}
      onFinish={onVerify}
    >
      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[{
          required: true,
          message: 'Please enter password!',
        },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#=:?./|~><()^_+`\-,;{}\\[\]])[A-Za-z\d@$!%*?&#=:?./|~><()^_+`\-,;{}\\[\]]{8,}$/,
          message: `Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character`
        }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        dependencies={['newPassword']}
        hasFeedback
        rules={
          [
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator: (_, value) => {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]
        }
      >
        <Input.Password />
      </Form.Item>
      <div className="text-right mt-4">
        <Button type="primary" htmlType="submit" loading={loading}>
          Set password
        </Button>
      </div>
    </Form>);
};


export default SetPasswordForm;
