import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'antd';
import { WhatsappSettings } from "models";
import WhatsappSettingsPage from 'components/whatsapp-meta/WhatsappSettings/WhatsappSettingsUpdateForm';
import {getWhatsappSettingsService} from "services/dashboard/WhatsappService";
import PageHeader from 'components/layout/PageHeader';

const Settings = () => {
  const [whatsappSettings, setWhatsappSettings] = useState<WhatsappSettings>({call_back_url: "", webhook_secret: ""});
  const [loading, setLoading] = useState(false);


  const fetchWhatsappSettings = async () => {
    setLoading(true);
    try {
      const response = await getWhatsappSettingsService();
      if (response !== undefined) {
        setWhatsappSettings(response);
      }
    } catch (error) {
      // setSMSSettings(undefined);
    }
    setLoading(false);
  };


  useEffect(() => {
    Promise.all([fetchWhatsappSettings()]);
  }, []);

  const resetWhatsappSettings = (settings: WhatsappSettings) => {
    setWhatsappSettings(settings);
  };

  return (
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
          <PageHeader display title="page.whatsapp.settings" showBreadCrumb={false}/>
          <Card className="mt-4" loading={loading}>
            <WhatsappSettingsPage whatsappSettings={whatsappSettings} reset={resetWhatsappSettings} loading={loading}
                                  setLoading={setLoading}/>
          </Card>
        </Col>
      </Row>

  );
};

export default Settings;
