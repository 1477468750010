import React from 'react';

const Icon = (props: { type: any, className?: string }): JSX.Element => {
  const { type, className } = props;
  return (
    <>{React.createElement(type, { className })}</>
  );
};
Icon.defaultProps = {
  className: ''
};

export default Icon;