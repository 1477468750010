import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Applications = lazy(() => import("./Applications"));
// const SandBox = lazy(() => import("./SandBox"));


const DeveloperPages = () => (
  <Routes>
    {/* <Route path="/" element={<Navigate to="/developer/applications" replace />} /> */}
    {/* <Route path='/applications' element={<Applications />} /> */}
    {/* <Route path='/sandbox' element={<SandBox />} /> */}
    <Route path='/' element={<Applications />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default DeveloperPages;
