import {
  AutoRechargeCreateRequest,
  AutoRechargeUpdateRequest,
  CaptureRequest,
  CheckoutRequest
} from 'models/requests';

import {
  AutoRechargeCreateResponse,
  AutoRechargeDeleteResponse,
  AutoRechargeResponse,
  AutoRechargeUpdateResponse,
  CaptureResponse,
  CheckoutResponse,
  InvoiceDownloadResponse,
  InvoiceListResponse,
  SavedCardDeleteResponse,
  SavedCardResponse,
  SetupCheckoutResponse,
  UserWalletResponse
} from 'models/responses';


import DashboardApi from ".";

const checkoutPayment = async (data: CheckoutRequest) => DashboardApi.post<CheckoutResponse, CheckoutRequest>('payment/checkout', data);
const capturePayment = async (data: CaptureRequest) => DashboardApi.post<CaptureResponse, CaptureRequest>('payment/capture', data);
const setupCheckout = async () => DashboardApi.post<SetupCheckoutResponse>('payment/setup-checkout');
const getSavedCards = async () => DashboardApi.get<SavedCardResponse>('payment/saved-cards');
const getInvoices = async () => DashboardApi.get<InvoiceListResponse>('payment/invoices');
const downloadInvoice = async (invoiceId: string) => DashboardApi.get<InvoiceDownloadResponse>(`payment/invoices/${invoiceId}`);
const getAutoRecharge = async () => DashboardApi.get<AutoRechargeResponse>('payment/auto-recharge');
const addAutoRecharge = async (data: AutoRechargeCreateRequest) => DashboardApi.post<AutoRechargeCreateResponse, AutoRechargeCreateRequest>('payment/auto-recharge', data);
const updateAutoRecharge = async (id: string, data: AutoRechargeUpdateRequest) => DashboardApi.put<AutoRechargeUpdateResponse, AutoRechargeUpdateRequest>(`payment/auto-recharge/${id}`, data);
const deleteAutoRecharge = async (id: string) => DashboardApi.delete<AutoRechargeDeleteResponse>(`payment/auto-recharge/${id}`);
const getUserWallet = async () => DashboardApi.get<UserWalletResponse>('payment/user-wallet');

const deleteSavedCard = async (id: string) => DashboardApi.delete<SavedCardDeleteResponse>(`payment/delete-card/${id}`);
export {
  checkoutPayment,
  capturePayment,
  setupCheckout,
  getSavedCards,
  getInvoices,
  downloadInvoice,
  getAutoRecharge,
  addAutoRecharge,
  updateAutoRecharge,
  deleteAutoRecharge,
  getUserWallet,
  deleteSavedCard
};
