import React, { useEffect, useState } from "react";
import { Card, notification } from "antd";
import moment from 'moment';
import {NumberLookupApiReport} from "models";
import { useNavigate } from "react-router-dom";
import {
  getNumLookupApiFilterResultService, getNumLookupApiReportExportService,
  getNumLookupApiSearchResultService
} from "../../../services/dashboard/NumberLookupService";
import {NumLookupApiReportExportRequest, NumLookupApiReportFilterRequest} from "../../../models/requests";
import NumLookupApiReportList from "../../../components/numlookup/ApiReport";

const ApiReport = () => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<NumberLookupApiReport[]>([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: "",
    };
    notification.error(notificationParam);
  };

  const fetchAllApiReports = async (data?: NumLookupApiReportFilterRequest, page: number = 1, size: number = 10) => {
    // const  starDate = moment().subtract(1, 'month');
    // const endDate = moment();
    let response;
    try {
      setLoading(true);
      if (data) {
        response = await getNumLookupApiFilterResultService(data);
      }
      else {
        response = await getNumLookupApiFilterResultService({
          page,
          size,
          start_date: moment().subtract(1, 'month').format("YYYY-MM-DD"),
          end_date: moment().endOf('month').format("YYYY-MM-DD")
        });
      }
      setReports(response.items);
      setTotal(response.total);
    } catch (error: any) {
      showErrorNotification(error.message);
      setReports([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const fetchBySearch = async (key: string,data: NumLookupApiReportFilterRequest) => {

    try {
      const response = await getNumLookupApiSearchResultService(key,data);

      if (response.items) {
        setReports(response.items);
        setTotal(response.total);
      }
    } catch (error: any) {
      showErrorNotification(error.message);
      setReports([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const fetchByFilter = async (data: NumLookupApiReportFilterRequest) => {
    try {
      setLoading(true);
      const response = await getNumLookupApiFilterResultService(data);
      if (response.items) {
        setReports(response.items);
        setTotal(response.total);
      }
    } catch (error: any) {
      showErrorNotification(error.message);
      setReports([]);
      setTotal(0);
    }
    setLoading(false);
  };



  const onPaginate = async (
    page: number,
    newSize: number,
    data: NumLookupApiReportFilterRequest,

    searchText?: string,
  ) => {

    setLoading(true);

    const paginatedData = {
      start_date: data.start_date,
      end_date: data.end_date,
      page,
      size: newSize,
      ...(data.lookup_status && {lookup_status: data.lookup_status}),
      ...(data.country && {country: data.country}),
    };
    if (searchText) {
      fetchBySearch(searchText,paginatedData);
    } else {
      fetchAllApiReports(paginatedData, page, newSize);
    }
  };


  const createExportFile = async (data: NumLookupApiReportExportRequest) => {
    try {
      const response = await getNumLookupApiReportExportService(data);
      if (response) {
        navigate('/importexport');
      }
    } catch (error: any) {
      showErrorNotification(error.message);
    }
    setLoading(false);
  };

  const onExportClick=async (data: NumLookupApiReportExportRequest)=>{
    createExportFile(data);
  };


  useEffect(() => {
    fetchAllApiReports();
  }, []);

  return (
    <Card className="mt-4">
      <NumLookupApiReportList
        reports={reports}
        loading={loading}
        total={total}
        onPaginate={onPaginate}
        fetchByFilter={fetchByFilter}
        onExportLog={onExportClick}
      />
    </Card>
  );
};

export default ApiReport;
