import { Col, Row, Typography } from 'antd';
import React from 'react';
import Avatar from 'react-avatar';

const { Title, Text } = Typography;

interface ContactMessageProps {
  contact: {
    contacts: {
      name: {
        last_name: string;
        first_name: string;
        formatted_name: string;
      };
      phones: {
        type: string;
        phone: string;
        wa_id: string;
      }[];
    }[];
  };
}

const WhatsappContactMessage: React.FC<ContactMessageProps> = ({ contact }) => {
  const contactInfo = contact?.contacts?.[0];
  const phoneInfo = contactInfo?.phones?.[0];

  return (
    <div style={{ width:"270px", borderRadius: '4px', background: '#f0f0f0', padding: "10px", display: "flex"}}>
      <Row gutter={16} align="middle">
        <Col>
          <Avatar name={contactInfo?.name?.formatted_name} size="45" textSizeRatio={1.75} round/>
        </Col>
        <Col style={{flex:1}}>
          <Title level={4} style={{marginBottom:"0px"}}>{contactInfo?.name?.formatted_name ?? "Unavailable"}</Title>
          <Text type="secondary" style={{marginBottom:"0px", fontSize:"0.8rem"}}>{phoneInfo?.phone ?? "Unavailable"}</Text>
        </Col>
      </Row>
      
    </div>
  );
};

export default WhatsappContactMessage;
