import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const GettingStarted = lazy(() => import("./GettingStarted"));
const Settings = lazy(() => import("./Settings"));
const Report = lazy(() => import("./Report"));
const ApiLogs = lazy(() => import("./ApiLogs"));

const VerifyPages = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/verify/getting-started" replace />} />
    <Route path='getting-started' element={<GettingStarted />} />
    <Route path='settings' element={<Settings />} />
    <Route path='report' element={<Report />} />
    <Route path='api-logs' element={<ApiLogs />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default VerifyPages;