import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { WhatsappCampaignListngModel} from "models";
import { useNavigate } from "react-router-dom";
import {
  getAllWhatsappCampaignsService, getWhatsappCampaignMsgLogService,
  whatsappCampaignReportSearchService
} from "../../../services/dashboard/WhatsappCampaignService";
import WhatsappCampaignReportList from "../../../components/whatsapp/WhatsappCampaignReport";

const WhatsappCampaignReport = () => {
  const [list, setCampaigns] = useState<WhatsappCampaignListngModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };

  const fetchAllWhatsappCampaign = async (page: number = 1, size: number = 10) => {
    try {
      setLoading(true);
      const response = await getAllWhatsappCampaignsService({ page, size });
      setCampaigns(response.items);
      setTotal(response.total);
    } catch (error: any) {
      showErrorNotification(error.message);
      setCampaigns([]);
    }
    setLoading(false);
  };


  const fetchBySearch = async (key: string) => {
    try {
      const response = await whatsappCampaignReportSearchService(key);
      if (response.items) {
        setCampaigns(response.items);
        setTotal(response.total);
      }
    } catch (error: any) {
      showErrorNotification(error.message);
      setCampaigns([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const onPaginate = async (page: number, size: number, searchText?: string) => {
    setLoading(true);
    if (searchText) {
      fetchBySearch(searchText);
    } else {
      fetchAllWhatsappCampaign(page, size);
    }
  };
  const createExportFile = async (id: string) => {
    try {
      const response = await getWhatsappCampaignMsgLogService(id);
      if (response) {
        navigate('/importexport');
      }
    } catch (error: any) {
      showErrorNotification(error.message);
    }
    setLoading(false);
  };

  const onExportClick=async (id:string)=>{
    createExportFile(id);
    };

  useEffect(() => {
    fetchAllWhatsappCampaign();
  }, []);


  return (
      <WhatsappCampaignReportList whatsappCampaigns={list} loading={loading} onError={showErrorNotification} total={total} onPaginate={onPaginate} onExportLog={onExportClick} onRefreshLog={fetchAllWhatsappCampaign}/>
  );
};

export default WhatsappCampaignReport;

