import React from "react";
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import { RootState } from 'App';
import MenuContent from './MenuContent';

const { Sider } = Layout;

export const SideNav = (props: { routeInfo?: any, hideGroupTitle?: boolean, localization?: boolean }) => {
  const { routeInfo, hideGroupTitle, localization } = props;
  const { navCollapsed, sideNavTheme } = useSelector((state: RootState) => state.theme);
  return (
    <Sider
      className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''}`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
        <MenuContent
          type={NAV_TYPE_SIDE}
          routeInfo={routeInfo}
          hideGroupTitle={hideGroupTitle}
          localization={localization}
        />
      </Scrollbars>
    </Sider>
  );
};
SideNav.defaultProps = {
  routeInfo: undefined,
  hideGroupTitle: false,
  localization: false
};
export default SideNav;
