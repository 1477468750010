import React, { useEffect, useState } from 'react';
import {Card, notification, Typography} from 'antd';
import {NumLookupPricing} from "models";
import { InfoCircleTwoTone } from '@ant-design/icons';
import {getNumberLookupUserPriceListService} from "../../../services/dashboard/NumberLookupService";
import NumLookupPricingList from "../../../components/numlookup/Pricing";

const { Text } = Typography;
const Pricing = () => {
  const [loading, setLoading] = useState(false);
  const [pricing, setPricing] = useState<NumLookupPricing[]>([]);
  const [initialPricing, setInitialPricing] = useState<NumLookupPricing[]>([]);


  const showErrorNotification = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };

  const fetchAllUserPricing = async () => {
    try {
      setLoading(true);
      const response = await getNumberLookupUserPriceListService();
      setPricing(response);
      setInitialPricing(response);
    } catch (error: any) {
      showErrorNotification("Failed to fetch pricing!");
      setPricing([]);
      setInitialPricing([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllUserPricing();
  }, []);

  const onSearch = async (searchText?: string) => {
    setLoading(true);
    if (searchText) {
      const filteredData = initialPricing.filter(entry =>
        entry.country.includes(searchText)
      );
      setPricing(filteredData);
      setLoading(false);
    } else {
      setPricing(initialPricing);
      setLoading(false);
    }
  };

  return (
    <Card className="mt-4" loading={loading}>
      {(pricing?.length===0) ?
        <><InfoCircleTwoTone className='mr-2 h3' /><Text className='font-size-md text-left'>No Pricing Available. <a href="https://d7networks.com/contact/">Contact Us!</a></Text></>:
        <NumLookupPricingList pricing={pricing} loading={loading} onSearch={onSearch} />
      }
    </Card>
  );
};

export default Pricing;

