import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorPageLayout from "layouts/error";

const E403 = lazy(() => import("./403"));
const E404 = lazy(() => import("./404"));
const E500 = lazy(() => import("./500"));

const ErrorPages = () => (
	<Routes>
		<Route element={<ErrorPageLayout />}>
			<Route path="/403" element={<E403 />} />
			<Route path="/404" element={<E404 />} />
			<Route path="/500" element={<E500 />} />
			<Route path="*" element={<Navigate to="/error/404" replace />} />
		</Route>
	</Routes>

);

export default ErrorPages;
