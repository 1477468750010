import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

import { THEME_CONFIG } from "configs/AppConfig";
import store from "redux/store";
import Pages from "./pages";
import "country-flag-icons/3x2/flags.css";

const themes = {
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const App = () => (
  <div className="App">
    <Provider store={store}>
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint="styles-insertion-point"
      >
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Pages />} />
            </Routes>
          </BrowserRouter>
      </ThemeSwitcherProvider>
    </Provider>
  </div>
);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default App;
