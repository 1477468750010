import React, {useEffect} from 'react';
import { RootState } from 'App';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import WelcomeSideBanner from 'components/layout/WelcomeSideBanner';
import {analytics} from "../../../utils/google_tag_manager_script";

const VerfiyEmailPage = () => {
  const theme = useSelector((state: RootState) => state.theme.currentTheme);

    useEffect(() => {
      analytics(window, document, 'script', 'dataLayer', 'GTM-N4P6V8W');
    },[]);

  return (
    <div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center" className='text-center'>
              <Col xs={24} sm={24} md={20} lg={15} xl={15}>
                <h1 className="font-weight-bold mb-4 display-4">Verify Your E-mail Address</h1>
                <div className="mt-4">
                  <p className='text-center'>We have an email to you for verification. Follow the link provided to finalize the signup process.
                  <br />Please contact us if you do not receive it within a few minute.</p>

                  <p className='text-center'>Already Verified? <a href="/signin" >Sign In</a></p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={0} sm={0} md={0} lg={8}>
          <WelcomeSideBanner />
        </Col>
      </Row>
    </div>);
}
  ;

export default VerfiyEmailPage;
