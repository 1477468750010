import { ClaimFreeCreditResponse } from 'models/responses';
import DashboardApi from ".";

const claimFreeCredit = async () => DashboardApi.get<ClaimFreeCreditResponse>('credit/claim-free-credit');
const testApi2 = async () => 'testApi2';

export {
  claimFreeCredit,
  testApi2
};
